import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        fontSize: "12px",
        fontWeight: 600,
        height: theme.spacing(6),
        backgroundColor: "#FCC46B",
        borderRadius: "7px",
        color: "#221A16",
        "&:focus": {
            backgroundColor: "#ffb237",
        },
    },
    deleteIcon: {
        width: "16px",
        boxShadow: "none",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: theme.spacing(1),
        marginRight: theme.spacing(6),
    },
    form: {
        background: "#FFF",
        width: "100%",
        overflowX: "hidden",
        padding: "28px 0",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            "& .MuiSelect-icon": {
                right: "12px",
                top: "calc(50% - 8px)",
            },
        },
        "& .MuiSelect-root": {
            color: "#000",
            fontWeight: "normal",
            height: "18px !important",
        },
        "& .MuiTextField-root > .MuiOutlinedInput-root": {
            padding: "8px 14px 18px 14px",
        },
        "& textarea": {
            fontSize: "13px",
            lineHeight: "15px",
        },
        "& button": {
            boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important",
            "&:hover, &:active": {
                boxShadow: "none !important",
            },
        },
    },
    selectField: {
        "& .MuiChip-root": {
            marginTop: "8px",
            "&  svg": {
                fill: "#221A16",
            },
        },
    },
    inputContainer: {
        "& .MuiGrid-container": {
            margin: "unset",
            marginBottom: "25px",
            color: "#000",
        },
    },
    labelText: {
        color: "#2F2824",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        marginBottom: "10px",
    },
    inputField: {
        "& .MuiInputBase-input": {
            height: "unset",
        },
        "& .MuiGrid-container": {
            justifyContent: "space-between",
            color: "#000",
        },
        "& .MuiOutlinedInput-root, .MuiInputBase-root": {
            height: "40px",
        },
        "& .MuiGrid-grid-sm-6": {
            maxWidth: "48%",
            flexBasis: "48%",
            marginBottom: "25px",
        },
    },
    uploadCv: {
        maxWidth: 158,
        [theme.breakpoints.between(0, 506)]: {
            marginTop: "20px",
        },
        "& .MuiButton-root": {
            height: "40px",
            borderRadius: 7,
            backgroundColor: "#363840",
        },
        "& .MuiButton-label": {
            fontSize: "15px",
            fontWeight: 600,
            color: "#221A16",
            textTransform: "none",
            "&:hover": {
                color: "#221A16",
            },
        },
        "& .MuiChip-labelSmall": {
            width: "150px",
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 7,
        boxShadow: "unset",
        fontSize: "15px",
        fontWeight: 600,
        height: "40px",
        color: "#221A16",
        backgroundColor: "#FCC46B",
        textTransform: "capitalize",
        "&:hover, &:active": {
            backgroundColor: "#ffb237",
            boxShadow: "none",
        },
    },
    divider: {
        width: "100%",
        background: "#E7E8EC",
        height: "1px",
        margin: "20px 0",
    },
    checkBoxes: {
        "& .Mui-checked": {
            "& svg": {
                fill: "#221A16",
                background: "#FFF",
            },
        },
        "& .MuiCheckbox-root": {
            "&  svg": {
                fill: "#C0C0C0",
                width: "1.250em",
                height: "1.250em",
            },
        },
        " & .MuiTypography-root": {
            color: "#000",
            fontSize: "13px",
            fontWeight: "normal",
        },
    },
    input: {
        marginBottom: theme.spacing(1),
    },
    compassLogo: { width: 288 },
    imageUpload: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-contained": {
            "&:hover, &:active": {
                backgroundColor: "#ffb237 !important",
            },
        },
    },
    avatarButton: {
        [theme.breakpoints.between(0, 506)]: {
            alignItems: "start",
            "& button": {
                display: "none"
            }
        },
        "& .MuiGrid-grid-sm-4": {
            maxWidth: "35%",
            flexBasis: "35%",
            [theme.breakpoints.between(0, 506)]: {
                marginBottom: "20px",
            },
        },
        "& .MuiAvatar-root": {
            height: "98px",
            width: "98px",
            [theme.breakpoints.between(0, 506)]: {
                height: "120px",
                width: "120px",
            },
            background: "#DBEBEB",
        },
        "& .MuiGrid-grid-sm-8": {
            flexBasis: "unset",
        },
        "& .MuiGrid-container": {
            "& .MuiButton-root": {
                height: "40px",
                width: "150px",
                fontSize: "15px",
                fontWeight: 600,
                color: "#FFF",
                [theme.breakpoints.between(0, 506)]: {
                    // display: "none"
                },
                "&:hover, &:active": {
                    // backgroundColor: "#ffb237 !important",
                    // boxShadow: "none",
                },
            },
        },
    },
    icon: { fontSize: "4em" },
    uploadCVButton: {
        height: theme.spacing(9.2),
        width: 158,
        color: "#221A16 !important",
        backgroundColor: "#FCC46B !important",
        boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        "&:hover": {
            backgroundColor: "#ffb237 !important",
            boxShadow: "none",
        },
    },
    inputRoot: {
        fontSize: theme.spacing(3),
    },
    outlineInput: {
        padding: theme.spacing(3),
    },
    errorBorder: {
        border: "1px solid  rgb(229, 115, 115) !important",
        "&:focus": {
            border: "1px solid  rgb(229, 115, 115) !important",
            transition: theme.transitions.create(["border"]),
        },
    },
    formLabelRoot: {
        fontWeight: 700,
        fontSize: theme.spacing(2),
    },
    peepAvatar: {
        borderRadius: "50% !important",
        backgroundColor: "#F7CD61 !important",
    },
    commentTextArea: {
        border: "none",
        width: "100%",
        display: "block",
        resize: "none",
        overflow: "hidden",

        color: "#483E3E",
        fontSize: 13,
        fontWeight: 400,
        marginLeft: "10px",
        "&::placeholder": {
            fontSize: 13,
            fontWeight: 400,
        },
        "&:focus": {
            outline: "none",
        },
    },
    commentButton: {
        width: 98,
        height: 30,
        textTransform: "none",
        fontWeight: 600,
        fontSize: 13,
        padding: "0px 4px",
        margin: "13px 10px 13px",
        alignSelf: "flex-end",
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none",
        },
    },
    confirmationTitle: {
        paddingBottom: 0,
        marginBottom: 10,
    },
    confirmationContent: {
        padding: "0px 24px",
        marginBottom: 15,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    confirmationActions: {
        padding: "0 8px",
    },
    contentEditable: {
        fontSize: 14,
        fontWeight: 500,
        minHeight: 72,
        border: "1px solid #AEAEB2",
        borderRadius: 8,
        padding: 10,
        fontFamily: "Montserrat",
        lineHeight: "18px",
        color: "#2F2824",
        "&:focus": {
            border: "1px solid #363840",
            borderRadius: 8,
            transition: theme.transitions.create(["border"]),
        },
        "&:focus-visible": {
            outlineWidth: 0,
        },
        "&:hover": {
            border: "1px solid #363840",
            borderRadius: 8,
            transition: theme.transitions.create(["border"]),
        },
    },
}));

export default useStyles;
