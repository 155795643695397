import React, { useRef, useState, useCallback, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import {
  IAlgoliaCandidate,
  SubmitButton,
  ErrorMsg,
  SuccessMsg,
} from "compass-shared";
import { ICandidateNote } from "../../CreateCandidate/interface";
import { firestore } from "../../../../firebase";
import { selectStyles } from "./utils";
import { AddJobSchema } from "../../../../Utils/FormValidationSchema";
import { withStyles } from '@material-ui/core/styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { functions } from "../../../../firebase";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as _ from "lodash";

import useStyles from "../style";

const initialJobOptions: any = [];
const initialState = {
  skills_nice_to_have: [],
  skills_must_have: [],
};

const BlackCheckBox = withStyles({
	root: {
	  '&$checked': {
		color: "black",
	  },
	},
	checked: {},
  })((props: CheckboxProps) => <Checkbox {...props} />);

const ToCompassModal = ({
  open,
  close,
  candidate,
}: {
  open: boolean;
  close: () => void;
  candidate: IAlgoliaCandidate & ICandidateNote;
}) => {
  const classes = useStyles({});
  const jobsRef: any = useRef();
  const [availableJob, setAvailableJob]: any = useState(null);
  const [jobOptionsList, setJobOptionsList]: any = useState(initialJobOptions);
  const [state, setState] = useState(initialState);
  const [loadingJobs, setLoadingJobs]: any = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors, setValue, triggerValidation } =
    useForm({
      validationSchema: AddJobSchema,
    });

  const customStyles = {
    ...selectStyles,
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      border: styles.isFocused
        ? 0
        : errors.skills
        ? "1px solid  rgb(229, 115, 115)"
        : "1px solid #AEAEB2",
      padding: "2px 0px",
      borderRadius: 8,
      boxShadow: styles.isFocused ? 0 : 0,
      minHeight: 47,
      "&:hover": {
        border: styles.isFocused
          ? 0
          : errors.skills
          ? "1px solid  rgb(229, 115, 115)"
          : "1px solid #363840",
      },
    }),
  };

  const customJobStyles = {
    ...selectStyles,
    ...customStyles,
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      border: styles.isFocused
        ? 0
        : errors.jobs
        ? "1px solid  rgb(229, 115, 115)"
        : "1px solid #AEAEB2",
      padding: "2px 0px",
      borderRadius: 8,
      boxShadow: styles.isFocused ? 0 : 0,
      minHeight: 47,
      "&:hover": {
        border: styles.isFocused
          ? 0
          : errors.jobs
          ? "1px solid  rgb(229, 115, 115)"
          : "1px solid #363840",
      },
    }),
  };

  const onKeyPress = (e: any) => {
    if (e && e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const noOptionsMessage = () => "No auto-suggestions";

  const create_skill_array = (object: any) => {
    if (!object) {
      return [];
    }
    return Object.keys(object)?.map((field) => {
      return { name: object[field], selected: false };
    });
  };

  const handleFetchV2Jobs = useCallback(async () => {
    // let payload: any = {
    //   job_status: "active",
    //   // job_function: "all",
    //   company_id: currUser?.company_id,
    // };
    setLoadingJobs(true);
    const v2_JobRef = await firestore
      .collection("v2_jobs")
      .where("status", "==", "ACTIVE")
      .get();
    if (!v2_JobRef.empty) {
      let v2_jobs: { [key: string]: string }[] = [];
      await Promise.all(
        v2_JobRef.docs.map((el) => {
          const job_data = el.data();
          if (job_data.company_name) {
            v2_jobs.push({
              ...job_data,
              job_id: el.id,
            });
          }
        })
      );
      if (v2_jobs?.length) {
        let options: any = [];
        v2_jobs.map((el: any) => {
          options.push({
            value: el.job_function,
            label: `${
              el.company_name
                ? el.company_name + " >> " + el.job_title
                : el.job_title
            }`,
            id: el.job_id,
            job_title: el.job_title,
            company_id: el.company_id,
            skills_must_have: create_skill_array(el.skills_must_have),
            skills_nice_to_have: create_skill_array(el.skills_nice_to_have),
          });
        });
        const list = [...jobOptionsList, ...options];
        setJobOptionsList(_.uniqBy(list, "id"));
      }
    }
    setLoadingJobs(false);
  }, []);

  const handleSelectJob = (newValue: any) => {
    console.log("new value is", newValue);
    if (newValue) {
      setState((st: any) => ({
        ...st,
        skills_must_have: newValue.skills_must_have,
        skills_nice_to_have: newValue.skills_nice_to_have,
      }));

      const option = {
        ...newValue,
        label: `${newValue.label.split(" >> ")[0]} ${
          newValue.label.split(" >> ")[1]
            ? ` - ${newValue.label.split(" >> ")[1]}`
            : ""
        }`,
      };
      setValue("jobs", option);
      setAvailableJob(option);
    }

    triggerValidation("jobs");
  };

  const onCreate = async (formData: any) => {
    setSubmitting(true);
    const candidate_id = firestore.collection("candidates").doc().id;
    formData = {
      skills_must_have: state.skills_must_have,
      skills_nice_to_have: state.skills_nice_to_have,
    };
    formData.job_id = availableJob.id;
    formData.source = "compass";
    formData.firstName = candidate.firstName;
    formData.lastName = candidate.lastName;
    formData.email = candidate.email;
    formData.location = candidate.location;
    formData.connected_links = candidate.connected_links;
    formData.currency = candidate.currency;
    formData.experience_level = candidate.experience_level;
    formData.candidate_experiences = candidate.experiences;
    formData.max_salary_per_year = candidate.max_salary_per_year;
    formData.min_salary_per_year = candidate.min_salary_per_year;
    formData.thumbnail_avatar_url = candidate.thumbnail_avatar_url;
    formData.original_avatar_url = candidate.original_avatar_url;
    formData.educations = candidate.educations;
    formData.creation_type = candidate.creation_type;
    formData.job_function = candidate.job_function;
    formData.skills = candidate.skills;
    formData.reasonToBeRelevant = "";
    formData.reasonForNotRelevance = "";
    formData.candidate_avatar_url = candidate.original_avatar_url;
    const candidateData = {
      ...formData,
      candidate_id,
    };
    const addCandidate: any = await functions.httpsCallable(
      "extension-compassScrap"
    )({ ...candidateData });
    if (addCandidate.data.success == true) {
      close();
      setAvailableJob(null);
      setState(initialState);
      toast.success(<SuccessMsg text={addCandidate.data.message} />);
    } else {
      toast.error(<ErrorMsg text={addCandidate.data.message} />);
    }
    console.log("addCandidate response", addCandidate);
    setSubmitting(false);
    // console.log(candidateData);
  };

  const closeModal = () => {
    close();
    setAvailableJob(null);
    setState(initialState);
  };

  useEffect(() => {
    handleFetchV2Jobs();
  }, [handleFetchV2Jobs]);

  useEffect(() => {
    register("jobs");
    setValue("jobs", availableJob);
  }, []);

  const submitMarginTop = state?.skills_nice_to_have?.length == 0 && state?.skills_must_have?.length == 0 ? "12px" : "0";

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="request-intro-dialog"
      classes={{
        paper: classes.dialogPaperRoot,
        container: classes.dialogContainer,
      }}
    >
      <DialogTitle
        disableTypography
        id="request-intro-dialog"
        classes={{
          root: `${classes.dialogTitleRoot} ${classes.directContactTitle}`,
        }}
      >
        <Typography
          component="div"
          variant="h6"
          classes={{
            root: `${classes.titleText} ${classes.directContactTitleText}`,
          }}
        >
          Add to Connect Job
        </Typography>
        <Button
          variant="contained"
          disableElevation
          className={classes.itemButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onCreate)} autoComplete="off">
          <Grid item xs={12}>
            <Grid style={{ position: "relative" }} item xs={12}>
              <Typography style={{paddingBottom: "8px"}}>
                Choose a Connect job to which this candidate will be added:
              </Typography>
              <div onKeyPress={onKeyPress}>
                <Select
                  closeMenuOnSelect={true}
                  options={jobOptionsList}
                  value={availableJob}
                  noOptionsMessage={
                    loadingJobs ? () => "Loading..." : noOptionsMessage
                  }
                  placeholder="Add candidate to a job"
                  onChange={handleSelectJob}
                  ref={jobsRef}
                  styles={customJobStyles}
                />
              </div>
              {/* { !availableJobs.length && <span style={{ position: 'absolute', left: '49%', bottom: '26%', color: '#FF0000'}}> * </span> } */}
            </Grid>

            {errors.jobs && <Box color="error.main">{errors.jobs.message}</Box>}
          </Grid>
          {state?.skills_must_have?.length > 0 && (
            <Grid
              style={{
                padding: "0 28px",
                paddingTop: "14px",
                position: "relative",
              }}
              item
              sm={6}
              xs={12}
            >
              Must-have:
              {state?.skills_must_have?.map((s: any, i: number) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <BlackCheckBox
                        onChange={(e) => {
                          const skills = state.skills_must_have as any;
                          const skills_must_have = skills.map((skill: any) => {
                            if (skill.name === s.name) {
                              return { ...skill, selected: e.target.checked };
                            }
                            return skill;
                          });
                          setState((st: any) => ({
                            ...st,
                            skills_must_have,
                          }));
                          // updateCandidateData( candidatesData, { skills_must_have });
                        }}
                        value={s}
                        checked={s.selected}
                      />
                    }
                    label={s.name}
                  />
                </div>
              ))}
            </Grid>
          )}

          {state?.skills_nice_to_have?.length > 0 && (
            <Grid
              style={{
                padding: "0 28px",
                paddingTop: "14px",
                position: "relative",
              }}
              item
              sm={6}
              xs={12}
            >
              Nice-to-have:
              {state?.skills_nice_to_have?.map((s: any, i: number) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <BlackCheckBox
                        onChange={(e) => {
                          const skills = state?.skills_nice_to_have as any;
                          const skills_nice_to_have = skills.map(
                            (skill: any) => {
                              if (skill.name === s.name) {
                                return { ...skill, selected: e.target.checked };
                              }
                              return skill;
                            }
                          );
                          setState((st: any) => ({
                            ...st,
                            skills_nice_to_have,
                          }));
                          // updateCandidateData( candidatesData, { skills_nice_to_have });
                        }}
                        value={s}
                        checked={s.selected}
                      />
                    }
                    label={s.name}
                  />
                </div>
              ))}
            </Grid>
          )}
          <Grid item xs={12} md={12}  style={{marginTop: submitMarginTop }}>
            <SubmitButton
              disabled={isSubmitting}
              loading={isSubmitting}
              className={classes.submit}
              text="Submit"
            />
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ToCompassModal;
