import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    contentRoot: {
        padding: "0px",
    },
    dialogPaperRoot: {
        borderRadius: 8,
        maxWidth: 400
    },
    dialogTitleRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 12,
        backgroundColor: "#221A16"
    },
    dialogTitle: {
        fontSize: 15,
        fontWeight: 600,
        color: "#FFFFFF",
    },
    dialogContentItem: {
        marginTop: "10px",
        marginBottom: "6px"
    },
    jobOpeningTitleText: {
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "21px"
    },
    submitButton: {
        height: 40,
        fontSize: 15,
        fontWeight: 600,
        color: "#221A16",
        backgroundColor: "#FCC46B",
        "&:hover": {
            backgroundColor: "#ffb237",
            boxShadow: "none"
        }
    },
    dialogActions: {
        marginTop: "10px",
        borderTop: "1px solid #E7E8EC",
        padding: "20px 24px 25px"
    },
    skillChip: {
        height: 25,
        margin: "0px 4px 4px 0px",
        backgroundColor: "#A7C7D8",
        fontSize: "12px",
        fontWeight: 600,
        color: "#245978",
        "&:focus": {
            backgroundColor: "#A7C7D880",
        },
    },
    itemButton: {
        minWidth: 22,
        height: 22,
        backgroundColor: "#FF6A66",
        color: "#FFFFFF",
        textTransform: "none",
        padding: 0,
        "&:hover": {
            backgroundColor: "#ff5252",
            boxShadow: "none"
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
        flexDirection: "column",
    },
    loadingText: {
        fontSize: 10,
        fontWeight: "bold",
        marginTop: 4
    }
}));

export default useStyles;
