import useStyles from "./style";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "@reach/router";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import React, { FC } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import CustomToolTip from "../../SharedComponents/ToolTip";

interface IProps {
    selected?: string;
    Icon: any;
    path: string;
    disabled?: boolean;
    isSideBarOpen: boolean;
    styles?: object;
    title: string;
    handleDrawersToggle?: () => void
}

const NavItem: FC<IProps> = ({
    selected, Icon, path, disabled, styles, title, isSideBarOpen,
    handleDrawersToggle
}) => {
    const classes = useStyles({ isSideBarOpen });

    const navItems = () => (
        <Link className={classes.openedSidebarLink} to={path}>
            <ListItem
              button
              disableGutters
              disabled={disabled}
              style={{
                  padding: 0,
                  marginBottom: 8,
                  borderRadius: 4,
                  ...styles
              }}
              className={(selected === path) ? classes.NavItem : ""}
            >
                <Link to={path}>
                    <ListItemIcon classes={{ root: classes.IconWidth }}>
                        <Icon style={{ fontSize: 28 }} className={classes.navItemIcon} color="primary" />
                    </ListItemIcon>
                </Link>
                <Link onClick={handleDrawersToggle} className={classes.openedSidebarLink} to={path}>
                    <ListItemText className={classes.sidebarText} primary={title} />
                </Link>
            </ListItem>
        </Link>
    );

    return (
        <>
            {isSideBarOpen
                ? navItems()
                : (
                    <CustomToolTip
                      title={title}
                      arrow
                      placement="right"
                      className={classes.reactTooltip}
                    >
                        <div>
                            {navItems()}
                        </div>
                    </CustomToolTip>
                )}
        </>
    );
};

export default NavItem;
