import React, {
    FC, MouseEventHandler, useState, useEffect
} from "react";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, RouteComponentProps } from "@reach/router";
import {
    Box,
    Container,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import { firebase, functions } from "../../../firebase";
import {
    signInWithEmailAndPassword,
    signInWithGMail
} from "../../../Components/AuthenticationProvider/AuthenticationProvider";
import {
    PasswordField,
    EmailField,
    GoogleButton,
    SubmitButton,
    ErrorMsg,
    IRecruiter,
    CustomInput
} from "compass-shared";
import { SignUpSchema } from "../../../Utils/FormValidationSchema";
import useStyles from "./styles";
import { ISignUpResp, ISignUpData } from "./Interface";
import { recruitersRef } from "../../../FirebaseUtils/collectionRefs";
import {
    ROUTE_UNAUTHENTICATED_LOGIN,
    ROUTE_UNAUTHENTICATED_REGISTER
} from "../../../routes";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import CompassLogo from "../../../Assets/img/compassLogo.png";

const SignUp: FC<RouteComponentProps> = () => {
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm({
        validationSchema: SignUpSchema
    });

    useEffect(() => {
        hitPageOnAnalytics(ROUTE_UNAUTHENTICATED_REGISTER);
		navigate("https://recruiters.acelr8.com/sign-up");
    }, []);

    const onSubmit = async (formData: any) => {
        setIsLoading(true);
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();

        const signUp: any = functions
            .httpsCallable("recruiters-signUpWithEmailPassword");
        try {
            const signUpAuthData: ISignUpData = {
                email: formData.email,
                password: formData.password,
                name: `${formData.firstName} ${formData.lastName}`
            };
            const recruiterObj: IRecruiter = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                is_active: true,
                has_been_onboarded: true,
                linkedin_url: "",
                created_at: timestamp
            };
            const signUpResp: ISignUpResp = (await signUp(signUpAuthData)).data;

            if (signUpResp.result) {
                await recruitersRef.doc(signUpResp.uid).set(recruiterObj);
                await signInWithEmailAndPassword(
                    formData.email,
                    formData.password
                );
                navigate("/search-candidates");
            } else {
                toast.error(<ErrorMsg text={signUpResp.message} />);
            }
            setIsLoading(false);
        } catch (err) {
            toast.error(<ErrorMsg />);
            setIsLoading(false);
        }
    };

    const handleClick: MouseEventHandler = async () => {
        setIsLoading(true);
        await signInWithGMail();
        setIsLoading(false);
    };

    return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          paddingTop="80px"
          height="100vh"
          style={{ backgroundColor: "#F2F2F3" }}
        >
            <img
              src={CompassLogo}
              alt="compass logo"
              className={classes.compassLogo}
            />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5" className={classes.title}>
                    Sign up
                </Typography>
                <br />
                <GoogleButton
                  handleClick={handleClick}
                  text="Google"
                  loading={isLoading}
                />
                {/* eslint-disable-next-line max-len */}
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CustomInput
                              autoComplete="fname"
                              name="firstName"
                              variant="outlined"
                              fullWidth
                              id="firstName"
                              placeholder="First Name"
                              autoFocus
                              className={classes.input}
                              inputRef={register}
                            />
                            {errors.firstName
                && (
                <Box color="error.main">
                    {(errors.firstName as any).message}
                </Box>
                )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomInput
                              variant="outlined"
                              fullWidth
                              id="lastName"
                              placeholder="Last Name"
                              name="lastName"
                              autoComplete="lname"
                              className={classes.input}
                              inputRef={register}
                            />
                            {errors.lastName
                && (
                <Box color="error.main">
                    {(errors.lastName as any).message}
                </Box>
                )}
                        </Grid>
                        <Grid item xs={12}>
                            <EmailField register={register} errors={errors} />
                            <PasswordField
                              register={register}
                              errors={errors}
                            />
                        </Grid>
                    </Grid>
                    <SubmitButton
                      className={classes.submit}
                      text="Sign Up"
                      loading={isLoading}
                    />
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to={ROUTE_UNAUTHENTICATED_LOGIN} className={classes.link}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Box>
    );
};

export default SignUp;
