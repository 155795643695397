import React, { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { useForm } from "react-hook-form";
import {
    Grid,
    Typography
} from "@material-ui/core";
import {
    sendPasswordResetEmail
} from "../../../Components/AuthenticationProvider/AuthenticationProvider";
import { ForgotPasswordSchema } from "../../../Utils/FormValidationSchema";
import useStyles from "./styles";
import {
    ROUTE_UNAUTHENTICATED_LOGIN,
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD
} from "../../../routes";
import {
    ErrorMsg,
    SuccessMsg,
    SubmitButton,
    EmailField
} from "compass-shared";

import CompassLogo from "../../../Assets/img/compassLogo.png";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import Box from "@material-ui/core/Box";

const ResetPassword: FC<RouteComponentProps> = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: ForgotPasswordSchema
    });
    const classes = useStyles();

    useEffect(() => {
        hitPageOnAnalytics(ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD);
    }, []);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(data.email);
            setIsLoading(false);
            toast.success(
                <SuccessMsg text="Reset password link sent" />
            );
            navigate(ROUTE_UNAUTHENTICATED_LOGIN);
        } catch (error) {
            if (error.code === "auth/user-not-found") {
                toast.error(<ErrorMsg text="User not found" />);
            } else {
                toast.error(<ErrorMsg text={error.message} />);
            }
            setIsLoading(false);
        }
    };

    return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          paddingTop="80px"
          height="100vh"
          style={{ backgroundColor: "#F2F2F3" }}
        >
            <img
              src={CompassLogo}
              alt="compass logo"
              className={classes.compassLogo}
            />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5" className={classes.title}>
                    Forgot Password?
                </Typography>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={classes.form}
                >

                    <EmailField register={register} errors={errors} autoFocus />

                    <SubmitButton
                      className={classes.submit}
                      text="Reset password"
                      loading={isLoading}
                    />
                    <Grid container>
                        <Grid item>
                            <Link to={ROUTE_UNAUTHENTICATED_LOGIN} className={classes.link}>
                                Back to sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Box>
    );
};

export default ResetPassword;
