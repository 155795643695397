import React, { FC } from "react";
import {
    Box,
    Button, Grid, Typography
} from "@material-ui/core";
import { IExperience } from "compass-shared";
import EditExperienceItem from "./ExperienceItem/EditExperienceItem";
import useAppContext from "../../../../context/store/store";
import { doAddProfileExperienceData } from "../../../../context/reducer/Actions";
import useStyles from "../style";

export type ExperienceProps = {
    experiences: IExperience[];
    disabled: boolean;
}

const EditExperience: FC<ExperienceProps> = ({
    experiences, disabled
}) => {
    const classes = useStyles();
    const [, dispatch]: any = useAppContext();
    const handleAddExperience = () => {
        dispatch(doAddProfileExperienceData({ id: new Date().getTime() }));
    };

    return (
        <Box display="flex" flexDirection="column" mb={1}>

            <Grid style={{ padding: "0 18px" }} item xs={12}>
                <Typography className={classes.title} variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Experience
                </Typography>
            </Grid>

            {
              Object.values(experiences).length > 0
                  ? (
                      Object.values(experiences).map((experience: any, index) => {
                          const key = Object.keys(experiences)[index];
                          return (
                              <EditExperienceItem
                                experience={experience}
                                key={key}
                                id={key}
                                disabled={disabled}
                              />
                          );
                      })
                  )
                  : ""
          }
            <Button
              variant="outlined"
              onClick={handleAddExperience}
              className={`${classes.addButton} ${classes.experienceButton}`}
            >
              Add Experience
            </Button>
        </Box>
    );
};

export default EditExperience;
