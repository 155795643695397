import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        fontSize: theme.spacing(2.5),
        height: theme.spacing(6),
        backgroundColor: "#A7C7D8",
        color: "#245978",
        "&:focus": {
            backgroundColor: "#A7C7D880",
        },
    },
    deleteIcon: {
        width: "16px",
    },

    skillsOptions: {
        position: "absolute",
        marginTop: "8px",
        zIndex: 30,
    },
    carouselSection: {
        [theme.breakpoints.down("md")]: {
            marginTop: (props: any) => (props.isSearchFormOpen ? 111 : 56),
        },
    },
    searchBox: {
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 1001,
        width: "100%",
        padding: "16px 0",
        backgroundColor: "#F2F2F3",
        [theme.breakpoints.down("xs")]: {
            position: "relative",
            padding: "0",
        },
    },

    formContainer: {
        transition: "all .3s ease",
        paddingRight: "16px",
        width: "98%",
        margin: "0 auto",
        "& p": {
            fontSize: "0.9rem",
        },

        [theme.breakpoints.down("xs")]: {
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
            width: "94%",
        },
    },
    daysWrapper: {
        display: "flex",
        alignItems: "center",
        "& p": {
            color: theme.palette.secondary.light,
            whiteSpace: "nowrap",
        },
        "& input": {
            paddingLeft: 3,
        },
        "& p:first-child": {
            marginRight: 5,
        },
        "& p:last-child": {
            marginLeft: 5,
        },
    },
    flexCenterWrapper: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px",
        },
    },
    button: {
        minHeight: "30px",
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: "#245978",
        },
        "&:disabled": {
            backgroundColor: "rgba(159, 162, 180, 0.8)",
            color: "#fff",
        },
    },
    textFieldRoot: {
        backgroundColor: theme.palette.secondary.light,
        borderColor: "#D8D9DF",
        borderRadius: "8px",
        "& label.Mui-focused": {
            color: "#AEAEB2",
        },
        "& label": {
            color: "#AEAEB2",
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]": {
            padding: "5px 4px",
        },
        "& .MuiSelect-select": {
            "&:focus": {
                border: "1px solid #AEAEB2",
                backgroundColor: "unset",
            },
        },
    },
    textOutlined: {
        color: "#AEAEB2",
    },
    formControlLabel: {
        fontSize: "0.9rem",
        color: theme.palette.primary.main,
    },
    checkboxRoot: {
        color: theme.palette.primary.main,
    },
    locationWrapper: {
        color: theme.palette.primary.main,
        fontSize: "0.9rem",
    },
    locationTextFieldRoot: {
        backgroundColor: "#ffffff",
    },
    dropDownIcon: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: 45,
    },
    showFormIconClass: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 35.25,
        width: 35.25,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8,
        transition: theme.transitions.create(["border-width"]),
        marginRight: 24,
        color: "inherit",
        border: "1px solid #AEAEB2",
        padding: 8,
        cursor: "pointer",
        outline: "inherit",
        "&:hover": {
            borderColor: "#221A16",
        },
    },
    openFormIconClass: {
        borderRadius: 8,
        border: "1px solid #221A16",
        transition: theme.transitions.create(["border-width"]),
    },
    searchMobileView: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column-reverse",
            gap: "10px",
        },
    },
    invisibleSection: {
        display: "flex",
        alignItems: "center",
        opacity: 1,
        transition: "opacity .35s",
        marginTop: "4px !important",
    },
    invisible: {
        opacity: 0,
        visibility: "hidden",
        display: "none",
    },
    resetButton: {
        textTransform: "unset",
    },
    createJobOpeningButton: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            // justifyContent: "center",
        },
    },
    resetLabel: {
        fontSize: 13,
        fontWeight: 600,
        borderBottom: "1px black dotted",
        color: "#585454",
    },
    createCandidateButton: {
        height: 37,
        backgroundColor: "#FCC46B",
        fontSize: 13,
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: 600,
        color: "#221A16",
        textTransform: "none",
        marginRight: 20,
        minWidth: 190,
        borderRadius: "7px",
        "&:hover": {
            backgroundColor: "#ffb237",
            boxShadow: "none",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 11,
            maxWidth: 190,
            minWidth: "180px",
            marginRight: 0,
        },
        [theme.breakpoints.between(0, 376)]: {
            minWidth: 165,
        },
        [theme.breakpoints.between(376, 412)]: {
            minWidth: 195,
        },
    },
    reactTooltip: {
        fontSize: 13,
        fontWeight: "bolder",
        // borderRadius: "4px !important",
        "&:hover": {
            // backgroundColor: "#221A16",
        },
    },
    Carousel: {
        width: "98.2%",
        height: "100%",
        position: "unset",
        "& .react-multi-carousel-track": {
            maxWidth: "80vw",
            "& .react-multi-carousel-item": {
                width: "250px !important",
                marginRight: 22,
            },
        },
        "& .react-multiple-carousel__arrow": {
            "&:hover": {
                border: "1px solid rgb(76,77,86)",
                transform: "scale(1.105)",
                transition: "all .2s ease",
            },
            background: "#FFF",
            "&--right": {
                right: 36,
            },
            "&--left": {
                left: 18,
            },
            "&::before": {
                color: "#221A16",
                fontSize: 14,
            },
        },
        "& .CarouselItem": {
            width: "80%",
            margin: "0 auto",
        },
    },
    PremadeCard: {
        width: 248,
        height: 153,
        borderRadius: 10,
        cursor: "pointer",
        flexDirection: "column",
        color: "#221A16",
        "& .container": {
            background: "#D4E8EF",
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
        },
        "& .selectedContainer": {
            background: "#FCC46B",
            color: "#221A16",
            "&:hover": {
                // background: "#f1db93 !important"
            },
        },
        "& .title": {
            fontWeight: 600,
            fontSize: 16,
        },
        "& .candidates": {
            height: "30%",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            width: "90%",
            color: "#221A16",
            fontSize: 13,
        },
        "&:hover": {
            border: "1px solid #9c9c9c",
            transform: "scale(1.001)",
        },
    },
}));

export default useStyles;
