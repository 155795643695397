export const premadeQueries = [
    { title: "React/Node.js Engineers", value: ["React", "Node.js"], type: "skills" },
    { title: "Back-end Engineers", value: "Back-end Engineering", type: "job_function" },
    { title: "Senior Designers", value: "Senior", type: "experience_level" },
    { title: "Sales Representatives", value: "Sales", type: "job_function" },
    { title: "Engineering Managers", value: "Engineering (Management)", type: "job_function" },
    { title: "Product Managers", value: "Product Management", type: "job_function" },
    { title: "Customer Success Reps", value: "Customer Success", type: "job_function" },
    { title: "Front-end Engineers", value: "Front-end Engineering", type: "job_function" },
    { title: "Marketing Professionals", value: "Marketing", type: "job_function" },
    { title: "Full-stack Engineers", value: "Full-stack Engineering", type: "job_function" }
];

export const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export const defaultFormFields = {
    location: "",
    experience_level: "",
    relocation_status: "",
    involved_recruiter_ids: false,
    salary_per_year: { min_salary_per_year: 0, max_salary_per_year: 1000000 },
    created_at_last_days_number: "",
    name_email_query: "",
    job_function: "",
    skills: [],
    is_archived: "false"
};
