import { createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    subTitle: {
        fontSize: "16px",
        lineHeight: "19.5px",
        fontWeight: 600,
        fontStyle: "normal",
        marginBottom: "20px",
        marginTop: "20px",
        color: "#2F2824",
    },

    labelText: {
        color: "#2F2824",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        marginBottom: "10px",
    },

    formControl: {
        margin: theme.spacing(0.4),
        minWidth: 120,
        [theme.breakpoints.between(361, 500)]: {
            minWidth: "unset",
            maxWidth: 174,
            width: "100%",
        },
        [theme.breakpoints.between(361, 412)]: {
            minWidth: "unset",
            maxWidth: 156,
            width: "100%",
        },
        [theme.breakpoints.between(500, 768)]: {
            minWidth: 238,
        },
        [theme.breakpoints.between(0, 361)]: {
            minWidth: "unset",
            maxWidth: 148,
            width: "100%",
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 13px) scale(1)",
        },
    },
    inputOutlined: {
        padding: "10px",
    },
    inputLabel: {
        top: "-10px",
    },
    inputShrink: {
        transform: "translate(14px, 4px) scale(0.75) !important",
    },
    inputFilled: {
        top: "10px !important",
    },
}));
