import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { firebase } from "../../../firebase";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import { candidatesRef } from "../../../FirebaseUtils/collectionRefs";
import { AlertDialog, ErrorMsg } from "compass-shared";

interface IProp {
    recruiterId: string;
    candidateId: string;
    isArchived: boolean;
    wrapperClass?: any;
    iconClass?: any;
}

const ArchiveCandidate: FC<IProp> = ({
    recruiterId,
    candidateId,
    isArchived,
    wrapperClass,
    iconClass
}) => {
    const [shouldArchive, setShouldArchive] = useState(!isArchived);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        setIsLoading(true);
        try {
            const updateObj: any = {
                is_archived: shouldArchive,
                archived_by: shouldArchive ? recruiterId : "",
                archived_at: shouldArchive ? firebase.firestore.Timestamp.now() : null
            };

            if (shouldArchive === false) {
                updateObj.activated_at = firebase.firestore.Timestamp.now();
            }

            await candidatesRef.doc(candidateId).update(updateObj);
            setShouldArchive((s: boolean) => !s);
        } catch (err) {
            toast.error(<ErrorMsg text={err.message || "Something went wrong"} />);
        } finally {
            setIsLoading(false);
            setModalOpen(false);
        }
    };

    return (
        <>
            <AlertDialog
              handleClose={() => setModalOpen(false)}
              handleAccept={() => submit()}
              open={modalOpen}
              text={`Are you sure you want to ${shouldArchive ? "archive" : "unarchive"} this candidate?`}
              title={<strong>{`${shouldArchive ? "Archive" : "Unarchive"} candidate`}</strong>}
              loading={isLoading}
              acceptButtonText="Yes"
              cancelButtonText="No"
            />
            <Tooltip title={`${shouldArchive ? "Archive" : "Unarchive"} candidate`}>
                <IconButton
                  aria-label="archive"
                  className={wrapperClass || ""}
                  onClick={() => setModalOpen(true)}
                >
                    {shouldArchive
                        ? <ArchiveIcon classes={{ root: iconClass || "" }} />
                        : <UnarchiveOutlinedIcon classes={{ root: iconClass || "" }} />}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default ArchiveCandidate;
