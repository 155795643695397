import React, { FC } from "react";
import { Typography, Box } from "@material-ui/core";
import { IExperienceItem } from "../Interface";
import moment from "moment";

const ExperienceItem: FC<IExperienceItem> = ({ experience }) => (
    <Box mb={1}>
        <Typography variant="subtitle2">
            <Box fontWeight="600" fontSize="13px">
                <span style={{ textTransform: 'capitalize' }}>
                    {experience.title}
                </span> 
                <span> at </span>
                <span style={{ textTransform: 'capitalize' }}>{experience.company_name}</span>
            </Box>
        </Typography>
        <Typography variant="caption" gutterBottom style={{ fontWeight: 400, fontSize: 12 }}>
            {JSON.stringify(new Date(experience.start_at)) === "null" ? experience?.date_range?.split("-")[0].split(".")[0] || "":
            experience.start_at.toDate
                ? moment(experience.start_at.toDate()).format("MMM YYYY")
                : typeof experience.start_at === "number" && moment(experience.start_at).format("MMM YYYY")}
            {JSON.stringify(experience?.start_at).length && ` - `}
            {JSON.stringify(new Date(experience.end_at)) === "null" ?
                experience?.date_range?.split("-")[1].includes("Present") ? "Present":
                experience.date_range && `${experience.date_range?.split("-")[1].trim().split(" ")[0]} ${experience.date_range?.split("-")[1].trim().split(" ")[1]}` || "":
                        typeof experience.end_at === "string"
                            ? experience.end_at
                            : typeof experience.end_at === "number"
                                ? moment(experience.end_at).format("MMM YYYY")
                                : moment(experience.end_at.toDate()).format("MMM YYYY")
            }
        </Typography>
    </Box>
);

export default ExperienceItem;
