import React, { FC, useState } from "react";
import { Chip, Box, Button } from "@material-ui/core";
import { ISkills } from "./Interface";
import useStyles from "./style";
import { ISkill, SeniorityLevelChip } from "compass-shared";

const Skills: FC<ISkills> = ({ skills, experienceLevel, jobFunction }) => {
    const [isSeeMore, setIsMore] = useState(false);
    const classes = useStyles({ experience_level: experienceLevel });

    const createSkillChip = (skill: ISkill, i?: number) => (
        <Chip
          key={skill.name + Math.floor(Math.random() * 1000)}
          style={{ display: !skill.is_top_skill && i && i > 4 && !isSeeMore ? "none" : "" }}
          label={(
              <Box fontWeight="fontWeightBold" fontSize={9}>
                  {skill.name}
              </Box>
            )}
          classes={{ root: skill.is_top_skill ? classes.mainSkillchip : "" }}
          className={classes.chip}
        />
    );

    const makeSkillsChip = (skillsArr: ISkill[]) => {
        const mainSkills: ISkill[] = [];
        const secondarySkills: ISkill[] = [];
        skillsArr.forEach((skill: ISkill) => {
            if (skill.is_top_skill) {
                mainSkills.push(skill);
            } else {
                secondarySkills.push(skill);
            }
        });

        return (
            <>
                {!!mainSkills.length && mainSkills.map((skill: ISkill) => createSkillChip(skill))}
                {!!secondarySkills.length && <div style={{ marginTop: "5px" }} />}
                {!!secondarySkills.length
                    && secondarySkills.map((skill: ISkill, i: number) => createSkillChip(skill, i))}
                {secondarySkills.length > 5
                    && (
                        <Button
                          onClick={() => setIsMore((c) => !c)}
                          classes={{ root: classes.button }}
                          size="small"
                        >
                            See
                            {" "}
                            {isSeeMore ? "less" : `more (${secondarySkills.length - 5})`}
                        </Button>
                    )}
            </>
        );
    };

    return (
        <>
            {
                skills?.length || experienceLevel
                    ? (
                        <Box mt="5">
                            {experienceLevel && (
                                <SeniorityLevelChip
                                  experienceLevel={experienceLevel}
                                  chipClasses={classes.experienceChip}
                                />
                            )}

                            {jobFunction && (
                            <Chip
                              classes={{ root: classes.mainSkillchip }}
                              size="small"
                              label={(
                                  <Box fontWeight="fontWeightBold" fontSize={9}>
                                      {jobFunction}
                                  </Box>
                                )}
                              className={classes.chip}
                            />
                            )}

                            {makeSkillsChip(skills)}
                        </Box>
                    ) : null
            }
        </>
    );
};

export default React.memo(Skills);
