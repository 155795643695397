import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 8,
        width: "min(500px, 96%)",
        padding: "40px 30px"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: 20,
    },
    submit: {
        margin: "20px 0px 10px",
        height: 40,
        color: "#FFFFFF",
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: "#221A16",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        }
    },
    compassLogo: {
        width: 230,
    },
    title: {
        fontSize: 26,
        fontWeight: 600,
        marginBottom: 20
    },
    link: {
        fontSize: 13,
        fontWeight: 400,
        textDecoration: "none",
        color: "#AEAEB2",
        [theme.breakpoints.down("md")]: {
            fontSize: 11
        },
        "&:hover": {
            color: "#363840",
            transition: theme.transitions.create(["color"])
        }
    }
}));

export default useStyles;
