import { ICandidateEditProfile } from "./Interface";

export enum PROFILE_STATUS {
    Loading = "loading",
    NotFound = "not-found",
    ErrorAccured = "error",
}

export interface IData {
    id: string;
    data: ICandidateEditProfile;
}
