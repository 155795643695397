import React, { FC, MouseEventHandler, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, useNavigate } from "@reach/router";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@material-ui/core";
import {
  signInWithEmailAndPassword,
  signInWithGMail,
} from "../../../Components/AuthenticationProvider/AuthenticationProvider";
import { SignInSchema } from "../../../Utils/FormValidationSchema";
import useStyles from "./styles";
import {
  ROUTE_UNAUTHENTICATED_REGISTER,
  ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD,
  ROUTE_UNAUTHENTICATED_LOGIN,
} from "../../../routes";
import {
  ErrorMsg,
  GoogleButton,
  SubmitButton,
  PasswordField,
  EmailField,
} from "compass-shared";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import CompassLogo from "../../../Assets/img/compassLogo.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const SignIn: FC<RouteComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: SignInSchema,
  });
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    hitPageOnAnalytics(ROUTE_UNAUTHENTICATED_LOGIN);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toNewSignUP = () => {
    navigate("https://recruiters.acelr8.com/sign-up");
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(data.email, data.password);
      setIsLoading(false);
    } catch (error: any) {
      // eslint-disable-next-line max-len
      if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found"
      ) {
        // eslint-disable-next-line max-len
        toast.error(<ErrorMsg text="Invalid credentials. Please try again" />);
      } else {
        toast.error(<ErrorMsg text={error.message} />);
      }
      setIsLoading(false);
    }
  };

  const handleClick: MouseEventHandler = async () => {
    setIsLoading(true);
    await signInWithGMail();
    setIsLoading(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      paddingTop="80px"
      height="100vh"
      style={{ backgroundColor: "#F2F2F3" }}
    >
      <img
        src={CompassLogo}
        alt="compass logo"
        className={classes.compassLogo}
      />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          Sign In
        </Typography>
        <GoogleButton
          handleClick={handleClick}
          text="Google"
          loading={isLoading}
        />
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <EmailField register={register} errors={errors} autoFocus />
          <PasswordField register={register} errors={errors} />
          <SubmitButton
            className={classes.submit}
            text="Sign In"
            loading={isLoading}
          />
          <Grid container>
            <Grid item xs>
              <Link
                to={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD}
                className={classes.link}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to={"#"} className={classes.link} onClick={handleClickOpen}>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sign up on the new Connect product"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#0b0a0a" }}
          >
            We are going to merge this Compass product into the Connect product.<br/><br/>
            Therefore, please sign up on Connect by clicking on the "Proceed" button<br/><br/>
            P.S. Your Connect account will also work here on Compass.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "8px 8px 16px" }}>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={toNewSignUP}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignIn;
