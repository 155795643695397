import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, Chip, Drawer, Grid, Typography } from "@material-ui/core";
import ContentEditable from "react-contenteditable";
import {
  ERecruiterLogTypes,
  IAlgoliaCandidate,
  IRecruiterLog,
  ErrorMsg,
  UserAvatar,
  IConnectedLinksObj,
  EConnectedLinks,
} from "compass-shared";
import { useStyles } from "./style";
import EditProfile from "../../../Assets/icons/edit-profile.svg";
import { navigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { ICandidateNote } from "../CreateCandidate/interface";
import RequestIntroButton from "../CandidateCardsList/requestIntroButton";
import useAppContext from "../../../context/store/store";
import ExperienceItem from "../CandidateProfile/Experience/ExperienceItem/ExperienceItem";
import EducationItem from "../CandidateProfile/Education/EducationItem/EducationItem";
import Email from "@material-ui/icons/Email";
import { CompassAlert } from "../CandidateCardsList/modals/directContact";
import LogTimeLine from "../CandidateProfile/LogTimeLine/LogTimeLine";
import Grow from "@material-ui/core/Grow";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Backdrop from "@material-ui/core/Backdrop";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {
  candidatesAnalyticsRef,
  recruiterLogsRef,
  recruitersRef,
} from "../../../FirebaseUtils/collectionRefs";
import { firebase, auth, functions } from "../../../firebase";
import { toast } from "react-toastify";
import Avatar from "@material-ui/core/Avatar";
import PdfIcon from "../../../Assets/icons/pdf-icon.svg";
import LinkedInIcon from "../../../Assets/icons/linkedin-icon.svg";
import moment from "moment";
import SkillChip from "../../../SharedComponents/SkillChip";
import { toPascalCase } from "../../../Utils/strings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ToCompassModal from "../CandidateCardsList/modals/toCompass";
import ReactTooltip from "react-tooltip";
import { firestore } from "firebase";
import { Emoji } from "emoji-mart";

const { Timestamp } = firebase.firestore;
interface IDrawerProps {
  candidate: IAlgoliaCandidate & ICandidateNote;
  drawerState: boolean;
  toggleDrawer: Function;
  parentClasses: any;
  handleRequestIntro: () => void;
  handleDirectContact: () => void;
  loading: boolean;
}

const initialState = {
  isPhoneCopied: false,
  isEmailCopied: false,
  requestedIntro: false,
  sendDirectContact: false,
  latestRequestedIntroDate: {},
  latestDirectContactDate: {},
};

export const convertTimeToShortString = (time: any) => {
  const timeCheck = time?.seconds || time?._seconds;
  const date = new Date(timeCheck * 1000);
  return moment(date).format("DD MMM YYYY");
};

const CandidatesExpanded: FC<IDrawerProps> = ({
  candidate,
  drawerState,
  toggleDrawer,
  parentClasses,
  handleRequestIntro,
  handleDirectContact,
  loading,
}) => {
  const classes = useStyles();

  const [page] = useState("search-candidates");
  const [state, setState] = useState(initialState);
  const [comment, setComment] = useState<string>("");
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [addingComment, setAddingComment] = useState(false);
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [deletingComment, setDeletingComment] = useState(false);
  const [recruiters, setRecruiters] = useState([] as Array<any>);
  const [connectModalStatus, setConnectModalStatus] = useState(false);

  const openConnectModal = () => {
    setConnectModalStatus(true);
  };

  const closeConnectModal = () => {
    setConnectModalStatus(false);
  };

  const smallScreens = useMediaQuery("(max-width:767px)");
  const extraSmallScreens = useMediaQuery("(max-width:506px)");

  const [{ user }]: any = useAppContext();

  const getRequestIntroStatus = useCallback(async () => {
    await recruitersRef
      .doc(user.id)
      .collection("candidates_i_requested_intro")
      .doc(candidate.objectID)
      .onSnapshot((snap) => {
        setState((st) => ({
          ...st,
          requestedIntro: snap.exists,
          latestRequestedIntroDate: snap?.data()?.requested_at,
        }));
      });
  }, []);
  const getDirectContactStatus = useCallback(async () => {
    await recruitersRef
      .doc(user.id)
      .collection("candidates_i_direct_contact")
      .doc(candidate.objectID)
      .onSnapshot((snap) => {
        setState((st) => ({
          ...st,
          sendDirectContact: snap.exists,
          latestDirectContactDate: snap?.data()?.requested_at,
        }));
      });
  }, []);

  useEffect(() => {
    getRequestIntroStatus();
    getDirectContactStatus();
  }, [user, candidate]);

  useEffect(() => {
    const linkedInObj: IConnectedLinksObj | undefined =
      candidate?.connected_links?.find(
        (link: IConnectedLinksObj) => link.key === EConnectedLinks.LINKEDIN
      );
    setLinkedInUrl(linkedInObj?.link || "");
  }, []);

  const getRecruiters = async () => {
    const recruiterIds =
      candidate?.involved_recruiter_ids ||
      (candidate?.recruiter_provider_id && [
        candidate?.recruiter_provider_id,
      ]) ||
      [];
    let recruiter: any = [];
    if (recruiterIds.length) {
      recruiter = (
        await recruitersRef
          .where(firestore.FieldPath.documentId(), "in", recruiterIds)
          .get()
      ).docs;
    }

    const data = recruiter.map((doc: any) => doc.data());
    setRecruiters(data);
  };

  const updateProfileViewAnalytics = async () => {
    await candidatesAnalyticsRef.doc().set({
      candidateId: candidate.objectID,
      recruiterId: user.id,
      viewed_at: Timestamp?.fromDate(new Date()),
    });
  };

  useEffect(() => {
    if (drawerState) {
      updateProfileViewAnalytics();
      getRecruiters();
    }
  }, [drawerState]);

  const editCandidate = () => {
    navigate(`/candidates/${candidate.objectID}/edit`);
  };

  const closeDrawer = () => {
    window.history.replaceState(null, `${page}`, `/${page}`);
    toggleDrawer(false);
    setRecruiters([]);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setState((st) => ({
      ...st,
      isEmailCopied: false,
      isPhoneCopied: false,
    }));
  };

  const ShowSnackBarAlert = (copiedIted = "Email") => (
    <CompassAlert onClose={handleClose} severity="success">
      {copiedIted} copied to clipboard
    </CompassAlert>
  );

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleDeleteComment = async (id: string) => {
    setSelectedComment(id);
  };

  const addNewComment = async () => {
    try {
      if (comment === "") {
        return;
      }

      let i = 0;
      let j = 200;

      while (i < comment.length) {
        const section = comment.substring(i, j);
        if (section.length >= 200 && !section.includes(" ")) {
          toast.error("Invalid comment");
          return;
        }
        i = j;
        j += 200;
      }

      setAddingComment(true);
      const newLog: IRecruiterLog = {
        type: ERecruiterLogTypes.NOTE,
        created_at: firebase.firestore.Timestamp.now(),
        candidate_id: candidate.objectID,
        note: comment,
        recruiter_id: auth.currentUser ? auth.currentUser.uid : "",
        recruiter_name: `${user?.firstName} ${user?.lastName}`,
      };
      await recruiterLogsRef.add(newLog);
      setComment("");
      setAddingComment(false);
    } catch (error) {
      setAddingComment(false);
      toast.error(<ErrorMsg />);
    }
  };

  const handleCommentKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();

      addNewComment();
    }
  };

  const onDeleteComment = async () => {
    if (selectedComment) {
      setDeletingComment(true);
      const id = selectedComment;
      setSelectedComment(null);
      await recruiterLogsRef.doc(id).delete();
      setDeletingComment(false);
    }
  };

  const getDaysPast = () => {
    const days =
      moment().diff(moment(candidate?.created_at?._seconds * 1000), "days") ||
      0;
    return `${days} ${days !== 1 ? "Days" : "Day"} Ago`;
  };

  const displayDate = () => {
    const combinedDates = {
      ...state.latestRequestedIntroDate,
      ...state.latestDirectContactDate,
    };
    const availableDates: any = [] as any;
    Object.keys(combinedDates).forEach((el: any) => {
      if (el.split("_")[0] === "request" || el.split("_")[0] === "dc") {
        availableDates.push({ requestName: el, date: combinedDates[el] });
      }
    });
    const sorted = availableDates.sort((a: any, b: any) =>
      a.date.toDate() > b.date.toDate()
        ? -1
        : b.date.toDate() < a.date.toDate()
        ? 1
        : 0
    );
    return sorted.map((el: any, i: number) => {
      const parsedTime = moment(el.date.toDate()).format("DD MMM YYYY");
      const { requestName } = el;

      if (requestName.split("_")[0] === "request") {
        return (
          <div
            key={`${i}-${parsedTime}`}
            style={{ width: "17rem", marginRight: "0.2rem", marginTop: 5 }}
          >
            Intro Requested - {parsedTime}
          </div>
        );
      }
      if (requestName.split("_")[0] === "dc") {
        return (
          <div
            key={`${i}-${parsedTime}`}
            style={{ width: "17rem", marginRight: "0.2rem", marginTop: 5 }}
          >
            Direct Contacted - {parsedTime}
          </div>
        );
      }
      return null;
    });
  };

  const logDirectContact = async () => {
    await functions.httpsCallable("recruiters-handleDirectContact")({
      candidateID: candidate.objectID,
      recruiterID: candidate?.recruiter_provider_id,
      requestedBy: user.id,
      status: "direct_contact_sent",
    });
  };

  return (
    <>
      <ToCompassModal open={connectModalStatus} close={closeConnectModal} candidate={candidate} />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={selectedComment !== null}
      >
        <DialogTitle
          classes={{
            root: parentClasses.confirmationTitle,
          }}
          id="confirmation-dialog-title"
        >
          Confirmation
        </DialogTitle>
        <DialogContent classes={{ root: parentClasses.confirmationContent }}>
          <Backdrop className={parentClasses.backdrop} open={deletingComment}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography>Are you sure you want to delete this comment?</Typography>
        </DialogContent>
        <DialogActions classes={{ root: parentClasses.confirmationActions }}>
          <Button
            autoFocus
            onClick={() => setSelectedComment(null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={onDeleteComment} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="right"
        classes={{
          paper: classes.drawerPaperRoot,
        }}
        open={drawerState}
        onClose={closeDrawer}
      >
        <div>
          <div className={classes.actionsContainer}>
            <Box display="flex" className={classes.buttonContainer}>
              <RequestIntroButton
                handleRequestIntro={handleRequestIntro}
                handleDirectContact={handleDirectContact}
                loading={loading}
                candidateEmail={candidate?.email}
                hasRequestedIntro={state.requestedIntro}
                hasSendDirectContact={state.sendDirectContact}
                recruiterID={user.id}
				openConnectModal={openConnectModal}
              />
            </Box>
            <Button
              color="primary"
              onClick={editCandidate}
              variant="contained"
              disableElevation
              className={classes.itemButton}
            >
              <img src={EditProfile} alt="icon" />
            </Button>

            <Button
              variant="contained"
              disableElevation
              className={`${classes.itemButton} ${classes.itemCloseButton}`}
              onClick={closeDrawer}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.contactsDate}>{displayDate()}</div>
          <div className={classes.candidateProfile}>
            <Box
              width={smallScreens ? "100%" : "40%"}
              style={{ textAlign: smallScreens ? "center" : "start" }}
            >
              <UserAvatar
                variant="rounded"
                src={
                  candidate?.candidate_avatar_url ||
                  candidate?.thumbnail_avatar_url ||
                  ""
                }
                styleClass={parentClasses.avatar}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              marginTop={smallScreens ? 0 : "50px"}
              width={smallScreens ? "auto" : "62%"}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={smallScreens ? "center" : "start"}
                alignItems="center"
                style={{
                  marginBottom: smallScreens ? "20px" : "12px",
                }}
              >
                <Typography
                  component="div"
                  data-for={`${candidate.firstName} ${candidate.lastName}`}
                  data-iscapture="true"
                  data-tip={`${candidate.firstName || "Unknown"} ${
                    candidate.lastName || "Unknown"
                  }`}
                  className={classes.nameSection}
                  style={{
                    fontSize: "26px",
                    fontWeight: 600,
                    overflow: "hidden",
                    maxWidth: "17rem",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {`${candidate.firstName || "Unknown"} ${
                    candidate?.lastName?.slice(0, 1)?.toUpperCase() || "Unknown"
                  }.`}
                  {candidate.is_a_player && <Emoji emoji=":fire:" size={24} />}
                </Typography>
                {/* <ReactTooltip
                                    id={`${candidate.firstName} ${candidate.lastName}`}
                                    place="bottom"
                                    effect="solid"
                                    textColor="#221A16"
                                    backgroundColor="#E1E0E1"
                                    border
                                    clickable
                                    borderColor="#dadde9"
                                    className={classes.reactTooltip}
                                    /> */}

                {linkedInUrl && (
                  <a
                    href={linkedInUrl}
                    onClick={logDirectContact}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", marginLeft: "8px" }}
                  >
                    <img
                      src={LinkedInIcon}
                      alt="icon"
                      className={parentClasses.icons}
                    />
                  </a>
                )}
                {candidate.cv_url && (
                  <a
                    href={candidate.cv_url}
                    onClick={logDirectContact}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", marginLeft: "8px" }}
                  >
                    <img
                      src={PdfIcon}
                      alt="icon"
                      className={parentClasses.icons}
                    />
                  </a>
                )}
              </Box>

              <div>
                <div className="profileInfo">
                  <span className="title">
                    {" "}
                    {extraSmallScreens ? "Function" : "Job Function"}:
                  </span>
                  <div>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      classes={{ root: classes.profile }}
                    >
                      {candidate?.job_function || "Unknown"}
                    </Typography>
                  </div>
                </div>

                <div className="profileInfo">
                  <span className="title"> Seniority: </span>
                  <div>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      classes={{ root: classes.profile }}
                    >
                      {candidate?.experience_level || "none"}
                    </Typography>
                  </div>
                </div>

                <div className="profileInfo">
                  <span className="title"> Location: </span>
                  <div>
                    <Typography
                      component="div"
                      variant="subtitle1"
                      classes={{
                        root: `${classes.profile} ${classes.location}`,
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: 180,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {candidate?.location || "Unknown"}
                      </span>
                    </Typography>
                  </div>
                </div>

                <div className="profileInfo">
                  <span className="title"> Salary: </span>
                  <div>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      classes={{
                        root: `${parentClasses.currency} ${classes.profile}`,
                      }}
                    >
                      {`${candidate?.currency === "dollar" ? "$" : "€"}
                                                 ${
                                                   candidate?.min_salary_per_year?.toLocaleString() ||
                                                   0
                                                 } -
                                                ${
                                                  candidate?.max_salary_per_year?.toLocaleString() ||
                                                  0
                                                }`}
                    </Typography>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className={classes.expandedList}>
            {candidate?.skills?.map((skill: any, idx: number) => (
              <SkillChip
                label={toPascalCase(skill?.name || skill)}
                key={skill?.name || skill}
              />
            ))}
          </div>

          <div className={classes.skillsContainer}>
            <div className="skills">
              <span className="title"> Open to Remote </span>
              <Chip
                className={parentClasses.chip}
                size="small"
                style={{ width: "52px", height: "26px" }}
                label={candidate && candidate.is_open_to_remote ? "Yes" : "-"}
              />
            </div>

            <div className="skills">
              <span className="title"> Skill Assessed </span>
              <Chip
                className={parentClasses.chip}
                size="small"
                style={{ width: "52px", height: "26px" }}
                label={candidate?.is_skills_assessed ? "Yes" : "-"}
              />
            </div>

            <div className="skills">
              <span className="title"> A-Player </span>
              <Chip
                className={parentClasses.chip}
                size="small"
                style={{ width: "52px", height: "26px" }}
                label={candidate?.is_a_player ? "Yes" : "-"}
              />
            </div>

            <div className="skills">
              <span className="title"> Created </span>
              <Chip
                className={parentClasses.chip}
                size="small"
                style={{ height: "26px" }}
                label={getDaysPast()}
              />
            </div>
          </div>

          {candidate?.note && <div className={classes.divider} />}

          <div className={classes.motivation}>
            <Grid item xs={12} className={parentClasses.itemSection}>
              {candidate?.note ? (
                <Grid
                  className={classes.textContainerSmall}
                  container
                  style={{ display: "flex" }}
                >
                  <Grid
                    item
                    md={3}
                    xs={3}
                    className={`${parentClasses.centredFlex}
                                              centeredFlexCard`}
                  >
                    <Typography
                      component="div"
                      variant="subtitle1"
                      style={{ textAlign: "right" }}
                      classes={{ root: classes.sectionTitleDrawer }}
                    >
                      Career Aspirations
                    </Typography>
                  </Grid>
                  <Grid item md={9} xs={9} style={{ marginTop: "-15px" }}>
                    <ContentEditable
                      disabled
                      tagName="div"
                      onChange={(e) => console.log(e)}
                      html={candidate.note}
                      className={classes.contentEditable}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
          {(candidate?.educations && candidate.educations.length) ||
          (candidate?.experiences && candidate.experiences.length) ? (
            <div className={classes.divider} />
          ) : (
            ""
          )}
          <div className={classes.experienceContainer}>
            {candidate?.experiences && candidate.experiences.length ? (
              <>
                <Grid
                  className={classes.textContainerSmall}
                  container
                  style={{ display: "flex", marginBottom: "44px" }}
                >
                  <Grid
                    item
                    md={3}
                    xs={3}
                    className={`${parentClasses.centredFlex} centeredFlexCard`}
                  >
                    <Typography
                      component="div"
                      variant="subtitle1"
                      style={{ lineHeight: "26px" }}
                      classes={{ root: classes.sectionTitleDrawer }}
                    >
                      Experience
                    </Typography>
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Box display="flex" flexDirection="column">
                      {candidate.experiences.map((experience) => (
                        <div
                          key={experience.start_at._seconds * 1000}
                          className="experienceItem"
                        >
                          <ExperienceItem
                            key={experience.start_at._seconds * 1000}
                            experience={{
                              ...experience,
                              start_at:
                                experience.start_at?.seconds * 1000 ||
                                experience.start_at._seconds * 1000,
                              end_at:
                                experience.end_at === "present"
                                  ? "present"
                                  : experience.end_at?.seconds * 1000 ||
                                    experience.end_at._seconds * 1000,
                            }}
                          />
                        </div>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
                <div className={classes.divider} />
              </>
            ) : (
              ""
            )}
            {candidate?.educations && candidate.educations.length ? (
              <Grid
                className={classes.textContainerSmall}
                container
                style={{ display: "flex" }}
              >
                <Grid
                  item
                  md={3}
                  xs={3}
                  className={`${parentClasses.centredFlex}
                                          centeredFlexCard`}
                >
                  <Typography
                    component="div"
                    variant="subtitle1"
                    style={{ lineHeight: "26px" }}
                    classes={{ root: classes.sectionTitleDrawer }}
                  >
                    Education
                  </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Box display="flex" flexDirection="column">
                    {candidate.educations.map((education) => (
                      <div
                        key={education.degree_title}
                        className="experienceItem"
                      >
                        <EducationItem
                          key={education.degree_title}
                          education={{
                            ...education,
                            start_at:
                              education.start_at?.seconds * 1000 ||
                              education.start_at._seconds * 1000,
                            end_at:
                              education.end_at === "present"
                                ? "present"
                                : education.end_at?.seconds * 1000 ||
                                  education.end_at._seconds * 1000,
                          }}
                        />
                      </div>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </div>

          {/* {
                        ((candidate?.educations && candidate.educations.length) || (candidate?.experiences && candidate.experiences.length))
                            ? <div className={classes.divider} /> : ""
                    } */}
          <div className={classes.divider} />
          <div
            className={`${classes.recruiterSection} ${classes.textContainerSmall}`}
          >
            <Grid
              item
              md={3}
              xs={3}
              className={`${parentClasses.centredFlex} centeredFlexCard`}
            >
              <Typography
                component="div"
                variant="subtitle1"
                style={{
                  maxWidth: smallScreens ? "auto" : 94,
                  textAlign: "right",
                }}
                classes={{ root: classes.sectionTitleDrawer }}
              >
                First Contact
              </Typography>
            </Grid>
            <Grid item md={9} xs={9}>
              <Grid item md={10} xs={9}>
                <Box display="flex" flexDirection="row">
                  {recruiters.map((recruiter, index) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ columnGap: "20px" }}
                      key={index.toString()}
                    >
                      <Avatar
                        style={{ width: 70, height: 70 }}
                        data-for="avatar"
                        data-iscapture="true"
                        data-tip=""
                        aria-label="thumbnail"
                        src={recruiter?.thumbnail_avatar_url}
                      />

                      <Typography style={{ fontWeight: 400, fontSize: 14 }}>
                        {`${recruiter.firstName} ${recruiter.lastName}`}
                      </Typography>

                      <ReactTooltip
                        id="avatar"
                        place="bottom"
                        effect="solid"
                        textColor="#221A16"
                        backgroundColor="#E1E0E1"
                        border
                        clickable
                        borderColor="#dadde9"
                        className={classes.reactTooltip}
                      >
                        <Box display="flex" flexDirection="column">
                          <div className="recruiterInfo">
                            <pre style={{ fontWeight: 400 }}>
                              {`${recruiter.firstName} ${recruiter.lastName}`}
                            </pre>
                          </div>

                          <div className="recruiterInfo">
                            <pre style={{ fontWeight: 400 }}>
                              {convertTimeToShortString(candidate.created_at)}
                            </pre>
                          </div>
                        </Box>
                      </ReactTooltip>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </div>

          <div style={{ marginBottom: "20px" }} className={classes.divider} />

          <div className={classes.commentsSection}>
            <Grid item xs={12}>
              <Grid container>
                <LogTimeLine
                  userId={auth.currentUser?.uid}
                  candidateId={candidate.objectID}
                  expanded
                  handleDeleteComment={handleDeleteComment}
                />
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Grow in>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{ display: "inline-block" }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          border="1px solid #245978"
                          borderRadius="10px"
                        >
                          <InputBase
                            name="comment"
                            multiline
                            value={comment}
                            onChange={handleCommentChange}
                            classes={{ root: parentClasses.commentTextArea }}
                            placeholder="What else should we know about this candidate?"
                            onKeyPress={handleCommentKeyPress}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            className={parentClasses.commentButton}
                            onClick={addNewComment}
                            autoCapitalize="none"
                          >
                            {addingComment ? (
                              <CircularProgress
                                size={18}
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const CandidatesExpandedContainer = React.memo(CandidatesExpanded);
export { CandidatesExpandedContainer };
