import React, { FC, useRef, useEffect } from "react";
import { useStyles } from "./styles";

 interface IAutoCompleteProps {
     children: any;
     searchResults: [] | null;
     index: string;
     inputType: string;
     searchCallback: Function;
     setSearchResults: Function;
     setSearchIndex: Function;
 }

export const AutoCompleteForm: FC<IAutoCompleteProps> = ({
    children, searchResults, index, inputType, searchCallback, setSearchResults,
    setSearchIndex
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const updateOptionsView = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            if (index === inputType) {
                setSearchResults(null);
                setSearchIndex("");
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", updateOptionsView);
        return () => document.removeEventListener("click", updateOptionsView);
    });

    return (
        <div ref={ref}>
            { children }
            {
                searchResults && index === inputType
                    ? (
                        <div className={classes.container}>
                            {
                       searchResults && searchResults.length
                           ? searchResults.map((result: any, i: number) => (
                               <div
                                 className={classes.autoCompleteContainer}
                                 key={i.toString()}
                               >
                                   <button
                                     type="button"
                                     style={{ height: result.email ? "auto" : "40px" }}
                                     onClick={() => searchCallback(result.name)}
                                     className={classes.button}
                                   >
                                       { result.name }
                                   </button>
                                   { result.email ? (
                                       <button
                                         type="button"
                                         style={{ paddingBottom: "12px" }}
                                         onClick={() => searchCallback(result.email)}
                                         className={classes.button}
                                       >
                                           { result.email}
                                       </button>
                                   ) : "" }
                               </div>
                           ))
                           : ""
                   }
                        </div>
                    )
                    : ""
           }

        </div>
    );
};
