import React, { FC } from "react";
import FormControl from "@material-ui/core/FormControl";
import { yearsArray, monthNames, SelectMenu } from "compass-shared";
import { useStyles } from "./style";
import { ITimePickerProps } from "../Interface";
import { Controller, useForm } from "react-hook-form";

const TimePicker: FC<ITimePickerProps> = ({
    year, month, handleChangeYear, handleChangeMonth, disabled
}) => {
    const classes = useStyles();
    const { control } = useForm();
    return (
        <div>
            <FormControl style={{ marginRight: "17px" }} variant="outlined" className={classes.formControl}>
                <Controller
                  as={(
                      <SelectMenu
                        disabled={false}
                        defaultValue={year || ""}
                        items={yearsArray.map((_year) => ({ title: _year, value: _year }))}
                        label="Year"
                      />
                    )}
                  name="year"
                  control={control}
                  onChange={handleChangeYear}
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <Controller
                  as={(
                      <SelectMenu
                        disabled={false}
                        defaultValue={month || ""}
                        items={monthNames.map((_month) => ({ title: _month, value: _month }))}
                        label="Month"
                      />
                    )}
                  name="month"
                  control={control}
                  onChange={handleChangeMonth}
                />
            </FormControl>
        </div>
    );
};

export default TimePicker;
