import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import EducationItem from "./EducationItem/EducationItem";
import { IEducationsList } from "./interface";

const Educations: FC<IEducationsList> = ({ educations }) => (
    <>
        <Typography variant="h6" gutterBottom>
            <Box fontWeight="fontWeightBold" fontSize="14px">
                Education
            </Box>
        </Typography>
        {
                educations && educations.length
                    ? educations.map((education) => (
                        <EducationItem
                          education={education}
                          key={`${education.institution_name}-${education.degree_title}`}
                        />
                    ))
                    : (
                        <Typography variant="caption">
                        There isn't any education record.
                        </Typography>
                    )
            }
    </>
);

export default Educations;
