import React, { useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {
    CustomInput,
    ErrorMsg, ExperienceLevel, experienceLevelList, ISubscribedQueries,
    JobFunctionList, MultiSelectInput, SelectMenu, SubmitButton, SuccessMsg
} from "compass-shared";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";

import { doStartSearchJobOpenings, doToggleCreateJobOpening } from "../../../../context/reducer/Actions";
import useAppContext from "../../../../context/store/store";
import useStyles from "./style";
import { firebase } from "../../../../firebase";
import { subscribedQueriesRef } from "../../../../FirebaseUtils/collectionRefs";
import { toast } from "react-toastify";
import { JobOpeningSchema } from "../../../../Utils/FormValidationSchema";

export interface IJobOpening {
  jobTitle: string;
  companyName: string;
  urlToJobDescription: string;
  jobFunction: string;
  seniority: ExperienceLevel;
  countryAndCity: string;
  skills: [] | any;
}

export const CreateJobOpening = ({
    isOpen, isEdit, data, clearData
}: { isOpen: boolean, isEdit?: boolean, data?: ISubscribedQueries | undefined, clearData?: Function }) => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();

    const {
        handleSubmit, control, register, errors, reset, getValues, setValue
    } = useForm<IJobOpening>({
        validationSchema: JobOpeningSchema,
        defaultValues: useMemo(() => data, [data]),
    });

    useEffect(() => {
        reset({
            jobTitle: data?.name || "",
            companyName: data?.companyName || "",
            urlToJobDescription: data?.urlToJobDescription || "",
            jobFunction: data?.job_function || "",
            seniority: data?.experience_level || ExperienceLevel.NONE,
            countryAndCity: data?.location || "",
            skills: data?.skills || []
        });
    }, [data]);

    const handleSelectChange = ([event]: any) => event.value ?? "";

    const [{
        isSearchCandidates,
        user,
    }, dispatch]: any = useAppContext();

    const createStandardQueryObject = (searchQueryObject: IJobOpening): ISubscribedQueries | false => {
        const obj: ISubscribedQueries = {
            recruiter_id: user.id,
            name: searchQueryObject.jobTitle,
            skills: searchQueryObject.skills,
            location: searchQueryObject.countryAndCity,
            job_function: searchQueryObject.jobFunction,
            min_salary_per_year: 0,
            companyName: searchQueryObject.companyName,
            urlToJobDescription: searchQueryObject.urlToJobDescription,
            max_salary_per_year: 1000000,
            experience_level: searchQueryObject.seniority,
            notified_candidate_ids: [],
            created_at: firebase.firestore.FieldValue.serverTimestamp()
        };

        return obj;
    };

    const closeModal = (queryData = {}) => {
        dispatch(doToggleCreateJobOpening(false));
        if (clearData) clearData(queryData);
    };

    const onSubmit = async (formData: IJobOpening) => {
        const queryData = createStandardQueryObject(formData);
        setIsLoading(true);
        try {
            if (!isEdit) {
                await subscribedQueriesRef.doc().set({ ...queryData, is_active: "Open" });
            } else {
                await subscribedQueriesRef.doc(data?.objectID).update({ ...queryData, recruiter_id: data?.recruiter_id });
            }
            toast.success(<SuccessMsg text={isEdit ? "Job opening edited" : "Job opening created"} />);
        } catch (err) {
            toast.error(<ErrorMsg text={err.message || "Unknown error"} />);
        } finally {
            setIsLoading(false);
            closeModal({ ...data, ...queryData });
        }
    };

    return (
        <Dialog
          open={isOpen}
          fullWidth
          maxWidth="xs"
          aria-labelledby="create-candidate-dialog"
          classes={{
              paper: classes.dialogPaperRoot
          }}
        >
            <DialogTitle disableTypography id="create-candidate-dialog" classes={{ root: classes.dialogTitleRoot }}>
                <Typography className={classes.dialogTitle}>
                    {" "}
                    { isEdit ? "Edit" : "Create" }
                    {" "}
                    Job Opening
                </Typography>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.itemButton}
                  onClick={closeModal}
                >
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent className={classes.contentRoot}>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Box padding="10px 24px 0px">
                        <div className={classes.jobOpeningTitleText}>
                            As soon as someone creates a candidate that matches your job opening, you will hear about it in Slack.
                            Job openings are not shown to people outside of Acelr8.
                        </div>
                        <CustomInput
                          fullWidth
                          placeholder="Job Title*"
                          inputRef={register}
                          name="jobTitle"
                          className={classes.dialogContentItem}
                        />
                        {errors.jobTitle
                      && (
                          <Box color="error.main">
                              {(errors.jobTitle as any).message}
                          </Box>
                      )}
                        <CustomInput
                          fullWidth
                          placeholder="Company Name*"
                          inputRef={register}
                          name="companyName"
                          className={classes.dialogContentItem}
                        />
                        {errors.companyName
                      && (
                          <Box color="error.main">
                              {(errors.companyName as any).message}
                          </Box>
                      )}
                        <CustomInput
                          fullWidth
                          placeholder="URL to Job Description"
                          inputRef={register}
                          name="urlToJobDescription"
                          className={classes.dialogContentItem}
                        />
                        {errors.urlToJobDescription
                      && (
                          <Box color="error.main">
                              {(errors.urlToJobDescription as any).message}
                          </Box>
                      )}
                        <FormControl
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={classes.dialogContentItem}
                        >
                            <Controller
                              as={(
                                  <SelectMenu
                                    defaultValue={getValues().jobFunction as string}
                                    disabled={false}
                                    items={JobFunctionList}
                                    label="Job Function"
                                  />
                                  )}
                              name="jobFunction"
                              control={control}
                              onChange={handleSelectChange}
                            />
                        </FormControl>

                        <FormControl
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={classes.dialogContentItem}
                        >
                            <Controller
                              as={(
                                  <SelectMenu
                                    disabled={isSearchCandidates}
                                    defaultValue={getValues().seniority as string}
                                    items={experienceLevelList.map(({ name, value }) => ({
                                        title: name,
                                        value
                                    }))}
                                    label="Seniority"
                                  />
                                  )}
                              name="seniority"
                              control={control}
                              onChange={handleSelectChange}
                            />
                        </FormControl>

                        <CustomInput
                          fullWidth
                          placeholder="Country/City"
                          inputRef={register}
                          name="countryAndCity"
                          className={classes.dialogContentItem}
                        />

                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          className={classes.dialogContentItem}
                        >
                            <Controller
                              as={(
                                  <MultiSelectInput
                                    label="Required Skills"
                                    inputItems={getValues().skills}
                                    hasAdSdButton={false}
                                    chipClass={classes.skillChip}
                                  />
                          )}
                              control={control}
                              name="skills"
                              autoComplete="off"
                            />
                        </FormControl>

                    </Box>
                    <Box className={classes.dialogActions}>
                        <SubmitButton
                          className={classes.submitButton}
                          text={isEdit ? isLoading ? "Saving Changes..." : "Save Changes" : "Need more candidates?"}
                          loading={isLoading}
                        />
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};
