import React, { FC } from "react";
import {
    Button, Grid, Typography
} from "@material-ui/core";
import { IEducation } from "compass-shared";
import useAppContext from "../../../../context/store/store";
import { doAddProfileEducationData } from "../../../../context/reducer/Actions";
import EditEducationItem from "./EducationItem/EditEducationItem";
import useStyles from "../style";
import Box from "@material-ui/core/Box";

export type EducationProps = {
    educations: IEducation[];
    disabled: boolean;
}

const EditEducation: FC<EducationProps> = ({ educations, disabled }) => {
    const classes = useStyles();
    const [, dispatch]: any = useAppContext();
    const handleAddEducation = () => {
        dispatch(doAddProfileEducationData({ id: new Date().getTime() }));
    };

    return (
        <Box display="flex" flexDirection="column" mb={1}>
            <Grid style={{ padding: "0 18px" }} item xs={12}>
                <Typography className={classes.title} variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Education
                </Typography>
            </Grid>

            {
              Object.values(educations).length > 0
                  ? (
                      Object.values(educations).map((education: any, index) => {
                          const key = Object.keys(educations)[index];
                          return (
                              <EditEducationItem
                                education={education}
                                key={key}
                                id={key}
                                disabled={disabled}
                              />
                          );
                      })
                  )
                  : ""
          }
            <Button
              variant="outlined"
              onClick={handleAddEducation}
              className={classes.addButton}
            >
              Add Education
            </Button>
        </Box>
    );
};

export default EditEducation;
