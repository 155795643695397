export const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    border: styles.isFocused ? 0 : "1px solid #AEAEB2",
    // This line disable the blue border
    padding: "2px 0px",
    borderRadius: 8,
    boxShadow: styles.isFocused ? 0 : 0,
    "&:hover": {
      border: styles.isFocused ? 0 : "1px solid #363840",
    },
  }),
  option: (styles: any, state: any) => {
    // console.log('DDD', state);
    const { options, data } = state;
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.8)",
      cursor: "pointer",
      padding: "8px",
      fontSize: "0.75rem",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom:
        options[options.length - 1]?.value === data?.value
          ? ""
          : "1px solid #E1DFDF",
      borderRadius: 1,
      fontFamily: "Montserrat",
      backgroundColor: "#ffffff",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#8CB6CE",
      },
      ":active": {
        ...styles[":active"],
        // backgroundColor:
        //     !isDisabled && (isSelected ? data.color : 'purple'),
      },
    };
  },
  menu: (styles: any) => ({
    ...styles,
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    marginTop: "4px",
  }),
  multiValue: (styles: any) => ({
    ...styles,
    color: "#221A16",
    height: "24px",
    margin: "2px",
    fontSize: "12px",
    fontWeight: 600,
    borderRadius: "7px",
    backgroundColor: "#FCC46B",
    display: "flex",
    alignItems: "center",
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: "#221A16",
    fontSize: 12,
    height: 24,
    fontWeight: 600,
  }),
  input: (styles: any) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 500,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: "#FCC46B",
    cursor: "pointer",
    padding: 0,
    marginRight: 5,
    borderRadius: "50%",
    backgroundColor: "#221A16",
    "&:hover": {
      backgroundColor: "#221A16",
      color: "#FCC46B",
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#D8D8D8",
    fontSize: 14,
    fontWeight: 500,
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    textAlign: "left",
    color: "#D8D8D8",
    fontSize: 14,
    fontWeight: 500,
  }),
};
