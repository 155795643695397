import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    centredFlex: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: (props: any) => (
            props.parentComponent === "Create_candidate" ? "flex-start" : "flex-end"
        ),
        paddingRight: 30,
        [theme.breakpoints.down("xs")]: {
            paddingRight: 12
        }
    },
    logsCancelIcon: {
        color: "#245978",
        "&:hover": {
            cursor: "pointer"
        },
        marginTop: 13
    },
    logsCancelIconItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    note: {
        margin: 0,
        fontWeight: 400,
        fontSize: 15,
        color: "#483E3E",
        overflowWrap: "anywhere"
    },
    logDate: {
        textAlign: "right",
        fontSize: 14,
        lineHeight: "13px",
        fontWeight: 400,
        fontFamily: "Montserrat"
    }
}));

export default useStyles;
