import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 220;
const smallerDrawerWidth = 65;

// TODO: Use theme color
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "#ffffff16",
        boxShadow: "unset",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        color: "#fff",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    mainContainerScroll: {
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarColor: "#C4DBDB #E8E8E8",
        scrollbarWidth: "auto",
        "&::-webkit-scrollbar": {
            width: "10px !important",
            [theme.breakpoints.down("xs")]: {
                width: "5px !important",
            },
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#FCC46B",
        },
    },
    mainContainer: {
        maxHeight: "100vh",
        width: "100%",
    },
    navBar: {
        display: "flex",
        height: "inherit",
        flexDirection: "column",
        alignContent: "stretch",
        justifyContent: "space-between",
        paddingTop: theme.spacing(2),
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // padding: "0 8px",
        padding: "0 2px",
        fontSize: theme.spacing(5),
        minHeight: theme.spacing(10),
    },
    drawer1: {
        [theme.breakpoints.up("sm")]: {
            width: (props: any) => (props.isSideBarOpen ? drawerWidth : smallerDrawerWidth),
            flexShrink: 0,
        },
    },
    drawer: {
        maxWidth: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        backgroundColor: "#F2F2F3",
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#FFFBF4",
    },
    drawerOpen: {
        width: drawerWidth,
        backgroundColor: "#FFFBF4",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        backgroundColor: "#FFFBF4",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: (props: any) => (props.isSideBarOpen ? drawerWidth : smallerDrawerWidth),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    sidebarText: {
        marginLeft: 15,
        fontSize: ".78rem",
        fontWeight: 600,
        "& span": {
            fontSize: ".78rem",
            fontWeight: 600,
        },
    },
    sidebarMinimize: {
        cursor: "pointer",
    },
    muiNavList: {
        padding: 8,
        paddingLeft: 10,
    },
    logo: {
        height: 50,
        width: "auto",
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(2),
        backgroundColor: "#F2F2F3",
        // height: "100vh",
    },
    navItemIcon: {
        height: 28,
        width: 28,
        color: "#221A16",
        fontSize: "2.640625rem",
    },
    IconWidth: {
        minWidth: "unset !important",
        backgroundColor: "#FCC46B",
        padding: theme.spacing(1.5),
        borderRadius: theme.spacing(0.5),
        width: 40,
        height: 40,
        "&:hover": {
            backgroundColor: "#ffb237",
            boxShadow: "none",
        },
    },
    NavItem: {
        "&:before": {
            content: "''",
            width: theme.spacing(1),
            height: 40,
            backgroundColor: "#221A16",
            borderRadius: theme.spacing(0.5, 0, 0, 0.5),
            position: "absolute",
            top: 0,
            left: 0,
        },
    },
    reactTooltip: {
        fontSize: 13,
        fontWeight: "bolder",
        borderRadius: "4px !important",
        "&:hover": {
            // backgroundColor: "#221A16",
        },
    },
    customToolTip: {
        minWidth: 135,
        "& .tooltip-arrow": {
            width: "7em",
        },
        borderRadius: "8px !important",
        color: "#fff",
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: "#221A16",
        padding: "8px 11px",
        height: 43,
        "&:hover": {
            backgroundColor: "#221A16",
        },
    },
    drawerText: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
    },
    drawerTextClosed: {
        "&:hover": {
            backgroundColor: "unset",
        },
    },
    toggleDrawer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    toggleDrawerRoot: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    signOut: {
        display: "flex",
        alignItems: "center",
    },
    signOutButton: {
        height: 64,
        width: smallerDrawerWidth,
        backgroundColor: "#FCC46B",
        borderRadius: 0,
        "&:hover": {
            backgroundColor: "#ffb237",
        },
    },
    signoutText: {
        flex: 1,
    },
    openedSidebarLink: {
        textDecoration: "none",
        color: "#000000CC",
        "& .MuiListItem-button": {
            "&:hover": {
                backgroundColor: (props) => (props.isSideBarOpen ? "rgba(0, 0, 0, 0.04)" : "transparent"),
            },
        },
    },
    createdCandidates: {
        position: "absolute",
        bottom: "0rem",
        right: "0rem",
        zIndex: 2,
        // color: '#585454',
        color: "#fff",
        fontSize: 13,
        fontWeight: 600,
        border: "1px solid #585454",
        backgroundColor: "#483E3E",
        padding: "5px 10px",
        borderTopLeftRadius: 7,
        marginRight: 10,
    },
}));

export default useStyles;
