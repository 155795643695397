import { useEffect } from "react";
import { functions } from "../firebase";
import useAppContext from "../context/store/store";
import { doCompleteSearchJobOpenings } from "../context/reducer/Actions";

export const useJobOpening = () => {
    const [{ isSearchJobOpenings, jobOpeningSearchInfo, isUpdateRequest }, dispatch]: any = useAppContext();
    const getJobOpenings = async () => {
        try {
            const filterParams = { ...jobOpeningSearchInfo };
            delete filterParams.isSearchJobOpenings;
            const openingsList: any = await functions
                .httpsCallable("subscribedQueries-searchSubscribedQueries")(
                    {
                        job_function: "", recruiter_id: "", is_active: "", ...filterParams, isUpdateRequest
                    }
                );

            if (openingsList && openingsList.data) {
                dispatch(doCompleteSearchJobOpenings(openingsList.data));
            }
        } catch (error) {
            dispatch(doCompleteSearchJobOpenings([]));
        }
    };

    useEffect(() => {
        if (isSearchJobOpenings) {
            getJobOpenings();
        }
    }, [isSearchJobOpenings]);
};
