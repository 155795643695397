import React, {
    FC, useEffect, useState, useCallback
} from "react";
import { RouteComponentProps } from "@reach/router";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import useAppContext from "../../../context/store/store";
import CandidateCardsList from "../../../Components/Candidates/CandidateCardsList";
import useStyles from "./style";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import { ROUTE_AUTHENTICATED_ROOT } from "../../../routes";
import SearchForm from "../../../Components/SearchForm";
import { IAlgoliaCandidate } from "compass-shared";
import Box from "@material-ui/core/Box";
import { ESearchStatus } from "../../../context/reducer/Interface";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Button, InputBase } from "@material-ui/core";
import { CreateJobOpening } from "./CreateJobOpening";
import { FetchMoreProgress } from "../../../Components/FetchMoreProgress";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { RootState } from "../../../redux/store";
import { fetchCandidates, fetchGroupCandidates } from "../../../redux/services/candidates/candidates.actions";
import { doToggleMainSearchForm } from "../../../context/reducer/Actions";

interface IHome {
    candidateList: IAlgoliaCandidate[];
    searchStatus: ESearchStatus;
}

export const CustomSortSelectInput = withStyles((theme: Theme) => createStyles({
    input: {
        height: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 24,
        position: "relative",
        border: "1.5px solid #D8D8D8",
        fontSize: 10,
        fontWeight: "bold",
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border"]),
        width: 64,
        "&:focus": {
            borderRadius: 8,
            border: "2px solid #D8D8D8",
            transition: theme.transitions.create(["border"]),
        },
    },
}))(InputBase);

const Home: FC<RouteComponentProps & IHome> = React.memo(({
    searchStatus, ...props
}) => {
    const [
        {
            candidatesSearchInfo,
            isSearchFormOpen,
            currentSearchStatus,
            isCreateCandidateFormOpen,
            isMainSearchFormOpen,
            isSearchCandidates
        }, dispatch]: any = useAppContext();
    const candidateLists: any = useSelector((state: RootState) => state.candidates, shallowEqual);
    const isFetchingMore: boolean = useSelector((state: RootState) => state.candidates.isFetchingMore || false);
    const isFilterRequest: boolean = useSelector((state: RootState) => state.candidates.isFilterRequest);
    const searchData: any = useSelector((state: RootState) => state.candidates.searchData, shallowEqual);
    const isCreateCandidateAction: boolean = useSelector((state: RootState) => state.candidates.isCreateCandidate || false);

    const isExtraSmallScreens = useMediaQuery("(max-width:600px)");
    const [listHeight, setListHeight] = useState(window.innerHeight);
    const reduxDispatch = useDispatch();

    const getCandidates = useCallback(async () => {
        await reduxDispatch(fetchCandidates({
            candidatesSearchInfo,
            isFilterRequest: false,
            currentSearchStatus,
        }));
        await reduxDispatch(
            fetchGroupCandidates({
                index: "candidates"
            })
        );
        hitPageOnAnalytics(ROUTE_AUTHENTICATED_ROOT);
    }, []);

    useEffect(() => {
        if (!isCreateCandidateAction) {
            getCandidates();
        }
    }, []);

    useEffect(() => {
        setListHeight(window.innerHeight);
    }, [window.innerHeight]);

    const dataFetch = {
        index: "candidates",
        isFetchingMore: true,
        isFilterRequest: false,
    };

    const searchCandidateData = isFilterRequest ? searchData : dataFetch;

    const handleChange = useCallback(async () => {
        if (!isFetchingMore) {
            await reduxDispatch(fetchCandidates({
                candidatesSearchInfo: {
                    ...searchCandidateData,
                    isFetchingMore: true,
                    isFilterRequest,
                    page: candidateLists?.candidateList?.paginationInfo.currentPage + 1

                },
                currentSearchStatus,
                currentCandidateList: candidateLists?.candidateList?.candidates,
            }));
        }
    }, [candidateLists?.candidateList]);

    const {
        setSentinel,
        isRefining,
        setRefining
    } = useIntersectionObserver(handleChange);

    useEffect(() => {
        if (!isFetchingMore) {
            setRefining(false);
        }
    }, [isFetchingMore]);

    const handleElementUpdate = (e: any) => {
        if (e) {
            setSentinel(e);
        }
    };

    const classes = useStyles({ isSearchFormOpen, searchStatus, listHeight });

    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.between("xs", 960));
    const matches_ = useMediaQuery((theme: Theme) => theme.breakpoints.down(960));

    // eslint-disable-next-line react/destructuring-assignment
    const idNotInPath = props["*"] === "";
    const renderList = () => (
        <div className={classes.listWrapper}>
            <CandidateCardsList
              status={candidateLists?.status}
              isFetchingMore={isRefining}
              candidateList={candidateLists?.candidateList?.candidates}
              handleElementUpdate={handleElementUpdate}
            />

            { isRefining && <FetchMoreProgress /> }
        </div>
    );

    const renderMobileSearchButton = () => (

        <div>
            <Button
              variant="outlined"
              size="small"
              style={{
                  marginTop: "7rem",
                  marginLeft: "12px"
              }}
              onClick={(e: any) => {
                  e.preventDefault();
                  dispatch(doToggleMainSearchForm(!isMainSearchFormOpen));
              }}
              color="secondary"
            >
                 Search
            </Button>

        </div>

    );

    const renderUtil = () => (
        <Grid container spacing={2} style={{ padding: "10px 0" }}>
            <Grid item xs={12}>
                {currentSearchStatus === ESearchStatus.ACTIVE && <SearchForm />}
            </Grid>
        </Grid>
    );

    return (
        <div id="scrollArea" className={classes.homePageWrapper}>
            <CreateJobOpening isOpen={isCreateCandidateFormOpen} />
            {isExtraSmallScreens && renderMobileSearchButton()}
            {idNotInPath ? renderUtil() : (!idNotInPath && matches) ? null : renderUtil()}
            {!idNotInPath && matches_ && <Box mt={6} />}
            {idNotInPath ? renderList() : (!idNotInPath && matches) ? null : renderList()}
        </div>
    );
});

export default Home;
