import { createAsyncThunk } from "@reduxjs/toolkit";

import { functions } from "../../../firebase";

export const fetchJobs = createAsyncThunk('jobs', async(payload: any) => {
    try {
        const { jobOpeningSearchInfo, job_function = "", recruiter_id = "", is_active = "", } = payload;
        // isUpdateRequest
        const jobList: any = await functions
            .httpsCallable("subscribedQueries-searchSubscribedQueries")(
                {
                    job_function, recruiter_id, is_active, ...jobOpeningSearchInfo
                }
            );
        return jobList;
    } catch (error) {
        return error;
    }
});