import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    button: {
        cursor: "pointer",
        padding: "8px",
        textAlign: "start",
        background: "#FFF",
        border: "none",
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "0.75rem",
        fontFamily: "Montserrat",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // borderRadius: "4px",
        "&:hover": {
            // backgroundColor: "rgba(255,255,255,.8)",
            backgroundColor: "#8CB6CE",
            boxShadow: "none"
        },
    },
    autoCompleteContainer: {
        fontWeight: "normal",
        borderBottom: "1px solid #E1DFDF",
        display: "flex",
        flexDirection: "column",
        "&:last-child": {
            borderBottom: "none",
        },
    },
    container: {
        position: "absolute",
        background: "#FFF",
        width: "100%",
        zIndex: 99,
        marginTop: "4px",
        borderRadius: "4px",
        boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    },
}));
