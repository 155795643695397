// Un authenticated pages
export const ROUTE_UNAUTHENTICATED_LOGIN = "/";
export const ROUTE_UNAUTHENTICATED_REGISTER = "/sign-up";
export const ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD = "/forgot-password";

// Authenticated pages
export const ROUTE_AUTHENTICATED_ROOT = "/";
export const ROUTE_AUTHENTICATED_SETTINGS = "/settings";
export const ROUTE_AUTHENTICATED_FAQ = "/faq-onboarding";
export const ROUTE_AUTHENTICATED_CANDIDATE_LIST = "/search-candidates/";
export const ROUTE_AUTHENTICATED_SEARCH = "/search-candidates/";
export const ROUTE_AUTHENTICATED_CREATE_CANDIDATE = "/create";
export const ROUTE_AUTHENTICATED_DASHBOARD = "/dashboard";
export const ROUTE_AUTHENTICATED_JOB_OPENINGS = "/job-openings";
export const ROUTE_AUTHENTICATED_ID = "/:id";
export const ROUTE_AUTHENTICATED_EDIT = "/edit";
export const ROUTE_EDIT_CANDIDATE = `${ROUTE_AUTHENTICATED_CANDIDATE_LIST}${ROUTE_AUTHENTICATED_ID}${ROUTE_AUTHENTICATED_EDIT}`;
// export const ROUTE_SEND_INTRO = '/send-intro/:recruiterId/:candidateId';
export const ROUTE_SEND_INTRO = "/send-intro";

// Public pages
export const ROUTE_PUBLiC_TEST = "test";
export const ROUTE_PUBLiC_TEST_CLASS_COMPONENT = "test-class-component";
