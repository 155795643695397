import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    parent: {
        display: "flex",
        height: "100vh",
        paddingTop: 41,
        // alignItems: 'center',
        justifyContent: "center"
    },
    cardContainer: {
        width: "71.7em",
        height: "49.7em",
        borderRadius: 10,
        border: 0,
        boxSizing: "border-box",
        boxShadow: "0px 0px 17px 2px rgba(0,0,0,0.06)"
    },
    cardHeaderRoot: {
        backgroundColor: "#2D5D77",
        padding: "12px 0px 7px 20px"
    },
    cardHeaderTitle: {
        fontSize: 12,
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: "15px",
        color: "#ffffff",
        textTransform: "uppercase"
    },
    cardContent: {
        padding: "30px 20px 11px 20px !important"
    },
    cardContentHeader: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
    },
    cardToText: {
        color: "#245978",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "17px",
        padding: "5px 0px"
    },
    cardEmailText: {
        fontSize: 13,
        color: "#AEAEB2",
        lineHeight: "16px",
        marginLeft: 10
    },
    imageSRC: {
        borderRadius: "10px",
        height: theme.spacing(18.5),
        width: theme.spacing(18.5)
    },
    personIcon: {
        fontSize: "40px"
    },
    imageContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    emailBodyTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 5
    },
    avatartTextItem: {
        width: 74,
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: 10,
        lineHeight: "12px",
        color: "#000000"
    },
    contentEditable: {
        border: "1px solid #D8D9DF",
        boxSizing: "border-box",
        borderRadius: "7px",
        padding: 10,
        fontFamily: "Montserrat",
        fontSize: 12,
        lineHeight: "18px",
        color: "#245978",
        marginTop: 20,
        minHeight: 272
    },
    sendIntro: {
        backgroundColor: "#363840",
        marginTop: "11px",
        width: "100%",
        height: 43,
        borderRadius: 7,
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 15,
        lineHeight: "18px",
        textAlign: "center",
        color: "#ffffff",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none"
        }
    }
}));

export default useStyles;
