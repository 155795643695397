import React, { FC } from "react";
import { RouteComponentProps } from "@reach/router";

import useAppContext from "../../../context/store/store";

const TestPublic: FC<RouteComponentProps> = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [store, dispatch]: any = useAppContext();

    return (
        <>
            <h1>Public page </h1>
        </>
    );
};

export default TestPublic;
