import React, { FC } from "react";
import { ERelocationStatus } from "compass-shared";
import { Tooltip, Box } from "@material-ui/core";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@material-ui/icons/AirplanemodeInactive";
import { IRelocationProps } from "./Interface";

const RelocationStatusIcon: FC<IRelocationProps> = ({ status }) => {
    const getStatus = () => {
        switch (status) {
            case ERelocationStatus.INTERESTED:
                return (
                    <Tooltip title="Interested">
                        <AirplanemodeActiveIcon fontSize="small" color="primary" />
                    </Tooltip>
                );
            case ERelocationStatus.INTERESTED_NEED_VISA:
                return (
                    <Tooltip title="Interested, need Visa">
                        <AirplanemodeActiveIcon fontSize="small" color="primary" />
                    </Tooltip>
                );
            case ERelocationStatus.NOT_INTERESTED:
                return (
                    <Tooltip title="Not interested">
                        <AirplanemodeInactiveIcon fontSize="small" color="secondary" />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    return (
        <Box marginX={1} display="flex">
            {getStatus()}
        </Box>
    );
};

export default RelocationStatusIcon;
