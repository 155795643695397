import { makeStyles } from "@material-ui/core/styles";
import { ESearchStatus } from "../../../context/reducer/Interface";

const innerHeightWithCloseFrom = 56.5;
const innerHeightWithOpenFrom = 104.5;

const useStyles = makeStyles((theme) => ({
    listWrapperParent: {
        flexGrow: 1,
        overflow: "hidden",
    },
    listWrapper: {
        height: (props: any) => `calc(100vh - ${
            !props.isSearchFormOpen
                ? innerHeightWithCloseFrom
                : innerHeightWithOpenFrom
        }px)`,
        [theme.breakpoints.down("sm")]: {
            height: (props: any) => `calc(100vh - ${props.isSearchFormOpen ? 40 : 68}px)`,
        },
    },
    paginationWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "75%",
        margin: "12px auto 12px auto",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    homePageWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            overflowX: "hidden",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: (props: any) => (props.searchStatus === ESearchStatus.ARCHIVED ? theme.spacing(6) : 0),
        },
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarColor: "#C4DBDB #E8E8E8",
        scrollbarWidth: "auto",
        "&::-webkit-scrollbar": {
            width: "10px !important",
            [theme.breakpoints.down("xs")]: {
                width: "5px !important",
            },
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#FCC46B",
        },
    },
    navContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
    },
    sortContainer: {
        marginRight: "12px !important",
        borderRadius: "12px",
    },
    flexHorizontal: {
        display: "flex",
        alignItems: "center",
    },
    refreshIcon: {
        fontSize: 30,
        backgroundColor: "#FFF",
        borderRadius: 4,
        fontWeight: 200,
        padding: 4,
    },
    selectMenuList: {
        padding: 0,
    },
    selectMenuPaper: {
        marginTop: 4,
        borderRadius: 8,
        border: "1px solid #D8D8D8",
    },
    selectIcon: {
        top: "calc(50% - 8px)",
        marginRight: 4,
    },
    sortText: {
        fontSize: 10,
        fontWeight: "bold",
        marginRight: 12,
    },
    itemButton: {
        minWidth: 25,
        height: 25,
        backgroundColor: "#FF6A66",
        color: "#FFFFFF",
        textTransform: "none",
        padding: 4,
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none",
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    dialogPaperRoot: {
        borderRadius: 8,
        maxWidth: 300,
    },
    dialogTitleRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 12,
        backgroundColor: "#245978",
    },
    dialogTitle: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#FFFFFF",
    },
    dialogContentItem: {
        marginBottom: 8,
    },
    submitButton: {
        height: 40,
        color: "#FFFFFF",
        fontSize: 10,
        fontWeight: "bold",
        backgroundColor: "#31B33D",
    },
    dialogActions: {
        borderTop: "1px solid #E7E8EC",
        padding: 12,
    },
    skillChip: {
        height: 25,
        margin: "0px 4px 4px 0px",
        backgroundColor: "#5EAAA8",
        fontSize: 10,
        fontWeight: "bold",
        color: "#FFFFFF",
    },
}));

export default useStyles;
