import {
    ActionType, ESearchStatus, IAction, IDirectContact, IFieldErrors
} from "./Interface";
import { IAlgoliaCandidate, ICandidateSearchObject, IRecruiter } from "compass-shared";
import { firebase } from "../../firebase";
import { IJobOpenings } from "../../../../compass-shared/src/types/CandidateInAlgolia";

const { Timestamp } = firebase.firestore;

const initialState = {
    user: {} as IRecruiter,
    theme: "default",
    isSearchCandidates: false,
    candidatesSearchInfo: {
        index: "candidates"
    } as ICandidateSearchObject,
    isSearchCandidateProfile: false,
    isSearchJobOpenings: false,
    isFilterRequest: false,
    profileSearchInfo: {},
    paginationInfo: {},
    isOnCreateEmptyProfile: false,
    isMainSearchFormOpen: false,
    jobOpenings: null,
    editProfile: {
        loading: false,
        experiences: {} as any,
        skills: "" as any,
        connected_link: [] as any,
        educations: {} as any,
    },
    isUpdateRequest: false,
    isUpdateCandidateRequest: false,
    candidates: [],
    jobOpeningSearchInfo: {} as IJobOpenings,
    recruiterInTouch: {},
    isDirectContactOpen: {
        candidate: {} as any,
        isOpen: false as boolean
    } as IDirectContact,
    editProfileErrors: {},
    isSearchFormOpen: false,
    isCreateJobOpeningOpen: false,
    isRequestIntroOpen: false,
    currentSearchStatus: ESearchStatus.ACTIVE,
    isFetchingMore: false,
    searchGroupData: null,
    navBarOpen: false
};

const appReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ActionType.SET_USER:
            return { ...state, user: action.payload };

        case ActionType.SET_NAVBAR_STATUS:
            return { ...state, navBarOpen: action.payload };

        case ActionType.START_SEARCH_CANDIDATES:
            return {
                ...state,
                isFilterRequest: action.payload.isFilterRequest,
                isSearchCandidates: action.payload.isSearchCandidates,
                isFetchingMore: action.payload.isFetchingMore,
                candidatesSearchInfo: action.payload.candidatesSearchInfo
            };

        case ActionType.START_REQUERY_CANDIDATES:
            return {
                ...state,
                isSearchCandidates: false,
                isSearchJobOpenings: false
            };

        case ActionType.START_SEARCH_JOB_OPENINGS:
            return {
                ...state,
                isSearchJobOpenings: true,
                isUpdateRequest: action.payload.isUpdateRequest,
                jobOpeningSearchInfo: {
                    ...action.payload
                }
            };

        case ActionType.COMPLETE_SEARCH_JOB_OPENINGS:
            return {
                ...state,
                isSearchJobOpenings: false,
                isUpdateRequest: false,
                jobOpeningSearchInfo: {} as IJobOpenings,
                jobOpenings: action.payload.jobOpenings
            };

        case ActionType.COMPLETE_SEARCH_CANDIDATES:
            return {
                ...state,
                isSearchCandidates: action.payload.isSearchCandidates,
                paginationInfo: action.payload.paginationInfo,
                candidates: action.payload.candidates,
                isFetchingMore: false
            };

        case ActionType.COMPLETE_SEARCH_CANDIDATES_GROUP:
            return {
                ...state,
                searchGroupData: action.payload
            };

        case ActionType.UPDATE_PROFILE:
            const skills = action.payload.candidatesInfo.data.skills
                ? action.payload.candidatesInfo.data.skills.map((skill: any) => skill.name)
                : [];
            const candidates: Array<any> = [...state.candidates];
            candidates.forEach((candidate : IAlgoliaCandidate, index: number) => {
                if (candidate.objectID === action.payload.currentCandidateId) {
                    candidates[index] = {
                        ...candidate,
                        ...action.payload.candidatesInfo.data,
                        skills,
                        top_skills: skills
                    };
                }
            });

            return {
                ...state,
                isUpdateCandidateRequest: true,
                candidates
            };
        case ActionType.START_SEARCH_PROFILE:
            return {
                ...state,
                isSearchCandidateProfile:
                    action.payload.isSearchCandidateProfile,
                profileSearchInfo: action.payload.profileSearchInfo
            };

        case ActionType.COMPLETE_SEARCH_PROFILE:
            return {
                ...state,
                isSearchCandidateProfile:
                    action.payload.isSearchCandidateProfile,
                profileSearchInfo: action.payload.profileSearchInfo
            };

        case ActionType.RESET_SEARCH_DATA:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.CHANGE_CREATE_EMPTY_PROFILE_MODE:
            return {
                ...state,
                isOnCreateEmptyProfile: action.payload.isOnCreateEmptyProfile
            };

        case ActionType.RESET_PROFILE_DATA:
            return {
                ...state,
                editProfile: action.payload
            };

        case ActionType.SET_PROFILE_DATA:
            if (action.payload.key === "skills" && action.payload.changeType === "modify") {
                let skills = [];
                if (Array.isArray(action.payload.value)) {
                    skills = [
                        ...state.editProfile.skills.filter((skillItem: any) => (
                            skillItem.is_top_skill !== action.payload.value[0].is_top_skill
                        )),
                        ...action.payload.value
                    ];
                } else {
                    skills = [...state.editProfile.skills];
                    skills.push(action.payload.value);
                }
                return {
                    ...state,
                    editProfile: {
                        ...state.editProfile,
                        [action.payload.key]: skills
                    }
                };
            }
            if (action.payload.key === "connected_link" && !Array.isArray(action.payload.value)) {
                const connectedLinks = [...state.editProfile.connected_link];
                connectedLinks.push(action.payload.value);
                return {
                    ...state,
                    editProfile: {
                        ...state.editProfile,
                        [action.payload.key]: connectedLinks
                    }
                };
            }
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    [action.payload.key]: action.payload.value
                }
            };

        case ActionType.RESET_EDIT_PROFILE_DATA:
            return {
                ...state,
                editProfile: {
                    loading: false,
                    experiences: {},
                    skills: "",
                    connected_link: [],
                    educations: {},
                }
            };

        case ActionType.SET_PROFILE_DATA_EXPERIENCE:
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    experiences: {
                        ...state.editProfile.experiences,
                        [action.payload.id]: {
                            ...state.editProfile.experiences[action.payload.id],
                            [action.payload.key]: action.payload.value
                        }
                    }
                }
            };

        case ActionType.SET_PROFILE_DATA_EDUCATION:
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    educations: {
                        ...state.editProfile.educations,
                        [action.payload.id]: {
                            ...state.editProfile.educations[action.payload.id],
                            [action.payload.key]: action.payload.value
                        }
                    }
                }
            };

        case ActionType.SET_PROFILE_DATA_ADD_EXPERIENCE:
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    experiences: {
                        ...state.editProfile.experiences,
                        [action.payload.id]: {
                            company_name: "",
                            end_at: "present",
                            start_at: Timestamp?.fromDate(new Date()),
                            title: ""
                        }
                    }
                }
            };

        case ActionType.SET_PROFILE_DATA_ADD_EDUCATION:
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    educations: {
                        ...state.editProfile.educations,
                        [action.payload.id]: {
                            degree_title: "",
                            end_at: "present",
                            start_at: Timestamp?.fromDate(new Date()),
                            institution_name: ""
                        }
                    }
                }
            };

        case ActionType.COMPLETE_EDIT_PROFILE: {
            return {
                ...state,
                isUpdateCandidateRequest: false,
                isSearchCandidates: false,
                paginationInfo: action.payload || state.paginationInfo
            };
        }

        case ActionType.SET_EDIT_PROFILE_ERRORS:
            let errorFields = {};
            action.payload.forEach((errorField: IFieldErrors) => {
                errorFields = {
                    ...errorFields,
                    [errorField.field]: errorField
                };
            });

            return {
                ...state,
                editProfileErrors: {
                    ...state.editProfileErrors,
                    ...errorFields
                }
            };

        case ActionType.CLEAR_EDIT_PROFILE_ERRORS:
            const field = action.payload.field || "";
            const editProfileErrors = { ...state.editProfileErrors };
            delete editProfileErrors[field];
            return {
                ...state,
                editProfileErrors: {
                    ...editProfileErrors
                }
            };

        case ActionType.REMOVE_EXPERIENCE:
            // eslint-disable-next-line no-case-declarations
            const _experiences = { ...state.editProfile.experiences };
            delete _experiences[action.payload];
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    experiences: {
                        ..._experiences,
                    }
                }
            };

        case ActionType.REMOVE_EDUCATION:
            // eslint-disable-next-line no-case-declarations
            const _educations = { ...state.editProfile.educations };
            delete _educations[action.payload];
            return {
                ...state,
                editProfile: {
                    ...state.editProfile,
                    educations: {
                        ..._educations,
                    }
                }
            };

        case ActionType.TOGGLE_SEARCH_FORM: {
            const isOpen = state.isSearchFormOpen;
            return { ...state, isSearchFormOpen: !isOpen };
        }

        case ActionType.TOGGLE_MAIN_SEARCH_FORM: {
            return { ...state, isMainSearchFormOpen: action.payload };
        }

        case ActionType.CHANGE_SEARCH_STATUS: {
            return { ...state, currentSearchStatus: action.payload };
        }

        case ActionType.TOGGLE_CREATE_JOB_OPENING: {
            return { ...state, isCreateCandidateFormOpen: action.payload };
        }

        case ActionType.TOGGLE_REQUEST_INTRO: {
            return { ...state, isRequestIntroOpen: action.payload };
        }

        case ActionType.SET_RECRUITER_IN_TOUCH: {
            return { ...state, recruiterInTouch: action.payload };
        }

        case ActionType.TOGGLE_DIRECT_CONTACT: {
            return { ...state, isDirectContactOpen: action.payload };
        }

        default:
            return state;
    }
};

export { appReducer, initialState };

