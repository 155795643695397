import React, {
    FC, useState, useEffect, useCallback
} from "react";
import { RouteComponentProps } from "@reach/router";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import Button from "@material-ui/core/Button";
import useAppContext from "../../../context/store/store";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import { firebase } from "../../../firebase";
import { IUploadAvatarRes } from "../../../Utils/Interface";
import { recruitersRef } from "../../../FirebaseUtils/collectionRefs";
import { Settingschema } from "../../../Utils/FormValidationSchema";
import {
    ErrorMsg,
    SuccessMsg,
    IRecruiter,
    UploadButton,
    UserType,
    SubmitButton,
    LabledInput,
    SettingsButton
} from "compass-shared";
import { toast } from "react-toastify";
import { ROUTE_AUTHENTICATED_SETTINGS } from "../../../routes";
import { uploadAvatar } from "../../../Utils/UploadAvatar";
import useStyles from "./style";
import Slack from "../../../Assets/icons/slack.svg";
import { Box } from "@material-ui/core";

const Setting: FC<RouteComponentProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [originalImage, setOriginalImage] = useState("" as any);
    const [thumbnail, setThumbnail] = useState("");
    const [currentTime, setCurrentTime] = useState("" as string);
    const [store]: any = useAppContext();
    const user = store.user as IRecruiter;
    const classes = useStyles();
    const matches = useMediaQuery("(min-height:800px)");

    useEffect(() => {
        hitPageOnAnalytics(ROUTE_AUTHENTICATED_SETTINGS);
    }, []);

    const {
        register, errors, handleSubmit, formState
    } = useForm({
        validationSchema: Settingschema,
        defaultValues: user,
        mode: "onChange"
    });

    const onFormSubmit = (data: any) => {
        const {
            email, name, slack_id, calendly_link
        } = data;

        onSubmit({
            thumbnail, originalImage, name, email, slack_id, calendly_link
        });
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        const {
            originalImage, thumbnail, name, email, slack_id, calendly_link
        } = data;
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const profileData: IRecruiter = {
            // ...user,
            email,
            firstName: name?.split(" ")[0],
            lastName: name?.split(" ")[1] || "",
            slack_id,
            is_active: user.is_active,
            updated_at: timestamp,
            calendly_link
        };

        if (thumbnail) profileData.thumbnail_avatar_url = thumbnail;
        if (originalImage) profileData.original_avatar_url = originalImage;
        // // TODO: write cloud function for creating log
        // if (clientName !== user.last_client_name) {
        //     profileData.client_name_logs = [...user.client_name_logs || [],
        //         {
        //             created_at: firebase.firestore.Timestamp.now(),
        //             name: data.clientName
        //         }
        //     ];
        // }

        updateRecruiterDoc(profileData);
    };

    const updateRecruiterDoc = (profileData: any, successMsg = "Profile updated") => {
        recruitersRef
            .doc(user.id)
            .set(profileData, { merge: true })
            .then(() => {
                setLoading(false);
                toast.success(<SuccessMsg text={successMsg} />, {
                    position: "bottom-right"
                });
            // dispatch(doSetUser(profileData));
            })
            .catch(() => {
                setLoading(false);
                toast.error(<ErrorMsg />);
            });
    };

    const handleFileSelect = (event: any) => {
        event.preventDefault();
        event.persist();
        const file = event.target.files[0];
        if (!file) { return; }
        handleFileSubmit({
            file,
            url: URL && URL.createObjectURL(file)
        });
    };

    const handleFileSubmit = ({ file }: any) => {
        setOriginalImage("");
        setImageLoading(true);

        uploadAvatar(user.id, UserType.Recruiter, file, false)
            .then(async (res: IUploadAvatarRes) => {
                if (res.original_avatar_url && res.thumbnail_avatar_url) {
                    if (originalImage) {
                        setOriginalImage("");
                    }

                    setImageLoading(false);
                    setOriginalImage(res.original_avatar_url);
                    setThumbnail(res.thumbnail_avatar_url);
                    const data = {
                        thumbnail_avatar_url: res.thumbnail_avatar_url,
                        original_avatar_url: res.original_avatar_url
                    };
                    const successMsg = "Profile picture uploaded";
                    await updateRecruiterDoc(data, successMsg);
                    // toast.success(<SuccessMsg text="Profile picture uploaded successfully" />);
                } else {
                    toast.error(<ErrorMsg />);
                }
            })
            .catch((err: IUploadAvatarRes) => {
                setImageLoading(false);

                if (originalImage) {
                    setOriginalImage("");
                }

                toast.error(<ErrorMsg text={err.message} />);
            });
    };

    const _time = new Date().getHours();
    const greetingText = useCallback(() => {
        if (_time >= 6 && _time < 12) {
            setCurrentTime("Good Morning,");
        } else if (_time >= 12 && _time < 16) {
            setCurrentTime("Good Afternoon,");
        } else {
            setCurrentTime("Good Evening,");
        }
    }, [_time]);

    useEffect(() => {
        greetingText();
    }, [greetingText]);

    return (
        <>
            <div className={matches ? classes.parentXLHeight : classes.parent}>
                <div className={classes.container}>
                    <div className={classes.layout}>
                        <div className={classes.header}>
                            <div className={classes.headerSalutation}>
                                <div className={classes.salutation}>
                                    <div className={classes.salutationTitle}>
                                        {currentTime}
                                        {" "}
                                        <label style={{ color: "#00D0FF" }}>
                                            {store?.user?.firstName}
                                        </label>
                                        !
                                        {" "}
👋
                                    </div>
                                    <div className={classes.salutationBody}>
                                        It’s a great day to place some candidates.
                                    </div>
                                </div>
                            </div>
                            <div className={classes.sideText}>
                                "All of our customers are partners in your mission."
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.formContainer}>
                                <div className={classes.title}>Your Info</div>
                                <div className={classes.formAvatarContainer}>
                                    <label htmlFor="icon-button-file">
                                        <Button
                                          onChange={handleFileSelect}
                                          component="label"
                                          className={classes.profileIconButton}
                                          classes={{
                                              root: classes.profileButtonRoot
                                          }}
                                          disableRipple
                                        >
                                            <Avatar
                                              alt={`${store?.user?.firstName} ${store?.user?.lastName}`}
                                              src={imageLoading ? "" : store?.user?.thumbnail_avatar_url}
                                              className={classes.imageSRC}
                                            >
                                                {imageLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <PersonRoundedIcon className={classes.personIcon} />
                                                )}
                                            </Avatar>
                                            <input
                                              accept="image/x-png,image/gif,image/jpeg"
                                              style={{ display: "none" }}
                                              id="icon-button-file"
                                              type="file"
                                            />
                                        </Button>
                                    </label>
                                    <UploadButton
                                      className={classes.changeProfileAvatar}
                                      loading={imageLoading}
                                      text="Change Avatar"
                                      onSelect={handleFileSubmit}
                                    />
                                </div>
                                <form
                                  onSubmit={handleSubmit(onFormSubmit)}
                                    // className={classes.form}
                                >
                                    <div className={classes.form}>
                                        <div>
                                            <LabledInput
                                              inputRef={register}
                                              errors={errors.name}
                                              id="name"
                                              label="Your Name"
                                              required
                                              value={`${store?.user?.firstName} ${store?.user?.lastName}`}
                                              className={classes.textInputStyles}
                                            />
                                            {errors.name
                                            && (
                                                <Box marginBottom="10px" marginTop="-18px" color="error.main">
                                                    {(errors.name as any).message}
                                                </Box>
                                            )}
                                        </div>
                                        <div>
                                            <LabledInput
                                              inputRef={register}
                                              errors={errors.email}
                                              id="email"
                                              label="Work Email"
                                              required
                                              value={store?.user?.email}
                                              className={classes.textInputStyles}
                                            />
                                            {errors.email
                                            && (
                                                <Box marginBottom="10px" marginTop="-18px" color="error.main">
                                                    {(errors.email as any).message}
                                                </Box>
                                            )}
                                        </div>
                                        <div className={classes.inputContainer}>
                                            <LabledInput
                                              inputRef={register}
                                              errors={errors.calendly_link}
                                              id="calendly_link"
                                              label="Calendly Link"
                                              value={user?.calendly_link || ""}
                                              className={classes.textInputStyles}
                                            />
                                            {errors.calendly_link
                                            && (
                                                <Box marginBottom="10px" marginTop="-18px" color="error.main">
                                                    {(errors.calendly_link as any).message}
                                                </Box>
                                            )}
                                        </div>
                                        <div>
                                            <LabledInput
                                              inputRef={register}
                                              errors={errors.slack_id}
                                              id="slack_id"
                                              label="Slack Member ID"
                                              hasIcon
                                              IconURL={Slack}
                                              value={store?.user?.slack_id}
                                              className={classes.textInputStyles}
                                            />
                                            {errors.slack_id
                                                && (
                                                    <Box marginBottom="10px" marginTop="-18px" color="error.main">
                                                        {(errors.slack_id as any).message}
                                                    </Box>
                                                )}
                                        </div>
                                        <div className={`${classes.text} ${classes.formText}`}>
                                            Without having your Slack Member ID, we cannot send you automated messages in Slack. Your Slack Member ID looks something like "U016YP92ZUH" (and not e.g. "@John").
                                            Boris can help you find your Slack Member ID.
                                        </div>
                                    </div>
                                    <div className={classes.submitButtonContainer}>
                                        <SubmitButton
                                          className={`${classes.changeProfileAvatar} ${classes.submitButton}`}
                                          loading={loading}
                                          disabled={
                                                // (Object.keys(errors).length > 0
                                                (
                                                    imageLoading
                                                    || loading
                                                )
                                            }
                                          text={user ? "Update Profile" : "Save and Continue"}
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className={classes.compassInfo}>
                                <div className={classes.infoContainer}>
                                    <div className={`${classes.title} ${classes.infoTitle}`}>What candidates to add?</div>
                                    <div className={classes.cardContent}>
                                        <p style={{ marginTop: 0, lineHeight: "21px" }}>
                                            Add every candidate that you want to recommend to the other recruiters!

                                            As long as you would recommend them - add them.
                                        </p>
                                        <p> Maybe you have interviewed them, but didn't hire them. </p>
                                        <p>  Maybe you know them from your previous job. Maybe they are your neighbour (but you know they are good). </p>
                                        <Box display="flex">
                                            <p style={{
                                                fontWeight: 600, fontSize: "14px", lineHeight: "17px", marginBottom: 15, marginRight: 4
                                            }}
                                            >
                                            P.S. Install the Chrome extension for 1-click candidate creation.
                                                {" "}

                                            </p>
                                            <SettingsButton
                                              className={classes.settingsButton}
                                              target="_blank"
                                              rel="noopener
                                            noreferrer"
                                              href="https://chrome.google.com/webstore/detail/compass-widget/leaphahkakdjhhagfjmbbfennmpcmlhc"
                                            >
                                            Install
                                            </SettingsButton>
                                        </Box>
                                    </div>
                                </div>

                                <div style={{ marginTop: "30px" }} className={classes.infoContainer}>
                                    <div className={`${classes.title} ${classes.infoTitle}`}>Compass and GDPR</div>
                                    <div className={classes.cardContent}>
                                        <p style={{ marginTop: 0 }}>
                                            In order to be GDPR compliant, please make sure to receive consent from your candidates before adding them to Compass,
                                        </p>
                                        <p>
                                            A valid consent is a consent where the person agrees his/her data to be used for the given purpose. You do not need to explain Compass to them, just the purpose of the data processing (e.g. "to help you land your next role"). Note that the consent must be actively given (no reply to the consent question is not a valid consent).
                                        </p>
                                        <p style={{ fontWeight: 600 }}>
                                            You can use this template:
                                        </p>
                                        <p>
                                            "I’d be happy to help you land your next role. Should I share your profile with my recruiter network?"
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Setting;
