import React, {
    FC, useEffect, useState, useMemo, useRef, useCallback
} from "react";
import { useForm, Controller } from "react-hook-form";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import {
    Box,
    Grid,
    FormControl,
    Typography,
    Chip
} from "@material-ui/core";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Backdrop from "@material-ui/core/Backdrop";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./style";
import useAppContext from "../../../../context/store/store";
import {
    doStartSearchProfile,
    doChangeCreateEmptyProfileMode,
    doSetProfileData
} from "../../../../context/reducer/Actions";
import {
    EmailField,
    SubmitButton,
    ErrorMsg,
    SuccessMsg,
    UploadButton,
    PeepsAvatarPicker,
    experienceLevelList,
    emptyCandidate,
    ICandidate,
    ECreationType,
    ISkill,
    JobFunctionList,
    VettingFields, CustomInput,
    SelectMenu,
    UserType,
    IExperience,
    IEducation,
    IRecruiterLog,
    ERecruiterLogTypes
} from "compass-shared";
import { CreateProfileSchema } from "../../../../Utils/FormValidationSchema";
import { firebase, functions } from "../../../../firebase";
import { candidatesRef, recruiterLogsRef, recruitersRef } from "../../../../FirebaseUtils/collectionRefs";
import { ISignUpResp, ISignUpData } from "../../../../Pages/UnAuthenticated/SignUp/Interface";
import { uploadCV } from "../../../../Utils/UploadCV";
import { IUploadAvatarRes, IUploadCVRes } from "../../../../Utils/Interface";
import { ICandidateNote, ICreateCandidateFormProps } from "../interface";
import { uploadAvatar } from "../../../../Utils/UploadAvatar";
import countryList from "react-select-country-list";
import { ROUTE_AUTHENTICATED_SEARCH } from "../../../../routes";
import EditExperience from "../../CandidateProfile/Experience/EditExperience";
import EditEducation from "../../CandidateProfile/Education/EditEducation";
import { validateFields, ValidateFields_ } from "../../../../Utils/ValidateFields";
import LogTimeLine from "../../CandidateProfile/LogTimeLine/LogTimeLine";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createCandidate, getJobSkills } from "../../../../redux/services/candidates/candidates.actions";
import { RootState } from "../../../../redux/store";
import { selectStyles } from "./utils";

const CreateCandidateForm: FC<ICreateCandidateFormProps> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const aspirationText = useRef("");
    const jobFunctionRef: any = useRef();
    const countryRef: any = useRef();
    const seniorityRef: any = useRef();
    const careerAspRef: any = useRef();
    const selectSkillsRef: any = useRef();

    const [peepAvatarUrl, setPeepAvatarUrl]: any = useState("");
    const [CVFile, setCVFile]: any = useState(null);
    const [submitButtonText, setSubmitButtonText] = useState("");
    const [mainSkills, setMainSkills] = useState([] as ISkill[]);
    const [searchText, setSearchText] = useState<string>("");
    const [imageLoading, setImageLoading] = useState(false);
    const [originalImage, setOriginalImage] = useState("" as any);
    const [thumbnail, setThumbnail] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const [addingComment, setAddingComment] = useState(false);
    const [autoCandidateID, setCandidateID] = useState("" as string);
    const [selectedJobFunction, setSelectedJobFunction] = useState("" as string);
    const [selectedComment, setSelectedComment] = useState<string | null>(null);
    const [deletingComment, setDeletingComment] = useState(false);
    const [skillsOptions, setSkillsOptions] = useState([]);
    const [stateSkills, setStateSkills] = useState([]);

    const options = useMemo(() => countryList().getData(), []);

    const [{
        user,
        editProfile: {
            experiences,
            educations
        },
    }, dispatch]: any = useAppContext();

    const reduxDispatch = useDispatch();
    const candidateLists: any = useSelector((state: RootState) => state.candidates, shallowEqual);

    const tempCandidate = localStorage.getItem("tempCandidateData") as string;

    useEffect(() => {
        if (JSON.parse(tempCandidate)?.autoCandidateID) {
            setCandidateID(JSON.parse(tempCandidate).autoCandidateID);
        }
    }, [tempCandidate]);

    useEffect(() => {
        const candidateId: string = candidatesRef.doc().id as string;
        const tempCandidateData = {
            autoCandidateID: candidateId,
            isCandidateSubmitted: false
        } as any;
        if (JSON.parse(tempCandidate)?.isCandidateSubmitted || !JSON.parse(tempCandidate)?.autoCandidateID) {
            localStorage.setItem("tempCandidateData", JSON.stringify(tempCandidateData));
        }
        dispatch(doSetProfileData({ key: "experiences", value: {}}));
        dispatch(doSetProfileData({ key: "educations", value: {}}));
    }, []);

    const {
        register, handleSubmit, errors, control, setValue, clearError, triggerValidation, setError, getValues
    } = useForm({
        validationSchema: CreateProfileSchema
    });

    const classes = useStyles({ errors });

    useEffect(() => {
        register("skills");
        setValue("skills", stateSkills);
        if (stateSkills.length && (stateSkills.length >= 3 && stateSkills.length <= 10)) {
            clearError("skills");
        }
        if (stateSkills.length) {
            triggerValidation("skills");
        }
    }, [stateSkills]);

    const uploadCVFunction = async (candidateId: string): Promise<IUploadCVRes> => {
        if (!CVFile) { return {}; }

        setSubmitButtonText("Uploading files ...");
        try {
            const resp: IUploadCVRes = await uploadCV(candidateId, CVFile, false);
            return resp;
        } catch (error) {
            toast.error(<ErrorMsg text={error.message || "Unknown error in avatar cv"} />);
            return {};
        }
    };

    const handleCommentChange = (e: any) => {
        setComment(e.target.value);
    };

    const handleCommentKeyPress = (e: any) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();

            addNewComment();
        }
    };

    const addNewComment = async () => {
        try {
            if (comment === "") {
                return;
            }

            let i = 0;
            let j = 200;

            while (i < comment.length) {
                const section = comment.substring(i, j);
                if (section.length >= 200 && !section.includes(" ")) {
                    toast.error("Invalid comment");
                    return;
                }
                i = j;
                j += 200;
            }

            setAddingComment(true);

            const newLog: IRecruiterLog = {
                type: ERecruiterLogTypes.NOTE,
                created_at: firebase.firestore.Timestamp.now(),
                candidate_id: autoCandidateID,
                note: comment,
                recruiter_id: user.id,
                recruiter_name: `${user?.firstName} ${user?.lastName}`,
            };
            await recruiterLogsRef.add(newLog);
            setComment("");
            setAddingComment(false);
        } catch (error) {
            setAddingComment(false);
            toast.error(<ErrorMsg />);
        }
    };

    const handleDeleteComment = async (id: string) => {
        setSelectedComment(id);
    };

    const onDeleteComment = async () => {
        if (selectedComment) {
            setDeletingComment(true);
            const id = selectedComment;
            setSelectedComment(null);
            await recruiterLogsRef.doc(id).delete();
            setDeletingComment(false);
        }
    };

    const handleSelectChange = ([event]: any) => event.value || "";

    const getSkills = useCallback(async () => {
        if (selectedJobFunction) {
            await reduxDispatch(getJobSkills({
                jobfunction: selectedJobFunction
            }));
        } else {
            await reduxDispatch(getJobSkills({
                jobfunction: ""
            }));
        }
    }, [selectedJobFunction]);

    useEffect(() => {
        getSkills();
    }, [getSkills]);

    useEffect(() => {
        if (errors.skills && errors.skills.type === "max") {
            toast.error(<ErrorMsg text={errors.skills.message || ""} />);
        }
    }, [errors]);

    const selectChangeHandler = (e: any) => {
        const selectedJob = e[0].value;
        setSelectedJobFunction(selectedJob);
        setValue("skills", []);
        setStateSkills([]);
        if (selectSkillsRef?.current?.select?.select) {
            selectSkillsRef.current.select.select.clearValue();
        }
        return handleSelectChange(e);
    };

    useEffect(() => {
        if (candidateLists?.jobFunctionSkills?.skills) {
            const opts: any = [];
            candidateLists.jobFunctionSkills.skills.map((el: any) => {
                opts.push({
                    value: el.toLowerCase(), label: el
                });
            });
            setSkillsOptions(opts);
        }
    }, [candidateLists.jobFunctionSkills]);

    const onCreate = async (formData: any) => {
        if (selectSkillsRef?.current?.state?.value?.length > 10) {
            setError("skills", "max", "10 is the maximum number of skills required");
            return;
        }
        setIsLoading(true);
        const _experiences: IExperience[] = Object.values(experiences || {});
        const _educations: IEducation[] = Object.values(educations || {});
        const educationIds = Object.keys(educations || 0);
        const experienceIds = Object.keys(experiences || 0);
        let isExperienceFieldsValid = true;
        let isEducationFieldsValid = true;
        if (experienceIds.length) {
            // Check that each field is valid
            // eslint-disable-next-line no-restricted-syntax
            for (const id of experienceIds) {
                const experience = experiences[id] || {};
                isExperienceFieldsValid = validateFields(ValidateFields_.Experience, { id, ...experience }, dispatch).valid;
            }
        }
        if (educationIds.length) {
            // Check that each field is valid
            // eslint-disable-next-line no-restricted-syntax
            for (const id of educationIds) {
                const education = educations[id] || {};
                isEducationFieldsValid = validateFields(ValidateFields_.Education, { id, ...education }, dispatch).valid;
            }
        }
        if (!isExperienceFieldsValid || !isEducationFieldsValid) {
            return;
        }

        const selectedSkills: ISkill[] = [];
        if (selectSkillsRef.current.state.value.length) {
            selectSkillsRef.current.state.value.map((el:any) => {
                selectedSkills.push({
                    name: el.label,
                    is_top_skill: true,
                    rate: 0
                });
            });
        }

        let emptyCandidateObj: ICandidate & ICandidateNote = {
            ...emptyCandidate,
            created_at: firebase.firestore.Timestamp.now(),
            is_active: true,
            is_verified: true,
            has_been_onboarded: true,
            firstName: formData.firstName,
            lastName: formData.lastName,
            location: `${formData.country}, ${formData.city}`,
            email: formData.email,
            phone: formData.phone,
            experiences: _experiences,
            educations: _educations,
            // note: formData.note || "",
            note: aspirationText.current || "",
            job_function: formData.job_function,
            skills: selectedSkills,
            original_avatar_url: peepAvatarUrl || "",
            thumbnail_avatar_url: peepAvatarUrl || "",
            creation_type:
                formData.email
                    ? ECreationType.BY_RECRUITER_WITH_AUTH_ACCOUNT
                    : ECreationType.BY_RECRUITER_WITHOUT_AUTH_ACCOUNT,
            recruiter_provider_id: user.id,
            experience_level: formData.experience_level,
            is_open_to_remote: formData.is_open_to_remote,
            is_skills_assessed: formData.is_skills_assessed,
            is_a_player: formData.is_a_player,
        };

        try {
            // let candidateId: string = candidatesRef.doc().id;
            const candidateId: string = autoCandidateID;

            // if we have email, we create a account in firebase auth
            if (formData.email) {
                const signUp: any = functions
                    .httpsCallable("candidates-signUpWithEmailPassword");
                const signUpAuthData: ISignUpData = {
                    email: formData.email,
                    password: formData.email,
                    name: `${formData.firstName} ${formData.lastName}`,
                    candidateId
                };
                const signUpResp: ISignUpResp = (await signUp(signUpAuthData)).data;

                // finish the process if the result of sign up equals false
                if (!signUpResp.result) {
                    setIsLoading(false);
                    toast.error(<ErrorMsg text={signUpResp?.message || ""} />);
                    return;
                }
                toast.success(<SuccessMsg text="Candidate created" />, {
                    position: "bottom-right"
                });

                // candidateId = signUpResp.uid || "";
            }

            // run upload functions
            // const [uploadAvatarResp, uploadCVResp]: [IUploadAvatarRes, IUploadCVRes] = await Promise.all([
            //     uploadAvatarFunction(candidateId),
            //     uploadCVFunction(candidateId)
            // ]);
            const uploadCVResp: IUploadCVRes = await uploadCVFunction(candidateId);

            // update object with returned url from upload functions
            emptyCandidateObj = {
                ...emptyCandidateObj,
                cv_url: uploadCVResp?.cv_url || "",
                // original_avatar_url: uploadAvatarResp?.original_avatar_url || "",
                // thumbnail_avatar_url: uploadAvatarResp?.thumbnail_avatar_url || ""
            };

            setSubmitButtonText("Creating candidate...");

            const newCandidateProfile = {
                objectID: candidateId,
                top_skills: mainSkills.map((i: ISkill) => i.name),
                ...emptyCandidateObj,
                involved_recruiter_ids: [user.id]
            };

            await reduxDispatch(
                createCandidate({
                    candidateId,
                    emptyCandidateObj,
                    newCandidateProfile,
                    candidates: candidateLists?.candidateList
                })
            );

            navigate(ROUTE_AUTHENTICATED_SEARCH);
            localStorage.removeItem("tempCandidateData");
            dispatch(doChangeCreateEmptyProfileMode(false));
            dispatch(doStartSearchProfile({ id: candidateId }));
        } catch (error) {
            toast.error(<ErrorMsg text={error?.message || ""} />);
            setIsLoading(false);
            localStorage.removeItem("tempCandidateData");
            setSubmitButtonText("");
        }
    };

    const updateRecruiterDoc = (profileData: any, successMsg = "Profile updated") => {
        recruitersRef
            .doc(user.id)
            .set(profileData, { merge: true })
            .then(() => {
                setLoading(false);
                toast.success(<SuccessMsg text={successMsg} />, {
                    position: "bottom-right"
                });
            // dispatch(doSetUser(profileData));
            })
            .catch(() => {
                setLoading(false);
                toast.error(<ErrorMsg />);
            });
    };

    const handleFileSubmit = ({ file }: any) => {
        setOriginalImage("");
        setImageLoading(true);

        uploadAvatar(user.id, UserType.Recruiter, file, false)
            .then(async (res: IUploadAvatarRes) => {
                if (res.original_avatar_url && res.thumbnail_avatar_url) {
                    if (originalImage) {
                        setOriginalImage("");
                    }

                    setImageLoading(false);
                    setOriginalImage(res.original_avatar_url);
                    setThumbnail(res.thumbnail_avatar_url);
                    const data = {
                        thumbnail_avatar_url: res.thumbnail_avatar_url,
                        original_avatar_url: res.original_avatar_url
                    };
                    const successMsg = "Profile picture uploaded";
                    await updateRecruiterDoc(data, successMsg);
                } else {
                    toast.error(<ErrorMsg />);
                }
            })
            .catch((err: IUploadAvatarRes) => {
                setImageLoading(false);
                if (originalImage) {
                    setOriginalImage("");
                }

                toast.error(<ErrorMsg text={err.message} />);
            });
    };

    const handleChange = (evt: any) => {
        aspirationText.current = evt.target.value;
        if (aspirationText.current.length) {
            clearError("note");
        }
    };

    const sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br"],
        allowedAttributes: { a: ["href"] }
    };

    const sanitize = () => {
        const sanitizedHTML = sanitizeHtml(aspirationText.current);
        aspirationText.current = sanitizedHTML;
        if (aspirationText.current.length) {
            register("note");
            clearError("note");
            setValue("note", aspirationText.current);
        } else {
            triggerValidation("note");
        }
    };

    const handleChangeSelect = (newValue: any) => {
        triggerValidation("skills");
        setStateSkills(newValue);
        setValue("skills", newValue);
    };

    const onKeyPress = (e: any) => {
        if (e && (e.key === "Enter")) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const customStyles = {
        ...selectStyles,
        control: (styles: any) => ({
            ...styles,
            backgroundColor: "white",
            // border: styles.isFocused ? 0 : '1px solid #AEAEB2',
            border: styles.isFocused ? 0
                : errors.skills ? "1px solid  rgb(229, 115, 115)" : "1px solid #AEAEB2",
            // This line disable the blue border
            padding: "2px 0px",
            borderRadius: 8,
            boxShadow: styles.isFocused ? 0 : 0,
            "&:hover": {
            //    border: styles.isFocused ? 0 : '1px solid #363840'
                border: styles.isFocused ? 0
                    : errors.skills ? "1px solid  rgb(229, 115, 115)" : "1px solid #363840",
            }
        }),
    };

    const noOptionsMessage = (a: any) => "No auto-suggestions";

    return (
        <>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={selectedComment !== null}
            >
                <DialogTitle
                  classes={{
                      root: classes.confirmationTitle
                  }}
                  id="confirmation-dialog-title"
                >
                    Confirmation
                </DialogTitle>
                <DialogContent classes={{ root: classes.confirmationContent }}>
                    <Backdrop className={classes.backdrop} open={deletingComment}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Typography>Are you sure you want to delete this comment?</Typography>
                </DialogContent>
                <DialogActions classes={{ root: classes.confirmationActions }}>
                    <Button autoFocus onClick={() => setSelectedComment(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onDeleteComment} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <form autoComplete="off" className={classes.form} onSubmit={handleSubmit(onCreate)}>
                <Grid container spacing={2}>
                    <Grid
                      style={{ padding: "0 22px" }}
                      item
                      xs={12}
                      md={12}
                      className={`${classes.imageUpload} ${classes.avatarButton}`}
                    >
                        <PeepsAvatarPicker
                          loading={isLoading}
                          useRandomAvatar
                          onChangeAvatar={setPeepAvatarUrl}
                          handleFileSubmit={handleFileSubmit}
                          styles={classes.peepAvatar}
                        />

                        <Grid style={{ position: "relative" }} className={classes.uploadCv} item xs={12} sm={6}>
                            <UploadButton
                              loading={isLoading}
                              text="Upload CV"
                              accept=".pdf,.doc,.docx"
                              themeButton={false}
                              className={classes.uploadCVButton}
                              onSelect={({ file }: { file: any }) => {
                                  const indexOfExtension = file.name.lastIndexOf(".");
                                  const { type } = file;
                                  const extension = file.name.slice(indexOfExtension);
                                  const acceptedFormats = [".pdf", ".doc", ".docx"];
                                  const fileType = [
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                      "application/msword",
                                      "application/pdf"
                                  ];
                                  if (acceptedFormats.includes(extension) && fileType.includes(type)) {
                                      setCVFile(file);
                                  } else {
                                      setCVFile(undefined);
                                      toast.error(
                                          <ErrorMsg text="Wrong file type, please upload .pdf, .doc or .docx" />
                                      );
                                  }
                              }}
                            />
                            <span style={{
                                position: "absolute",
                                top: "45px",
                                left: "3px",
                                fontWeight: 500
                            }}
                            >
                                This is optional
                            </span>
                            <div style={{ position: "absolute", marginTop: "4px" }}>
                                { CVFile?.name
                                    ? (
                                        <span>
                                            <Chip
                                              label={CVFile?.name}
                                              size="small"
                                              onDelete={() => setCVFile(null)}
                                              className={classes.chip}
                                              classes={{
                                                  deleteIcon: classes.deleteIcon
                                              }}
                                            />
                                        </span>
    )
                                    : ""}
                            </div>
                        </Grid>
                    </Grid>

                    <div className={classes.divider} />

                    <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    First Name
                                        <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                    </Box>
                                </Grid>
                                <CustomInput
                                  autoComplete="fname"
                                  name="firstName"
                                  fullWidth
                                  id="firstName"
                                  placeholder="First Name"
                                  autoFocus
                                  inputRef={register}
                                  classes={{
                                      root: classes.inputRoot,
                                      input: `${classes.outlineInput} ${errors.firstName ? classes.errorBorder : ""}`
                                  }}
                                />
                                {errors.firstName
                                    && (
                                        <Box color="error.main">
                                            {(errors.firstName as any).message}
                                        </Box>
                                    )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Last Name
                                        <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                    </Box>
                                </Grid>
                                <CustomInput
                                  fullWidth
                                  id="lastName"
                                  placeholder="Last Name"
                                  name="lastName"
                                  autoComplete="lname"
                                  inputRef={register}
                                  classes={{
                                      root: classes.inputRoot,
                                      input: `${classes.outlineInput} ${errors.lastName ? classes.errorBorder : ""}`
                                  }}
                                />
                                {errors.lastName
                                    && (
                                        <Box color="error.main">
                                            {(errors.lastName as any).message}
                                        </Box>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Email
                                    </Box>
                                </Grid>

                                <EmailField register={register} errors={errors} outlinedInput />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Phone
                                    </Box>
                                </Grid>

                                <CustomInput
                                  fullWidth
                                  id="phone"
                                  placeholder="Phone Number"
                                  name="phone"
                                  className={classes.input}
                                  inputRef={register}
                                  onChange={() => triggerValidation("phone")}
                                  classes={{
                                      root: classes.inputRoot,
                                      input: `${classes.outlineInput} ${errors.phone ? classes.errorBorder : ""}`
                                  }}
                                />
                                {errors.phone
                                && (
                                    <Box color="error.main">
                                        {(errors.phone as any).message}
                                    </Box>
                                )}

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>

                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    Country
                                        <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                    </Box>
                                </Grid>

                                <FormControl variant="outlined" fullWidth size="small">
                                    <Controller
                                      as={(
                                          <SelectMenu
                                            disabled={false}
                                            items={options}
                                            label="Country"
                                            inputRef={countryRef}
                                            errors={errors?.country?.message}
                                          />
                                        )}
                                      name="country"
                                      onFocus={() => countryRef?.current?.focus()}
                                      control={control}
                                      defaultValue=""
                                      onChange={handleSelectChange}
                                    />
                                    {errors.country
                                    && (
                                        <Box color="error.main">
                                            {(errors.country as any).message}
                                        </Box>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    City / Town
                                        <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                    </Box>
                                </Grid>
                                <CustomInput
                                  fullWidth
                                  id="city"
                                  placeholder="e.g. Berlin"
                                  name="city"
                                  inputRef={register}
                                  classes={{
                                      root: classes.inputRoot,
                                      input: `${classes.outlineInput} ${errors.city ? classes.errorBorder : ""}`
                                  }}
                                />
                                {errors.city
                                && (
                                    <Box color="error.main">
                                        {(errors.city as any).message}
                                    </Box>
                                )}

                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid className={classes.inputContainer} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    Job Function
                                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <Controller
                                      as={(
                                          <SelectMenu
                                            disabled={false}
                                            items={JobFunctionList}
                                            label="Job Functions"
                                            inputRef={jobFunctionRef}
                                            errors={errors?.job_function?.message}
                                          />
                                        )}
                                      name="job_function"
                                      control={control}
                                      defaultValue=""
                                      onFocus={() => jobFunctionRef?.current?.focus()}
                                      rules={{ required: true }}
                                      onChange={selectChangeHandler}
                                    />
                                    {errors.job_function
                                        && (
                                            <Box color="error.main">
                                                {(errors.job_function as any).message}
                                            </Box>
                                        )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.inputContainer} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    Seniority
                                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <Controller
                                      as={(
                                          <SelectMenu
                                            disabled={false}
                                            items={experienceLevelList.map(({ name, value }) => ({
                                                title: name,
                                                value
                                            }))}
                                            label="Seniority"
                                            inputRef={seniorityRef}
                                            errors={errors?.experience_level?.message}
                                          />
                                        )}
                                      name="experience_level"
                                      onFocus={() => seniorityRef?.current?.focus()}
                                      control={control}
                                      defaultValue=""
                                      onChange={handleSelectChange}
                                    />
                                    {errors.experience_level
                                    && (
                                        <Box color="error.main">
                                            {(errors.experience_level as any).message}
                                        </Box>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.selectField} style={{ padding: "0 22px" }} item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    Hard Skills / Spoken Languages
                                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                    <br />
                                    <span
                                      style={{
                                          fontSize: 10,
                                          fontWeight: 400
                                      }}
                                    >
                                        Press Enter to submit your entry
                                    </span>
                                </Box>

                                <Grid style={{ position: "relative" }} item xs={12}>
                                    <div onKeyPress={onKeyPress}>
                                        <CreatableSelect
                                          closeMenuOnSelect={false}
                                          noOptionsMessage={noOptionsMessage}
                                          isMulti
                                          options={selectedJobFunction ? skillsOptions : []}
                                          placeholder="Hard Skills / Spoken Languages"
                                          onChange={handleChangeSelect}
                                          styles={customStyles}
                                          ref={selectSkillsRef}
                                        />
                                    </div>
                                    {errors.skills
                                    && (
                                        <Box color="error.main">
                                            {searchText.length
                                                ? "Press Enter to submit your entry"
                                                : `${(errors.skills as any).message}` }
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className={classes.divider} />

                    <Grid style={{ padding: "0 24px" }} className={classes.checkBoxes} item xs={12}>
                        <VettingFields register={register} />
                    </Grid>

                    <div className={classes.divider} />

                    <Grid style={{ padding: "0 22px" }} item xs={12}>
                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                            Career Aspirations
                            <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                        </Box>
                        <ContentEditable
                          innerRef={careerAspRef}
                          onFocus={() => careerAspRef?.current?.focus()}
                          className={`${classes.contentEditable} ${errors.note ? classes.errorBorder : ""}`}
                          tagName="div"
                          html={aspirationText.current} // innerHTML of the editable div
                                // disabled={!this.state.editable} // use true to disable edition
                          onChange={handleChange} // handle innerHTML change
                          onBlur={sanitize}
                        />

                        {errors.note
                        && (
                            <Box color="error.main">
                                {(errors.note as any).message}
                            </Box>
                        )}
                    </Grid>
                    <div className={classes.divider} />
                    {/* Experience */}
                    <Grid item xs={12}>
                        <EditExperience experiences={experiences || []} disabled={isLoading} />
                    </Grid>
                    <div className={classes.divider} />
                    {/* Educations */}
                    <Grid item xs={12}>
                        <EditEducation educations={educations || []} disabled={isLoading} />
                    </Grid>
                    <div className={classes.divider} style={{ marginTop: "-10px" }} />
                    <Grid style={{ padding: "0 22px" }} item xs={12}>
                        <LogTimeLine
                          userId={user?.id}
                          candidateId={autoCandidateID}
                          expanded
                          handleDeleteComment={handleDeleteComment}
                          parentComponent="Create_candidate"
                        />
                        {/* <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                            Comments
                        </Box> */}
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          border="1px solid #245978"
                          borderRadius="10px"
                        >
                            <InputBase
                              name="comment"
                              multiline
                              value={comment}
                              onChange={handleCommentChange}
                              classes={{ root: classes.commentTextArea }}
                              placeholder="What else should we know about this candidate?"
                              onKeyPress={handleCommentKeyPress}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.commentButton}
                              onClick={addNewComment}
                              autoCapitalize="none"
                            >
                                { addingComment
                                    ? <CircularProgress size={18} style={{ color: "#fff" }} />
                                    : "Submit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <div className={classes.divider} />

                <div style={{ padding: "0 22px" }}>
                    <SubmitButton
                      className={classes.submit}
                      text={submitButtonText || "Create Candidate"}
                      loading={isLoading}
                    />
                </div>

            </form>
        </>
    );
};

export default CreateCandidateForm;
