import React from "react";

import { Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomToolTip = withStyles((theme: Theme) => ({
    tooltipArrow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 135,
        color: "#fff",
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: "#221A16",
        padding: "8px 11px",
        borderRadius: 8,
        height: 43
    },
    arrow: {
        color: "#221A16",
        fontSize: 20
    }
}))(Tooltip);

export default CustomToolTip;
