import { createAsyncThunk } from "@reduxjs/toolkit";
import { ESearchStatus } from "../../../context/reducer/Interface";
import { functions } from "../../../firebase";
import { candidatesRef } from "../../../FirebaseUtils/collectionRefs";
import { ISignUpData, ISignUpResp } from "../../../Pages/UnAuthenticated/SignUp/Interface";

export const fetchCandidates = createAsyncThunk("candidates",
    async (payload: any, { rejectWithValue }) => {
        try {
            const {
                candidatesSearchInfo = {},
                currentSearchStatus = "ACTIVE",
                currentCandidateList = [],
            }: any = payload;
            let condition: any = {};
            switch (currentSearchStatus) {
                case ESearchStatus.ACTIVE:
                    condition = {
                        ...candidatesSearchInfo
                    };
                    break;
                default:
                    break;
            }
            // TODO Fix this logic
            if (condition.min_salary_per_year === 0 && condition.max_salary_per_year === 1000000) {
                delete condition.min_salary_per_year;
                delete condition.max_salary_per_year;
            }

            const search: any = await functions
                .httpsCallable("candidates-searchCandidates")(
                    { ...condition, is_active: true }
                );
            if (!(search.data && search.data.hits.length)) {
                return {
                    paginationInfo: [],
                    candidates: [],
                };
            }
            const pagination = {
                totalHits: search.data.nbHits,
                currentPage: search.data.page,
                countOfPages: search.data.nbPages,
                hitsPerPage: search.data.hitsPerPage
            };
            const totalCandidates = [
                ...currentCandidateList,
                ...search.data.hits
            ];
            return {
                paginationInfo: pagination,
                candidates: totalCandidates
            };
        } catch (error) {
            return error;
        }
    });

export const fetchGroupCandidates = createAsyncThunk("groupCandidates", async (payload: any) => {
    try {
        const {
            candidatesSearchInfo = {},
            currentSearchStatus = "ACTIVE"
        }: any = payload;
        let condition: any = {};
        switch (currentSearchStatus) {
            case ESearchStatus.ACTIVE:
                condition = {
                    ...candidatesSearchInfo
                };
                break;
            default:
                break;
        }
        const searchGroups: any = await functions
            .httpsCallable("candidates-groupCandidatesSearchData")(
                { ...condition, is_active: true }
            );
        return searchGroups.data || null;
    } catch (error) {
        return error;
    }
});

export const signUpCandidate = createAsyncThunk("signupCandidate", async (payload: any) => {
    try {
        if (payload.email) {
            const signUp: any = functions.httpsCallable("candidates-signUpWithEmailPassword");
            const signUpAuthData: ISignUpData = {
                email: payload.email,
                password: payload.email,
                name: `${payload.firstName} ${payload.lastName}`,
                candidateId: payload.candidateId
            };
            const signUpResp: ISignUpResp = (await signUp(signUpAuthData)).data;
            return signUpResp;
        }
    } catch (error) {
        return error;
    }
});

export const getJobSkills = createAsyncThunk("jobskills", async (payload: any) => {
    try {
        if (payload.jobfunction) {
            const searchSkills:any = await functions
                .httpsCallable("sync-groupCandidatesSearchData")({
                    jobfunction: payload.jobfunction
                })
            return searchSkills?.data?.hits[0] || [];
        }
    } catch (error) {
        return error;
    }
});

export const createCandidate = createAsyncThunk("createNewCandidate", async (payload: any) => {
    try {
        const {
            candidateId,
            emptyCandidateObj
        } = payload;
        await candidatesRef.doc(candidateId).set(emptyCandidateObj);
    } catch (error) {
        return error;
    }
});

export const updateCandidate = createAsyncThunk(
    "updateCandidate",
    async (payload: any) => payload
);
