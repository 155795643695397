import { doSetProfileFieldErrors } from "../context/reducer/Actions";
import { IFieldErrors } from "../context/reducer/Interface";

export enum ValidateFields_ {
    Experience = "experiences",
    Education = "education"
}

export const validateFields = (type: ValidateFields_, fields: any, dispatch: Function) => {
    let valid = true;
    const errors: Array<IFieldErrors> = [];
    if (type === ValidateFields_.Experience) {
        if (!fields.title.trim().length) {
            valid = false;
            errors.push({
                field: `title${fields.id}`,
                section: "experience",
                id: `title${fields.id}`
            });
        }

        if (!fields.company_name.trim().length) {
            valid = false;
            errors.push({
                field: `company_name${fields.id}`,
                section: "experience",
                id: `company_name${fields.id}`
            });
        }
    }

    if (type === ValidateFields_.Education) {
        if (!fields.degree_title.trim().length) {
            valid = false;
            errors.push({
                field: `degree_title${fields.id}`,
                section: "education",
                id: `degree_title${fields.id}`
            });
        }

        if (!fields.institution_name.trim().length) {
            valid = false;
            errors.push({
                field: `institution_name${fields.id}`,
                section: "education",
                id: `institution_name${fields.id}`,
            });
        }
    }

    dispatch(doSetProfileFieldErrors(errors));

    return { valid };
};
