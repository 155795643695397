import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./style";
import { IintroProps } from "./Interface";
import { Box } from "@material-ui/core";
import ReactTooltip from "react-tooltip";

const RequestIntroButton = ({
  handleRequestIntro,
  handleDirectContact,
  loading,
  hasRequestedIntro,
  candidateEmail,
  openConnectModal
}: IintroProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isRequestIntroDisabled, setDisabledRequestIntro] =
    useState<boolean>(false);
  const anchorRef: any = useRef(null);
  const classes = useStyles({ isRequestIntroDisabled, open, selectedIndex });
  const options = candidateEmail
    ? ["Request Intro", "Direct Contact"]
    : ["Direct Contact"];

  useEffect(() => {
    if (loading || hasRequestedIntro) {
      setDisabledRequestIntro(true);
    } else {
      setDisabledRequestIntro(false);
    }
  }, [loading, hasRequestedIntro]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (selectedIndex === 0 && candidateEmail) {
      handleRequestIntro();
    } else {
      handleDirectContact();
    }
  };

  const handleMenuItemClick = (event: any, index: any) => {
    event.stopPropagation();
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (e: any) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef?.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid className={classes.buttonContainer}>
      <ButtonGroup
        classes={{
          groupedHorizontal: classes.buttonGroupGroupedHorizontal,
          contained: classes.buttonGroupContained,
        }}
        variant="contained"
        color="primary"
        aria-label="split button"
		style={{marginRight: 10}}
      >
        <Button
          color="primary"
          variant="contained"
          style={{
            borderBottomLeftRadius: open ? 0 : 5,
            borderBottomRightRadius: candidateEmail ? 0 : 5,
            borderTopLeftRadius: 5,
            minWidth: candidateEmail ? 150 : 189,
          }}
		  onClick={openConnectModal}
        >
          Add to Connect Job
        </Button>
      </ButtonGroup>
      <ButtonGroup
        classes={{
          groupedHorizontal: classes.buttonGroupGroupedHorizontal,
          contained: classes.buttonGroupContained,
        }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <ReactTooltip
          id="introButton"
          place="left"
          effect="solid"
          textColor="#221A16"
          backgroundColor="#E1E0E1"
          border
          className={classes.reactTooltip}
        >
          <Box display="flex" flexDirection="column">
            <span style={{ fontWeight: 400 }}>
              {selectedIndex === 0 && candidateEmail
                ? "Request an introduction from the recruiter who created this candidate."
                : "Click to get the contact information of the candidate."}
            </span>
          </Box>
        </ReactTooltip>

        <Button
          onClick={handleClick}
          data-for="introButton"
          data-iscapture="true"
          data-tip=""
          color="primary"
          variant="contained"
          endIcon={
            loading ? <CircularProgress color="secondary" size={18} /> : null
          }
          style={{
            borderBottomLeftRadius: open ? 0 : 5,
            borderBottomRightRadius: candidateEmail ? 0 : 5,
            borderTopLeftRadius: 5,
            minWidth: candidateEmail ? 150 : 189,
          }}
        >
          {options[selectedIndex]}
        </Button>
        {candidateEmail && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.requestIntro}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popper
        style={{ zIndex: 99 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <div
            style={{
              width: 165,
              left: "0px",
            }}
          >
            <Grow
              {...TransitionProps}
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginLeft: "-12px",
                marginRight: "-12px",
              }}
            >
              <Paper
                classes={{
                  root: classes.paperContainer,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) =>
                      index === selectedIndex ? (
                        ""
                      ) : (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                          classes={{
                            root: classes.MenuItemRoot,
                            selected: classes.MenuItemSelected,
                          }}
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </div>
        )}
      </Popper>
    </Grid>
  );
};

export default RequestIntroButton;
