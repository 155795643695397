import { combineReducers } from "redux";

import candidates from "./services/candidates/candidates";
import jobs from "./services/jobs/jobs";

const rootReducer = combineReducers({
    candidates,
    jobs
});

export default rootReducer;

