export const toSentenceCase = (word: string) => {
    const result = word.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toPascalCase = (word: string) => {
    let result = word?.split(" ");
    result = result?.map((w) => w.charAt(0).toUpperCase() + w.slice(1));
    return result?.join(" ");
};
