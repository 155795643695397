import React, { FC, useState, useEffect } from "react";
import { ITimeLineProps, IRecruiterLogWithId } from "./interface";
import { recruiterLogsRef } from "../../../../FirebaseUtils/collectionRefs";
import { IRecruiterLog } from "compass-shared";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import marked from "marked";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./style";
import CustomToolTip from "../../../../SharedComponents/ToolTip";

const LogTimeLine: FC<ITimeLineProps> = ({
    candidateId, expanded, handleDeleteComment, userId, parentComponent = ""
}) => {
    const [logs, setLogs] = useState<IRecruiterLogWithId[]>([]);
    useEffect(() => {
        const query = recruiterLogsRef.where("candidate_id", "==", candidateId).orderBy("created_at", "asc");

        const unsubscribe = query.onSnapshot((querySnapshot) => {
            const newLogs: IRecruiterLogWithId[] = [];
            querySnapshot.forEach((doc) => {
                const newLog: IRecruiterLogWithId = {
                    id: doc.id,
                    log: (doc.data() as IRecruiterLog)
                };
                newLogs.push(newLog);
            });
            setLogs([...newLogs].reverse());
        });
        return () => unsubscribe();
    }, [candidateId]);
    const classes = useStyles({ parentComponent });

    const urlify = (text: string): string => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url:string) => `<a href="${url}">${url}</a>`);
    };

    const onDeleteClick = (id: string) => () => {
        handleDeleteComment(id);
    };

    const showDate = (creationDate: string) => {
        const nextDay = moment(creationDate).add(1, "day").utc().format(); // calculate 24 hours next to last access
        const currentTime = moment().utc().format(); // current time
        // if(moment(currentTime).isSameOrAfter(nextDay)) {
        //     return moment(creationDate).format("DD MMM YYYY")
        // } else {
        //     return moment(creationDate, "YYYYMMDD").fromNow()
        // }
        return moment(creationDate).format("DD MMM YYYY");
    };

    return (
        <>
            {logs
                && (
                <div
                  style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 600,
                      fontFamily: "Montserrat"
                  }}
                >
                    Recruiter Notes
                </div>
                )}
            {logs
                ? logs.slice(
                    0, expanded ? logs.length : 2
                ).map(({ log, id }) => (
                    <Grid style={{ marginBottom: 10 }} item xs={12} key={id}>
                        <Grow in>
                            <Grid container>
                                <Grid item md={3} xs={4} className={classes.centredFlex}>
                                    <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 13 }}>
                                        {`${log.recruiter_name}`}
                                        <div className={classes.logDate}>
                                            {showDate(log.created_at.toDate())}
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item md={9} xs={8}>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Typography
                                              variant="body2"
                                              classes={{ body2: classes.note }}
                                              dangerouslySetInnerHTML={{
                                                  __html: marked(urlify(log.note as string))
                                              }}
                                            />
                                        </Grid>
                                        {
                                            log.recruiter_id === userId
                                                && (
                                                    <Grid item xs={2} className={classes.logsCancelIconItem}>
                                                        <CustomToolTip title="Delete Comment" arrow placement="top">
                                                            <CloseIcon
                                                              className={classes.logsCancelIcon}
                                                              onClick={onDeleteClick(id)}
                                                            />
                                                        </CustomToolTip>
                                                    </Grid>
                                                )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grow>
                    </Grid>
                ))
                : ""}
        </>
    );
};

export default LogTimeLine;
