import React from "react";
import { CircularProgress } from "@material-ui/core";

export const FetchMoreProgress = () => (
    <div style={{
        display: "flex", justifyContent: "center", width: "100%", height: "70px"
    }}
    >
        <CircularProgress />
    </div>
);
