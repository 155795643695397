import { createSlice } from "@reduxjs/toolkit";
import { fetchJobs } from "./jobs.actions";

export interface IState {
    status: string;
    jobList: []
}

const initialState: IState = {
    status: "",
    jobList: []
};

export const jobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchJobs.pending, (state, action) => {
            state.status = "pending";
        })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.jobList = action.payload;
            })
            .addCase(fetchJobs.rejected, (state, action) => {
                state.status = "rejected";
            });
    }
});

// export const {  } = jobsSlice.actions;
export default jobsSlice.reducer;
