import React, { FC } from "react";
import { Typography, Box } from "@material-ui/core";
import { IEducationItem } from "../interface";
import moment from "moment";

const EducationItem: FC<IEducationItem> = ({ education }) => {
    return <Box mb={1}>
        <Typography variant="subtitle2">
            <Box fontWeight="600" fontSize="13px">
                {education.institution_name}
            </Box>
        </Typography>
        <Typography variant="caption" gutterBottom style={{ fontWeight: 400, fontSize: 12 }}>
            {education.degree_title && `${education.degree_title}, `}
            {JSON.stringify(new Date(education.start_at)) === "null" ? education?.date_range && education?.date_range?.split("-")[0].split(".")[0] || "":
                education.start_at.toDate
                    ? moment(education.start_at.toDate()).format("MMM YYYY")
                    : typeof education.start_at === "number" && moment(education.start_at).format("MMM YYYY")
            }
            {JSON.stringify(education?.start_at).length && ` - `}
            {JSON.stringify(new Date(education.end_at)) === "null" ?
                education?.date_range?.split("-")[1] && education?.date_range?.split("-")[1].includes("Present") ? "Present":
                education.date_range?.split("-")[1] && `${education?.date_range?.split("-")[1].trim().split(" ")[0]} ${education?.date_range?.split("-")[1].trim().split(" ")[1]}` || "":
                        typeof education.end_at === "string"
                            ? education.end_at
                            : typeof education.end_at === "number"
                                ? moment(education.end_at).format("MMM YYYY")
                                : moment(education.end_at.toDate()).format("MMM YYYY")
            }
        </Typography>
    </Box>
};

export default EducationItem;
