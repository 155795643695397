import React from "react";
import { LoadingPage, EmptyList } from "compass-shared";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Emoji } from "emoji-mart";

import useStyles from "../style";
import { IRequestIntroProps } from "../Interface";

const RequestIntroModal = ({
    isRequestIntroOpen,
    recruiterInTouch,
    closeModal
}: IRequestIntroProps) => {
    const classes = useStyles({});

    return (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={isRequestIntroOpen}
          aria-labelledby="request-intro-dialog"
          classes={{
              paper: classes.dialogPaperRoot,
              container: classes.dialogContainer
          }}
        >
            <DialogContent className={classes.contentRoot}>
                <DialogTitle
                  disableTypography
                  id="request-intro-dialog"
                  classes={{ root: classes.dialogTitleRoot }}
                >
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.itemButton}
                        // onClick={() => { dispatch(doToggleRequestIntro(false)); }}
                      onClick={closeModal}
                    >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <div className={classes.dialogContent}>
                    <div>
                        <Emoji emoji=":slightly_smiling_face::skin-tone-1:" size={64} />
                        <Emoji emoji=":thumbsup::skin-tone-1:" size={64} />
                    </div>
                    <Typography component="div" variant="h6" classes={{ root: classes.titleText }}>
                        Intro Requested!
                    </Typography>
                    <Typography component="div" variant="h6" classes={{ root: classes.bodyText }}>
                        We have sent a Slack message to
                        {" "}
                        {recruiterInTouch.firstName}
. They should make the intro soon.
                    </Typography>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default RequestIntroModal;
