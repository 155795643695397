import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import ExperienceItem from "./ExperienceItem/ExperienceItem";
import { IExperiencesList } from "./Interface";

const Experiences: FC<IExperiencesList> = ({ experiences }) => (
    <>
        <Typography variant="h6" gutterBottom>
            <Box fontWeight="fontWeightBold" fontSize="14px">
                Experience
            </Box>
        </Typography>
        {
                experiences && experiences.length
                    ? experiences.map((experience) => (
                        <ExperienceItem
                          key={`${experience.title}-${experience.company_name}-${Math.random()}`}
                          experience={experience}
                        />
                    ))
                    : (
                        <Typography variant="caption">
                        There isn't any experience record.
                        </Typography>
                    )
            }
    </>
);

export default Experiences;
