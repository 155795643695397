import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Emoji } from "emoji-mart";
import { navigate } from "@reach/router";

import { IResponse } from "./interface";
import useStyles from "./style";
import { ROUTE_AUTHENTICATED_SEARCH } from "../../routes";

const RequestModalResponse = ({
    isModalOpen,
    titleText,
    status,
    bodyText,
    closeModal
}: IResponse) => {
    const classes = useStyles();
    return (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={isModalOpen}
          aria-labelledby="request-intro-dialog"
          classes={{
              paper: classes.dialogPaperRoot,
              container: classes.dialogContainer
          }}
        >
            <DialogContent className={classes.contentRoot}>
                <DialogTitle
                  disableTypography
                  id="request-intro-dialog"
                  classes={{ root: classes.dialogTitleRoot }}
                >
                    {status === "rejected" ? null
                        : (
                            <Button
                              variant="contained"
                              disableElevation
                              className={classes.itemButton}
                              onClick={closeModal}
                            >
                                <CloseIcon />
                            </Button>
                        )}
                </DialogTitle>
                <div className={classes.dialogContent}>
                    <div>
                        <Emoji emoji=":slightly_smiling_face::skin-tone-1:" size={64} />
                    </div>
                    <Typography component="div" variant="h6" classes={{ root: classes.titleText }}>
                        {titleText}
                    </Typography>
                    <Typography component="div" variant="h6" classes={{ root: classes.bodyText }}>
                        {bodyText}
                    </Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      className={classes.requestIntro}
                      onClick={() => navigate(ROUTE_AUTHENTICATED_SEARCH)}
                    >
                        Proceed to Search page
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default RequestModalResponse;
