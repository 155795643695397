import React, { FC } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "./style";
import { Box } from "@material-ui/core";

const Header: FC<any> = (props) => {
    const { children } = props;
    const classes = useStyles();
    return (
        <AppBar
          position="fixed"
          className={classes.appBar}
          classes={{ root: classes.elevation4, positionFixed: classes.positionFixed }}
        >
            <Toolbar classes={{ regular: classes.regular }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="600px"
                >
                    {children}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
