import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    urlToJobDescription: {
        textDecoration: "none"
    },
    requirements: {
        lineHeight: "18px",
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "capitalize",
        width: "100%",
        color: "#2F2824",
        "& .list-item": {
            display: "grid",
            gridTemplateColumns: "2fr 3fr",
            "& .title": {
                fontWeight: 500,
                fontSize: "14px",
            }
        }
    },
    ownAvatar: {
        height: "50px",
        width: "50px",
    },
    recruiterAvatar: {
        height: "50px",
        width: "50px",
    },
    personIcon: {
        fontSize: "25px"
    },
    flexCenterWrapper: {
        "& .MuiGrid-grid-md-2": {
            maxWidth: "unset",
            flexBasis: "unset",
        },
        "& .MuiFormControl-fullWidth": {
            width: "100px"
        },
        "& .MuiGrid-grid-xs-12": {
            "&:not(:last-child)": {
                marginRight: "9px"
            }
        }
    },
    actionContainer: {
        "& .MuiFormLabel-root": {
            fontSize: "15px !important"
        }
    },
    textFieldRoot: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: "8px",
        "& label.Mui-focused": {
            color: "#AEAEB2"
        },
        "& label": {
            color: "#AEAEB2"
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& .MuiSelect-select": {
            "&:focus": {
                backgroundColor: "unset"
            }
        }
    },
    tableCell: {
        flex: 1,
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
    },
    noClick: {
        cursor: "initial",
    },
    table: {
        "& .MuiTableCell-root": {
            flexDirection: "unset",
            textAlign: "start",
            alignItems: "start",
            width: "100%",
            padding: "16px 16px 16px 20px",
            borderBottom: "1px solid #DBEBEB"
        },
        "& .ReactVirtualized__Table__headerRow": {
            "& .header-title": {
                color: "#fff",
                fontSize: "14px",
                fontWeight: 600
            },
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            backgroundColor: "#221A16",
            paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
        },
        "& .ReactVirtualized__Grid ReactVirtualized__Table__Grid": {
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            border: "1px solid #DBEBEB",
            borderTop: "none",
        },
        "& .ReactVirtualized__Grid": {
            "&::-webkit-scrollbar": {
                width: "2px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#221A16"
            }
        },
        "& .ReactVirtualized__Table__row": {
            cursor: "initial",
            "& .MuiTableCell-root": {
                padding: "24px 16px 16px 20px"
            },
            "&:hover": {
                backgroundColor: "#fff"
            }
        },
        "& .ReactVirtualized__Table__rowColumn": {
            "&:not(:last-child)": {
                borderRight: "1px solid #E6F5F5"
            }
        },
        "& .ReactVirtualized__Table__headerColumn": {
            "&:not(:last-child)": {
                borderRight: "1px solid #979797"
            }
        }
    },
    tableRow: {
        cursor: "pointer",
    },
    tableRowHover: {
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        },
    },
    reactTooltip: {
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        fontWeight: "bolder",
        borderRadius: "10px !important"
    },
}));

export default useStyles;
