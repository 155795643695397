import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useIntersectionObserver = (handleChange: Function) => {
    const candidateLists: any = useSelector((state: RootState) => state.candidates);
    const paginationInfo: any = candidateLists?.candidateList?.paginationInfo;

    const [sentinel, setSentinel] = useState <Element | null>(null);
    const [isRefining, setRefining] = useState(false);
    const refineList = ([entry]: any) => {
        const nextPages = (paginationInfo && (paginationInfo.currentPage + 1) < paginationInfo.countOfPages);
        const hasMore = (paginationInfo && paginationInfo.countOfPages > 1);
        if (entry.isIntersecting && nextPages && hasMore) {
            setRefining(true);
            handleChange();
        }
    };
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.25
        };
        const observer = new IntersectionObserver(refineList, options);
        if (sentinel) {
            observer.observe(sentinel);
        }
        return () => observer.disconnect();
    }, [sentinel, paginationInfo]);

    return {
        setSentinel,
        isRefining,
        setRefining
    };
};
