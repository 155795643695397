const RecruiterOptions = [
    { title: "Own Jobs", value: "Own Jobs" },
    { title: "All Jobs", value: "All Jobs" }
];

const JobStatus = [
    { title: "Open", value: "Open" },
    { title: "Archived", value: "Archived" }
];

const ActionsOwnerArchive = [
    { title: "Edit", value: "Edit" },
    { title: "Archive", value: "Archive" },
    { title: "Leave", value: "Leave" }
];

const ActionsOwnerUnarchive = [
    { title: "Edit", value: "Edit" },
    { title: "Un-archive", value: "Un-archive" },
    { title: "Leave", value: "Leave" }
];

const ActionsJoin = [
    { title: "Join", value: "Join" }
];

const columns = [
    {
        width: 280,
        label: "Job Openings",
        dataKey: "job_function",
    },
    {
        width: 331,
        label: "Requirements",
        dataKey: "requirements",
    },
    {
        width: 331,
        label: "Recruiters",
        dataKey: "recruiters",
    },
    {
        width: 175,
        label: "Status",
        dataKey: "status",
    },
    {
        width: 175,
        label: "",
        dataKey: "actions",
    }
];

export {
    RecruiterOptions, JobStatus, columns, ActionsOwnerArchive, ActionsOwnerUnarchive, ActionsJoin
};
