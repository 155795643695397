import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAppContext from "../../../context/store/store";
import CandidateCardItem from "../CandidateCardItem/CandidateCardItem";
import { IProp } from "./Interface";
import { LoadingPage, EmptyList, IAlgoliaCandidate } from "compass-shared";
import useStyles from "./style";
import { RouteComponentProps, useMatch } from "@reach/router";
import { doToggleRequestIntro, doSetDirectContact } from "../../../context/reducer/Actions";
import RequestIntroModal from "./modals/requestIntro";
import DirectContactModal from "./modals/directContact";
import { Box } from "@material-ui/core";
import { RootState } from "../../../redux/store";
import { candidatesRef, recruiterLogsRef } from "../../../FirebaseUtils/collectionRefs";
import { ICandidateNote } from "../CreateCandidate/interface";

const CandidateCardsList: FC<RouteComponentProps & IProp> = ({
    candidateList,
    status, isFetchingMore,
    handleElementUpdate
}) => {
    const match = useMatch(`/${window.location.pathname.split("/")[1]}/:candidateId`);
    const candidateLists: any = useSelector((state: RootState) => state.candidates);

    const [{
        isRequestIntroOpen,
        recruiterInTouch,
        isDirectContactOpen,
        isSearchFormOpen,
        user
    }, dispatch]: any = useAppContext();

    const [candidates, setCandidates] = useState(candidateList);
    const classes = useStyles({ isSearchFormOpen });
    const [isCandidateProfile, setCandidateProfile] = useState(false);
    const [isFetchingProfile, setIsFetchingProfile] = useState(false);

    const isCandidateSearch = !!(match && match.candidateId) && !(window.location.pathname === "/search-candidates");

    const getRecruiterLogs = async () => {
        const query = (await recruiterLogsRef.where("candidate_id", "==", match?.candidateId || "").get()).docs;
        const data = query.map((doc: any) => doc.data());
        return data;
    };

    const getCandidate = async () => {
        setIsFetchingProfile(true);
        const results = (await candidatesRef.doc(match?.candidateId || "").get()).data() as IAlgoliaCandidate & ICandidateNote;
        const { id } = (await candidatesRef.doc(match?.candidateId || ""));
        if (id && results) {
            if (!results.note && !results?.note?.length) {
                const recruiter_logs = await getRecruiterLogs();
                results.recruiter_logs = recruiter_logs || [];
            }
            results.objectID = id;
            const candidatesFiltered = [...candidateList].splice(0, 9);
            setCandidates([results,
                ...candidatesFiltered.filter((candidate) => candidate.objectID !== match?.candidateId)]);
            setCandidateProfile(true);
        } else {
            setCandidateProfile(false);
        }
        setIsFetchingProfile(false);
    };

    useEffect(() => {
        if (isCandidateSearch) {
            getCandidate();
        }
    }, [isCandidateSearch]);

    useEffect(() => {
        if (!isCandidateSearch) {
            setCandidates(candidateList);
        }
    }, [candidateList]);

    const closeDirectContact = () => {
        const directContactData = {
            candidate: {},
            isOpen: false
        };
        dispatch(doSetDirectContact(directContactData));
    };



    const renderCandidatesList = () => (((candidates && candidates.length) && (isCandidateProfile && isCandidateSearch))
            || ((candidates && candidates.length) && !isCandidateSearch)
    );

    return (
        <>
            {
                ((status === "pending") && !isFetchingMore) || isFetchingProfile
                    ? <LoadingPage text="Loading candidates..." />
                    : (renderCandidatesList()
                        ? (
                            <div className={classes.listWrapper}>
                                <RequestIntroModal
                                  isRequestIntroOpen={isRequestIntroOpen}
                                  recruiterInTouch={recruiterInTouch}
                                  closeModal={() => dispatch(doToggleRequestIntro(false))}
                                />
                                <DirectContactModal
                                  openDirectContact={isDirectContactOpen}
                                  closeModal={closeDirectContact}
                                  userID={user.id}
                                />
                                <Box className={classes.candidateWrapper} display="flex" justifyContent="center">
                                    <div
                                      className={`${classes.candidatesCount} countResults`}
                                    >
                                        {`${candidateLists?.candidateList?.paginationInfo?.totalHits} Results`}
                                    </div>
                                    <Box
                                      className={classes.listContainer}
                                      display="flex"
                                      width="65vw"
                                      alignItems="center"
                                      flexDirection="column"
                                    >
                                        {candidates
                                            .map((candidate: any) => (
                                                <CandidateCardItem
                                                  key={candidate.objectID}
                                                  candidate={candidate}
                                                />
                                            ))}
                                        {!(status === "pending")
                                        && <li style={{ color: "transparent" }} ref={handleElementUpdate}> </li> }
                                    </Box>
                                </Box>
                            </div>
                        )
                    // eslint-disable-next-line max-len
                        : isCandidateSearch
                            ? (
                                <Box
                                  height="80%"
                                  width="100%"
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                  justifyContent="center"
                                  className={classes.reloadText}
                                >
                                    <span style={{ margin: "8px 0" }}> Candidate data is being fetched. </span>
                                    <span style={{ margin: "8px 0" }}> Refresh the page in about 60 seconds. </span>
                                    <a
                                      style={{
                                          cursor: "pointer",
                                          marginLeft: 8,
                                          borderBottom: "dashed 1px #2F2824 "
                                      }}
                                      onClick={() => window.location.reload()}
                                    >
                                            Refresh
                                    </a>
                                </Box>
                            )
                            : <EmptyList text="No Search Results" />)
            }
        </>
    );
};

export default CandidateCardsList;
