import { IRecruiter } from "compass-shared";
import {
    ActionType, IAction, ESearchStatus, IFieldErrors
} from "./Interface";
import { IJobOpenings } from "../../../../compass-shared/src/types/CandidateInAlgolia";

// Action creators can be created here
// All action creators return an IAction
// eslint-disable-next-line import/prefer-default-export
export const doSetUser = (payload: IRecruiter): IAction => ({
    type: ActionType.SET_USER, payload
});

export const doSetNavbarStatus = (payload: { isOpen: boolean}): IAction => ({
    type: ActionType.SET_NAVBAR_STATUS, payload
});

// @TODO add interface for search queries
export const doStartSearchCandidates = (
    payload: { page?: number, isFilterRequest?: boolean, isFetchingMore?:boolean }
): IAction => ({
    type: ActionType.START_SEARCH_CANDIDATES,
    payload: {
        isFilterRequest: payload.isFilterRequest || false,
        isSearchCandidates: true,
        isFetchingMore: payload.isFetchingMore || false,
        candidatesSearchInfo: { ...payload }
    }
});

export const doStartSearchJobOpenings = (payload: IJobOpenings) => ({
    type: ActionType.START_SEARCH_JOB_OPENINGS,
    payload: {
        isSearchJobOpenings: true,
        ...payload
    }
});

export const doCompleteSearchJobOpenings = (payload: any) => ({
    type: ActionType.COMPLETE_SEARCH_JOB_OPENINGS,
    payload: {
        isSearchJobOpenings: false,
        jobOpenings: payload
    }
});

export const doCompleteEditProfile = (payload: any) => ({
    type: ActionType.COMPLETE_EDIT_PROFILE,
    payload
});

export const doUpdateProfile = (payload: { currentCandidateId: string, candidatesInfo: any }) => ({
    type: ActionType.UPDATE_PROFILE,
    payload
});

export const doStartEditProfile = () => ({
    type: ActionType.START_EDIT_PROFILE,
});

// @TODO add interface
export const doCompleteSearchCandidates = (
    payload: {
        pagination: {
            totalHits?: number;
            currentPage?: number;
            countOfPages?: number;
            hitsPerPage?: number;
        }
        candidates?: Array<any>
    }
): IAction => ({
    type: ActionType.COMPLETE_SEARCH_CANDIDATES,
    payload: {
        isSearchCandidates: false,
        paginationInfo: { ...payload.pagination },
        candidates: payload.candidates
    }
});

export const doStartRequery = (): {type: string} => ({
    type: ActionType.START_REQUERY_CANDIDATES,
});

export const doCompleteSearchCandidatesGroup = (
    payload: any
): IAction => ({
    type: ActionType.COMPLETE_SEARCH_CANDIDATES_GROUP,
    payload
});

export const doStartSearchProfile = (payload: { id: string }): IAction => ({
    type: ActionType.START_SEARCH_PROFILE,
    payload: {
        isSearchCandidateProfile: true,
        profileSearchInfo: { id: payload.id }
    }
});

export const doCompleteSearchProfile = (): IAction => ({
    type: ActionType.COMPLETE_SEARCH_PROFILE,
    payload: {
        isSearchCandidateProfile: false,
        profileSearchInfo: {}
    }
});

export const doResetSearchData = (): IAction => ({
    type: ActionType.RESET_SEARCH_DATA,
    payload: {
        isSearchCandidates: false,
        candidatesSearchInfo: { index: "candidates" },
        isSearchCandidateProfile: false,
        profileSearchInfo: {},
        paginationInfo: {}
    }
});

export const doChangeCreateEmptyProfileMode = (
    isOnCreateEmptyProfile: boolean
): IAction => ({
    type: ActionType.CHANGE_CREATE_EMPTY_PROFILE_MODE,
    payload: {
        isOnCreateEmptyProfile
    }
});

export const doSetProfileData = (payload: { key?: string; value?: any; changeType?: string }): IAction => ({
    type: ActionType.SET_PROFILE_DATA,
    payload
});

export const doResetEditProfileData = (): IAction => ({
    type: ActionType.RESET_EDIT_PROFILE_DATA,
    payload: {}
});

export const doSetProfileFieldErrors = (payload: Array<IFieldErrors>): IAction => ({
    type: ActionType.SET_EDIT_PROFILE_ERRORS,
    payload
});

export const doClearProfileFieldErrors = (payload: {field: string}): IAction => ({
    type: ActionType.CLEAR_EDIT_PROFILE_ERRORS,
    payload
});

export const doSetProfileExperienceData = (payload: { id: string; key: string; value: any }): IAction => ({
    type: ActionType.SET_PROFILE_DATA_EXPERIENCE,
    payload
});

export const doSetProfileEducationData = (payload: { id: string; key: string; value: any }): IAction => ({
    type: ActionType.SET_PROFILE_DATA_EDUCATION,
    payload
});

export const doAddProfileExperienceData = (payload: any): IAction => ({
    type: ActionType.SET_PROFILE_DATA_ADD_EXPERIENCE,
    payload
});

export const doAddProfileEducationData = (payload: any): IAction => ({
    type: ActionType.SET_PROFILE_DATA_ADD_EDUCATION,
    payload
});

export const doRemoveExperience = (payload: string): IAction => ({
    type: ActionType.REMOVE_EXPERIENCE,
    payload
});

export const doRemoveEducation = (payload: string): IAction => ({
    type: ActionType.REMOVE_EDUCATION,
    payload
});

export const doResetProfileData = (): IAction => ({
    type: ActionType.RESET_PROFILE_DATA,
    payload: {
        experiences: {} as any,
        skills: "" as any,
    }
});

export const doToggleSearchForm = (): { type: ActionType } => ({
    type: ActionType.TOGGLE_SEARCH_FORM
});

export const doToggleMainSearchForm = (payload: boolean): IAction => ({
    type: ActionType.TOGGLE_MAIN_SEARCH_FORM,
    payload
});

export const doChangeSearchStatus = (payload: ESearchStatus): IAction => ({
    type: ActionType.CHANGE_SEARCH_STATUS,
    payload
});

export const doToggleCreateJobOpening = (payload: boolean): IAction => ({
    type: ActionType.TOGGLE_CREATE_JOB_OPENING,
    payload
});
export const doToggleRequestIntro = (payload: boolean): IAction => ({
    type: ActionType.TOGGLE_REQUEST_INTRO,
    payload
});
export const doSetRecruiterInTouch = (payload: any): IAction => ({
    type: ActionType.SET_RECRUITER_IN_TOUCH,
    payload
});

export const doSetDirectContact = (payload: any): IAction => ({
    type: ActionType.TOGGLE_DIRECT_CONTACT,
    payload
});
