/* eslint-disable no-param-reassign */
import React, { FC, useState } from "react";
import {
    Grid, Box, Typography, Chip, Paper, Fade
} from "@material-ui/core";
import ContentEditable from "react-contenteditable";
import { IProp } from "./Interface";
import useStyles from "./style";
import Button from "@material-ui/core/Button";
import {
    ErrorMsg, UserAvatar
} from "compass-shared";
import { toast } from "react-toastify";
import SkillChip from "../../../SharedComponents/SkillChip";
import { useMatch } from "@reach/router";
import { functions } from "../../../firebase";
import { doToggleRequestIntro, doSetRecruiterInTouch, doSetDirectContact } from "../../../context/reducer/Actions";
import useAppContext from "../../../context/store/store";
import { toPascalCase, toSentenceCase } from "../../../Utils/strings";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CandidatesExpandedContainer } from "../CandidateExpandedContainer";
import ReactTooltip from "react-tooltip";
import { Emoji } from "emoji-mart";

const CandidateCardItem: FC<IProp> = ({ candidate }) => {
    const match = useMatch(`/${window.location.pathname.split("/")[1]}/:candidateId`);
    const isCandidateList = window.location.pathname === "/search-candidates";

    const [page] = useState("search-candidates");
    const [loading, setLoading] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [drawerState, toggleDrawer] = useState((match?.candidateId === candidate.objectID) && !isCandidateList);
    const [inHover, setHover] = useState(false as boolean);

    const classes = useStyles({ ...candidate });
    const smallScreensProfile = useMediaQuery("(max-width: 960px)");

    const [{
        user,
    }, dispatch]: any = useAppContext();

    const handleDirectContact = async () => {
        setLoading(true);
        const directContactData = {
            candidate,
            isOpen: true
        };
        try {
            const recruiters = await functions
                .httpsCallable("recruiters-handleDirectContact")({
                    candidateID: candidate.objectID,
                    recruiterID: candidate?.recruiter_provider_id,
                    requestedBy: user.id,
                    status: "direct_contact_sent"
                });
            setLoading(false);
            dispatch(doSetDirectContact(directContactData));
        } catch (err) {
            setLoading(false);
        }
    };

    const handleRequestIntro = async () => {
        try {
            if (user.id === candidate.recruiter_provider_id) {
                toast.error(<ErrorMsg text="You cannot request intro for a candidate you created" />);
                return;
            }
            setLoading(true);
            const contactRecruiterData = {
                currentRecruiter: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    id: user.id
                },
                candidate
            };
            const contactRecruiterResp: any = await functions
                .httpsCallable("slack-contactRecruiterIntouch")(contactRecruiterData);
            if (contactRecruiterResp.data) {
                setLoading(false);
                dispatch(doSetRecruiterInTouch({
                    firstName: contactRecruiterResp.data.firstName,
                    lastName: contactRecruiterResp.data.lastName,
                }));
                dispatch(doToggleRequestIntro(true));
            }
        } catch (error) {
            setLoading(false);
            toast.error(<ErrorMsg text="Error occurred sending slack DM" />);
            dispatch(doToggleRequestIntro(false));
        }
    };

    const getDaysPast = () => {
        const days = moment().diff(moment(candidate?.created_at?._seconds * 1000), "days") || 0;
        return `${days} ${days !== 1 ? "Days" : "Day"} Ago`;
    };

    const convertToProperText = (status: string): string => {
        let statusWords = status.split(" ");
        statusWords = [...statusWords.slice(0, 1), "In Relocation", ...statusWords.slice(1)];
        return toSentenceCase(statusWords.join(" "));
    };

    const displayCandidateDetails = (e: any) => {
        e.stopPropagation();
        window.history.replaceState(null, `${page}`, `/${page}/${candidate.objectID}`);
        toggleDrawer(true);
    };
    const convertCardTitleText = () => {
        const firstJobFunction = candidate?.job_function?.split("-")[0];
        const secondPart = candidate?.job_function?.split("-")[1];
        if (secondPart) {
            return `${
                firstJobFunction?.charAt(0).toUpperCase()
                + firstJobFunction?.slice(1)}-${secondPart?.charAt(0)?.toLowerCase()
            + secondPart?.slice(1)}`;
        }
        return `${(firstJobFunction?.charAt(0).toUpperCase() + firstJobFunction?.slice(1)) || 'Unknown'}`;
    };
    const renderSkills = () => candidate?.skills?.map((skill: any, idx: number) => (
        <div key={idx.toString()}>
            {seeMore ? (
                <span
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                    <SkillChip
                      label={toPascalCase(skill?.name || skill)}
                      key={skill?.name || skill}
                      styles={{
                          maxWidth: "fit-content"
                      }}
                    />

                </span>
            ) : (
                <span
                  data-for="skill"
                  data-iscapture="true"
                  data-tip={(skill?.name || skill)}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                    <SkillChip
                      label={toPascalCase(skill?.name || skill)}
                      key={skill?.name || skill}
                      setHoverState
                      styles={{
                          display: `${(idx + 1 > 10) ? "none" : "auto"}`,
                          maxWidth: candidate?.skills && candidate.skills.length > 7 ? "110px" : "fit-content",
                      }}
                    />
                </span>
            )}
        </div>
    ));

    const handleSeeMore = (e: any) => {
        e.stopPropagation();
        setSeeMore(!seeMore);
    };

    const renderExperience = (expArray = [] as any[]) => {
        if (expArray.length) {
            const experienceArray = [...expArray];
            const sorted = experienceArray.sort((a: any, b: any) => (
                b?.end_at?._seconds * 1000 - a?.end_at?._seconds * 1000
            ));
            return sorted[0];
        }
        return null;
    };

    const renderTitle = () => {
        if (candidate?.experiences?.length) {
            const experience: any = renderExperience(candidate?.experiences);
            return `${experience.title} at ${experience?.company_name}`;
        }
        return `${(candidate.experience_level?.charAt(0)?.toUpperCase() || "Unknown")
                + (candidate?.experience_level?.slice(1) || '')} ${
            convertCardTitleText()} Candidate`;
    };

    return (
        <>
            {inHover
                && (
                    <>
                        <ReactTooltip
                          id="skill"
                          place="bottom"
                          effect="solid"
                          textColor="#ffffff"
                          backgroundColor="#221A16"
                          className={classes.reactTooltip}
                        />
                    </>
                )}
            <Paper onClick={displayCandidateDetails} className={classes.paper}>
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                              height: smallScreensProfile
                                  ? "auto"
                                  : "100%",
                              gap: smallScreensProfile ? 0 : "20px"
                          }}
                        >
                            <Grid
                              style={{ height: smallScreensProfile ? "auto" : "100%" }}
                              item
                              md={3}
                              sm={12}
                              xs={12}
                              className={classes.avatarCentered}
                            >
                                <div
                                  style={{
                                      height: smallScreensProfile ? "auto" : "218px",
                                  }}
                                  className={classes.containerAvatar}
                                >
                                    <UserAvatar
                                      variant="rounded"
                                      src={candidate?.candidate_avatar_url || candidate?.thumbnail_avatar_url || ""}
                                      styleClass={classes.avatar}
                                    />

                                    {
                                        !smallScreensProfile ? null
                                            : (
                                                <div className={classes.smallContainer}>
                                                    <div className="profileInfo">
                                                        <span className="title"> Title: </span>
                                                        <div>
                                                            <Typography
                                                              component="span"
                                                              variant="subtitle1"
                                                              classes={{ root: classes.profile }}
                                                            >
                                                                {candidate?.job_function}
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                    <div className="profileInfo">
                                                        <span className="title"> Level: </span>
                                                        <div>
                                                            <Typography
                                                              component="span"
                                                              variant="subtitle1"
                                                              classes={{ root: classes.profile }}
                                                            >
                                                                {candidate?.experience_level}
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                    <div className="profileInfo">
                                                        <span className="title"> Location: </span>
                                                        <div>
                                                            <Typography
                                                              component="div"
                                                              variant="subtitle1"
                                                              classes={{ root: classes.profile }}
                                                            >
                                                                {candidate?.location}
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                    }

                                </div>

                                <div
                                  style={{ marginTop: smallScreensProfile ? 21 : 0 }}
                                  className={classes.sectionDivider}
                                />

                                <div style={{ display: smallScreensProfile ? "none" : "block" }}>

                                    <Grid style={{ marginBottom: 5 }} item md={2} xs={12}>
                                        <Typography
                                          component="div"
                                          variant="subtitle1"
                                          classes={{ root: classes.sectionTitle }}
                                        >
                                            Screening
                                        </Typography>
                                    </Grid>

                                    <Grid item md={12} xs={10}>
                                        <div className="userDetails">
                                            <span> Open to remote </span>
                                            <span>
                                                {" "}
                                                {(candidate && candidate.is_open_to_remote) ? "Yes" : "-"}
                                                {" "}
                                            </span>

                                        </div>

                                        <div className="userDetails">
                                            <span> Skill Assessed </span>
                                            <span>
                                                {" "}
                                                {candidate?.is_skills_assessed ? "Yes" : "-"}
                                                {" "}
                                            </span>
                                        </div>

                                        <div className="userDetails">
                                            <span> A-Player </span>
                                            <span>
                                                {" "}
                                                { candidate?.is_a_player ? "Yes" : "-"}
                                                {" "}
                                            </span>
                                        </div>

                                        <div className="userDetails">
                                            <span> Created </span>
                                            <span>
                                                {" "}
                                                { getDaysPast()}
                                                {" "}
                                            </span>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid
                              className={classes.profileContainer}
                              style={{
                                  height: smallScreensProfile ? "auto" : "100%", maxWidth: "72%", flexBasis: "75%"
                              }}
                              item
                              md={8}
                              sm={9}
                              xs={9}
                            >

                                <div
                                  style={{
                                      paddingTop: "20px",
                                      position: "relative",
                                      minHeight: "238px",
                                      marginTop: "-20px"
                                  }}
                                  className={classes.skillsMobileView}
                                >
                                    <Box
                                      display="flex"
                                      width="100%"
                                      justifyContent="space-between"
                                    >
                                        { !smallScreensProfile && (
                                        <Typography
                                          component="div"
                                          variant="subtitle1"
                                          classes={{ root: classes.sectionTitle }}
                                          style={{
                                              width: "auto",
                                              display: "flex",
                                              alignItems: "center"
                                          }}
                                        >
                                            {renderTitle()}

                                            {candidate.is_a_player
                                            && <Emoji emoji=":fire:" size={24} />}
                                        </Typography>
                                        )}

                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="flex-end"
                                          alignItems="center"
                                          className={classes.buttonContainer}
                                        >
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className={classes.viewProfileContainer}
                                            >
                                                View Profile
                                            </Button>
                                        </Box>

                                    </Box>

                                    <Box
                                      marginTop="20px"
                                      display="flex"
                                      flexDirection={smallScreensProfile ? "column-reverse" : "column"}
                                    >
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          flexWrap="wrap"
                                          paddingTop="10px"
                                          className={classes.cardItemContainer}
                                        >
                                            <div style={{ display: smallScreensProfile ? "none" : "block" }}>
                                                {candidate.job_function && (
                                                <Chip
                                                  className={classes.chip}
                                                  size="small"
                                                  label={toPascalCase(candidate.job_function)}
                                                />
                                                )}

                                                {candidate.experience_level && (
                                                <Chip
                                                  className={classes.chip}
                                                  size="small"
                                                  label={toPascalCase(candidate.experience_level)}
                                                />
                                                )}

                                                {candidate.location && (
                                                <Chip
                                                  className={classes.chip}
                                                  size="small"
                                                  label={toPascalCase(candidate.location)}
                                                />
                                                )}
                                            </div>

                                        </Box>
                                        { !smallScreensProfile && (
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          flexWrap="wrap"
                                          className={classes.skillsContainer}
                                        >
                                            {renderSkills()}
                                            {Number(candidate?.skills?.length) > 10
                                                ? (
                                                    <Button
                                                      onClick={handleSeeMore}
                                                      className={classes.showMoreButton}
                                                    >
                                                        {seeMore
                                                            ? `See less`
                                                            : `See more (${Number(candidate?.skills?.length) - 10})`}
                                                    </Button>
                                                ) : null}
                                        </Box>
                                        )}
                                    </Box>
                                </div>

                                { smallScreensProfile ? null : (<div className={classes.sectionDivider} />) }

                                <div>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      sm={12}
                                      style={{ marginLeft: "6px" }}
                                      className={classes.itemSection}
                                    >
                                        {candidate?.note && candidate.note.replace(/ /g, "").length
                                            ? (
                                                <Fade in>
                                                    <Grid container className={classes.itemSectionContainer}>
                                                        <Grid style={{ marginBottom: 5 }} item md={2} xs={3}>
                                                            <Typography
                                                              component="div"
                                                              variant="subtitle1"
                                                              classes={{ root: classes.sectionTitle }}
                                                            >
                                                            Career Aspirations
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={10} xs={12}>
                                                            <Box display="flex" flexDirection="column">
                                                                <ContentEditable
                                                                  disabled
                                                                  tagName="span"
                                                                  onChange={(e) => console.log(e)}
                                                                  html={candidate.note}
                                                                  className={classes.contentEditable}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Fade>
                                        )
                                            : candidate.recruiter_logs && candidate.recruiter_logs[0]

                                                ? (
                                                    <Fade in>
                                                        <Grid container className={classes.itemSectionContainer}>
                                                            <Grid style={{ marginBottom: 5 }} item md={2} xs={3}>
                                                                <Typography
                                                                  component="div"
                                                                  variant="subtitle1"
                                                                  classes={{ root: classes.sectionTitle }}
                                                                >
                                                                    Recruiter Notes
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={10} xs={12}>
                                                                <Box display="flex" flexDirection="column">
                                                                    <ContentEditable
                                                                      disabled
                                                                      tagName="span"
                                                                      onChange={(e) => console.log(e)}
                                                                      html={candidate.recruiter_logs[0].note || ""}
                                                                      className={classes.contentEditable}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>
                                                )

                                                : ""}
                                    </Grid>
                                </div>

                                { smallScreensProfile && (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  flexWrap="wrap"
                                  className={classes.skillsContainer}
                                >
                                    {renderSkills()}
                                    {Number(candidate?.skills?.length) > 10
                                        ? (
                                            <Button
                                              onClick={handleSeeMore}
                                              className={classes.showMoreButton}
                                            >
                                                {seeMore
                                                    ? `See less`
                                                    : `See more (${Number(candidate?.skills?.length) - 10})`}
                                            </Button>
                                        ) : null}
                                </Box>
                                )}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
            {drawerState
                && (
                <CandidatesExpandedContainer
                  parentClasses={classes}
                  toggleDrawer={toggleDrawer}
                  drawerState={drawerState}
                  candidate={candidate}
                  handleRequestIntro={handleRequestIntro}
                  handleDirectContact={handleDirectContact}
                  loading={loading}
                />
                )}

        </>
    );
};

export default React.memo(CandidateCardItem);
