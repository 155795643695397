import React, {
    FC, useEffect, useState, useCallback
} from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import { Link, RouteComponentProps } from "@reach/router";
import useStyles from "./style";
import { signOut } from "../AuthenticationProvider/AuthenticationProvider";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ReactComponent as Logo } from "../../Assets/img/logo.svg";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import NavList from "./NavList";
import {
    ROUTE_AUTHENTICATED_CANDIDATE_LIST,
    ROUTE_AUTHENTICATED_ROOT,
} from "../../routes";
import useAppContext from "../../context/store/store";
import { doSetNavbarStatus } from "../../context/reducer/Actions";
import hitPageOnAnalytics from "../../Utils/gTagHandler";
import { useMediaQuery } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Header from "../Header";
import { candidatesRef } from "../../FirebaseUtils/collectionRefs";
import { useMatch } from "@reach/router";

const initialState = {
    candidatesCount: 0
};

const NavigationBar: FC<RouteComponentProps> = (props) => {
    const { children, location } = props;

    const matches = useMediaQuery("(max-width:600px)");
    const path = useMatch(window.location.pathname);

    const smallMediumScreens = useMediaQuery("(max-width:1091px)");
    const userNavStatus = localStorage.getItem("navStatus");

    const isUserNavBarOpen = userNavStatus ? JSON.parse(userNavStatus) : true;

    const [isSearchCandidates, setIsSearchCandidates] = useState(false);

    const [selected, setSelected] = useState(ROUTE_AUTHENTICATED_ROOT);
    const [isSideBarOpen, setSideBarOpen] = React.useState(smallMediumScreens ? false : isUserNavBarOpen);
    const classes = useStyles({
        isSideBarOpen,
    });
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [state, setState] = React.useState(initialState);

    useEffect(() => {
        if (path?.uri) {
            setIsSearchCandidates(path?.uri.includes("/search-candidates"));
        }
    }, [path]);

    const handleMobileDrawerToggle = () => {
        if (matches) {
            setMobileOpen(((prevState) => !prevState));
        }
    };

    const [{ user }, dispatch]: any = useAppContext();

    const handleDrawersToggle = () => {
        setSideBarOpen(!isSideBarOpen);
        localStorage.setItem("navStatus", `${!isSideBarOpen}`);
    };

    useEffect(() => {
        setSideBarOpen(smallMediumScreens ? false : isUserNavBarOpen);
    }, [smallMediumScreens]);

    useEffect(() => {
        dispatch(doSetNavbarStatus({ isOpen: isSideBarOpen }));
    }, [isSideBarOpen]);

    useEffect(() => {
        if (location?.pathname) {
            setSelected(location.pathname);
        }
    }, [location?.pathname]);

    const getNumberCreated = useCallback(async () => {
        if (user.id) {
            const today = new Date();
            const sevenDaysAgoMs = 7 * 24 * 60 * 60 * 1000;
            const lastWeek = new Date(today.getTime() - sevenDaysAgoMs);

            await candidatesRef
                .where("created_at", ">", lastWeek)
                .where("recruiter_provider_id", "==", user.id)
                .orderBy("created_at", "desc")
                .onSnapshot((snap) => {
                    setState((st) => ({
                        ...st,
                        candidatesCount: snap.size
                    }));
                });
        }
    }, [user]);

    useEffect(() => {
        getNumberCreated();
    }, [getNumberCreated]);

    const drawer = (
        <div className={classes.navBar}>
            <div>
                <Link to={ROUTE_AUTHENTICATED_CANDIDATE_LIST} className={classes.toolbar}>
                    <Logo className={classes.logo} />
                </Link>
                <NavList
                  handleDrawersToggle={handleDrawersToggle}
                  handleMobileDrawerToggle={handleMobileDrawerToggle}
                  selected={selected}
                  location={location}
                  isSideBarOpen={matches ? mobileOpen : isSideBarOpen}
                />
            </div>
            <div className={classes.signOut}>
                <IconButton
                  aria-label="logout"
                  onClick={signOut}
                  className={classes.signOutButton}
                  data-for="logout"
                  data-tip="Sign Out"
                  data-iscapture="true"
                >
                    <ExitToAppIcon className={classes.navItemIcon} color="secondary" fontSize="large" />
                </IconButton>
                <div className={`${classes.sidebarText} ${classes.signoutText}`}>
                    Sign Out
                </div>
            </div>

            <ReactTooltip
              id="logout"
              place="right"
              effect="solid"
              textColor="#ffffff"
              backgroundColor="#221A16"
              border
              borderColor="#dadde9"
              className={classes.reactTooltip}
            />
        </div>
    );

    useEffect(() => {
        hitPageOnAnalytics(ROUTE_AUTHENTICATED_ROOT);
    }, []);

    return (
        <div className={classes.root}>
            {matches && (
            <Header>
                <div style={{ marginLeft: "8px" }}>
                    <Link to={ROUTE_AUTHENTICATED_CANDIDATE_LIST}>
                        <Logo className={classes.logo} />
                    </Link>
                </div>

                <div style={{
                    display: "flex",
                    backgroundColor: "#221A16",
                    justifyContent: "center",
                    width: 65,
                    height: 65
                }}
                >
                    <IconButton
                      aria-label="open drawer"
                      onClick={handleMobileDrawerToggle}
                      className={classes.menuButton}
                    >
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </div>
            </Header>
            )}
            <nav className={classes.drawer} aria-label="sidebar menu">
                <Hidden smUp implementation="css">
                    <Drawer
                      variant="temporary"
                      anchor="left"
                      open={mobileOpen}
                      onClose={handleMobileDrawerToggle}
                      classes={{
                          paper: classes.drawerPaper,
                      }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                      className={clsx(classes.drawer, {
                          [classes.drawerOpen]: isSideBarOpen,
                          [classes.drawerClose]: !isSideBarOpen,
                      })}
                      classes={{
                          paper: clsx({
                              [classes.drawerOpen]: isSideBarOpen,
                              [classes.drawerClose]: !isSideBarOpen,
                          }),
                      }}
                      variant="permanent"
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <div className={`${classes.mainContainer} ${isSearchCandidates ? "" : classes.mainContainerScroll}`}>
                <main className={classes.content} style={{ minHeight: "100vh" }}>
                    {children}
                </main>
            </div>
            <div className={classes.createdCandidates}>
                    Hi
                {" "}
                {user?.firstName}
                {" "}
[
                {state.candidatesCount}
                {" "}
out of 5]
            </div>
        </div>
    );
};

export default NavigationBar;
