import React, { useState, FC } from "react";
import {
    Box, List, ListItem, ListItemText, ListItemIcon, Divider, Typography, Button
} from "@material-ui/core";
import marked from "marked";
import MdEditor from "react-markdown-editor-lite";
import useStyles from "./style";
import { IAddLogProps } from "./interface";
import { recruiterLogsRef } from "../../../../FirebaseUtils/collectionRefs";
import { firebase, auth } from "../../../../firebase";
import { toast } from "react-toastify";
import {
    ErrorMsg,
    CreateIcon,
    logLabels,
    ILogLabel,
    ERecruiterLogTypes,
    IRecruiterLog
} from "compass-shared";
import "react-markdown-editor-lite/lib/index.css";

const AddLog: FC<IAddLogProps> = ({ candidateId, recruiterName }) => {
    const classes = useStyles();
    const [selectedLabelType, setSelectedLabelType] = useState<ERecruiterLogTypes>(ERecruiterLogTypes.NOTE);
    const [noteHtml, setNoteHtml] = useState<string>("");
    const [noteText, setNoteText] = useState<string>("");

    const getRecruiterName = (): string => {
        if (recruiterName) {
            return recruiterName;
        } if (auth.currentUser && auth.currentUser.displayName) {
            return auth.currentUser.displayName;
        }
        return "";
    };

    const addNewLog = async () => {
        if (!(noteHtml.trim())) {
            setNoteText("");
            setNoteHtml("");
            return;
        }
        try {
            const newLog: IRecruiterLog = {
                type: selectedLabelType,
                created_at: firebase.firestore.Timestamp.now(),
                candidate_id: candidateId,
                note: noteHtml,
                recruiter_id: auth.currentUser ? auth.currentUser.uid : "",
                recruiter_name: getRecruiterName()
            };
            await recruiterLogsRef.add(newLog);
            setNoteText("");
            setNoteHtml("");
        } catch (error) {
            toast.error(<ErrorMsg />);
        }
    };

    function handleEditorChange({ html, text }: { html: string; text: string }) {
        setNoteHtml(html);
        setNoteText(text);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" gutterBottom>
                <Box fontWeight="fontWeightBold" fontSize="14px">
                    Recruiter activity
                </Box>
            </Typography>
            <Box display="flex" color="#b7b7b7" borderBottom={1} borderTop={1} mb={2}>
                <Box borderRight={1}>
                    <List component="div" aria-label="main" disablePadding>
                        {logLabels.map((label: ILogLabel, index: number, arr) => (
                            <React.Fragment key={label.id}>
                                <ListItem
                                  className={classes.logWrapper}
                                  button
                                  selected={selectedLabelType === label.key}
                                  dense
                                  disableGutters
                                  onClick={() => setSelectedLabelType(label.key)}
                                >
                                    <ListItemIcon className={classes.icon}>
                                        <Box color={label.color}>
                                            <CreateIcon icon={label.key} />
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText classes={{ primary: classes.listText }}>
                                        {label.text}
                                    </ListItemText>
                                </ListItem>
                                {index + 1 !== arr.length ? <Divider /> : ""}
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                    <MdEditor
                      style={{ height: "100%" }}
                      config={{
                          view: { menu: true, md: true, html: false },
                          canView: {
                              menu: true,
                              md: true,
                              html: false,
                              fullScreen: false,
                              hideMenu: true,
                          }
                      }}
                      value={noteText}
                      renderHTML={(text) => marked(text)}
                      onChange={handleEditorChange}
                    />
                    <Button variant="outlined" onClick={addNewLog}>Submit</Button>
                </Box>
            </Box>
        </div>
    );
};

export default AddLog;
