import React, { FC } from "react";
import List from "@material-ui/core/List";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import PeopleIcon from "@material-ui/icons/People";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Box, useMediaQuery } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import IconButton from "@material-ui/core/IconButton";

import NavItem from "./NavItem";
import useStyles from "./style";
import {
    ROUTE_AUTHENTICATED_SETTINGS,
    ROUTE_AUTHENTICATED_CREATE_CANDIDATE,
    ROUTE_AUTHENTICATED_SEARCH,
    ROUTE_AUTHENTICATED_JOB_OPENINGS,
    ROUTE_AUTHENTICATED_FAQ
} from "../../routes";
import { WindowLocation } from "@reach/router";
import CustomToolTip from "../../SharedComponents/ToolTip";

interface IProps {
    selected: string;
    location?: WindowLocation;
    handleDrawersToggle: () => void;
    handleMobileDrawerToggle: () => void,
    isSideBarOpen: boolean;
}
const NavList: FC<IProps> = ({
    selected, children, handleDrawersToggle, isSideBarOpen,
    handleMobileDrawerToggle
}) => {
    const classes = useStyles({});

    const matches = useMediaQuery("(min-width:600px)");

    const renderToggleDrawer = () => (
        isSideBarOpen ? (
            <div
              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              className={classes.drawerText}
              onClick={handleDrawersToggle}
            >
                <div
                  className={`${classes.IconWidth} ${classes.toggleDrawer}`}
                >
                    <IconButton
                      classes={{
                          root: classes.toggleDrawerRoot
                      }}
                      onClick={handleDrawersToggle}
                    >
                        {isSideBarOpen ? <ChevronLeftIcon fontSize="large" /> : <ChevronRightIcon fontSize="large" />}
                    </IconButton>
                </div>
                <div onClick={handleDrawersToggle} className={`${classes.sidebarText} ${classes.sidebarMinimize}`}>
              Collapse
                </div>
            </div>
        ) : (
            <CustomToolTip
              title="Expand"
              arrow
              placement="right"
              className={classes.reactTooltip}
            >
                <div
                  style={{ display: "flex", alignItems: "center", width: 46 }}
                  className={classes.drawerTextClosed}
                >
                    <div
                      className={`${classes.IconWidth} ${classes.toggleDrawer}`}
                    >
                        <IconButton
                          classes={{
                              root: classes.toggleDrawerRoot
                          }}
                          onClick={handleDrawersToggle}
                        >
                            {isSideBarOpen
                                ? <ChevronLeftIcon fontSize="large" />
                                : <ChevronRightIcon fontSize="large" />}
                        </IconButton>
                    </div>
                </div>
            </CustomToolTip>
        )
    );

    return (
        <List
          classes={{
              padding: classes.muiNavList
          }}
        >
            <Box mb={4} position="relative">
                <NavItem
                  selected={selected}
                  Icon={PeopleIcon}
                  path={ROUTE_AUTHENTICATED_SEARCH}
                  title="Search Candidates"
                  isSideBarOpen={isSideBarOpen}
                  handleDrawersToggle={handleMobileDrawerToggle}
                />
                {/* {location?.pathname !== ROUTE_AUTHENTICATED_SETTINGS && (
            <NavItem
              selected={selected}
              Icon={PersonAddIcon}
              path={ROUTE_AUTHENTICATED_CREATE_CANDIDATE}
              title="Create Candidate"
            />
            )} */}
                <NavItem
                  selected={selected}
                  Icon={PersonAddIcon}
                  path={ROUTE_AUTHENTICATED_CREATE_CANDIDATE}
                  title="Create Candidate"
                  isSideBarOpen={isSideBarOpen}
                  handleDrawersToggle={handleMobileDrawerToggle}
                />
                <NavItem
                  selected={selected}
                  Icon={WorkOutlineIcon}
                  path={ROUTE_AUTHENTICATED_JOB_OPENINGS}
                  title="Job Openings"
                  isSideBarOpen={isSideBarOpen}
                  handleDrawersToggle={handleMobileDrawerToggle}
                />
                <NavItem
                  selected={selected}
                  Icon={SettingsIcon}
                  path={ROUTE_AUTHENTICATED_SETTINGS}
                  title="Settings"
                  isSideBarOpen={isSideBarOpen}
                  handleDrawersToggle={handleMobileDrawerToggle}
                />

                <NavItem
                  selected={selected}
                  Icon={HelpIcon}
                  path={ROUTE_AUTHENTICATED_FAQ}
                  title="FAQ / Onboarding"
                  isSideBarOpen={isSideBarOpen}
                  handleDrawersToggle={handleMobileDrawerToggle}
                />

                {matches && renderToggleDrawer()}
            </Box>
            <Box>
                {children}
            </Box>
        </List>
    );
};

export default NavList;
