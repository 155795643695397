import { makeStyles } from "@material-ui/core/styles";
// TODO: get the theme colors right
const useStyles = makeStyles((theme) => ({
    "@keyframes slideIn": {
        "0%": {
            transform: "translateX(100%)"
        },
        "100%": {
            transform: "translateX(0)"
        }
    },
    deleteIcon: {
        padding: "unset",
        boxShadow: "none !important"
    },
    close: {
        height: "26px"
    },
    title: {
        color: "#2F2824",
        fontSize: "14px",
        fontWeight: 600
    },
    inputRoot: {
        fontSize: theme.spacing(3)
    },
    outlineInput: {
        padding: theme.spacing(3)
    },
    inputField: {
        "& .MuiInputBase-input": {
            height: "unset",
        },
        "& .MuiGrid-container": {
            justifyContent: "space-between",
            color: "#000"
        },
        "& .MuiOutlinedInput-root, .MuiInputBase-root": {
            height: "40px"
        },
        "& .MuiGrid-grid-sm-6": {
            maxWidth: "48%",
            flexBasis: "48%",
            marginBottom: "25px"
        }
    },
    sectionDivider: {
        width: "100%",
        background: "#E7E8EC",
        height: "1px",
        margin: "20px 0",
    },
    labelText: {
        color: "#2F2824",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        marginBottom: "10px"
    },
    subTitle: {
        fontSize: "16px",
        lineHeight: "19.5px",
        fontWeight: 600,
        fontStyle: "normal",
        marginTop: "10px",
        color: "#2F2824"
    },
    box: {
        animation: "$slideIn 300ms",
        backgroundColor: theme.palette.background.default,
        borderRadius: "10px",
        border: "1px solid transparent",
        margin: "0",
        boxShadow: "2px -1px 4px 2px #0000000d",
        overflow: "hidden",
        padding: theme.spacing(2.5),
        paddingTop: theme.spacing(0.5),
        "&:hover": {
            border: "1px solid #c3c2c2",
        }
    },

    boxContainer: {
        height: "100%"
    },

    closeIconWrapper: {
        background: theme.palette.grey[300],
        margin: theme.spacing(0.5),
        padding: theme.spacing(1)
    },

    addButton: {
        height: "40px",
        marginRight: "22px",
        marginBottom: "40px",
        marginTop: "20px",
        width: "100%",
        maxWidth: "150px",
        backgroundColor: "#221A16",
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "14px",
        color: "#FFF",
        alignSelf: "flex-end",
        border: "none",
        textAlign: "center",
        borderRadius: 7,
        "&:hover": {
            backgroundColor: "rgba(54,55,64,.8)",
            boxShadow: "none"
        }
    },
    experienceButton: {
        marginBottom: "5px",
        marginTop: "20px",
    }
}));

export default useStyles;
