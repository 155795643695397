import { createSlice } from "@reduxjs/toolkit";

import {
    fetchCandidates,
    fetchGroupCandidates,
    createCandidate,
    updateCandidate,
    getJobSkills,
} from "./candidates.actions";

export interface IState {
  candidateList: any;
  candidateGroups: any;
  error: string;
  status: string;
  groupStatus: string;
  isFetchingMore: boolean;
  isFilterRequest: boolean;
  searchData: any;
  isCreateCandidate: boolean;
  fetchingJobSkills: string;
  jobFunctionSkills: any;
}

const initialState: IState = {
    candidateList: [],
    candidateGroups: null,
    error: "",
    status: "",
    groupStatus: "",
    isFetchingMore: false,
    isFilterRequest: false,
    searchData: null,
    isCreateCandidate: false,
    fetchingJobSkills: '',
    jobFunctionSkills: []
};

export const candidateSlice = createSlice({
    name: "candidates",
    initialState,
    reducers: {
        increment: (state, action) => {
            state.candidateList = [1, 2];
            // ✅ CORRECT: logs a plain JS copy of the current data
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCandidates.pending, (state, action) => {
            const { isFilterRequest } = action.meta.arg.candidatesSearchInfo;
            state.isFetchingMore = action.meta.arg.candidatesSearchInfo.isFetchingMore;
            state.isFilterRequest = isFilterRequest;
            state.status = "pending";
            if (isFilterRequest) {
                state.searchData = action.meta.arg.candidatesSearchInfo;
            } else {
                state.searchData = null;
            }
        })
            .addCase(fetchCandidates.fulfilled, (state, action) => {
                state.candidateList = action.payload;
                state.status = "fulfilled";
                state.isFetchingMore = false;
                state.isCreateCandidate = false;
            })
            .addCase(fetchCandidates.rejected, (state, action: any) => {
                state.status = "rejected";
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    state.error = action.payload.errorMessage;
                } else {
                    state.error = action.error.message;
                }
            });
        builder
            .addCase(fetchGroupCandidates.pending, (state, action) => {
                state.groupStatus = "pending";
            })
            .addCase(fetchGroupCandidates.fulfilled, (state, action) => {
                state.candidateGroups = action.payload;
                state.groupStatus = "fulfilled";
            })
            .addCase(fetchGroupCandidates.rejected, (state, action) => {
                state.groupStatus = "rejected";
            });

        builder
            .addCase(createCandidate.pending, (state, action) => {
                state.isCreateCandidate = false;
            })
            .addCase(createCandidate.fulfilled, (state, action) => {
                state.isCreateCandidate = true;
                state.candidateList = {
                    ...action.meta.arg.candidates,
                    paginationInfo: {
                        ...action.meta.arg.candidates.paginationInfo,
                        totalHits:
                      action.meta.arg.candidates.paginationInfo.totalHits + 1,
                    },
                    candidates: [
                        action.meta.arg.newCandidateProfile,
                        ...action.meta.arg.candidates.candidates
                    ],
                };
            })
            .addCase(createCandidate.rejected, (state, action) => {
                state.isCreateCandidate = false;
            });

        builder
            .addCase(updateCandidate.pending, (state, action) => {
                state.isCreateCandidate = false;
            })
            .addCase(updateCandidate.fulfilled, (state, action) => {
                state.isCreateCandidate = true;
                state.candidateList = action.payload.candidates;
            })
            .addCase(updateCandidate.rejected, (state, action) => {
                state.isCreateCandidate = false;
            });
        
        builder
            .addCase(getJobSkills.pending, (state, action) => {
                state.fetchingJobSkills = 'pending';
            })
            .addCase(getJobSkills.fulfilled, (state, action) => {
                state.fetchingJobSkills = 'fulfilled';
                state.jobFunctionSkills = action.payload;
            })
            .addCase(getJobSkills.rejected, (state, action) => {
                state.fetchingJobSkills = 'rejected';
            })
    }
});

export const { increment } = candidateSlice.actions;
export default candidateSlice.reducer;
