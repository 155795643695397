import React, {
    FC, useState, useEffect, useCallback
} from "react";
import { RouteComponentProps } from "@reach/router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useStyles from "./style";
import useAppContext from "../../../context/store/store";
import {
    Button, CircularProgress, FormControl, Grid, Typography
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import {
    EmptyList, ErrorMsg, IRecruiter, ISubscribedQueries,
    JobFunctionList, LoadingPage,
    SelectMenu, SuccessMsg
} from "compass-shared";
import {
    doCompleteSearchJobOpenings,
    doStartSearchJobOpenings,
    doToggleCreateJobOpening
} from "../../../context/reducer/Actions";
import {
    JobStatus, RecruiterOptions, columns, ActionsJoin, ActionsOwnerUnarchive, ActionsOwnerArchive
} from "./utils";
import { CreateJobOpening } from "../Home/CreateJobOpening";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { subscribedQueriesRef } from "../../../FirebaseUtils/collectionRefs";
import { toast } from "react-toastify";
import { functions } from "../../../firebase";
import { archiveJobOpeningTemplate } from "../RequestIntro/email-template";
import { fetchJobs } from "../../../redux/services/jobs/jobs.actions";
import { RootState } from "../../../redux/store";
import JobListsComponent from "../../../Components/jobOpenings";

const JobOpeningsComponent: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const reduxDispatch = useDispatch();

    const [{
        jobOpeningSearchInfo,
        user,
        isCreateCandidateFormOpen
    }, dispatch]: any = useAppContext();

    const [editJobOpening, setEditJobOpening] = useState(false);
    const [selectedJobOpening, setSelectedJobOpening] = useState({} as ISubscribedQueries);
    const [selectedAction, setSelectedAction] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchJobOpeningState, setSearchJobOpeningState] = useState(false);
    const [allJobOpening, setAllJobOpenings] = useState<any>([]);

    const jobList: any = useSelector((state: RootState) => state.jobs, shallowEqual);

    const getJobs = useCallback(async () => {
        await reduxDispatch(fetchJobs({
            jobOpeningSearchInfo
        }));
    }, []);

    useEffect(() => {
        getJobs();
    }, []);

    useEffect(() => {
        if (jobList && jobList.jobList.data) {
            setAllJobOpenings(jobList.jobList.data);
        }
    }, [jobList]);

    const {
        handleSubmit, control, getValues, reset
    } = useForm({
        defaultValues: {
            job_function: "",
            job_status: "",
            recruiter: "",
            actions: ""
        }
    });

    const notificationMessage = () => {
        switch (selectedAction) {
            case "join": return "joined";
            case "leave": return "left";
            case "un-archive": return "opened";
            case "archive": return "archived";
        }
    };

    const formatWords = (word: any) => {
        const formatted = Array.isArray(word) ? word[0] : word;
        if (!formatted.length) return "-";
        return formatted.toLowerCase() === "none" ? "-" : formatted;
    };

    const sendEmail = async (recruiter:IRecruiter) => {
        const senderEmail = process.env.REACT_APP_JOB_OPENINGS_EMAIL || "";
        const action = notificationMessage();
        const emailMessage = archiveJobOpeningTemplate({
            recruiterName: recruiter.firstName,
            jobOpening: {
                job_function: formatWords(selectedJobOpening.job_function),
                experience_level: formatWords(selectedJobOpening.experience_level),
                companyName: formatWords(selectedJobOpening.companyName),
                skills: selectedJobOpening?.skills || [],
                location: selectedJobOpening.location || "-"
            },
            actionedBy: user.firstName === recruiter.firstName ? "You" : user.firstName,
            action
        });
        const emailData = {
            emailObject: {
                sender: senderEmail,
                receiver: recruiter.email,
            },
            subject: `Job ${action}`,
            actualMessage: emailMessage
        };
        await functions.httpsCallable("emails-archiveUnarchiveJobOpening")(emailData);
    };

    useEffect(() => {
        dispatch(doStartSearchJobOpenings({ ...jobOpeningSearchInfo, isUpdateRequest: false }));
        return () => dispatch(doCompleteSearchJobOpenings(null));
    }, []);

    const onSearch = async (formData: any) => {
        await reduxDispatch(fetchJobs({
            ...jobOpeningSearchInfo,
            job_function: formData.job_function[0],
            is_active: formData.job_status[0],
            recruiter_id: formData.recruiter[0] === "Own Jobs" ? user.id : "",
            isUpdateRequest: false
        }));
    };

    const onChangeSubmit = () => {
        setTimeout(() => onSearch(getValues()), 100);
    };

    const handleSelectChange = ([event]: any) => {
        if (event && event.type === "click") {
            setTimeout(() => onChangeSubmit());
        }
        return [event.value] ?? "";
    };

    const onRowClick = () => ({});

    const resetForm = () => {
        reset({
            job_function: "",
            job_status: "",
            recruiter: "",
            actions: ""
        });
        onChangeSubmit();
    };

    const handleActionSelect = ([event]: any[], data: any) => {
        if (!event) return;

        switch (event.value) {
            case "Edit": {
                dispatch(doToggleCreateJobOpening(true));
                setSelectedJobOpening(data);
                setEditJobOpening(true);
                return;
            }

            case "Leave": {
                setSelectedAction("leave");
                setSelectedJobOpening(data);
                return;
            }

            case "Join": {
                setSelectedAction("join");
                setSelectedJobOpening(data);
                return;
            }

            case "Archive": {
                setSelectedAction("archive");
                setSelectedJobOpening(data);
                return;
            }

            case "Un-archive": {
                setSelectedAction("un-archive");
                setSelectedJobOpening(data);
            }
        }
    };

    const updateJobOpeningUI = (data: ISubscribedQueries) => {
        if (!allJobOpening.length) { return; }
        if (selectedAction === "archive") {
            const updatedJobOpenings = allJobOpening.filter((opening: ISubscribedQueries) => opening.objectID !== data.objectID);
            updatedJobOpenings.push(data);
            setAllJobOpenings(updatedJobOpenings);
            return;
        }

        if (selectedAction === "un-archive") {
            const updatedJobOpenings = allJobOpening.filter((opening: ISubscribedQueries) => opening.objectID !== data.objectID);
            updatedJobOpenings.unshift(data);
            setAllJobOpenings(updatedJobOpenings);
            return;
        }
        const updatedJobOpenings = allJobOpening.map((opening: ISubscribedQueries) => {
            if (opening.objectID === data.objectID) {
                return data;
            }
            return opening;
        });
        setAllJobOpenings(updatedJobOpenings);
    };

    const resetStateValues = (queryData = {}) => {
        updateJobOpeningUI(queryData as ISubscribedQueries);
        setSelectedJobOpening({} as ISubscribedQueries);
        setEditJobOpening(false);
        reset({ actions: "" });
    };

    const cancelStateActions = () => {
        setSelectedJobOpening({} as ISubscribedQueries);
        setSelectedAction("");
        reset({ actions: "" });
    };
    const onSubmit = async (data: ISubscribedQueries) => {
        const { recruiters } = data;
        try {
            const payload = { ...data };
            delete payload.recruiters;
            setIsSubmitting(true);
            await subscribedQueriesRef.doc(data?.objectID).update({ ...payload });
            setIsSubmitting(false);
            toast.success(<SuccessMsg text={`Job opening ${notificationMessage()}`} />);
        } catch (err) {
            toast.error(<ErrorMsg text={err.message || "Unknown error"} />);
            setIsSubmitting(false);
        } finally {
            setIsSubmitting(false);
            cancelStateActions();
            updateJobOpeningUI(data);
            if (!recruiters) return;
            recruiters.forEach((recruiter: IRecruiter) => sendEmail(recruiter));
        }
    };

    const submitAction = () => {
        switch (selectedAction) {
            case "leave": {
                const data = { ...selectedJobOpening };
                if (Array.isArray(data.recruiter_id)) {
                    data.recruiter_id = data.recruiter_id.filter((id: string) => id !== user.id);
                } else {
                    data.recruiter_id = "";
                }
                if (data?.recruiters) {
                    data.recruiters = data.recruiters.filter((recruiter) => recruiter.objectID !== user.id);
                }
                onSubmit(data);
                return;
            }

            case "join": {
                const data = { ...selectedJobOpening };
                if (Array.isArray(data.recruiter_id)) {
                    data.recruiter_id.push(user.id);
                } else if (data.recruiter_id) {
                    data.recruiter_id = [data.recruiter_id, user.id];
                } else {
                    data.recruiter_id = user.id;
                }
                if (data?.recruiters) {
                    data.recruiters = [...data.recruiters, { ...user, objectID: user.id }];
                }
                onSubmit(data);
                return;
            }

            case "archive": {
                const data = { ...selectedJobOpening };
                data.is_active = "Archived";
                onSubmit(data);
                return;
            }
            case "un-archive": {
                const data = { ...selectedJobOpening };
                data.is_active = "Open";
                onSubmit(data);
            }
        }
    };

    const isOwnerJobOwner = (recruiterIds: string | []) => (Array.isArray(recruiterIds)
        ? recruiterIds.some((id: string) => user.id === id)
        : recruiterIds === user.id);

    const renderActions = (recruiterIds: string | [], status : boolean | undefined) => {
        const isOwner = isOwnerJobOwner(recruiterIds);
        if (isOwner) {
            return status === undefined ? ActionsOwnerArchive : status ? ActionsOwnerArchive : ActionsOwnerUnarchive;
        }
        return ActionsJoin;
    };

    const matches = useMediaQuery("(min-height:800px)");

    return (
        <>
            <CreateJobOpening
              clearData={resetStateValues}
              data={selectedJobOpening}
              isEdit={editJobOpening}
              isOpen={isCreateCandidateFormOpen}
            />

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={!!selectedAction}
            >
                <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to
                        {" "}
                        {selectedAction}
                        {" "}
                        this Job Opening?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancelStateActions} color="primary">
                        Cancel
                    </Button>

                    {
                        isSubmitting
                            ? (
                                <div className={classes.actions}>
                                    <CircularProgress color="secondary" />
                                </div>
                            )
                            : (
                                <Button
                                  onClick={submitAction}
                                  color="primary"
                                >
                                Yes
                                </Button>
                            )
                    }
                </DialogActions>
            </Dialog>

            <div className={matches ? classes.parentXLHeight : classes.parent}>
                <div className={classes.container}>
                    <div className={classes.layout}>
                        <div className={classes.jobOpeningsFilter}>
                            <form onSubmit={handleSubmit(onSearch)}>
                                <Grid container spacing={2} className={classes.flexCenterWrapper}>
                                    <Grid item xs={12} className={classes.searchContainer}>
                                        <Grid
                                          container
                                          className={classes.searchContainerLayout}
                                          style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              width: "100%",
                                              alignItems: "center"
                                          }}
                                        >
                                            <Grid item xs={12} sm={3} md={2}>
                                                <Button
                                                  disabled={jobList.status === "pending"}
                                                  size="small"
                                                  onClick={(e: any) => {
                                                      e.preventDefault();
                                                      resetForm();
                                                  }}
                                                  classes={{ root: classes.resetButton, label: classes.resetLabel }}
                                                  color="primary"
                                                >
                                                Reset Filters
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} sm={3} md={2}>
                                                <FormControl
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                  classes={{ root: classes.textFieldRoot }}
                                                >
                                                    <Controller
                                                      as={(
                                                          <SelectMenu
                                                            items={JobFunctionList}
                                                            label="Job Function"
                                                          />
                                                                )}
                                                      name="job_function"
                                                      control={control}
                                                      defaultValue=""
                                                      onChange={handleSelectChange}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={3} md={2}>
                                                <FormControl
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                  classes={{ root: classes.textFieldRoot }}
                                                >
                                                    <Controller
                                                      as={(
                                                          <SelectMenu
                                                            items={JobStatus}
                                                            label="Job Status"
                                                          />
                                                                )}
                                                      name="job_status"
                                                      control={control}
                                                      defaultValue=""
                                                      onChange={handleSelectChange}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={3} md={2}>
                                                <FormControl
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                  classes={{ root: classes.textFieldRoot }}
                                                >
                                                    <Controller
                                                      as={(
                                                          <SelectMenu
                                                            items={RecruiterOptions}
                                                            label="Job Owners"
                                                          />
                                                                )}
                                                      name="recruiter"
                                                      control={control}
                                                      defaultValue=""
                                                      onChange={handleSelectChange}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={2} xs={6} className={classes.createJobOpeningButton}>
                                                <Button
                                                  onClick={() => { dispatch(doToggleCreateJobOpening(true)); }}
                                                  className={classes.createCandidateButton}
                                                >
                                                            Need more candidates?
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </div>
                        {
                            jobList && jobList.status === "pending"
                                ? (
                                    <div style={{ height: "calc(62vh)" }}>
                                        <LoadingPage text="Loading Job Openings..." />
                                    </div>
                                )
                                : allJobOpening && allJobOpening.length
                                    ? (
                                        <div className={classes.jobOpeningContainer}>
                                            <Paper style={{
                                                height: "80vh",
                                                width: "100%",
                                                margin: "0 auto",
                                                borderRadius: "9px"
                                            }}
                                            >
                                                <JobListsComponent
                                                  formatWords={formatWords}
                                                  isOwnerJobOwner={isOwnerJobOwner}
                                                  renderActions={renderActions}
                                                  handleActionSelect={handleActionSelect}
                                                  onRowClick={onRowClick}
                                                  control={control}
                                                  allJobOpening={allJobOpening}
                                                  columns={columns}
                                                />
                                            </Paper>
                                        </div>
                                    )
                                    : (
                                        <div className={classes.emptyContainer}>
                                            <EmptyList text="No Search Results" />
                                        </div>
                                    )
                                    }
                    </div>
                </div>
            </div>

        </>
    );
};

const JobOpenings = React.memo(JobOpeningsComponent);
export { JobOpenings };
