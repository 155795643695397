import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 72;

const useStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        backgroundColor: "transparent",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    regular: {
        minHeight: "56px",
        padding: 0,
        backgroundColor: "#ffffff",
    },
    searchContainer: {
        height: 65,
        width: "94%",
        margin: "0 auto",
        backgroundColor: "#FFFFFF"
    },
    elevation4: {
        boxShadow: "unset"
    },
    positionFixed: {
        left: 0,
        right: "unset",
    }
}));

export default useStyles;
