import React, { FC, useEffect, useState } from "react";
import {
    Grid, Box, Typography, Divider, IconButton
} from "@material-ui/core";
import useStyles from "./style";
import { Close, Edit } from "@material-ui/icons";
import AddLog from "../AddLog/AddLog";
import Educations from "../Education/Education";
import Experiences from "../Experience/Experience";
import Skills from "../Skills/Skills";
import RelocationStatusIcon from "../RelocationStatusIcon/RelocationStatusIcon";
import RecruitersInTouch from "../RecruitersInTouch";
import {
    UserAvatar,
    CopyToClipboard,
    ICandidate,
    EConnectedLinks,
    IConnectedLinksObj,
    VettingChips,
    getSalaryKey
} from "compass-shared";
import LinkedInIcon from "../../../../Assets/icons/linkedin-icon.svg";
import PdfIcon from "../../../../Assets/icons/pdf-icon.svg";
import { Link, RouteComponentProps } from "@reach/router";
import ArchiveCandidate from "../../ArchiveCandidate";

type ProfileDataPropsType = {
    candidate: ICandidate;
    currentCandidateId: string;
    closeProfile: any;
    user: any;
}

const areEqual = (prevProp: any, nextProp: any) => prevProp.currentCandidateId === nextProp.currentCandidateId;

const ProfileData: FC<RouteComponentProps & ProfileDataPropsType> = ({
    candidate, currentCandidateId, closeProfile, user
}) => {
    const classes = useStyles();
    const [linkedInUrl, setLinkedInUrl] = useState("");
    useEffect(() => {
        const linkedInObj: IConnectedLinksObj | undefined = candidate.connected_links
            .find((link: IConnectedLinksObj) => link.key === EConnectedLinks.LINKEDIN);
        setLinkedInUrl(linkedInObj?.link || "");
    }, []);
    return (
        <>
            <Box display="flex" justifyContent="flex-end" mr={-1.5}>
                <ArchiveCandidate
                  recruiterId={user.id}
                  candidateId={currentCandidateId}
                  isArchived={!!candidate.is_archived}
                  wrapperClass={classes.closeIconWrapper}
                  iconClass={classes.smallIcon}
                />
                <Link to="edit">
                    <IconButton
                      aria-label="edit"
                      color="secondary"
                      className={classes.closeIconWrapper}
                    >
                        <Edit classes={{ root: classes.smallIcon }} />
                    </IconButton>
                </Link>
                <IconButton
                  aria-label="close"
                  onClick={closeProfile}
                  className={classes.closeIconWrapper}
                >
                    <Close classes={{ root: classes.smallIcon }} />
                </IconButton>
            </Box>
            <Grid item xs={12} className={classes.personalInfo}>
                <UserAvatar
                  variant="square"
                  src={candidate?.candidate_avatar_url || candidate?.thumbnail_avatar_url || ""}
                  styleClass={classes.avatar}
                />
                <Box className={classes.overallInformation}>
                    <Box display="flex" alignItems="center">
                        <Typography component="div" variant="h6" color="textPrimary">
                            <Box fontWeight="fontWeightBold" fontSize={12}>
                                {`${candidate.firstName} ${candidate.lastName}`}
                            </Box>
                        </Typography>
                        {(linkedInUrl || candidate.cv_url) && (
                        <Divider
                          orientation="vertical"
                          classes={{ vertical: classes.divider }}
                        />
                        )}
                        {linkedInUrl && (
                            <a
                              href={linkedInUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: "flex" }}
                            >
                                <img
                                  src={LinkedInIcon}
                                  alt="icon"
                                  className={classes.icons}
                                />
                            </a>

                        )}

                        {candidate.cv_url && (
                            <a
                              href={candidate.cv_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: "flex" }}
                            >
                                <img
                                  src={PdfIcon}
                                  alt="icon"
                                  className={classes.icons}
                                />
                            </a>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography component="div" color="textSecondary">
                            <Box fontSize={10} fontWeight={600}>
                                <CopyToClipboard text={candidate.email || ""} />
                                <CopyToClipboard text={candidate.phone || ""} />
                            </Box>
                        </Typography>
                    </Box>
                    <Box clone display="flex" alignItems="center">
                        <Typography component="div" variant="subtitle1" color="textPrimary">
                            <Box fontSize={10}>
                                {candidate?.location}
                            </Box>
                            <RelocationStatusIcon status={candidate.relocation_status || ""} />
                        </Typography>
                    </Box>
                    <div className={classes.centredAlignedFlex}>
                        {
                            typeof candidate.min_salary_per_year === "number"
                            && (
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="textPrimary"
                              style={{ fontSize: "1em", fontWeight: "bold" }}
                            >
                                {
                                    getSalaryKey(candidate.max_salary_per_year, candidate.min_salary_per_year)
                                    === "default"
                                        ? ""
                                        : getSalaryKey(candidate.max_salary_per_year, candidate.min_salary_per_year)
                                }

                            </Typography>
                            )
                        }
                    </div>
                </Box>
            </Grid>
            <Box my={2.5} />
            {/* Skills */}
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
                {candidate.skills
                    && (
                    <Skills
                      skills={candidate.skills}
                      experienceLevel={candidate.experience_level}
                      jobFunction={candidate.job_function}
                    />
                    )}
            </Grid>

            { (candidate.is_a_player
              || candidate.is_skills_assessed
              || candidate.is_interviewed)
            && (<Box my={1.25} />)}
            {/* Vetting */}
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Box display="flex" alignItems="space-between">
                    <VettingChips
                      is_interviewed={candidate.is_interviewed}
                      is_skills_assessed={candidate.is_skills_assessed}
                      is_a_player={candidate.is_a_player}
                    />
                </Box>
            </Grid>

            <Box my={2.5} />
            {/* Experience */}
            <Grid item xs={12}>
                <Experiences experiences={candidate.experiences} />
            </Grid>
            <Box my={2.5} />
            {/* Educations */}
            <Grid item xs={12}>
                <Educations educations={candidate.educations} />
            </Grid>
            <Box my={2.5} />
            {/* Recruiters in touch */}
            <Grid item xs={12}>
                <RecruitersInTouch candidateId={currentCandidateId} user={user} />
            </Grid>
            <Box my={2.5} />
            {/* Add log */}
            <Grid item xs={12}>
                <AddLog candidateId={currentCandidateId} recruiterName={`${user.firstName} ${user.lastName}`} />
            </Grid>
            <Grid item xs={12}>
                {/* <LogTimeLine candidateId={currentCandidateId} /> */}
            </Grid>
        </>
    );
};

export default React.memo(ProfileData, areEqual);
