import React, {
    FC, useCallback, useEffect, useState, useRef
} from "react";
import { RouteComponentProps } from "@reach/router";
import * as QueryString from "query-string";
import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";

import { IState } from "./interface";
import { functions } from "../../../firebase";
import useStyles from "./style";
import {
    acceptedCandidateTemplate,
    rejectedRecruiterTemplate,
    acceptedRecruiterTemplate
} from "./email-template";
import hitPageOnAnalytics from "../../../Utils/gTagHandler";
import { ROUTE_SEND_INTRO } from "../../../routes";
import RequestIntroItem from "../../../Components/RequestIntro";
import {
    NotFound,
    LoadingPage,
    SuccessMsg,
    ErrorMsg
} from "compass-shared";
import useAppContext from "../../../context/store/store";

const initialState = {
    loading: false,
    imageLoading: false,
    fetchingIntroData: false,
    hasInvalidParams: false,
    isModalOpen: false,
    contentEdidatableDisabled: false,
    textAreaValue: "",
    errorMessage: "",
    status: "pending",
    modalTitle: "",
    modalBody: "",
    recruiterIntouch: {},
    requestedBy: {},
    candidate: {},
    searchParams: {}
} as IState;

const RequestIntro: FC<RouteComponentProps> = (props: any) => {
    const classes = useStyles();

    const [state, setState] = useState<IState>(initialState);
    const editableEl = useRef(null);
    const [{
        user,
    }, ]: any = useAppContext();

    const getIntroData = useCallback(async () => {
        const params = QueryString.parse(props.location.search);
        const acceptedStatus: any = ["accepted", "rejected"];
        if (params?.recruiterId && params?.candidateId && (acceptedStatus.includes(params.status))) {
            const { recruiterId, candidateId, status } = params;
            setState((st) => ({ ...st, fetchingIntroData: true }));
            const requestIntroSnap = await functions
                .httpsCallable("recruiters-handleRequestIntro")({ recruiterId, candidateId, status });
            if (requestIntroSnap.data.candidate
                && requestIntroSnap.data.requestedBy
                && requestIntroSnap.data.recruiterIntouch
            ) {
                let templateText = "No template available";
                if (requestIntroSnap.data.status === "accepted") {
                    templateText = acceptedCandidateTemplate({ ...requestIntroSnap.data });
                } else if (requestIntroSnap.data.status === "rejected") {
                    const CURRENT_HOST = `${props?.location?.origin}/search-candidates/${params?.candidateId}`;
                    templateText = rejectedRecruiterTemplate(
                        { ...requestIntroSnap.data, candidateProfile: CURRENT_HOST }
                    );
                }
                setState((st) => ({
                    ...st,
                    fetchingIntroData: false,
                    searchParams: params,
                    recruiterIntouch: requestIntroSnap.data.recruiterIntouch,
                    requestedBy: requestIntroSnap.data.requestedBy,
                    candidate: requestIntroSnap.data.candidate,
                    status: requestIntroSnap.data.status,
                    textAreaValue: templateText
                }));
            } else {
                setState((st) => ({
                    ...st,
                    hasInvalidParams: true,
                    errorMessage: requestIntroSnap.data.errorMessage
                }));
            }
        } else {
            setState((st) => ({ ...st, hasInvalidParams: true }));
        }
    }, [props.location]);

    const hitAnalytics = useCallback(() => {
        hitPageOnAnalytics(ROUTE_SEND_INTRO);
    }, []);

    useEffect(() => {
        getIntroData();
        hitAnalytics();
    }, [getIntroData, hitAnalytics]);

    const handleChanges = (event: any) => {
        setState((st) => ({
            ...st,
            textAreaValue: event.target.value
        }));
    };

    const sendEmail = async ({
        emailObject, subject, status, actualMessage
    }: any) => {
        const emailData = {
            emailObject,
            subject,
            status,
            actualMessage
        };

        const contactRecruiterResp: any = await functions
            .httpsCallable("emails-sendAcceptRejectIntroEmail")(emailData);
        return contactRecruiterResp;
    };

    const handleRequestIntro = async () => {
        setState((st) => ({ ...st, loading: true }));
        if (state.status === "accepted") {
            const toCandidate = {
                emailObject: {
                    sender: state?.recruiterIntouch,
                    candidate: state?.candidate,
                    requestedBy: state?.requestedBy?.email,
                    recruiterIntouchId: state?.searchParams?.recruiterId,
                    candidateId: state?.searchParams?.candidateId
                },
                subject: `${state.recruiterIntouch?.firstName} <> ${state.candidate?.firstName}  intro`,
                status: state.status,
                actualMessage: state.textAreaValue
            };
            const emailTemplate = acceptedRecruiterTemplate({
                recruiterIntouch: state.recruiterIntouch,
                requestedBy: state.requestedBy,
                candidate: state.candidate
            });
            const sendCandidate = await sendEmail({ ...toCandidate });
            const res = await Promise.allSettled([sendCandidate]);
            if (res[0]?.status !== "fulfilled" || !res[0]?.value?.data?.success) {
                setState((st) => ({ ...st, loading: false }));
                toast.error(<ErrorMsg text="Error sending email to candidate" />);
            } else {
                // if(res[1]?.status !== "fulfilled") {
                //     // contact recruiter with correct logs.
                // }
                setState((st) => ({
                    ...st,
                    loading: false,
                    modalTitle: "Intro Sent!",
                    isModalOpen: true,
                    modalBody: "We have now sent the email."
                }));
                // toast.success(<SuccessMsg text="Email sent" />);
            }
        } else if (state.status === "rejected") {
            const toRequestingRecruiter = {
                emailObject: {
                    sender: state?.recruiterIntouch,
                    candidate: state?.candidate,
                    requestedBy: state?.requestedBy?.email,
                    recruiterIntouchId: state?.searchParams?.recruiterId,
                    candidateId: state?.searchParams?.candidateId
                },
                subject: "Intro rejected",
                status: state.status,
                actualMessage: state.textAreaValue
            };
            const resp = await sendEmail({ ...toRequestingRecruiter });
            if (!resp.data.success) {
                setState((st) => ({ ...st, loading: false }));
                // toast.error(<ErrorMsg text='Error sending email to requesting recruiter' />);
            } else {
                // toast.success(<SuccessMsg text="Email sent" />);
                setState((st) => ({
                    ...st,
                    loading: false,
                    isModalOpen: true,
                    modalTitle: "Intro Rejected!",
                    modalBody: "We have now sent the email."
                }));
            }
        }
    };

    const handleOnBlur = () => {
        const sanitizeConf = {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br"],
            allowedAttributes: { a: ["href"] }
        };
        setState((st) => ({
            ...st,
            contentEdidatableDisabled: true,
            textAreaValue: sanitizeHtml(state.textAreaValue, sanitizeConf)
        }));
    };

    const renderIntroItem = () => {
        if (state.status === "accepted" || state.status === "rejected") {
            return (
                <RequestIntroItem
                  textAreaValue={state.textAreaValue}
                  fetchingIntroData={state.fetchingIntroData}
                  candidate={state.candidate}
                  recruiterIntouch={state.recruiterIntouch}
                  imageLoading={state.imageLoading}
                  loading={state.loading}
                  editableEl={editableEl}
                  status={state.status}
                  contentEdidatableDisabled={state.contentEdidatableDisabled}
                  handleChanges={handleChanges}
                  onBlur={handleOnBlur}
                  handleRequestIntro={handleRequestIntro}
                  isModalOpen={state.isModalOpen}
                  modalBody={state.modalBody}
                  modalTitle={state.modalTitle}
                  candidateProfile={`${props?.location?.origin}/candidates/${state.searchParams?.candidateId}`}
                  closeModal={() => setState((st) => ({ ...st, isModalOpen: false }))}
                />
            );
        }
        return <LoadingPage size={50} text="fetching intro data" />;
    };

    return (
        <div className={classes.parent}>
            {state.hasInvalidParams
                ? <NotFound text={state.errorMessage ? state.errorMessage : "Page Not found"} />
                : renderIntroItem()}
        </div>
    );
};

export default RequestIntro;
