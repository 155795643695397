import { toSentenceCase } from "../../../Utils/strings";

export const acceptedCandidateTemplate = ({ recruiterIntouch, requestedBy, candidate }: any) => (`
    Hi ${candidate?.firstName || ""}  👋 <br><br>

    I hope you're doing well!<br><br>

    As mentioned, I shared your profile with <b>${requestedBy.firstName} ${requestedBy.lastName}</b> at <b>${requestedBy.company || "ACELR8"}</b>. ${requestedBy.firstName} is looking for a <b>${candidate.jobTitle}</b>, and
    since you are looking for <b>[insert specific candidate needs]</b>, this could be a good fit for your next step.<br><br>

    Best regards,<br>
    ${recruiterIntouch.firstName} ${recruiterIntouch.lastName}
`);

export const acceptedRecruiterTemplate = ({ recruiterIntouch, requestedBy, candidate }: any) => (`
    Hi ${requestedBy.firstName} 👋 <br><br>

    Please meet <b>${candidate.firstName}</b>, an experienced <b>${candidate.jobTitle}</b> looking to land the next role. ${candidate.firstName} has been wonderful<br>
    to speak to and he could be a great addition to <b>${requestedBy.company}</b>. Feel free to take the conversation forward!<br><br>

    Best,<br>
    ${recruiterIntouch.firstName} ${recruiterIntouch.lastName}
`);

export const rejectedRecruiterTemplate = ({
    recruiterIntouch, requestedBy, candidate, candidateProfile
}: any) => (`
    Hi ${requestedBy?.firstName || ""}  👋 <br><br>

    Sorry - I won’t be able to introduce you directly to <a style="cursor:pointer" href=${candidateProfile} target="_blank" rel="noopener noreferrer">${candidate.firstName}</a> at the moment.<br><br>

    The reason is that ${candidate.firstName} is still in our interview process and has not been officially rejected. But I’d be
    more than happy to share my notes with you if you’d like. <br><br>Let me know if I can help!<br><br>

    Best regards,<br>
    ${recruiterIntouch.firstName} ${recruiterIntouch.lastName}
`);

export const archiveJobOpeningTemplate = ({
    recruiterName, jobOpening, actionedBy, action
}: any) => (`
   <div style="font-family: Arial, sans-serif;font-size: 13px">
   <span style="font-size: 13px" > Hi ${recruiterName || ""}  👋 <br><br> </span>

    <span style="font-size: 13px" >${actionedBy} ${action} ${actionedBy === "You" ? "a job opening:" : "one of your job openings:"} <br><br></span>
    <div style="width: 48%; font-size: 13px; line-height: 1.7">
       <span style="width: 124px; display: inline-block; font-weight: bold"> Job Title: </span> <span> ${toSentenceCase(jobOpening.job_function || "")}</span><br>
       <span style="width: 124px; display: inline-block; font-weight: bold">Company Name:</span> <span>${toSentenceCase(jobOpening.companyName || "")} </span><br>
       <span style="width: 124px; display: inline-block; font-weight: bold">Job Function:</span>  <span>${toSentenceCase(jobOpening.job_function || "")}</span><br>
       <span style="width: 124px; display: inline-block; font-weight: bold">Seniority:</span>  <span>${toSentenceCase(jobOpening.experience_level || "")}</span><br>
       <span style="width: 124px; display: inline-block; font-weight: bold">Location:</span> ${toSentenceCase(jobOpening.location || "")}<br>
       <span style="width: 124px; display: inline-block; font-weight: bold">Skills:</span>  ${jobOpening?.skills.length ? toSentenceCase(jobOpening?.skills.reduce((curr: string, next: string) => `${curr}, ${next}`) || "") : " None"}<br><br>
    </div>

   <span style="font-size: 13px" >You can learn more about the job openings here: <br> <a style="cursort:pointer" href='https://compass-staging.acelr8.com/job-openings' target="_blank" rel="noopener noreferrer"> ${process.env.REACT_APP_MARKETPLACE_BASE_URL} </a> <br><br> </span>

   <div style="font-size: 13px">
    <span>Kind regards,</span><br>
    <span> Compass. </span>
    </div>
    </div>
`);
