import { makeStyles } from "@material-ui/core/styles";

// TODO: Get the theme colors right
const useStyles = makeStyles((theme) => ({
    boxRoot: {
        width: "601px",
        margin: "20px auto",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    headerLabel: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#FFFFFF",
    },
    header: {
        height: "39px",
        backgroundColor: "#221A16",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0 22px",
        color: "#FFF",
        borderRadius: " 10px 10px 0px 0px",
    },
    errorBorder: {
        border: "1px solid  rgb(229, 115, 115) !important",
        "&:focus": {
            border: "1px solid  rgb(229, 115, 115) !important",
            transition: theme.transitions.create(["border"]),
        },
    },
    form: {
        background: "#FFF",
        width: "100%",
        overflowX: "hidden",
        padding: "28px 0",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            "& .MuiSelect-icon": {
                right: "12px",
                top: "calc(50% - 8px)",
            },
        },
        "& .MuiSelect-root": {
            color: "#000",
            fontWeight: "normal",
            height: "18px !important",
        },
        "& .MuiTextField-root > .MuiOutlinedInput-root": {
            padding: "8px 14px 18px 14px",
        },
        "& textarea": {
            fontSize: "13px",
            height: "46px !important",
            lineHeight: "15px",
        },
        "& button": {
            boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important",
            "&:hover, &:active": {
                boxShadow: "none !important",
            },
        },
    },
    imageUpload: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-contained": {
            "&:hover, &:active": {
                backgroundColor: "#ffb237 !important",
            },
        },
    },
    avatarButton: {
        [theme.breakpoints.between(0, 506)]: {
            alignItems: "start",
            "& button": {
                display: "none",
            },
        },

        "& .MuiGrid-grid-sm-4": {
            maxWidth: "35%",
            flexBasis: "35%",
            [theme.breakpoints.between(0, 506)]: {
                marginBottom: "20px",
            },
        },
        "& .MuiAvatar-root": {
            height: "98px",
            width: "98px",
            [theme.breakpoints.between(0, 506)]: {
                height: "120px",
                width: "120px",
            },
            background: "#DBEBEB",
        },
        "& .MuiGrid-grid-sm-8": {
            flexBasis: "unset",
        },
        "& .MuiGrid-container": {
            [theme.breakpoints.between(0, 506)]: {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
            },
            "& .MuiButton-root": {
                height: "40px",
                width: "150px",
                fontSize: "15px",
                fontWeight: 600,
                color: "#fff",
                boxShadow: "none",
                "&:hover, &:active": {
                    // backgroundColor: "#ffb237 !important",
                    // boxShadow: "none",
                },
            },
        },
    },
    uploadCv: {
        [theme.breakpoints.between(0, 506)]: {
            marginTop: "20px",
        },
        maxWidth: 158,
        "& .MuiButton-root": {
            backgroundColor: "#363840",
            height: "40px",
            borderRadius: "7px",
        },
        "& .MuiButton-label": {
            fontSize: "15px",
            fontWeight: 600,
            color: "#221A16",
            textTransform: "none",
            "&:hover": {
                color: "#221A16",
            },
        },
        "& .MuiChip-labelSmall": {
            width: "150px",
        },
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: "12px",
        fontWeight: 600,
        height: theme.spacing(6),
        backgroundColor: "#FCC46B",
        color: "#221A16",
        "&:focus": {
            backgroundColor: "#A7C7D880",
        },
    },
    uploadCVButton: {
        height: theme.spacing(9.2),
        width: "100%",
        color: "#221A16 !important",
        backgroundColor: "#FCC46B !important",
        boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        "&:hover": {
            backgroundColor: "#ffb237 !important",
            boxShadow: "none",
        },
    },
    deleteIcon: {
        boxShadow: "none",
        width: "16px",
        color: "#221A16",
    },
    avatar: {
        width: theme.spacing(28),
        height: theme.spacing(28),
        borderRadius: 10,
        border: "solid 1px black",
    },
    avatarChange: {
        height: "40px",
        width: "100%",
        backgroundColor: "#8EAABA",
        color: "#FFF",
    },
    centredFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    centredAlignedFlex: {
        display: "flex",
        alignItems: "center",
    },
    inputRoot: {
        fontSize: "12px",
    },
    icons: {
        marginRight: theme.spacing(1),
        width: theme.spacing(4),
    },
    outlineInput: {
        padding: "12px",
    },
    personalInfo: {
        display: "flex",
        marginBottom: theme.spacing(2),
    },
    overallInformation: {
        marginLeft: "1rem",
    },
    divider: {
        width: theme.spacing(0.25),
        height: theme.spacing(2),
        margin: theme.spacing(0, 1),
    },
    sectionDivider: {
        width: "100%",
        background: "#E7E8EC",
        height: "1px",
        margin: "20px 0",
    },
    inputField: {
        "& .MuiInputBase-input": {
            height: "unset",
        },
        "& .MuiGrid-container": {
            justifyContent: "space-between",
            color: "#000",
        },
        "& .MuiOutlinedInput-root, .MuiInputBase-root": {
            height: "40px",
        },
        "& .MuiGrid-grid-sm-6": {
            maxWidth: "48%",
            flexBasis: "48%",
            marginBottom: "25px",
        },
    },
    labelText: {
        color: "#2F2824",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        marginBottom: "10px",
    },
    inputContainer: {
        "& .MuiGrid-container": {
            margin: "unset",
            marginBottom: "25px",
            color: "#000",
        },
    },
    selectField: {
        "& .MuiChip-root": {
            fontWeight: 600,
            fontSize: "12px",
            backgroundColor: "#FCC46B",
            borderRadius: "7px",
            color: "#221A16",
            "&:focus": {
                backgroundColor: "#ffb237",
            },
            marginTop: "8px",
            "&  svg": {
                fill: "#221A16",
            },
        },
    },
    salaryField: {
        "& .MuiSelect-icon": {
            right: "2%",
        },
        "& .MuiInputLabel-root": {
            transform: "translate(14px, 12px) scale(1)",
        },
    },
    checkBoxes: {
        "& .Mui-checked": {
            "& svg": {
                fill: "#221A16",
                background: "#FFF",
            },
        },
        "& .MuiCheckbox-root": {
            "&  svg": {
                fill: "#C0C0C0",
                width: "1.250em",
                height: "1.250em",
            },
        },
        " & .MuiTypography-root": {
            color: "#000",
            fontSize: "12px",
            fontWeight: "normal",
        },
    },
    closeIconWrapper: {
        background: theme.palette.text.primary,
        color: theme.palette.secondary.light,
        margin: theme.spacing(0.5),
        padding: theme.spacing(1),
        "&:hover": {
            // background: theme.palette.secondary.main,
            background: "#245978",
            color: theme.palette.text.primary,
        },
    },
    smallIcon: {
        fontSize: theme.spacing(3),
        margin: theme.spacing(1),
        color: "white",
    },
    addButton: {
        height: "40px",
        width: "100%",
        maxWidth: "175px",
        backgroundColor: "#9FA2B4",
        color: "white",
    },
    chipRoot: {
        marginRight: "6px",
        fontSize: "11px",
        height: "max-content",
    },
    deleteIconChip: {
        cursor: "unset",
    },
    formLabel: {
        fontWeight: 700,
        fontSize: "12px",
    },
    input: {
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
        borderRadius: "7px",
        boxShadow: "unset",
        fontSize: "15px",
        fontWeight: 600,
        height: "40px",
        color: "#221A16",
        backgroundColor: "#FCC46B",
        textTransform: "capitalize",
        "&:hover, &:active": {
            backgroundColor: "#ffb237",
            boxShadow: "none",
        },
    },
    peepAvatar: {
        borderRadius: "50% !important",
        backgroundColor: "#F7CD61 !important",
    },
    contentEditable: {
        fontSize: 14,
        fontWeight: 500,
        minHeight: 72,
        border: "1px solid #AEAEB2",
        borderRadius: 8,
        padding: 10,
        fontFamily: "Montserrat",
        lineHeight: "18px",
        color: "#2F2824",
        whiteSpace: "pre-wrap",
        "&:focus": {
            border: "1px solid #363840",
            borderRadius: 8,
            transition: theme.transitions.create(["border"]),
        },
        "&:focus-visible": {
            outlineWidth: 0,
        },
        "&:hover": {
            border: "1px solid #363840",
            borderRadius: 8,
            transition: theme.transitions.create(["border"]),
        },
    },
}));

export default useStyles;
