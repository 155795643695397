import React, { FC, useState } from "react";
import {
    Grid, InputLabel, Divider, IconButton, Box, TextField, OutlinedInput, Typography
} from "@material-ui/core";
import PeriodPicker from "../../../../../SharedComponents/PeriodPicker";
import { IEducation } from "compass-shared";
import { Close } from "@material-ui/icons";
import useAppContext from "../../../../../context/store/store";
import {
    doClearProfileFieldErrors,
    doRemoveEducation, doSetProfileEducationData, doSetProfileExperienceData
} from "../../../../../context/reducer/Actions";
import useStyles from "../../style";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

export type EditEducationItem = {
    education: IEducation;
    id: string;
    disabled: boolean;
}
const EditEducationItem: FC<EditEducationItem> = ({ education, id, disabled }) => {
    const classes = useStyles();

    const [{ editProfileErrors = {}}, dispatch]: any = useAppContext();

    const [selectedEducation, setSelectedEducation] = useState<any>(null);

    const removeEducation = () => {
        dispatch(doRemoveEducation(selectedEducation));
    };

    return (
        <Grid container spacing={2} key={id}>

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={selectedEducation !== null}
            >
                <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this education?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setSelectedEducation(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={removeEducation} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} />

                    <Grid item xs={12} sm={6}>
                        <Box p={0} textAlign="right" width="100%">
                            <IconButton
                              className={classes.deleteIcon}
                              aria-label="delete"
                              onClick={() => setSelectedEducation(id)}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                Degree
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>
                        <OutlinedInput
                          fullWidth
                          placeholder="Degree Title"
                          name="degree_title"
                          value={education.degree_title}
                          onChange={(e) => {
                              dispatch(doSetProfileEducationData({
                                  key: "degree_title",
                                  value: e.target.value,
                                  id
                              }));
                              if (editProfileErrors[`degree_title${id}`]) {
                                  dispatch(doClearProfileFieldErrors({ field: `degree_title${id}` }));
                              }
                          }}
                          classes={{
                              root: classes.inputRoot,
                              input: classes.outlineInput
                          }}
                        />

                        {editProfileErrors[`degree_title${id}`] && editProfileErrors[`degree_title${id}`].id === `degree_title${id}`
                        && (
                            <Box color="error.main">
                                Degree Title is required
                            </Box>
                        )}

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                School
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>

                        <OutlinedInput
                          fullWidth
                          id="schoolName"
                          placeholder="School Name"
                          name="institution_name"
                          value={education.institution_name}
                          onChange={(e: any) => {
                              dispatch(doSetProfileEducationData({
                                  key: "institution_name",
                                  value: e.target.value,
                                  id
                              }));

                              if (editProfileErrors[`institution_name${id}`]) {
                                  dispatch(doClearProfileFieldErrors({ field: `institution_name${id}` }));
                              }
                          }}
                          classes={{
                              root: classes.inputRoot,
                              input: classes.outlineInput
                          }}
                        />
                        {editProfileErrors[`institution_name${id}`] && editProfileErrors[`institution_name${id}`].id === `institution_name${id}`
                        && (
                            <Box color="error.main">
                                School Name is required
                            </Box>
                        )}

                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ padding: "0 22px" }} item xs={12}>
                <PeriodPicker
                  disabled={disabled}
                  startDate={education.start_at}
                  endDate={education.end_at}
                  id={id}
                  doSetProfilePeriodData={doSetProfileEducationData}
                />
            </Grid>

            <div className={classes.sectionDivider} />

        </Grid>
    );
};

export default EditEducationItem;
