import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    reloadText: {
        fontSize: 18,
        fontWeight: 600
    },
    reactTooltip: {
        fontSize: "15px !important",
        padding: "14px !important",
        borderRadius: "10px !important",
        maxWidth: 220,
        height: "88px !important",
        borderColor: "#dadde9 !important",
    },
    listWrapper: {
        padding: theme.spacing(1),
        "& a:first-child > div": {
            marginTop: "0px"
        },
        marginBottom: 16,
        [theme.breakpoints.only("sm")]: {
            marginTop: (props: any) => (props.isSearchFormOpen ? 116 : 100),
        },
    },
    candidatesCount: {
        fontSize: 13,
        fontWeight: 600,
        position: "absolute",
        left: "2%",
    },
    listContainer: {
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        [theme.breakpoints.down("xs")]: {
            margin: "0 auto"
        }
    },

    candidateWrapper: {
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            width: "94%",
            margin: "0 auto",
            "& .countResults": {
                position: "relative",
                margin: "10px 0 10px 0",
                left: 0
            }
        }
    },

    itemButton: {
        minWidth: 22,
        height: 22,
        backgroundColor: "#FF6A66",
        color: "#FFFFFF",
        textTransform: "none",
        padding: 0,
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    contentRoot: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "15px 20px 20px 20px !important",
        width: 400,
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        height: 270
    },
    directContactContentRoot: {
        padding: "15px 0px 20px 0px !important",
        height: "auto"
    },
    dialogPaperRoot: {
        // backgroundColor: "#bcbcbc",
        width: 420,
        // height: 290,
        padding: "10px",
        borderRadius: "10px",
		height: "460px"
    },
    dialogContainer: {
        height: "auto",
        marginTop: "100px"
    },
    dialogTitleRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 0,
        paddingBottom: 17
    },
    directContactTitle: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderBottom: "1px solid #E6E6E6",
        paddingBottom: 21,
        paddingLeft: 20,
        paddingRight: 20
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    directContactDialogContent: {
        display: "flex",
        padding: 20,
        borderBottom: "1px solid #E6E6E6",
    },
    candidateInfo: {
        paddingLeft: 20,
        display: "flex",
        flexDirection: "column"
    },
    titleText: {
        fontSize: 18,
        color: "#2F2824",
        fontWeight: "bold",
        lineHeight: "22px",
        marginTop: "12px",
        marginBottom: "18px"
    },
    directContactTitleText: {
        marginTop: 0,
        marginBottom: 0
    },
    bodyText: {
        fontSize: "14px",
        lineHeight: "22px",
        color: "#2F2824",
        textAlign: "center"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: 10
    },
    requestIntro: {
        height: 35,
    },
    updatedRequestIntroBtn: {
        backgroundColor: theme.palette.grey[300],
    },
    buttonGroupContained: {
        boxShadow: (props: any) => (
            props.isRequestIntroDisabled
                ? "none"
                : "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"

        )
    },
    buttonGroupGroupedHorizontal: {
        fontSize: 15,
        fontWeight: 600,
        color: "#ffffff",
        backgroundColor: "#221A16",
        cursor: "pointer",
        textTransform: "none",
        borderBottomRightRadius: (props: any) => (
            props.open ? 0 : 5
        ),
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        }
    },
    paperContainer: {
        backgroundColor: "#221A16",
        borderTopLeftRadius: 0,
    },
    MenuItemRoot: {
        fontSize: 13,
        fontWeight: 600,
        color: "#ffffff",
        textTransform: "none",
    },
    MenuItemSelected: {
        backgroundColor: (props: any) => (
            props.isRequestIntroDisabled ? theme.palette.grey[200] : "#221A16"
        ),
    },
    imageSRC: {
        borderRadius: "10px",
        height: 100,
        width: 100,
        border: "1px solid #483E3E",
        backgroundColor: "#DBEBEB",
    },
    personIcon: {
        fontSize: "40px"
    },
    candidateName: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 15,
        lineHeight: "18px",
        color: "#483E3E"
    },
    candiateText: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "16px",
        color: "#483E3E"
    },
    icons: {
        height: 20,
        minWidth: 20,
        marginLeft: 5
    },
    materialIcon: {
        marginRight: 2,
        height: 13,
        width: 13,
        color: "#483E3E"
    },
    copyToClipboardIcon: {
        height: 10,
        minWidth: 10,
        marginLeft: 8,
        cursor: "pointer"
    },
    directContactFooter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
        paddingBottom: 5
    },
    footerButton: {
        fontSize: 13,
        fontWeight: 600,
        color: "#ffffff",
        textTransform: "none",
        backgroundColor: "#221A16",
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        }
    },
	submit: {
        borderRadius: "4px",
        fontSize: '15px',
        fontWeight: 600,
        height: '40px',
        backgroundColor: '#221A16',
        textTransform: "capitalize",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) ",
        "&:hover, &:active" :{
            backgroundColor: '#363840',
            boxShadow: "none ",

        }
    }
}));

export default useStyles;
