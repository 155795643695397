/* eslint-disable max-len */
import * as yup from "yup";

export const SignUpSchema = yup.object().shape({
    firstName: yup.string().required("First Name required"),
    lastName: yup.string().required("Last Name required"),
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    password: yup
        .string()
        .required("Password is required")
        .matches(/(?=.*[a-z])/,
            {
                message: "Password must contain at least 1 lowercase alphabetical character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[A-Z])/,
            {
                message: "Password must contain at least 1 uppercase alphabetical character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[0-9])/,
            {
                message: "Password must contain at least 1 numeric character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[!@#$%^&*])/,
            {
                message: "Password must contain at least one special character",
                excludeEmptyString: false
            })
        .matches(/(?=.{8,})/,
            {
                message: "Password must be eight characters or longer",
                excludeEmptyString: false
            }),
});

export const SignInSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    password: yup
        .string()
        .required("Password is required"),
    remember: yup.boolean().notRequired(),
});

export const Settingschema = yup.object().shape({
    name: yup.string().required("Name required"),
    slack_id: yup.string(),
    calendly_link: yup.string(),
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
});

export const OnboardingSchema = yup.object().shape({
    bio: yup
        .string()
        .required("Please enter a description about yourself"),
    // linkedin_url: yup
    //     .string()
    //     .url("Enter a valid url")
    //     .required("LinkedIn url is required"),
    // last_client_name: yup
    //     .string()
    //     .required("Client name is required"),
});

export const ForgotPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required")
});

export const CreateProfileSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email address"),
    skills: yup.array().min(3, "At least 3 skill are required")
        .max(10, "At most 10 skills can be entered"),
    experience_level: yup.string().required("Seniority level is required"),
    job_function: yup.string().required("Job function is required"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    phone: yup.string().matches(/^(\s*|\+?\d{4,15})$/, "Invalid Phone number"),
    note: yup.string().required("Career Aspirations is required"),
    // comments: yup.string().required("Comments Aspirations is required"),
});

export const EditProfileSchema = yup.object().shape({
    firstName: yup.string().required("First Name required"),
    lastName: yup.string().required("Last Name required"),
    email: yup.string().email("Invalid email address"),
    linkedIn: yup
        .string()
        .trim()
        .notRequired()
        .matches(/^(https:\/\/[a-z]{2,3}\.linkedin\.com\/in\/.*|.{0})$/, {
            message:
        "Please enter a valid linkedin profile url e.g https://www.linkedin.com/in/profile-name",
        }),
    skills: yup
        .array()
        .min(3, "At least 3 skill are required")
        .max(10, "At most 10 skills can be entered"),
    experience_level: yup.string().required("Seniority level is required"),
    job_function: yup.string().required("Job function is required"),
    location: yup.string().required("Location is required."),
    phone: yup.string().matches(/^(\s*|\+?\d{4,12})$/, "Invalid Phone number"),
    note: yup.string().required("Career Aspirations is required"),
});

export const JobOpeningSchema = yup.object().shape({
    jobTitle: yup.string().required("Job Title is required"),
    companyName: yup.string().required("Company Name is required"),
});

export const AddJobSchema = yup.object().shape({
	jobs: yup.string().required("Candidate job is required."),
  });
