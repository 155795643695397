import { IRecruiter, UserType } from "compass-shared";

/* global gtag */

let user: IRecruiter | undefined;
let initialised = false;

function getUserType() {
    return UserType.Recruiter;
}

function getUserId() {
    return user && user.id ? user.id : null;
}

function init() {
    if (!initialised) {
        const targetId: string = process.env.REACT_APP_GOOGLE_ANALYTICS_TARGET_ID || "";
        const domains: string[] = (process.env.REACT_APP_GOOGLE_ANALYTICS_TARGET_DOMAINS
            && JSON.parse(process.env.REACT_APP_GOOGLE_ANALYTICS_TARGET_DOMAINS)) || [];
        if (targetId) {
            gtag("config", targetId, {
                linker: { domains },
                send_page_view: false,
                custom_map: { dimension1: "user_type", dimension2: "user_id" }
            });
            initialised = true;
            gtag("set", { user_type: getUserType(), user_id: getUserId() });
        } else console.error("Failed initialising GA - no target ID");
    }
}

export function setUserIdOnAnalytics(uid: string) {
    init();
    gtag("set", { user_id: uid });
    gtag("set", { user_type: getUserType() });
}

export const setUserForAnalytics = (_user: IRecruiter) => {
    user = _user;
    setUserIdOnAnalytics(user.id || "");
};

export const removeUserForAnalytics = () => {
    user = undefined;
};

export function signOutEventOnAnalytics() {
    init();
    gtag("event", "sign out", {
        event_category: "User"
    });
}

export default function hitPageOnAnalytics(path: string | undefined) {
    init();
    if (path) {
        gtag("event", "page_view", {
            page_path: path,
            user_type: getUserType()
        });
    } else console.error("Calling hitPageOnAnalytics without defined path");
}

