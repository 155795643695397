import React, { FC, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Accordion, AccordionDetails, AccordionSummary, Box
} from "@material-ui/core";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

const FaqPage: FC<RouteComponentProps> = () => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel: string) => {
        setExpanded(expanded === panel ? "" : panel);
    };

    return (
        <div style={{ display: "flex" }}>
            <Box className={classes.boxRoot}>
                <div className={classes.header}>
                    <label className={classes.headerLabel}> FAQ / Onboarding </label>
                </div>
                <Box className={classes.accordionContainer} margin="20px 0" padding="0 20px">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={() => handleChange("panel1")}
                    >
                        <AccordionSummary
                          expandIcon={expanded === "panel1" ? <Remove /> : <Add />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                            <Typography className={classes.heading}> When should I upload a candidate? </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {/* <video controls> */}
                            {/*    <source */}
                            {/*      src="https://firebasestorage.googleapis.com/v0/b/acelr8-marketplace-stagi-63e84.appspot.com/o/Animated%20Commercial%20-%20LegalShield%20(%20720%20X%201280%20).mp4?alt=media&token=d744a8ee-1968-4049-b815-888d75cd5c0c" */}
                            {/*      type="video/mp4" */}
                            {/*    /> */}
                            {/*    <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" /> */}
                            {/* </video> */}

                            <Typography>
                                We'd love to have a network of candidates that are great candidates,
                                who are currently open to job offers. If a candidate is no longer
                                in the process with your client,
                                but you still think they can be a great hire for others – upload them to Compass!
                            </Typography>

                            <Typography>
                                If someone in your network is open to opportunities,
                                we encourage you to upload them to Compass even if you haven't
                                officially interviewed them 😄
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={() => handleChange("panel2")}
                    >
                        <AccordionSummary
                          expandIcon={expanded === "panel2" ? <Remove /> : <Add />}
                          aria-controls="panel2a-content"
                          id="panel3a-header"
                        >
                            <Typography className={classes.heading}>
                                Is it GDPR compliant to upload a candidate?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                If you upload their LinkedIn profile – yes.
                                Because it is publicly visible information.
                                If you upload their CV, resume, or phone number
                                or email address, make sure to receive consent.
                                We recommend sending a message to the candidate
                                (or add it to your rejection email template).
                                You can use the example here:
                            </Typography>

                            <Typography>
                                "I would love to help you in your next
                                career step. Can I share your profile/CV/
                                resume with my recruiter network?"
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={() => handleChange("panel3")}
                    >
                        <AccordionSummary
                          expandIcon={expanded === "panel3" ? <Remove /> : <Add />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                            <Typography className={classes.heading}>
                                What does the "Need more candidates" button do?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                If you can't find ideal candidates
                                in the current Compass network, we notify you when they are
                                uploaded to Compass in the future! By clicking on
                                "Need more candidates" button, you
                                will be able to put in the basic information about
                                the role you have open, plus other criteria. Whenever
                                a candidate that fits your criteria gets uploaded,
                                you will receive a notification in Slack. To edit or
                                archive these open roles, simply go to "Job Openings"
                                in the menu bar on the left.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={() => handleChange("panel4")}
                    >
                        <AccordionSummary
                          expandIcon={expanded === "panel4" ? <Remove /> : <Add />}
                          aria-controls="panel4a-content"
                          id="panel3a-header"
                        >
                            <Typography className={classes.heading}>
                                How can I know if a given candidate is already uploaded?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                In the “Search candidates” page, you can click on
                                the downward-facing arrow that you see on the right side of the search bar.
                                his will expand to show further search filters,
                                including searching by name or email address.
                            </Typography>

                            <Typography>
                                You can also open their LinkedIn profile in your browser,
                                and if you have the Chrome Extension it will tell you in
                                case they already exist in Compass.
                            </Typography>

                        </AccordionDetails>
                    </Accordion>

                </Box>
            </Box>
        </div>
    );
};

export default React.memo(FaqPage);
