/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { RouteComponentProps } from "@reach/router";
import useAppContext from "../../../context/store/store";

class TestClassComponent extends Component<RouteComponentProps> {
    render() {
    // Wrong usage of the store hook
        const [store, dispatch]: any = useAppContext();

        return (
            <div>
                An error should be thrown when you set the user

            </div>
        );
    }
}

export default TestClassComponent;
