import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Box, Button, Chip, FormControl, Grid, Paper, Theme, useMediaQuery
} from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { doToggleCreateJobOpening, doToggleSearchForm } from "../../context/reducer/Actions";
import useAppContext from "../../context/store/store";
import {
    CustomInput,
    DaysSinceCreated,
    experienceLevelList,
    ICandidateSearchObject,
    JobFunctionList,
    relocationStatusList,
    SalaryField,
    SelectMenu
} from "compass-shared";
import useStyles from "./style";
import Collapse from "@material-ui/core/Collapse";
import searchClient from "../../Utils/algolia";
import { AutoCompleteForm } from "../AutoCompleteForm";
import Carousel from "react-multi-carousel";
import { defaultFormFields, premadeQueries, responsive } from "./utils";
import "react-multi-carousel/lib/styles.css";
import { IPremadeQuery, ISearchFormFields } from "./types";
import CustomToolTip from "../../SharedComponents/ToolTip";
import { RootState } from "../../redux/store";
import { fetchCandidates } from "../../redux/services/candidates/candidates.actions";

const SearchForm: FC = () => {
    const reduxDispatch = useDispatch();
    const candidateGroup: any = useSelector((state: RootState) => state.candidates.candidateGroups, shallowEqual);

    const [minSalary, setMinSalary] = useState<number>(0);
    const [maxSalary, setMaxSalary] = useState<number>(1000000);
    const [searchText, setSearchText] = useState<string>("");
    const [searchName, setSearchName] = useState<string>("");
    const [searchResults, setSearchResults] = useState<any>(null);
    const [index, setSearchIndex] = useState<string>("");
    const [optionSelected, setOptionSelected] = useState(false);
    const [isPremadeSearch, setPremadeSearch] = useState(false);
    const [searchCountry, setSearchCountry] = useState<string>("");
    const [skillsGroup, setSkillsGroup] = useState<any>(null);
    const [selectedCard, setSelectedCard] = useState({} as IPremadeQuery);

    const searchCandidatesStatus: string = useSelector((state: RootState) => state?.candidates?.status || "");

    const fetchSearchValues = async (search: string) => {
        const searchIndex = searchClient.initIndex(index);
        try {
            const results = await searchIndex.search(search);
            if (results.hits) {
                setSearchResults(results.hits);
            }
        } catch (error) {
            setSearchResults(null);
        }
    };

    const {
        handleSubmit, control, setValue, register, getValues, reset
    } = useForm({
        defaultValues: {
            ...defaultFormFields
        } as ISearchFormFields
    });

    const desktop = useMediaQuery("(min-width: 960px)");

    const handlepremadeSearch = (query: IPremadeQuery) => {
        if (query.title === selectedCard.title) {
            setSelectedCard({ title: "", type: "", value: "" });
        } else {
            setSelectedCard(query);
        }
        setPremadeSearch(true);
    };

    const [{
        candidatesSearchInfo,
        user,
        isSearchFormOpen,
        isMainSearchFormOpen,
        navBarOpen
    }, dispatch]: any = useAppContext();

    const classes = useStyles({ isSearchFormOpen });

    const onSearch = async (formData: any) => {
        const searchData: ICandidateSearchObject = { ...formData };

        if (formData.is_archived !== "all") {
            searchData.is_archived = formData.is_archived === "true";
        } else {
            searchData.is_archived = undefined;
        }

        if (searchData.involved_recruiter_ids) {
            searchData.involved_recruiter_ids = user.id;
        }

        if (searchData?.skills?.length && !Array.isArray(searchData?.skills)) {
            const skills = searchData.skills as string;
            searchData.skills = skills.split(",");
        }

        const optionalFilters: any = {};
        if (searchData?.skills?.length) {
            optionalFilters.top_skills = [...searchData?.skills];
        }

        if (formData.salary_per_year && Object.keys(formData.salary_per_year).length) {
            searchData.min_salary_per_year = formData.salary_per_year.min_salary_per_year;
            searchData.max_salary_per_year = formData.salary_per_year.max_salary_per_year;
        }

        delete searchData.salary_per_year;
        const isReset = Object.values(searchData).some((value: any) => !!value);
        const searchPayload = {
            isFilterRequest: isReset,
            ...candidatesSearchInfo,
            ...searchData,
            isFetchingMore: false,
            optionalFilters,
            page: 0
        };

        await reduxDispatch(fetchCandidates({
            candidatesSearchInfo: {
                ...searchPayload
            },
        }));
    };

    const onChangeSubmit = () => {
        onSearch(getValues());
    };

    useEffect(() => {
        if (searchText.length) {
            fetchSearchValues(searchText);
        }
    }, [searchText]);

    useEffect(() => {
        if (searchName.length) {
            fetchSearchValues(searchName);
        }
    }, [searchName]);

    useEffect(() => {
        if (searchCountry.length) {
            fetchSearchValues(searchCountry);
        }
    }, [searchCountry]);

    const updateSearchData = () => {
        setSearchResults(null);
        setOptionSelected(false);
        onChangeSubmit();
    };

    useEffect(() => {
        if (optionSelected) {
            updateSearchData();
        }
    }, [optionSelected]);

    const handleSelectChange = ([event]: any) => {
        if (event && event.type === "click") {
            setTimeout(() => onChangeSubmit());
        }
        return event.value ?? "";
    };

    const handleSearchTextChange = (e: any) => {
        setOptionSelected(false);
        setSearchText(e.target.value || "");
        if (index !== "skills")setSearchIndex("skills");
    };

    const handleSearchNameChange = (e: any) => {
        setOptionSelected(false);
        setSearchName(e.target.value || "");
        if (index !== "name")setSearchIndex("name");
    };

    const handleCountryChange = (e: any) => {
        if (index !== "location") setSearchIndex("location");
        setOptionSelected(false);
        setSearchCountry(e.target.value || "");
    };

    const isDuplicate = (item: string) => !!(Array.isArray(skillsGroup) && skillsGroup.find((skill) => skill === item));

    const handleFilterData = (item: string) => {
        if (index === "name") {
            setSearchName(item);
        } else if (index === "skills") {
            if (!isDuplicate(item)) {
                const updatedSkills = (Array.isArray(skillsGroup) ? [...skillsGroup, item] : [item]) as Array<string>;
                setSkillsGroup(updatedSkills);
                reset({
                    skills: updatedSkills
                });
                setSearchText("");
            }
        } else {
            setSearchCountry(item);
        }
        setSearchResults(null);
        setOptionSelected(true);
    };

    const deleteItem = (item: string) => {
        if (Array.isArray(skillsGroup)) {
            const skills = skillsGroup.filter((skill: string) => skill !== item);
            setSkillsGroup(skills);
            reset({
                skills
            });
            setSearchText("");
        }
        onChangeSubmit();
    };

    const submitSkill = (e: any) => {
        if (e && (e.key === "Enter")) {
            e.preventDefault();
            handleFilterData(searchText);
        }
    };

    const submitCountry = (e:any) => {
        if (e && (e.key === "Enter")) {
            e.preventDefault();
            setSearchCountry(searchCountry);
            setOptionSelected(true);
        }
    };

    const submitName = (e:any) => {
        if (e && (e.key === "Enter")) {
            e.preventDefault();
            setSearchName(searchName);
            setOptionSelected(true);
        }
    };

    const clearFormValues = () => {
        const formData = Object.keys(getValues());
        formData.forEach((data: string) => setValue(data, ""));
        setSkillsGroup(null);
        setSearchCountry("");
        setSearchName("");
    };

    const generateTopMargin = () => {
        if (isSearchFormOpen && (skillsGroup && skillsGroup.length)) {
            return "130px";
        } if (isSearchFormOpen) {
            return "111px";
        }
        if (skillsGroup && skillsGroup.length) {
            return "80px";
        }
        return "56px";
    };

    useEffect(() => {
        if (!isPremadeSearch) return;
        setPremadeSearch(false);
        clearFormValues();
        if (selectedCard.type !== "skills") {
            if (selectedCard.title === "Senior Designers") {
                setValue("experience_level", "senior");
                setValue("job_function", "Design");
            } else {
                setValue(`${selectedCard.type}`, selectedCard.value);
            }
        } else {
            reset({
                skills: selectedCard?.value || []
            });
            setSearchText("");
            setSkillsGroup(selectedCard.value);
        }
        onChangeSubmit();
    }, [selectedCard.title]);

    const resetForm = () => {
        setMinSalary(0);
        setMaxSalary(1000000);
        setSearchCountry("");
        setSearchName("");
        setSearchText("");
        setSkillsGroup(null);
        setSelectedCard({} as IPremadeQuery);
        reset({
            ...defaultFormFields,
            salary_per_year: {},
            skills: undefined
        });
        onChangeSubmit();
    };
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

    return (
        <>
            <div className={classes.searchBox}>
                <form
                  style={{
                      paddingLeft: navBarOpen.isOpen ? 220 : 68
                  }}
                  autoComplete="off"
                  key={navBarOpen}
                  onSubmit={handleSubmit(onSearch)}
                  className={`${classes.formContainer}`}
                >
                    <Collapse
                      in={matches || isMainSearchFormOpen}
                      style={{
                          width: "-webkit-fill-available",
                      }}
                    >
                        <Grid container spacing={2} className={classes.flexCenterWrapper}>
                            <Grid className={classes.searchMobileView} item xs={12}>
                                <Grid container spacing={2} style={{ display: "flex" }}>

                                    {/* Job function part */}
                                    <Grid item xs={12} sm={3} md={2}>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          classes={{ root: classes.textFieldRoot }}
                                        >
                                            <Controller
                                              as={(
                                                  <SelectMenu
                                                    defaultValue={getValues().job_function as string}
                                                    setFilterValue
                                                    disabled={searchCandidatesStatus === "pending"}
                                                    items={JobFunctionList}
                                                    label="Job Function"
                                                  />
                                        )}
                                              name="job_function"
                                              control={control}
                                              onChange={handleSelectChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* Seniority part */}
                                    <Grid item xs={12} sm={3} md={2}>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          classes={{ root: classes.textFieldRoot }}
                                        >
                                            <Controller
                                              as={(
                                                  <SelectMenu
                                                    defaultValue={getValues().experience_level as string}
                                                    setFilterValue
                                                    disabled={searchCandidatesStatus === "pending"}
                                                    items={experienceLevelList.map(({ name, value }) => ({
                                                        title: name,
                                                        value
                                                    }))}
                                                    label="Seniority"
                                                  />
                                        )}
                                              name="experience_level"
                                              control={control}
                                              onChange={handleSelectChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* Skills part */}
                                    <Grid style={{ position: "relative" }} item xs={12} sm={3} md={2}>
                                        <AutoCompleteForm
                                          searchResults={searchResults}
                                          index={index}
                                          inputType="skills"
                                          searchCallback={handleFilterData}
                                          setSearchResults={setSearchResults}
                                          setSearchIndex={setSearchIndex}
                                        >

                                            <CustomInput
                                              fullWidth
                                              style={{ margin: 0, height: "40px" }}
                                              placeholder="Required Skills"
                                              inputRef={register}
                                              autoComplete="off"
                                              name="skills"
                                              onKeyDown={submitSkill}
                                              value={searchText}
                                              key="skills"
                                              onChange={handleSearchTextChange}
                                            />
                                        </AutoCompleteForm>
                                        {skillsGroup && skillsGroup.length > 0
                                    && (
                                    <Grid item xs={12}>
                                        {skillsGroup.map((skill: string, i: number) => (
                                            <Chip
                                              key={i.toString()}
                                              label={skill}
                                              size="small"
                                              onDelete={() => deleteItem(skill)}
                                              className={classes.chip}
                                              classes={{
                                                  deleteIcon: classes.deleteIcon
                                              }}
                                            />
                                        ))}
                                    </Grid>
                                    )}
                                    </Grid>

                                    {/* Location part */}
                                    <Grid style={{ position: "relative" }} item xs={12} sm={3} md={2}>
                                        <AutoCompleteForm
                                          index={index}
                                          inputType="location"
                                          searchResults={searchResults}
                                          searchCallback={handleFilterData}
                                          setSearchResults={setSearchResults}
                                          setSearchIndex={setSearchIndex}
                                        >
                                            <CustomInput
                                              fullWidth
                                              style={{ margin: 0, height: "36.25px" }}
                                              placeholder="Country/City"
                                              inputRef={register}
                                              onKeyDown={submitCountry}
                                              type="search"
                                              autoComplete="off"
                                              value={searchCountry}
                                              name="location"
                                              onChange={handleCountryChange}
                                            />

                                        </AutoCompleteForm>
                                    </Grid>
                                    <Grid item md={2} xs={6} className={classes.dropDownIcon}>
                                        <CustomToolTip
                                          title={isSearchFormOpen ? "Collapse filters" : "Expand filters"}
                                          arrow
                                          placement="bottom"
                                          className={classes.reactTooltip}
                                        >

                                            <button
                                              type="button"
                                              className={
                                        `${classes.showFormIconClass}
                                        ${isSearchFormOpen
                                            ? classes.openFormIconClass : ""}`
                                        }
                                              onClick={() => {
                                                  setSearchResults(null);
                                                  dispatch(doToggleSearchForm());
                                              }}
                                            >
                                                {isSearchFormOpen
                                                    ? (

                                                        <KeyboardArrowUp
                                                          style={{ color: "#AEAEB2", fontSize: 30 }}
                                                        />

                                                    ) : (
                                                        <KeyboardArrowDown
                                                          style={{ color: "#AEAEB2", fontSize: 30 }}
                                                        />
                                                    )}
                                            </button>
                                        </CustomToolTip>

                                        <Button
                                          disabled={searchCandidatesStatus === "pending"}
                                          size="small"
                                          onClick={(e: any) => {
                                              e.preventDefault();
                                              resetForm();
                                          }}
                                          classes={{ root: classes.resetButton, label: classes.resetLabel }}
                                          color="primary"
                                        >
                                    Reset Filters
                                        </Button>
                                    </Grid>
                                    <Grid item md={2} xs={6} className={classes.createJobOpeningButton}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => { dispatch(doToggleCreateJobOpening(true)); }}
                                          className={classes.createCandidateButton}
                                        >
                                    Need more candidates?
                                        </Button>
                                    </Grid>

                                </Grid>
                                <Collapse in={isSearchFormOpen}>
                                    <Grid
                                      container
                                      spacing={2}
                                      className={`${classes.invisibleSection}`}
                                    >

                                        {/* Salary per year part */}
                                        <Grid item xs={12} sm={3} md={2}>
                                            <FormControl
                                              variant="outlined"
                                              fullWidth
                                              size="small"
                                              classes={{ root: classes.textFieldRoot }}
                                            >
                                                {/* <InputLabel id="salary-label">Salary</InputLabel> */}
                                                <Controller
                                                  name="salary_per_year"
                                                  control={control}
                                                  as={(
                                                      <SalaryField
                                                        setMinSalary={() => null}
                                                        setMaxSalary={() => null}
                                                        min={minSalary}
                                                        max={maxSalary}
                                                      />
                                                    )}
                                                  setMinSalary={(value: any) => {
                                                      setMinSalary(value);
                                                      setValue("salary_per_year", {
                                                          ...(getValues() as any)
                                                              .salary_per_year,
                                                          min_salary_per_year: value
                                                      });
                                                      onChangeSubmit();
                                                  }}
                                                  setMaxSalary={(value: any) => {
                                                      setMaxSalary(value);
                                                      setValue("salary_per_year", {
                                                          ...(getValues() as any)
                                                              .salary_per_year,
                                                          max_salary_per_year: value
                                                      });
                                                      onChangeSubmit();
                                                  }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        {/* Days Since Created part */}

                                        <Grid item xs={12} sm={3} md={2}>
                                            <FormControl
                                              variant="outlined"
                                              fullWidth
                                              size="small"
                                              classes={{ root: classes.textFieldRoot }}
                                            >
                                                <Controller
                                                  as={(
                                                      <SelectMenu
                                                        disabled={searchCandidatesStatus === "pending"}
                                                        items={DaysSinceCreated}
                                                        label="Days Since Created"
                                                      />
                                            )}
                                                  name="created_at_last_days_number"
                                                  control={control}
                                                  defaultValue=""
                                                  onChange={handleSelectChange}
                                                />
                                            </FormControl>
                                        </Grid>

                                        {/* Relocation status part */}
                                        <Grid item xs={12} sm={3} md={2}>
                                            <FormControl
                                              variant="outlined"
                                              fullWidth
                                              size="small"
                                              classes={{ root: classes.textFieldRoot }}
                                            >
                                                <Controller
                                                  as={(
                                                      <SelectMenu
                                                        disabled={searchCandidatesStatus === "pending"}
                                                        items={relocationStatusList.map(({ name, value }) => ({
                                                            title: name,
                                                            value
                                                        }))}
                                                        label="Relocation"
                                                      />
                                            )}
                                                  name="relocation_status"
                                                  control={control}
                                                  defaultValue=""
                                                  onChange={handleSelectChange}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                          style={{ position: "relative" }}
                                          item
                                          xs={12}
                                          sm={3}
                                          md={2}
                                        >
                                            <AutoCompleteForm
                                              index={index}
                                              inputType="name"
                                              searchResults={searchResults}
                                              searchCallback={handleFilterData}
                                              setSearchResults={setSearchResults}
                                              setSearchIndex={setSearchIndex}
                                            >

                                                <CustomInput
                                                  fullWidth
                                                  style={{ margin: 0, height: "35.25px" }}
                                                  placeholder="Name/Email"
                                                  inputRef={register}
                                                  type="search"
                                                  autoComplete="off"
                                                  onKeyDown={submitName}
                                                  value={searchName}
                                                  name="name_email_query"
                                                  onChange={handleSearchNameChange}
                                                />
                                            </AutoCompleteForm>
                                        </Grid>

                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Collapse>
                </form>
            </div>
            { desktop
            && (
                <Box
                  display="flex"
                  alignItems="center"
                  margin="8px auto"
                  height="180px"
                  paddingLeft="16px"
                  paddingRight="16px"
                  marginTop={generateTopMargin()}
                  className={classes.carouselSection}
                  style={{ pointerEvents: candidateGroup ? "all" : "none" }}
                >
                    <Carousel
                      className={classes.Carousel}
                      responsive={responsive}
                      showDots={false}
                      keyBoardControl
                      itemClass="carousel-item-padding-40-px"
                      containerClass="carousel-container"
                    >
                        {premadeQueries.map((value: any) => (
                            <Paper
                              onClick={() => handlepremadeSearch(value)}
                              key={value.title}
                              className={classes.PremadeCard}
                            >
                                <>
                                    <div
                                      className={`container ${selectedCard.title === value.title
                                          ? "selectedContainer"
                                          : ""}`}
                                    >
                                        <span className="title">{ value.title }</span>
                                    </div>
                                    <div className="candidates">
                                        <span>
                                            {(candidateGroup?.[value.title])
                                                ? candidateGroup?.[value.title].count
                                                : 0 }
                                            {" "}
                                              Candidates
                                        </span>
                                    </div>
                                </>
                            </Paper>
                        ))}
                    </Carousel>

                </Box>
            ) }
        </>
    );
};

export default SearchForm;
