/* eslint-disable no-nested-ternary */
import React, {
    ReactNode,
    FunctionComponent,
    useEffect,
    useState,
    createContext,
    useCallback
} from "react";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { auth, firestore, firebase } from "../../firebase";

import {
    LoadingPage, splitFullName, IRecruiter, ErrorMsg
} from "compass-shared";
import { AuthStatus } from "./AuthenticationProviderTypes";
import useAppContext from "../../context/store/store";
import { doSetUser } from "../../context/reducer/Actions";
import { recruitersRef } from "../../FirebaseUtils/collectionRefs";
import {
    ROUTE_AUTHENTICATED_CANDIDATE_LIST,
    ROUTE_AUTHENTICATED_SETTINGS
} from "../../routes";
import {
    setUserForAnalytics,
    removeUserForAnalytics,
    signOutEventOnAnalytics
} from "../../Utils/gTagHandler";

export type AuthenticationContextAttributes = IRecruiter | {};

interface IAuthenticationProviderProps {
    children: ((props: IRecruiter) => ReactNode) | ReactNode;
    renderWhenUnauthenticated: () => ReactNode;
    renderWhenUserCameFromLinkedIn: () => ReactNode;
    // getUser: any;
}

const provider = new firebase.auth.GoogleAuthProvider();
// eslint-disable-next-line max-len
export const AuthenticationContext = createContext<AuthenticationContextAttributes>({});

export const signInWithToken = (token: string): void => {
    auth.signInWithCustomToken(token).catch(console.error);
};
export const signInWithEmailAndPassword = (email: string, password: string) => (
    auth.signInWithEmailAndPassword(email, password)
);

export const signInWithGMail = async () => await auth.signInWithPopup(provider);

export const signOut = (): void => {
    auth.signOut().then(() => {
        signOutEventOnAnalytics();
        navigate("/");
    }).catch(console.error);
};
export const sendPasswordResetEmail = async (email: string) => (
    await auth.sendPasswordResetEmail(email)
);

export const defaultRecruiter: IRecruiter = {
    email: "",
    firstName: "",
    lastName: "",
    is_active: true, // @TODO PERMISSION change this true to false
    has_been_onboarded: false,
    linkedin_url: "",
    created_at: "",
    client_name_logs: []
};

// @TODO : PERMISSION START --> temporary approve recruiter, after that uncomment this block and remove rest
// const createRecruiterForGMailUsers = async (
//     uid: string, email: string, displayName: string
// ) => {
//     const [firstName, lastName] = splitFullName(displayName);
//     await recruitersRef.doc(uid).set({
//         ...defaultRecruiter,
//         email,
//         firstName,
//         lastName,
//         created_at: firebase.firestore.FieldValue.serverTimestamp()
//     });

//     await signOut();
//     toast.warn(
//         <WarnMsg
//           text="You do not have access yet, ask the Admin to set up your permissions"
//         />
//     );
// };

const createRecruiterForGMailUsers = async (
    uid: string, email: string, displayName: string
) => {
    try {
        const existRecruiter = await recruitersRef.doc(uid).get();
        if (existRecruiter.data()) {
            return true;
        }

        const [firstName, lastName] = splitFullName(displayName);
        await recruitersRef.doc(uid).set({
            ...defaultRecruiter,
            email,
            firstName,
            lastName,
            created_at: firebase.firestore.FieldValue.serverTimestamp()
        });
        return true;
    } catch (err) {
        console.log("error :: ", err);
    }
};
// @TODO : PERMISSION END

const useAuthentication = () => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.unknown);
    const [unknownStatusText, setUnknownStatusText] = useState("Compass...");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, dispatch]: any = useAppContext();
    useEffect(() => {
        let unsubscribe: null | (() => void) = null;
        const onSignIn = async (firebaseUser: any) => {
            const result = await firebaseUser.getIdTokenResult();
            // const { isRecruiter, isAdmin } = result.claims;

            // @TODO : PERMISSION START --> temporary approve recruiter, after that uncomment this block and remove rest
            // if (!isRecruiter && !isAdmin) {
            //     if (result.signInProvider === "google.com") {
            //         await createRecruiterForGMailUsers(
            //             firebaseUser.uid,
            //             firebaseUser.email,
            //             firebaseUser.displayName
            //         );
            //     } else {
            //         toast.warn(
            //             <WarnMsg
            //               text="You do not have access yet, ask the Admin to set up your permissions"
            //             />
            //         );
            //         await signOut();
            //         return;
            //     }
            // }

            if (result.signInProvider === "google.com") {
                await createRecruiterForGMailUsers(
                    firebaseUser.uid,
                    firebaseUser.email,
                    firebaseUser.displayName
                );
            }
            // @TODO : PERMISSION END

            const userRef = firestore
                .collection("recruiters")
                .doc(firebaseUser.uid);

            unsubscribe = userRef.onSnapshot((updatedUser: any) => {
                if (updatedUser.data()) {
                    // eslint-disable-next-line no-underscore-dangle
                    const _user = {
                        ...updatedUser.data(),
                        isRecruiter: true,
                        id: updatedUser.id,
                    } as IRecruiter;

                    dispatch(doSetUser(_user));
                    setUserForAnalytics(_user);
                    const is_active = _user.is_active as any;
                    if (is_active === "deactivated") {
                        toast.error(<ErrorMsg text="Your account is de-activated." />);
                    } else {
                        // check for recruiter has_been_onboarded status
                        if (!_user.has_been_onboarded) {
                            navigate(ROUTE_AUTHENTICATED_SETTINGS);
                        } else if (window.location.pathname === "/") {
                            navigate(ROUTE_AUTHENTICATED_CANDIDATE_LIST);
                        }
                        setAuthStatus(AuthStatus.authenticated);
                    }
                } else if (!updatedUser.data() && (firebaseUser.uid as string).startsWith("LinkedIn")) {
                    setAuthStatus(AuthStatus.linkedIn);
                } else {
                    setAuthStatus(AuthStatus.unauthenticated);
                }
            });
        };

        const onSignOut = () => {
            removeUserForAnalytics();
            setAuthStatus(AuthStatus.unauthenticated);
            // setUser({});
            dispatch(doSetUser(defaultRecruiter));
        };

        auth.onAuthStateChanged((firebaseUser) => {
            if (unsubscribe !== null) unsubscribe();
            if (firebaseUser) {
                // TODO: Determines how the render should happen when transient errors occur
                onSignIn(firebaseUser)
                    .catch((err) => {
                        console.error(err);
                        if (err && err.code && err.code === "auth/network-request-failed") {
                            setUnknownStatusText(`There seems to be a problem with your 
                            internet connection. Please check and try again!`);
                        } else {
                            setUnknownStatusText(err.message);
                        }
                    });
            } else {
                onSignOut();
            }
        });
    }, []);
    return { authStatus, unknownStatusText };
};

// eslint-disable-next-line max-len
const AuthenticationProvider: FunctionComponent<IAuthenticationProviderProps> = ({
    children,
    renderWhenUnauthenticated,
    renderWhenUserCameFromLinkedIn,
}) => {
    const { authStatus, unknownStatusText } = useAuthentication();
    const [{ user }]: any = useAppContext();
    // getUser(user);
    // TODO: create a view when there is no internet

    const checkUserStatus = useCallback(async () => {
        if (user.id) {
            await recruitersRef.doc(user.id).onSnapshot((snap) => {
                if (snap?.data()?.is_active === "deactivated") {
                    auth.signOut()
                        .then(() => {
                            navigate("/");
                        })
                        .catch((err) => console.log("ERR", err));
                }
            });
        }
    }, [user]);

    useEffect(() => {
        checkUserStatus();
    }, [checkUserStatus]);

    return (
        <AuthenticationContext.Provider value={defaultRecruiter}>
            {
                authStatus === AuthStatus.unknown
                    ? <LoadingPage text={unknownStatusText} size={50} />
                    : authStatus === AuthStatus.authenticated
                        ? children instanceof Function
                            ? children(defaultRecruiter as IRecruiter)
                        // eslint-disable-next-line no-constant-condition
                            : user.is_active || true // @TODO PERMISSION remove this true
                                ? children
                                : <h1>You do not have access, ask the Admin to set up your permissions</h1>
                        : authStatus === AuthStatus.unauthenticated
                            ? renderWhenUnauthenticated()
                            : renderWhenUserCameFromLinkedIn()
            }
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
