import React, { FC } from "react";
import { navigate, RouteComponentProps, Router } from "@reach/router";

import TestPublic from "./Publics/TestPublic/TestPublic";
import {
    ROUTE_AUTHENTICATED_CREATE_CANDIDATE, ROUTE_AUTHENTICATED_JOB_OPENINGS,
    ROUTE_AUTHENTICATED_ROOT,
    ROUTE_AUTHENTICATED_SETTINGS,
    ROUTE_PUBLiC_TEST,
    ROUTE_PUBLiC_TEST_CLASS_COMPONENT,
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD,
    ROUTE_UNAUTHENTICATED_LOGIN,
    ROUTE_UNAUTHENTICATED_REGISTER,
    ROUTE_SEND_INTRO, ROUTE_AUTHENTICATED_SEARCH, ROUTE_AUTHENTICATED_FAQ
} from "../routes";
import TestClassComponent from "./Publics/TestPublic/TestClassComponent";
import SignIn from "./UnAuthenticated/SignIn/SignIn";
import SignUp from "./UnAuthenticated/SignUp/SignUp";
import ResetPassword from "./UnAuthenticated/ResetPassword/ResetPassword";
// eslint-disable-next-line max-len
import AuthenticationProvider from "../Components/AuthenticationProvider/AuthenticationProvider";
import Home from "./Authenticated/Home/Home";
import NotFound from "./Authenticated/NotFound/NotFound";
import NavigationBar from "../Components/NavigationBar";
import Setting from "./Authenticated/Setting/Setting";
import CreateCandidateContainer from "../Components/Candidates/CreateCandidate";
import CandidateProfile from "../Components/Candidates/CandidateProfile/CandidateProfile";
import { Box } from "@material-ui/core";
import PathPic from "../Assets/img/team.png";
import Button from "@material-ui/core/Button";
import { ESearchStatus } from "../context/reducer/Interface";
import useAppContext from "../context/store/store";
import { JobOpenings } from "./Authenticated/JobOpenings/JobOpenings";
import { useJobOpening } from "../hooks/useJobOpenings";
import RequestIntro from "./Authenticated/RequestIntro/RequestIntro";
import FaqPage from "./Authenticated/Faq";

export const PublicRoutes: FC<RouteComponentProps> = () => (
    <>
        <Router>
            <TestPublic path={ROUTE_PUBLiC_TEST} />
            <TestClassComponent path={ROUTE_PUBLiC_TEST_CLASS_COMPONENT} />
        </Router>
    </>
);

const UnauthenticatedRouter = () => (
    <Router>
        <SignIn path={ROUTE_UNAUTHENTICATED_LOGIN} default />
        <SignUp path={ROUTE_UNAUTHENTICATED_REGISTER} />
        <ResetPassword path={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD} />
    </Router>
);

const LinkedInRouter = () => (
    <h1>LinkedIn</h1>
);

const DefaultView: FC<RouteComponentProps> = () => {
    const [{ currentSearchStatus }]: any = useAppContext();
    return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "-webkit-fill-available" }}
        >
            <img src={PathPic} alt="curious recruiters" style={{ height: "300px", marginBottom: "20px" }} />
            {currentSearchStatus === ESearchStatus.ACTIVE
            && (
                <>
                    <div style={{ fontWeight: "bold" }}>
                Creating candidates helps all of us to do more placements.
                    </div>
                    <Button
                      onClick={() => {
                          navigate(`/candidates/create`);
                      }}
                      style={{
                          padding: "1em 2em",
                          backgroundColor: "#539CAB",
                          width: "max-content",
                          fontWeight: "bold",
                          marginTop: "1em",
                          color: "#ffffff"
                      }}
                    >
                Create Candidate
                    </Button>
                </>
            )}
        </Box>
    );
};

export const PrivateRoutes: FC<RouteComponentProps> = React.memo(() => {
    useJobOpening();
    return (
        <AuthenticationProvider
          renderWhenUnauthenticated={UnauthenticatedRouter}
          renderWhenUserCameFromLinkedIn={LinkedInRouter}
        >
            <Router>
                <NavigationBar path={ROUTE_AUTHENTICATED_ROOT}>
                    <CandidateProfile candidateList={[]} path="/candidates/:candidateId/*" />
                    <Home
                      path={ROUTE_AUTHENTICATED_SEARCH}
                      candidateList={[]}
                      searchStatus={ESearchStatus.ACTIVE}
                    >
                        <DefaultView path="/:candidateId?" />
                    </Home>

                    <Home
                      path={ROUTE_AUTHENTICATED_SEARCH}
                      candidateList={[]}
                      searchStatus={ESearchStatus.ACTIVE}
                    >
                        <DefaultView path="/" />
                        <CandidateProfile path="/:candidateId/*" />
                    </Home>

                    <Home
                      path="/archived-candidates"
                      candidateList={[]}
                      searchStatus={ESearchStatus.ARCHIVED}
                    >
                        <DefaultView path="/" />
                        <CandidateProfile path="/:candidateId/*" />
                    </Home>

                    <CreateCandidateContainer
                      path={ROUTE_AUTHENTICATED_CREATE_CANDIDATE}
                      candidateList={[]}
                    />

                    <JobOpenings path={ROUTE_AUTHENTICATED_JOB_OPENINGS} />

                    <Setting path={ROUTE_AUTHENTICATED_SETTINGS} />
                    <FaqPage path={ROUTE_AUTHENTICATED_FAQ} />
                    <RequestIntro path={`${ROUTE_SEND_INTRO}/*`} />
                    <NotFound default />
                </NavigationBar>
            </Router>
        </AuthenticationProvider>
    );
});
