export interface IAction {
    type: ActionType;
    payload: any;
}

export enum ESearchStatus {
    ACTIVE = "ACTIVE",
    ARCHIVED = "ARCHIVED"
}

export interface IFieldErrors {
    field: string;
    section: string;
    id: string
}

export interface IDirectContact {
    candidate: any,
    isOpen: boolean
}

export enum ActionType {
    SET_USER = "SET_USER",
    SET_NAVBAR_STATUS = "SET_NAVBAR_STATUS",
    UPDATE_PROFILE="UPDATE_PROFILE",
    START_EDIT_PROFILE="START_EDIT_PROFILE",
    COMPLETE_EDIT_PROFILE="COMPLETE_EDIT_PROFILE",
    START_SEARCH_CANDIDATES = "START_SEARCH_CANDIDATES",
    START_SEARCH_JOB_OPENINGS = "START_SEARCH_JOB_OPENINGS",
    COMPLETE_SEARCH_JOB_OPENINGS = "COMPLETE_SEARCH_JOB_OPENINGS",
    COMPLETE_SEARCH_CANDIDATES = "COMPLETE_SEARCH_CANDIDATES",
    COMPLETE_SEARCH_CANDIDATES_GROUP = "COMPLETE_SEARCH_CANDIDATES_GROUP",
    START_REQUERY_CANDIDATES="START_REQUERY_CANDIDATES",
    START_SEARCH_PROFILE = "START_SEARCH_PROFILE",
    SET_EDIT_PROFILE_ERRORS = "SET_EDIT_PROFILE_ERRORS",
    CLEAR_EDIT_PROFILE_ERRORS = "CLEAR_EDIT_PROFILE_ERRORS",
    COMPLETE_SEARCH_PROFILE = "COMPLETE_SEARCH_PROFILE",
    RESET_SEARCH_DATA = "RESET_SEARCH_DATA",
    CHANGE_CREATE_EMPTY_PROFILE_MODE = "CHANGE_CREATE_EMPTY_PROFILE_MODE",
    SET_PROFILE_DATA = "SET_PROFILE_DATA",
    SET_PROFILE_DATA_EXPERIENCE = "SET_PROFILE_DATA_EXPERIENCE",
    SET_PROFILE_DATA_EDUCATION = "SET_PROFILE_DATA_EDUCATION",
    RESET_PROFILE_DATA = "RESET_PROFILE_DATA",
    SET_PROFILE_DATA_ADD_EXPERIENCE = "SET_PROFILE_DATA_ADD_EXPERIENCE",
    SET_PROFILE_DATA_ADD_EDUCATION = "SET_PROFILE_DATA_ADD_EDUCATION",
    REMOVE_EXPERIENCE = "REMOVE_EXPERIENCE",
    REMOVE_EDUCATION = "REMOVE_EDUCATION",
    TOGGLE_SEARCH_FORM = "TOGGLE_SEARCH_FORM",
    TOGGLE_MAIN_SEARCH_FORM = "TOGGLE_MAIN_SEARCH_FORM",
    RESET_EDIT_PROFILE_DATA = "RESET_EDIT_PROFILE_DATA",
    CHANGE_SEARCH_STATUS = "CHANGE_SEARCH_STATUS",
    TOGGLE_CREATE_JOB_OPENING = "TOGGLE_CREATE_JOB_OPENING",
    TOGGLE_REQUEST_INTRO = "TOGGLE_REQUEST_INTRO",
    SET_RECRUITER_IN_TOUCH = "SET_RECRUITER_IN_TOUCH",
    TOGGLE_DIRECT_CONTACT = "TOGGLE_DIRECT_CONTACT"
}
