import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(() => ({
    table: {
        textAlign: "center",
        width: "100%",
        padding: 0,
        margin: 0,
        borderSpacing: "unset",
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -2,
        left: -2,
        zIndex: 1,
    },
    disabledAvatar: {
        "&:before": {
            content: "''",
            position: "absolute",
            backgroundColor: "#ffffff9e",
            height: "100%",
            width: "100%"
        }
    },
    add: {
        backgroundColor: "black"
    },
    close: {
        backgroundColor: "#F38A87"
    },
    iconColor: {
        color: "white"
    },
}));

export default useStyles;
