import React, { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import ContentEditable from "react-contenteditable";

import { LoadingPage } from "compass-shared";
import { IProps } from "./interface";
import useStyles from "./style";
import RequestModalResponse from "./requestModalResponse";
import Box from "@material-ui/core/Box";

import useAppContext from "../../context/store/store";

const RequestIntro: FC<IProps> = ({
    textAreaValue,
    fetchingIntroData,
    candidate,
    recruiterIntouch,
    imageLoading,
    loading,
    editableEl,
    status,
    modalTitle,
    modalBody,
    isModalOpen,
    candidateProfile,
    contentEdidatableDisabled,
    closeModal,
    handleChanges,
    handleRequestIntro,
    onBlur,
}: IProps) => {
    const classes = useStyles();
    const [{
        user,
    },]: any = useAppContext();

    return (
        <>
            <RequestModalResponse
              isModalOpen={isModalOpen}
              titleText={modalTitle}
              bodyText={modalBody}
              status={status}
              closeModal={closeModal}
            />
            {fetchingIntroData ? <LoadingPage size={50} text="fetching intro data" />
                : (
                    <Card className={classes.cardContainer} variant="outlined">
                        <CardHeader
                          title={status === "accepted" ? "Send Intro" : "Reject Intro"}
                          classes={{
                              root: classes.cardHeaderRoot,
                              title: classes.cardHeaderTitle
                          }}
                        />
                        <CardContent className={classes.cardContent}>
                            <div className={classes.cardContentHeader}>
                                <Box display="flex" flexDirection="column ">
                                    <Box display="flex" paddingBottom="5px">
                                        <div className={classes.cardToText}>
                                            Receiver:
                                        </div>
                                        <span className={classes.cardEmailText}>
                                            {status === "rejected"
                                                ? `${recruiterIntouch?.email}; ${user.email}`
                                                : `${candidate?.email}; ${recruiterIntouch?.email}; ${user.email}`
                                            }
                                        </span>
                                    </Box>
                                    <Box display="flex">
                                        <div className={classes.cardToText}>
                                            Subject:
                                        </div>
                                        <span className={classes.cardEmailText}>
                                            {status === "rejected"
                                                ? "Intro rejected"
                                                : `${candidate?.firstName} <> ${user?.firstName} intro`}
                                        </span>
                                    </Box>
                                </Box>
                                <div />
                                <div className={classes.imageContainer}>
                                    <div className={classes.avatarContainer}>
                                        <Avatar
                                          alt={`${user?.firstName} ${user?.lastName}`}
                                          src={imageLoading ? "" : user?.original_avatar_url}
                                          className={classes.imageSRC}
                                        >
                                            {imageLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <PersonRoundedIcon className={classes.personIcon} />
                                            )}
                                        </Avatar>
                                    </div>
                                    <span
                                      style={{
                                          width: 33,
                                          height: 3,
                                          border: `1px dashed ${status === "accepted" ? "#31B33D" : "#F38A87"}`,
                                          boxSizing: "border-box"
                                      }}
                                    />
                                    {status === "accepted"
                                        ? (
                                            <CheckCircleIcon
                                              style={{ color: "#31B33D", height: "18px", width: "18px" }}
                                            />
                                        )
                                        : (
                                            <CancelIcon
                                              style={{ color: "#F38A87", height: "18px", width: "18px" }}
                                            />
                                        )}
                                    <span
                                      style={{
                                          width: 37,
                                          height: 3,
                                          border: `1px dashed ${status === "accepted" ? "#31B33D" : "#F38A87"}`,
                                          boxSizing: "border-box"
                                      }}
                                    />
                                    <div className={classes.avatarContainer}>
                                        <Avatar
                                          alt={`${candidate?.firstName} ${candidate?.lastName}`}
                                          src={imageLoading ? "" : candidate?.thumbnail_avatar_url}
                                          className={classes.imageSRC}
                                        >
                                            {imageLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <PersonRoundedIcon className={classes.personIcon} />
                                            )}
                                        </Avatar>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.emailBodyTitleContainer}>
                                <Typography className={classes.emailText} style={{ lineHeight: 0 }} variant="h3" component="div">
                            {/* Email Message */}
                                </Typography>
                                <div style={{ width: 235, display: "flex", justifyContent: "space-between" }}>
                                    <div className={classes.avatartTextItem}>
                                        {user?.firstName}
                                        {" "}
                                        {user?.lastName}
                                    </div>
                                    <div className={classes.avatartTextItem}>
                                        <a href={`${candidateProfile}`} rel="noopener noreferrer" target="_blank">
                                            <IconButton classes={{ root: classes.openInNew }} aria-label="delete">
                                                <OpenInNewIcon
                                                  style={{ color: "#000000", fontSize: "15px" }}
                                                />
                                            </IconButton>
                                        </a>
                                        {candidate?.firstName}
                                    </div>
                                </div>
                            </div>
                            <ContentEditable
                              innerRef={editableEl}
                              onChange={handleChanges}
                        // onBlur={onBlur}
                              html={textAreaValue}
                              className={classes.contentEditable}
                              disabled={contentEdidatableDisabled}
                            />
                            {status === "accepted" ? (
                                <Button
                                  variant="contained"
                                  disableElevation
                                  className={classes.sendIntro}
                                  onClick={handleRequestIntro}
                                  endIcon={loading ? <CircularProgress size={18} /> : null}
                                  disabled={loading}
                                >
                            Send Intro
                                </Button>
                            )
                                : (
                                    <Button
                                      variant="contained"
                                      disableElevation
                                      className={`${classes.sendIntro} ${classes.rejectSendIntro}`}
                                      onClick={handleRequestIntro}
                                      endIcon={loading ? <CircularProgress size={18} /> : null}
                                      disabled={loading}
                                    >
                            Reject Intro
                                    </Button>
                                )}
                        </CardContent>
                    </Card>
                )}
        </>
    );
};

export default RequestIntro;
