import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    drawerPaperRoot: {
        width: "738px",
        padding: "35px",

        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "20px",
        },
    },
    nameSection: {
        "& .emoji-mart-emoji": {
            marginLeft: 5,
        },
    },
    reactTooltip: {
        fontSize: 13,
        padding: "14px 21px !important",
        borderRadius: "10px !important",
        "& .recruiterInfo": {
            display: "flex",
            alignItems: "center",
            "& pre": {
                color: "#221A16",
                border: "none",
                padding: 0,
                fontSize: 15,
                margin: ".3em 0px",
                fontFamily: "Montserrat",
                lineHeight: "21px",
            },
        },
    },
    actionsContainer: {
        display: "flex",
        gap: "4px",
        justifyContent: "flex-end",
    },
    buttonContainer: {
        "& .MuiGrid-root": {
            marginRight: 0,
            position: "relative",
        },
        "& .MuiButtonGroup-groupedHorizontal": {
            height: "35px",
        },
    },
    candidateProfile: {
        [theme.breakpoints.between(0, 767)]: {
            marginTop: "20px",
        },
        display: "flex",
        "& label": {
            width: "250px !important",
            height: "250px !important",
            cursor: "auto",
        },
        justifyContent: "space-between",
        "& .profileInfo": {
            display: "grid",
            gridTemplateColumns: ".8fr 2fr",
            [theme.breakpoints.only("md")]: {
                gridTemplateColumns: "1fr 3fr",
            },
            [theme.breakpoints.down("xs")]: {
                gridTemplateColumns: "1fr 2fr",
            },
            marginBottom: "4px",
            alignItems: "center",
            "& .title": {
                fontSize: "15px",
            },
        },
        "& .MuiButton-label": {
            justifyContent: "unset",
            alignItems: "unset",
            [theme.breakpoints.down("xs")]: {
                width: "unset",
            },
        },
        "& .MuiButton-text": {
            padding: "unset",
        },
        "& .MuiAvatar-rounded": {
            width: "220px !important",
            height: "220px !important",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    divider: {
        background: "#F2F2F3",
        height: "1px",
        marginTop: "32px",
        marginBottom: "32px",
    },
    profile: {
        height: "22px",
        backgroundColor: "#FCC46B",
        borderRadius: "5px",
        fontSize: "15px",
        fontWeight: 400,
        color: "#221A16",
        padding: "2px 12px",
        textTransform: "capitalize",
    },
    textContainerSmall: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            "& .MuiTypography-subtitle1": {
                fontSize: "22px !important",
            },
            "& .centeredFlexCard": {
                justifyContent: "start",
            },
            "& .MuiGrid-grid-xs-3": {
                maxWidth: "100%",
                marginBottom: "21px",
            },
        },
    },
    location: {
        display: "flex",
        width: "fit-content",
        alignItems: "center",
    },
    copyToClipboardIcon: {
        height: 20,
        minWidth: 10,
        marginLeft: 8,
        cursor: "pointer",
    },
    candidateText: {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: 15,
        lineHeight: "21px",
    },
    expandedList: {
        marginTop: 20,
        marginBottom: 20,
    },
    skillsContainer: {
        gap: "20px",
        display: "flex",
        "& .skills": {
            display: "flex",
            flexDirection: "column",
            "& .title": {
                fontSize: "15px",
                fontWeight: 600,
                marginBottom: "11px",
            },
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "21px",
            gap: "25px",
            flexWrap: "wrap",
        },
    },
    sectionTitleDrawer: {
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "21px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
        },
    },
    motivation: {},
    experienceContainer: {
        "& .MuiBox-root": {
            fontSize: "15px",
            fontWeight: 600,
        },
        "& .MuiTypography-root": {
            fontSize: "15px !important",
        },
        "& .MuiTypography-subtitle1": {
            fontSize: "18px !important",
        },
        "& .experienceItem": {
            "&:not(:last-child)": {
                marginBottom: "18px",
            },
            "& .MuiBox-root": {
                // textTransform: "capitalize"
            },
        },
    },
    contacts: {
        display: "flex",
    },
    commentsSection: {},
    recruiterSection: {
        display: "flex",
    },
    itemButton: {
        minWidth: 35,
        height: 35,
        backgroundColor: "#221A16",
        color: "#FFFFFF",
        textTransform: "none",
        padding: 4,
        "&:hover": {
            backgroundColor: "rgb(76,77,86)",
            boxShadow: "none",
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    itemCloseButton: {
        backgroundColor: "#FF6A66",
        "&:hover": {
            backgroundColor: "#ff5252",
            boxShadow: "none",
        },
    },
    contentEditable: {
        fontSize: 15,
        fontWeight: 400,
        border: "none",
        padding: 0,
        whiteSpace: "pre-wrap",
        fontFamily: "Montserrat",
        lineHeight: "21px",
        color: "#221A16",
        "&:focus": {
            border: "none",
            outlineWidth: 0,
        },
        "&:focus-visible": {
            outlineWidth: 0,
        },
        "&:hover": {
            border: "none",
            outlineWidth: 0,
        },
    },
    contactsDate: {
        fontSize: 15,
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
}));
