import React, { FC, useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import { navigate, RouteComponentProps, Router } from "@reach/router";
import useStyles from "./style";
import useAppContext from "../../../context/store/store";
import ProfileData from "./ProfileData/ProfileData";
import {
    LoadingPage, NotFound, FailToLoad, ICandidate
} from "compass-shared";
import { candidatesRef } from "../../../FirebaseUtils/collectionRefs";
import {
    doCompleteSearchProfile,
    doResetProfileData,
    doStartSearchCandidates
} from "../../../context/reducer/Actions";
import { PROFILE_STATUS } from "./Enums";
import EditProfile from "./ProfileData/EditProfile";
import { ESearchStatus } from "../../../context/reducer/Interface";
import { IEditCandidate } from "../CreateCandidate/interface";

const CandidateProfile: FC<RouteComponentProps & IEditCandidate> = (props) => {
    const [{
        profileSearchInfo,
        isSearchCandidateProfile,
        user,
        candidatesSearchInfo,
        currentSearchStatus
    }, dispatch]: any = useAppContext();
    const [candidate, setCandidate] = useState<ICandidate | undefined>();
    const [profileStatus, setProfileStatus] = useState<PROFILE_STATUS>(PROFILE_STATUS.Loading);
    const [currentCandidateId, setCurrentCandidateId] = useState<string>("");
    const { candidateId, candidateList } = props as any;
    const getCandidateData = async () => {
        setCandidate(undefined);
        if (candidateId) {
            try {
                setProfileStatus(PROFILE_STATUS.Loading);
                const candidateDocumentData = await candidatesRef.doc(candidateId).get();
                if (!candidateDocumentData.exists) {
                    setProfileStatus(PROFILE_STATUS.NotFound);
                }
                const candidateData = await (candidateDocumentData.data() as ICandidate);
                setCandidate(candidateData);
            } catch (error) {
                setProfileStatus(PROFILE_STATUS.ErrorAccured);
            } finally {
                dispatch(doCompleteSearchProfile());
            }
        } else {
            setProfileStatus(PROFILE_STATUS.NotFound);
            dispatch(doCompleteSearchProfile());
        }
    };

    useEffect(() => {
        if (props["*"] !== "linkedin") {
            getCandidateData();
        } else {
            /**
             *  when user comes from extension
             */
            let unsubscribe: any;
            try {
                setCandidate(undefined);
                let currentCandidate: ICandidate | undefined;
                const query = candidatesRef.doc(candidateId);
                unsubscribe = query.onSnapshot((querySnapshot) => {
                    currentCandidate = querySnapshot.data() as ICandidate;
                    setCandidate(currentCandidate);
                    if (currentCandidate) {
                        setTimeout(() => {
                            dispatch(doStartSearchCandidates({ ...candidatesSearchInfo }));
                        }, 3500);
                    }
                });
                setTimeout(() => {
                    if (!currentCandidate) {
                        setProfileStatus(PROFILE_STATUS.ErrorAccured);
                    }
                }, 80000);
            } catch (error) {
                setProfileStatus(PROFILE_STATUS.ErrorAccured);
            }
            return () => unsubscribe();
        }
    }, [candidateId]);

    useEffect(() => {
        if (profileSearchInfo.id && profileSearchInfo.id !== currentCandidateId) {
            setCurrentCandidateId(profileSearchInfo.id);
        } else if (profileSearchInfo.id === currentCandidateId) {
            // If user try to open current candidate profile search result changes to compelete
            dispatch(doCompleteSearchProfile());
        }
    }, [isSearchCandidateProfile]);

    const classes = useStyles();

    const showProfileStatus = () => {
        let statusComponent;
        switch (profileStatus) {
            case PROFILE_STATUS.ErrorAccured:
                statusComponent = <FailToLoad onReload={getCandidateData} />;
                break;
            case PROFILE_STATUS.Loading:
                statusComponent = (
                    <LoadingPage
                      text={
                            props["*"] === "linkedin"
                                ? "We are working on getting data from LinkedIn..."
                                : ""
                        }
                    />
                );
                break;
            case PROFILE_STATUS.NotFound:
                statusComponent = <NotFound />;
                break;
            default:
                statusComponent = <FailToLoad onReload={getCandidateData} />;
        }
        return statusComponent;
    };

    const listUrl = () => {
        switch (currentSearchStatus) {
            case ESearchStatus.ACTIVE:
                return "/candidates/";
            default:
                return "";
        }
    };

    const closeProfile = () => {
        setCurrentCandidateId("");
        navigate(listUrl());
    };

    const closeEditProfile = () => {
        navigate(`${listUrl()}${candidateId}`);
        dispatch(doResetProfileData());
    };

    return (
        <>
            <Grid container className={classes.boxContainer} justify="center">
                {
                    candidate
                        ? (
                            <Router style={{ width: "100%" }}>
                                <ProfileData
                                  path="/"
                                  closeProfile={closeProfile}
                                  candidate={candidate}
                                  user={user}
                                  currentCandidateId={candidateId}
                                />
                                <ProfileData
                                  path="/linkedin"
                                  closeProfile={closeProfile}
                                  candidate={candidate}
                                  user={user}
                                  currentCandidateId={candidateId}
                                />
                                <EditProfile
                                  path="edit"
                                  candidate={candidate}
                                  currentCandidateId={candidateId}
                                  getCandidateData={getCandidateData}
                                />
                            </Router>
                        )
                        : (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                              height={0.9}
                            >
                                {showProfileStatus()}
                            </Box>
                        )
                }
            </Grid>
        </>
    );
};

export default CandidateProfile;
