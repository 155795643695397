
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    skillChip: {
        height: 30,
        marginRight: "5.36px",
        backgroundColor: "#E9F1F4",
        borderRadius: "5px",
        fontSize: "14px",
        fontWeight: 400,
        color: "#221A16",
        padding: "0px 12px",
        marginBottom: "15px"
    },
    addHover: {
        "&:hover": {
            cursor: "pointer"
        }
    }
}));

export default useStyles;
