import { IUploadAvatarRes, IUploadData } from "./Interface";
import { UserType, IImageResponse } from "compass-shared";
import { functions } from "../firebase";
import { candidatesRef } from "../FirebaseUtils/collectionRefs";

export const uploadAvatar = (
    uid: any,
    userType: UserType,
    avatarFile: any,
    shouldUpdateCollection = true
): Promise<IUploadAvatarRes> => new Promise((resolve, reject) => {
    // Max file size is set to 3mb
    if (avatarFile.size >= 1572864) {
        reject({
            result: false,
            code: "file_size_large",
            message: "file size is greater than 1.5mb"
        });
        return;
    }
    if (!avatarFile) {
        reject({
            result: false,
            code: "file_not_exists",
            message: "file not exists"
        });
        return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(avatarFile);
    reader.addEventListener("load", async () => {
        const base64image: any = reader.result;

        // Send to firebase and retrieve the url for the thumbnail and main image
        const data: IUploadData = {
            file: base64image,
            uid,
            sizes: [128],
            userType
        };

        try {
            const uploadAvatarResp: IImageResponse = await functions
                .httpsCallable("users-uploadImage")(data);

            if (shouldUpdateCollection) {
                // update candidate with avatar URLs
                await candidatesRef.doc(uid).update({
                    original_avatar_url: uploadAvatarResp?.data?.originalFileURL || "",
                    thumbnail_avatar_url: uploadAvatarResp?.data?.avatarThumbnailUrl || ""
                });
            }

            const resp: IUploadAvatarRes = {
                result: true,
                original_avatar_url: uploadAvatarResp?.data?.originalFileURL || "",
                thumbnail_avatar_url: uploadAvatarResp?.data?.avatarThumbnailUrl || ""
            };
            resolve(resp);
        } catch (error) {
            reject({
                result: false,
                code: "upload_failed",
                message: "There is a problem with upload avatar, please try later."
            });
        }
    }, false);
});
