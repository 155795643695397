import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    logInput: {
        width: "100%",
        border: "none",
        backgroundColor: "#0000",
        padding: "1rem"
    },
    icon: {
        height: "1.5rem",
        width: "1.5rem",
        marginRight: ".2rem",
        minWidth: ".8rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    iconSize: {
        height: "1.5rem",
        width: "1.5rem",
    },
    listText: {
        fontSize: theme.spacing(2.5),
        color: "#7d7979",
        paddingRight: "1em",
        fontWeight: "bold",
    },
    logWrapper: {
        display: "flex",
        alignItems: "flex-end",
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    editor: {
        // height: "unset"
    },
}));

export default useStyles;
