import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainSkillchip: {
        backgroundColor: "#A6C7D9",
        height: theme.spacing(3)
    },
    chip: {
        margin: "2px 2px 2px 0",
        height: theme.spacing(4),
    },
    experienceChip: {
        margin: theme.spacing(0, 1.2, 0, 0),
        fontWeight: 700,
        height: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
    },
    button: {
        textTransform: "unset"
    },
    topSkill: {
        backgroundColor: "#A6C7D9",
    }
}));

export default useStyles;
