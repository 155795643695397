import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    reactTooltip: {
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        fontWeight: "bolder",
        borderRadius: "10px !important"
    },
    parent: {
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
    },
    parentXLHeight: {
        display: "flex",
        height: "100vh",
        justifyContent: "center",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        maxWidth: "126.8em",
        padding: ".5em"
    },
    layout: {
        flexDirection: "column",
        flex: 1,
        borderRadius: "1em",
        display: "flex",
        padding: "2em",
        marginBottom: "15px"
    },
    sideText: {
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#ffffff",
    },
    createJobOpeningButton: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.between(0, 700)]: {
            "& .MuiButtonBase-root": {
                width: "100%",
            }
        },
        [theme.breakpoints.between(700, 890)]: {
            "& .MuiButtonBase-root": {
                marginTop: "20px",
                marginRight: "12px"
            }
        }

    },
    flexCenterWrapper: {
        "& .MuiGrid-grid-md-2": {
            maxWidth: "unset",
            flexBasis: "unset",
        },
        "& .MuiFormControl-fullWidth": {
            width: "142px"
        },
        "& .MuiGrid-grid-xs-12": {
            "&:not(:last-child)": {
                marginRight: "9px"
            }
        }
    },
    jobOpeningContainer: {
        marginTop: "30px",
        "& .job_function": {
            fontSize: "14px",
            fontWeight: 600,
            color: "#2F2824",
            lineHeight: "17.07px",
            "& .company-name": {
                fontSize: "15px"
            },
            "& a": {
                color: "#2F2824",
                "&:hover": {
                    color: "#2F2824",
                }
            }
        },
        "& .MuiPaper-root": {
            boxShadow: "0px 0px 17px 2px rgba(0, 0, 0, 0.05)"
        },
        "& .status-item": {
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "18.29px",
            color: "#363840"
        },
        "& .MuiFormControl-fullWidth": {
            width: "100px"
        },
    },
    textFieldRoot: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: "8px",
        "& label.Mui-focused": {
            color: "#AEAEB2"
        },
        "& label": {
            color: "#AEAEB2"
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& .MuiSelect-select": {
            "&:focus": {
                backgroundColor: "unset"
            }
        }
    },
    requirements: {
        lineHeight: "18px",
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "capitalize",
        width: "100%",
        color: "#2F2824",
        "& .list-item": {
            display: "grid",
            gridTemplateColumns: "2fr 3fr",
            "& .title": {
                fontWeight: 500,
                fontSize: "14px",
            }
        }
    },
    userAvatar: {
        height: "68px",
        width: "68px",
    },
    recruiterAvatar: {
        height: "50px",
        width: "50px",
    },
    ownAvatar: {
        height: "50px",
        width: "50px",
    },
    personIcon: {
        fontSize: "25px"
    },
    createCandidateButton: {
        backgroundColor: "#FCC46B",
        // width: 175,
        minWidth: 200,
        height: 37,
        fontSize: 13,
        fontWeight: "bold",
        color: "#221A16",
        borderRadius: "7px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#ffb237",
            boxShadow: "none"
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 11
        },
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
    },
    table: {
        "& .MuiTableCell-root": {
            flexDirection: "unset",
            textAlign: "start",
            alignItems: "start",
            width: "100%",
            padding: "16px 16px 16px 20px",
            borderBottom: "1px solid #DBEBEB"
        },
        "& .ReactVirtualized__Table__headerRow": {
            "& .header-title": {
                color: "#fff",
                fontSize: "14px",
                fontWeight: 600
            },
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            backgroundColor: "#221A16",
            paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
            [theme.breakpoints.down("sm")]: {
                overflowX: "auto !important",
                overflowY: "hidden !important"
            },
        },
        "& .ReactVirtualized__Grid ReactVirtualized__Table__Grid": {
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            border: "1px solid #DBEBEB",
            borderTop: "none",
        },
        "& .ReactVirtualized__Grid": {
            "&::-webkit-scrollbar": {
                width: "2px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#221A16"
            },
            "& .ReactVirtualized__Grid__innerScrollContainer": {
                overflow: "scroll !important"
            }
        },
        "& .ReactVirtualized__Table__row": {
            cursor: "initial",
            "& .MuiTableCell-root": {
                padding: "24px 16px 16px 20px"
            },
            "&:hover": {
                backgroundColor: "#fff"
            },
            [theme.breakpoints.down("sm")]: {
                width: "unset !important",
            },
        },
        "& .ReactVirtualized__Table__rowColumn": {
            [theme.breakpoints.down("xs")]: {
                width: 400,
            },
            "&:not(:last-child)": {
                borderRight: "1px solid #E6F5F5"
            }
        },
        "& .ReactVirtualized__Table__headerColumn": {
            "&:not(:last-child)": {
                borderRight: "1px solid #979797"
            }
        }
    },
    tableRow: {
        cursor: "pointer",
    },
    jobOpeningsFilter: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "60px"
        }
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
    },
    tableRowHover: {
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: "initial",
    },
    urlToJobDescription: {
        textDecoration: "none"
    },
    resetButton: {
        textTransform: "unset",
    },
    searchContainer: {
        "& .MuiFormLabel-root": {
            fontSize: "14px !important"
        }
    },
    searchContainerLayout: {
        [theme.breakpoints.between(0, 700)]: {
            justifyContent: "flex-start !important",
            flexDirection: "column-reverse",
            alignItems: "start !important",
            gap: "14px",
            "& .MuiGrid-root": {
                width: "100%",
                "& .MuiFormControl-fullWidth": {
                    width: "100%"
                }
            },
            "& button": {
                float: "right"
            }
        }
    },
    actionContainer: {
        "& .MuiFormLabel-root": {
            fontSize: "15px !important"
        }
    },
    resetLabel: {
        fontSize: 14,
        fontWeight: 600,
        borderBottom: "1px black dotted",
    },
    emptyContainer: {
        width: "100%",
        "& .MuiPaper-root": {
            maxWidth: "84%"
        }
    },
    actions: {
        "& .MuiCircularProgress-root": {
            width: "20px !important",
            height: "20px !important",
            marginRight: "20px"
        }
    }
}));

export default useStyles;
