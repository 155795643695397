import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        textAlign: "right",
        marginBottom: theme.spacing(0.5)
    },
    header: {
        height: "39px",
        backgroundColor: "#221A16",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0 22px",
        color: "#FFF",
        borderRadius: " 10px 10px 0px 0px"
    },
    boxRoot: {
        width: "601px",
        margin: "20px auto",
        position: "relative"
    },
    profileWrapper: {
        overflow: "hidden",
        height: "calc(100vh - 150px)",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            position: "static",
            height: "0px"
        }
    },
    headerLabel: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#FFFFFF"
    },
}));

export default useStyles;
