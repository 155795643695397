import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "2px -1px 4px 2px #4387bf1a",
        border: "1px solid transparent",
        borderRadius: "10px",
        padding: theme.spacing(1),
        margin: "15px 0",
        cursor: "pointer",
        "&:hover": {
            border: "1px solid #c3c2c2",
        },
    },
    viewProfileContainer: {
        minWidth: 200,
        height: 37,
        fontSize: 13,
        color: "#ffffff",
        borderRadius: "7px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(54,55,64,.8)",
            boxShadow: "none",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 14
        }
    },
    avatar: {
        backgroundColor: "#F7CD61",
        width: "160px !important",
        height: "160px !important",
        borderRadius: "50%",

        [theme.breakpoints.down("sm")]: {
            width: "111px !important",
            height: "111px !important",
        },
        [theme.breakpoints.down("xs")]: {
            width: "120px !important",
            height: "120px !important",
        },
    },
    profileContainer: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column-reverse",
            maxWidth: "100% !important",
            flexBasis: "100% !important",
            height: "auto !important",
        },
    },
    avatarCentered: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            paddingRight: 12,
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
        },
        "& label": {
            width: "unset !important",
            height: "unset !important",
            padding: "unset",
        },
        "& .userDetails": {
            display: "grid",
            gridTemplateColumns: "2.5fr 2fr",
            "& span": {
                textTransform: "capitalize",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "26px",
                color: "#221A16",
                "&:nth-child(2)": {
                    marginLeft: 8,
                },
            },
        },
    },
    daysText: {
        width: 98,
        textAlign: "center",
        fontSize: 12,
        fontWeight: 600,
        marginTop: 5,
        marginBottom: 5,
    },
    centredFlex: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        paddingRight: 30,
        [theme.breakpoints.down("xs")]: {
            paddingRight: 12,
        },
    },
    cardItemContainer: {
        flexFlow: "nowrap",
        alignItems: "flex-start",
    },
    smallContainer: {
        [theme.breakpoints.between(768, 800)]: {
            width: "48%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "80%",
        },
        [theme.breakpoints.only("md")]: {
            width: "60%",
            fontSize: 14,
        },
    },
    buttonContainer: {
        "& .MuiButtonGroup-groupedHorizontal": {
            height: "40px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            justifyContent: "flex-end",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            justifyContent: "center",
        },
    },
    confirmationTitle: {
        paddingBottom: 0,
        marginBottom: 10,
    },
    confirmationContent: {
        padding: "0px 24px",
        marginBottom: 15,
    },
    confirmationActions: {
        padding: "0 8px",
    },
    centredAlignedFlex: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    chip: {
        height: 30,
        marginRight: "5.36px",
        backgroundColor: "#FCC46B",
        marginBottom: 15,
        fontSize: 14,
        borderRadius: "5px",
        fontWeight: 400,
        color: "#221A16",
        padding: "0px 4px",
    },
    skillsContainer: {},
    chipRoot: {
        backgroundColor: "#3FC08C",
    },
    divider: {
        margin: theme.spacing(0.5),
    },
    sectionDivider: {
        background: "#F2F2F3",
        height: 2,
        marginBottom: "21px",
    },
    historyListLi: {
        color: theme.palette.text.disabled,
    },
    link: {
        textDecoration: "none",
        color: "unset",
    },
    experienceChip: {
        margin: theme.spacing(0, 1.2, 0, 0),
        height: theme.spacing(4),
        fontSize: theme.spacing(2),
    },
    titleText: {
        fontSize: 13,
        fontWeight: 700,
        // lineHeight: 18.29,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(2.8),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(2.5),
        },
    },
    location: {
        fontSize: 13,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(2.4),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(2.2),
        },
    },
    locationIcon: {
        marginLeft: "6px",
    },
    vettingChip: {
        marginRight: theme.spacing(1.5),
        height: "max-content",
        fontSize: theme.spacing(2.5),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(1.8),
        },
    },
    deleteIconChip: {
        cursor: "unset",
    },
    formLabel: {
        fontWeight: 700,
        fontSize: theme.spacing(2),
    },
    salaryText: {
        fontWeight: "bold",
        fontSize: theme.spacing(2.5),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(2.2),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(2),
        },
    },
    icons: {
        height: 30,
        minWidth: 30,
        marginRight: 8,
    },
    currency: {
        fontSize: 13,
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(2.4),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(2.2),
        },
    },
    sectionTitle: {
        minWidth: 200,
        fontSize: "16px",
        fontWeight: 800,
        "& .emoji-mart-emoji": {
            marginLeft: 5,
        },
    },
    containerAvatar: {
        [theme.breakpoints.down("sm")]: {
            gap: "40px",
            display: "flex",
            alignItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            width: "100%",
            fontSize: "11px",
            gap: "20px",
            alignItems: "unset",
            flexDirection: "column",
        },
        "& .profileInfo": {
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr 3fr",
            },
            [theme.breakpoints.down("xs")]: {
                gridTemplateColumns: "1.2fr 3fr",
            },
            marginBottom: "4px",
            alignItems: "center",
            "& .title": {
                fontWeight: 600,
            },
        },
    },
    profile: {
        height: "22px",
        backgroundColor: "#FCC46B",
        borderRadius: "5px",
        fontSize: "11px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            overflow: "hidden",
        },
        width: "fit-content",
        fontWeight: 400,
        color: "#221A16",
        padding: "2px 12px",
        textTransform: "capitalize",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        padding: "24px 30px",
        borderRadius: 12,
        margin: "0px auto 24px auto",
        width: "100%",
        border: "1px solid transparent",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            padding: "24px 21px",
            margin: "0px 4px 24px 4px",
        },
        minHeight: "359px",
        cursor: "pointer",
        "&:hover": {
            border: "1px solid #9c9c9c",
        },
    },
    expandButton: {
        margin: "0px auto -38px auto",
        textTransform: "none",
        // backgroundColor: "#245978",
        fontSize: 12,
        fontWeight: 600,
        "&:hover": {
            // backgroundColor: "rgb(80 80 80)",
            backgroundColor: "#245978",
            boxShadow: "none",
        },
    },
    collapseIcon: {
        transition: theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.short,
        }),
    },
    collapseIconOpen: {
        transform: "rotate(-180deg)",
    },
    collapseIconClosed: {
        transform: "rotate(0)",
    },
    skillChip: {
        height: 25,
        margin: "0px 5px 4px 0px",
        backgroundColor: "#E9F1F4",
        fontSize: 12,
        fontWeight: 600,
        color: "#FFFFFF",
        padding: "0px 4px",
    },
    requestIntro: {
        width: 160,
        height: 35,
        fontSize: 13,
        fontWeight: 600,
        color: "#ffffff",
        textTransform: "none",
        marginRight: 5,
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none",
        },
    },
    itemButton: {
        minWidth: 35,
        height: 35,
        color: "#FFFFFF",
        textTransform: "none",
        padding: 4,
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none",
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    logsCancelIcon: {
        color: "#FF6A66",
        "&:hover": {
            cursor: "pointer",
        },
    },
    logsCancelIconItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    itemSectionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    itemSection: {
        [theme.breakpoints.down("sm")]: {
            marginBottom: "21px",
        },
    },
    noteAvatarContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    commentTextArea: {
        border: "none",
        width: "100%",
        display: "block",
        resize: "none",
        overflow: "hidden",

        color: "#483E3E",
        fontSize: 13,
        fontWeight: 400,
        marginLeft: "10px",
        "&::placeholder": {
            fontSize: 13,
            fontWeight: 400,
        },
        "&:focus": {
            outline: "none",
        },
    },
    commentButton: {
        width: 120,
        height: 30,
        textTransform: "none",
        fontWeight: 600,
        fontSize: 15,
        padding: "0px 4px",
        margin: "13px 10px 13px",
        alignSelf: "flex-end",
        "&:hover": {
            backgroundColor: "#245978",
            boxShadow: "none",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    reactTooltip: {
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        fontWeight: "bolder",
        borderRadius: "10px !important",
    },
    dialogMuiRoot: {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    skillsMobileView: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column-reverse",
            minHeight: "unset !important",
        },
    },
    showMoreButton: {
        color: "#221A16",
        cursor: "pointer",
        padding: "0px 12px",
        fontSize: "14px",
        fontWeight: 400,
        marginBottom: "15px",
        textTransform: "initial",
    },
    contentEditable: {
        fontSize: 15,
        fontWeight: 400,
        border: "none",
        padding: 0,
        fontFamily: "Montserrat",
        lineHeight: "21px",
        color: "#221A16",
        "&:focus": {
            border: "none",
            outlineWidth: 0,
        },
        "&:focus-visible": {
            outlineWidth: 0,
        },
        "&:hover": {
            border: "none",
            outlineWidth: 0,
        },
    },
}));

export default useStyles;
