import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    parent: {
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
    },
    parentXLHeight: {
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.between(800, 900)]: {
            marginTop: "10rem !important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "18rem",
        },
        [theme.breakpoints.between(0, 376)]: {
            marginTop: "38rem",
        },
    },
    inputContainer: {
        position: "relative",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        maxWidth: "124em",
        padding: ".5em",
        "& button": {
            boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            "&:hover, &:active": {
                boxShadow: "none",
                backgroundColor: "#ffb237",
            },
        },
    },
    layout: {
        flexDirection: "column",
        flex: 1,
        marginTop: 20,
        paddingTop: 0,
        borderRadius: "1em",
        display: "flex",
        padding: "2em",
        marginBottom: "3em",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1.5em 2em",
        backgroundColor: "#221A16",
        borderRadius: "1em",
    },
    headerSalutation: {
        display: "flex",
        alignItems: "center",
        padding: ".1rem 0",
    },
    salutation: {},
    salutationTitle: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#ffffff",
    },
    salutationBody: {
        fontSize: "13px",
        lineHeight: "16px",
        marginTop: "3px",
        color: "#ffffff",
        fontFamily: "Montserrat",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    sideText: {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "15px",
        color: "#ffffff",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    imageSRC: {
        borderRadius: 10,
        height: 98,
        width: 98,
    },
    body: {
        display: "flex",
        marginTop: "30px",
        borderRadius: "1em",
    },
    formContainer: {
        flex: 1,
        marginRight: "2em",
        borderRadius: "1em",
    },
    compassInfo: {
        flex: 1,
        borderRadius: "1em",
    },
    infoContainer: {
        borderRadius: "1em",
    },
    settingsButton: {
        "& span": {
            "&:first-of-type": {
                borderBottom: "dashed 1px #2F2824",
            },
            display: "block",
        },
    },
    title: {
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "15px",
        color: "#ffffff",
        backgroundColor: "#221A16",
        borderTopRightRadius: ".5rem",
        borderTopLeftRadius: ".5rem",
        padding: ".8rem 1.5rem",
    },
    infoTitle: {
        paddingLeft: "1.9em",
    },
    formAvatarContainer: {
        padding: "20px",
        borderBottom: "1px solid #E7E8EC",
        display: "flex",
        backgroundColor: "#ffffff",
        alignItems: "center",
    },
    profileButtonRoot: {
        marginRight: 20,
    },
    cardContent: {
        color: "#221A16",
        fontSize: "14px",
        lineHeight: "21px",
        padding: "20px 20px 25px 20px",
        backgroundColor: "#ffffff",
        borderBottomRightRadius: ".5rem",
        borderBottomLeftRadius: ".5rem",
    },
    changeProfileAvatar: {
        backgroundColor: "#FCC46B",
        borderRadius: "7px",
        fontSize: "15px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#221A16",
        fontWeight: 600,
        height: "3em",
        padding: "0 2em",
        marginRight: ".5em",
        boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        "&:hover": {
            color: "#221A16",
            backgroundColor: "#ffb237 !important",
        },
    },
    form: {
        padding: "2em",
        paddingTop: "20px",
        paddingBottom: "0",
        borderBottom: "1px solid #E7E8EC",
        backgroundColor: "#ffffff",
    },
    text: {
        color: "#2F2824",
        fontSize: "14px",
        lineHeight: "16px",
    },
    formText: {
        paddingBottom: "20px",
        marginTop: "-10px",
        lineHeight: "21px",
    },
    textInputStyles: {
        marginBottom: "20px",
    },
    submitButtonContainer: {
        backgroundColor: "#ffffff",
        padding: "20px",
        borderBottomRightRadius: ".5rem",
        borderBottomLeftRadius: ".5rem",
    },
    submitButton: {
        height: "2.8em",
        borderRadius: "7px",
        boxShadow: "none",
    },
    profileIconButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    personIcon: {
        fontSize: "40px",
    },
    [theme.breakpoints.down("md")]: {
    // layout: {
    //     gridTemplateRows: "max-content max-content max-content",
    //     gridTemplateAreas: `". profile info ."
    //                         ". profile info ."
    //                         ". saved_search saved_search ."`
    // },
    },
    [theme.breakpoints.down("sm")]: {
        container: {
            marginTop: "2.5em",
        },
        body: {
            flexDirection: "column",
        },
        formContainer: {
            marginRight: 0,
            marginBottom: "2em",
        },
    },
    [theme.breakpoints.down("xs")]: {
        container: {
            marginTop: "6.5em",
        },
    },
    compassInfoContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default useStyles;
