import { firestore } from "../firebase";

export const recruitersRef: firebase.firestore.CollectionReference = (
    firestore.collection("recruiters")
);

export const candidatesRef: firebase.firestore.CollectionReference = (
    firestore.collection("candidates")
);

export const recruiterLogsRef: firebase.firestore.CollectionReference = (
    firestore.collection("recruiter_logs")
);

export const clientsRef: firebase.firestore.CollectionReference = (
    firestore.collection("clients")
);

export const subscribedQueriesRef: firebase.firestore.CollectionReference = (
    firestore.collection("subscribed_queries")
);

export const candidatesAnalyticsRef: firebase.firestore.CollectionReference = (
    firestore.collection("candidates_profile_analytics")
);
