import React, { FC, useEffect, useState } from "react";
import TimePicker from "./TimePicker/TimePicker";
import {
    Grid, FormHelperText, Box
} from "@material-ui/core";
import { Months } from "compass-shared";
import { firebase } from "../../firebase";
import useAppContext from "../../context/store/store";
import { IPeriodPickerProps } from "./Interface";
import { useStyles } from "./TimePicker/style";
import { doClearProfileFieldErrors, doSetProfileFieldErrors } from "../../context/reducer/Actions";
import { useMediaQuery } from "@material-ui/core";

const PeriodPicker: FC<IPeriodPickerProps> = ({
    startDate, endDate, id, doSetProfilePeriodData, disabled
}) => {
    const [{ editProfileErrors }, dispatch]: any = useAppContext();

    const classes = useStyles();

    const smallScreens = useMediaQuery("(max-width:767px)");

    const [endDateTime, setEndDate] = useState<any>(endDate);
    const [startDateTime, setStartDate] = useState<any>(startDate);
    const [endDateMonth, setEndDateMonth] = useState<any>(null);

    useEffect(() => {
        if ((endDateTime && endDateTime.seconds > 0) && (endDateTime && endDateTime.seconds < startDateTime.seconds)) {
            dispatch(doSetProfileFieldErrors([{ field: `year${id}`, section: "", id: `year${id}` }]));
        } else {
            dispatch(doClearProfileFieldErrors({ field: `year${id}` }));
        }
    }, [endDateTime]);

    const handleStartDateYear = ([value] : any) => {
        if (!value) return;
        const y = +value || new Date().getFullYear();
        const startMonth = startDate && startDate.toDate && startDate.toDate().getMonth();
        const timeWithYear = firebase.firestore.Timestamp.fromDate(new Date(Date.UTC(y, startMonth || 0)));
        setStartDate(timeWithYear);
        dispatch(doSetProfilePeriodData({
            key: "start_at",
            value: timeWithYear,
            id
        }));
    };
    const handleStartDateMonth = ([value]: any) => {
        if (!value) return;
        const m = Months[value];
        const startYear = startDate && startDate.toDate && startDate.toDate().getFullYear();
        const timeWithMonth = firebase.firestore
            .Timestamp.fromDate(new Date(Date.UTC((startYear || new Date().getFullYear()), +m)));
        setStartDate(timeWithMonth);
        dispatch(doSetProfilePeriodData({
            key: "start_at",
            value: timeWithMonth,
            id
        }));
    };
    const handleEndDateYear = ([value]: any) => {
        if (!value) return;
        const y = +value;
        const endMonth = (endDate !== "present" && endDate.toDate && endDate.toDate().getMonth()) || 0;
        const timeWithYear = firebase.firestore.Timestamp.fromDate(new Date(Date.UTC(y, endMonth)));
        setEndDate(timeWithYear);
        dispatch(doSetProfilePeriodData({
            key: "end_at",
            value: timeWithYear,
            id
        }));
    };
    const handleEndDateMonth = ([value]: any) => {
        if (!value) return;
        const m = Months[value];
        const endYear = (endDate !== "present" && endDate.toDate().getFullYear()) || undefined;
        const timeWithMonth = firebase.firestore
            .Timestamp.fromDate(new Date(Date.UTC((endYear || new Date().getFullYear()), +m)));
        setEndDate(timeWithMonth);
        setEndDateMonth(m);
        dispatch(doSetProfilePeriodData({
            key: "end_at",
            value: timeWithMonth,
            id
        }));
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                        Start
                        <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                    </Box>
                    <TimePicker
                      disabled={disabled}
                      month={startDate && startDate.toDate && Months[startDate.toDate().getMonth()]}
                      year={startDate && startDate.toDate ? startDate.toDate().getFullYear() : undefined}
                      handleChangeYear={handleStartDateYear}
                      handleChangeMonth={handleStartDateMonth}
                    />

                </Grid>
                <Grid style={{ paddingLeft: smallScreens ? 0 : "13px" }} item xs={12} sm={6}>
                    <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                        End
                    </Box>
                    <TimePicker
                      disabled={disabled}
                      month={
                            (endDate !== "present" && endDate.toDate && Months[endDate.toDate().getMonth()])
                            || undefined
                        }
                      year={(endDate !== "present" && endDate.toDate && endDate.toDate().getFullYear()) || undefined}
                      handleChangeYear={handleEndDateYear}
                      handleChangeMonth={handleEndDateMonth}
                    />
                    {editProfileErrors[`year${id}`] && editProfileErrors[`year${id}`].id === `year${id}`
                    && (
                        <Box color="error.main">
                            { !endDateMonth ? "Please select end month" : "End date cannot be past date."}
                        </Box>
                    )}

                    <FormHelperText>Leave blank if no end date yet</FormHelperText>
                </Grid>
            </Grid>
        </div>
    );
};

export default PeriodPicker;
