import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, CircularProgress } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import {
    IRecruitersInTouchProp, IRecruitersInTouchMap, IAddRecruiterInTouch
} from "./interface";
import { functions } from "../../../../firebase";
import { ErrorMsg, IRecruitersInTouch } from "compass-shared";
import { candidatesRef } from "../../../../FirebaseUtils/collectionRefs";
import useStyle from "./style";

const RecruitersInTouch: FC<IRecruitersInTouchProp> = ({ candidateId, user }) => {
    const [recruitersInTouch, setRecruitersInTouch] = useState<IRecruitersInTouchMap>({});
    const [following, setFollowing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const classes = useStyle();

    useEffect(() => {
        setLoading(true);
        const query = candidatesRef
            .doc(candidateId)
            .collection("involved_recruiters")
            .orderBy("joined_at", "desc");
        const unsubscribe = query.onSnapshot((querySnapshot) => {
            const _list = {} as IRecruitersInTouchMap;
            querySnapshot.forEach((doc) => {
                const data = doc.data() as IRecruitersInTouch;
                const { id } = doc;
                _list[id] = data;
            });
            setRecruitersInTouch(_list);
            if (_list[user.id]) {
                setFollowing(_list[user.id].is_join);
            }
        });
        setLoading(false);
        return () => unsubscribe();
    }, []);

    const handleAddRecruiter = async (is_join: boolean) => {
        try {
            setLoading(true);
            const _data: IAddRecruiterInTouch = {
                recruiter_id: user.id,
                candidate_id: candidateId,
                avatar_url: user.thumbnail_avatar_url,
                name: `${user.firstName} ${user.lastName}`,
                is_join: !is_join
            };
            const followCandidateAsync: any = functions.httpsCallable("recruiters-recruitersInTouch");
            const followCandidateResp: any = (await followCandidateAsync(_data)).data;
            if (!followCandidateResp.result) {
                toast.error(<ErrorMsg text="something went wrong, try again!" />);
            } else {
                /**
                 * @BRYCE because of your snapshot, you do not need this codes so we can remove get() from
                 *recruiters-recruitersInTouch function because you update your list (setRecruitersInTouch)
                 * in your snapshot
                 */

                // const {
                //     data: {
                //         joined_at,
                //         left_at,
                //     }
                // } = followCandidateResp;
                // const _joined_time = new firebase.firestore.Timestamp(joined_at._seconds, joined_at._nanoseconds);
                // const _left_time = left_at && new firebase.firestore.Timestamp(left_at._seconds, left_at._nanoseconds);

                // const serializedData = {
                //     ...followCandidateResp.data,
                //     joined_at: _joined_time,
                //     left_at: _left_time
                // };
                // const _recruitersInTouch = { ...recruitersInTouch, [user.id]: serializedData };
                // setRecruitersInTouch(_recruitersInTouch);
                // setFollowing(!is_join);
            }
        } catch (err) {
            toast.error(<ErrorMsg text="something went wrong, please try again!" />);
        } finally {
            setLoading(false);
        }
    };

    const HtmlTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 240,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
            padding: 0,
        },
        arrow: {
            fontSize: theme.spacing(4),
        }
    }))(Tooltip);

    return (
        <>
            <Typography variant="h6">
                <Box fontWeight="fontWeightBold" fontSize="14px">
                    Recruiters in touch
                </Box>
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={1}>
                {Object.values(recruitersInTouch).length === 0
                    ? (
                        <Typography
                          variant="caption"
                          style={{ marginLeft: "-8px" }}
                        >
                            No recruiters following this candidate
                        </Typography>
                    )
                    : (
                        <AvatarGroup max={10}>
                            {Object.values(recruitersInTouch).map((recruiter) => (
                                <HtmlTooltip
                                  key={recruiter.recruiter_id}
                                  arrow
                                  placement="top"
                                  title={(
                                      <>
                                          <CardHeader
                                            avatar={(
                                                <Avatar
                                                  aria-label="thumbnail"
                                                  src={recruiter.avatar_url}
                                                >
                                                    {recruiter.name.charAt(0).toUpperCase()}
                                                </Avatar>
                                            )}
                                            title={(
                                                <Typography variant="h6">
                                                    <Box fontWeight="fontWeightBold" fontSize={14}>
                                                        {recruiter.name}
                                                    </Box>
                                                </Typography>
                                              )}
                                          />
                                          <Divider />
                                          <table className={classes.table}>
                                              <tbody>
                                                  {recruiter.joined_at !== null && (
                                                  <TableRow>
                                                      <TableCell component="th" scope="row">
                                                        Joined
                                                      </TableCell>
                                                      <TableCell align="left">
                                                          {moment(recruiter.joined_at.toDate()).format(
                                                              "MMM DD, YYYY"
                                                          )}
                                                      </TableCell>
                                                  </TableRow>
                                                  )}
                                                  {recruiter.left_at !== null && (
                                                  <TableRow style={{ width: "100%" }}>
                                                      <TableCell component="th" scope="row">
                                                        Left
                                                      </TableCell>
                                                      <TableCell align="left">
                                                          {moment(recruiter.left_at.toDate()).format(
                                                              "MMM DD, YYYY"
                                                          )}
                                                      </TableCell>
                                                  </TableRow>
                                                  )}
                                              </tbody>
                                          </table>
                                      </>
                                    )}
                                >
                                    <Avatar
                                      alt={recruiter.name}
                                      src={recruiter.avatar_url}
                                      className={recruiter.is_join ? undefined : classes.disabledAvatar}
                                    />
                                </HtmlTooltip>
                            ))}
                        </AvatarGroup>
                    )}
                <Box position="relative" margin={1}>
                    <Fab
                      onClick={() => handleAddRecruiter(following)}
                      color="secondary"
                      aria-label="add"
                      size="small"
                      className={following ? classes.close : classes.add}
                      classes={{
                          secondary: classes.iconColor
                      }}
                    >
                        {following ? <CloseIcon /> : <AddIcon />}
                    </Fab>
                    {loading && <CircularProgress size={44} className={classes.fabProgress} />}
                </Box>
            </Box>
        </>
    );
};

export default React.memo(RecruitersInTouch);
