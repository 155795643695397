import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import FlightIcon from "@material-ui/icons/Flight";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import useStyles from "../style";
import LinkedInIcon from "../../../../Assets/icons/linkedin-icon.svg";
import PdfIcon from "../../../../Assets/icons/pdf-icon.svg";
import CopyToClipboardIcon from "../../../../Assets/icons/copy.svg";
import { functions } from "../../../../firebase";

const initialState = {
    linkedInUrl: "",
    isPhoneCopied: false,
    isEmailCopied: false,
};

export function CompassAlert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DirectContactModal = ({
    openDirectContact,
    closeModal,
    userID
}: any) => {
    const classes = useStyles({});
    const { candidate } = openDirectContact;

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const linkedInUrl = candidate?.connected_links?.find((link: any) => link.key === "linkedin");
        setState((st) => ({
            ...st,
            linkedInUrl: linkedInUrl?.link,

        }));
    }, [candidate]);

    const capitalize = (str: string) => str?.charAt(0)?.toUpperCase() + str?.slice(1);
    const handleClose = (event: any, reason: string) => {
        if (reason === "clickaway") {
            return;
        }

        setState((st) => ({
            ...st,
            isEmailCopied: false,
            isPhoneCopied: false
        }));
    };

    const ShowSnackBarAlert = (copiedIted = "Email") => (
        <CompassAlert onClose={handleClose} severity="success">
            {copiedIted}
            {" "}
            copied to clipboard
        </CompassAlert>
    );

    const logDirectContact = async () =>{
        await functions.httpsCallable("recruiters-handleDirectContact")({
            candidateID: candidate.objectID,
            recruiterID: candidate?.recruiter_provider_id,
            requestedBy: userID,
            status: "direct_contact_sent"
        });
    }

    return (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={openDirectContact.isOpen}
          aria-labelledby="request-intro-dialog"
          classes={{
              paper: classes.dialogPaperRoot,
              container: classes.dialogContainer
          }}
        >
            <DialogContent className={`${classes.contentRoot} ${classes.directContactContentRoot}`}>
                <DialogTitle
                  disableTypography
                  id="request-intro-dialog"
                  classes={{ root: `${classes.dialogTitleRoot} ${classes.directContactTitle}` }}
                >

                    <Typography
                      component="div"
                      variant="h6"
                      classes={{ root: `${classes.titleText} ${classes.directContactTitleText}` }}
                    >
                        Direct Contact
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.itemButton}
                      onClick={closeModal}
                    >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <div className={classes.directContactDialogContent}>
                    <Avatar
                      alt={`${candidate?.firstName} ${candidate?.lastName}`}
                      src={candidate?.candidate_avatar_url ||candidate?.thumbnail_avatar_url}
                      className={classes.imageSRC}
                    >
                        <PersonRoundedIcon className={classes.personIcon} />
                    </Avatar>
                    <div className={classes.candidateInfo}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              component="div"
                              variant="h6"
                              classes={{ root: classes.candidateName }}
                            >
                                {capitalize(candidate?.firstName)}
                                {" "}
                                {capitalize(candidate?.lastName)}

                            </Typography>
                            {state.linkedInUrl
                                && (
                                <a
                                  href={state.linkedInUrl}
                                  onClick={logDirectContact}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ display: "flex" }}
                                >
                                    <img
                                      src={LinkedInIcon}
                                      alt="icon"
                                      className={classes.icons}
                                    />
                                </a>
                                )}
                            {candidate?.cv_url
                                && (
                                <a
                                  href={candidate?.cv_url}
                                  onClick={logDirectContact}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ display: "flex" }}
                                >
                                    <img
                                      src={PdfIcon}
                                      alt="icon"
                                      className={classes.icons}
                                    />
                                </a>
)}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginTop: 6 }}>
                            <Typography
                              component="div"
                              variant="h6"
                              classes={{ root: classes.candiateText }}
                            >
                                {candidate?.location}

                            </Typography>
                        </div>

                        {candidate?.email  && (
                            <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
                                <Email fontSize="small" className={classes.materialIcon} />
                                <Typography
                                    component="div"
                                    variant="h6"
                                    style={{wordBreak: 'break-word'}}
                                    classes={{ root: classes.candiateText }}
                                >
                                    {candidate?.email}

                                </Typography>
                                <CopyToClipboard
                                    text={candidate?.email}
                                    onCopy={() => setState((st) => ({
                                        ...st,
                                        isEmailCopied: true
                                    }))}
                                >
                                    <img
                                        src={CopyToClipboardIcon}
                                        alt="copy to clipboard"
                                        className={classes.copyToClipboardIcon}
                                    />
                                </CopyToClipboard>
                                <Snackbar
                                    open={state.isEmailCopied}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                >
                                    {ShowSnackBarAlert("Email")}
                                </Snackbar>
                            </div>
                        )}

                        {
                            candidate?.phone && (
                                <div style={{ display: "flex", alignItems: "center", marginTop: 6 }}>
                                    <Phone fontSize="small" className={classes.materialIcon} />
                                    <Typography
                                        component="div"
                                        variant="h6"
                                        classes={{ root: classes.candiateText }}
                                    >
                                        {candidate?.phone}

                                    </Typography>
                                    <CopyToClipboard
                                        text={candidate?.phone}
                                        onCopy={() => setState((st) => ({
                                            ...st,
                                            isPhoneCopied: true
                                        }))}
                                    >
                                        <img
                                            src={CopyToClipboardIcon}
                                            alt="copy to clipboard"
                                            className={classes.copyToClipboardIcon}
                                        />
                                    </CopyToClipboard>
                                    <Snackbar
                                        open={state.isPhoneCopied}
                                        autoHideDuration={6000}
                                        onClose={handleClose}
                                    >
                                        {ShowSnackBarAlert("Phone")}
                                    </Snackbar>
                                </div>
                            )
                        }
                    </div>
                </div>
                {/* <div className={classes.directContactFooter}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.footerButton}
                      onClick={closeModal}
                      autoCapitalize="none"
                    >
                        I have contacted the candidate
                    </Button>
                </div> */}
            </DialogContent>
        </Dialog>
    );
};

export default DirectContactModal;
