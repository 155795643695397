import React, { FC, useState } from "react";
import {
    Grid, IconButton, Box, OutlinedInput, Typography
} from "@material-ui/core";
import PeriodPicker from "../../../../../SharedComponents/PeriodPicker";
import { IExperience } from "compass-shared";
import { Close } from "@material-ui/icons";
import useAppContext from "../../../../../context/store/store";
import {
    doSetProfileExperienceData,
    doRemoveExperience,
    doClearProfileFieldErrors
} from "../../../../../context/reducer/Actions";
import useStyles from "../../style";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

export type EditExperienceItem = {
    experience: IExperience;
    id: string;
    disabled: boolean;
}
const EditExperienceItem: FC<EditExperienceItem> = ({ experience, id, disabled }) => {
    const classes = useStyles();

    const [{ editProfileErrors = {}}, dispatch]: any = useAppContext();
    const [selectedExperience, setSelectedExperience] = useState<any>(null);

    const removeExperience = (_id: string) => {
        dispatch(doRemoveExperience(_id));
    };

    return (
        <Grid container spacing={2} key={id}>

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={selectedExperience !== null}
            >
                <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this experience?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setSelectedExperience(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => removeExperience(selectedExperience)} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                <Grid container className={classes.close} spacing={1}>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                        <Box p={0} textAlign="right" width="100%">
                            <IconButton
                              aria-label="delete"
                              className={classes.deleteIcon}
                              onClick={() => setSelectedExperience(id)}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                   Job Title
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>

                        <OutlinedInput
                          fullWidth
                          id="title"
                          placeholder="Job Title"
                          name="title"
                          value={experience.title}
                          onChange={(e) => {
                              dispatch(doSetProfileExperienceData({
                                  key: "title",
                                  value: e.target.value,
                                  id
                              }));

                              if (editProfileErrors[`title${id}`]) {
                                  dispatch(doClearProfileFieldErrors({ field: `title${id}` }));
                              }
                          }}
                          classes={{
                              root: classes.inputRoot,
                              input: classes.outlineInput
                          }}
                        />

                        {editProfileErrors[`title${id}`] && editProfileErrors[`title${id}`].id === `title${id}`
                        && (
                            <Box color="error.main">
                                Job Title is required
                            </Box>
                        )}

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                Company Name
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>

                        <OutlinedInput
                          fullWidth
                          id="company_name"
                          placeholder="Company Name"
                          name="company_name"
                          value={experience.company_name}
                          onChange={(e) => {
                              dispatch(doSetProfileExperienceData({
                                  key: "company_name",
                                  value: e.target.value,
                                  id
                              }));
                              if (editProfileErrors[`company_name${id}`]) {
                                  dispatch(doClearProfileFieldErrors({ field: `company_name${id}` }));
                              }
                          }}
                          classes={{
                              root: classes.inputRoot,
                              input: classes.outlineInput
                          }}
                        />

                        {editProfileErrors[`company_name${id}`] && editProfileErrors[`company_name${id}`].id === `company_name${id}`
                        && (
                            <Box color="error.main">
                                Company Name is required
                            </Box>
                        )}

                    </Grid>

                </Grid>
            </Grid>

            <Grid style={{ padding: "0 22px" }} item xs={12}>
                <PeriodPicker
                  startDate={experience.start_at}
                  endDate={experience.end_at}
                  id={id}
                  doSetProfilePeriodData={doSetProfileExperienceData}
                />
            </Grid>
            <div className={classes.sectionDivider} />
        </Grid>
    );
};

export default EditExperienceItem;
