import React, { FC, useEffect } from "react";
import useStyles from "./style";
import useAppContext from "../../../context/store/store";
import CreateCandidateForm from "./CreateForm";
import { doChangeCreateEmptyProfileMode } from "../../../context/reducer/Actions";
import { ICreateCandidateFormProps } from "./interface";
import { navigate, RouteComponentProps } from "@reach/router";
import { Box } from "@material-ui/core";

const CreateCandidateContainer: FC<RouteComponentProps & ICreateCandidateFormProps> = ({ candidateList }) => {
    const classes = useStyles();
    const [, dispatch]: any = useAppContext();

    useEffect(() => () => dispatch(doChangeCreateEmptyProfileMode(false)), []);
    const closeCreateCandidate = () => {
        dispatch(doChangeCreateEmptyProfileMode(false));
        navigate("/candidates/");
    };

    return (
        <div style={{ display: "flex" }}>
            <Box className={classes.boxRoot}>
                <div className={classes.header}>
                    <label className={classes.headerLabel}> Create Candidate </label>
                </div>
                <CreateCandidateForm candidateList={candidateList} />
            </Box>
        </div>
    );
};

export default CreateCandidateContainer;
