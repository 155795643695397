import { storage } from "../firebase";
import { candidatesRef } from "../FirebaseUtils/collectionRefs";
import { IUploadCVRes } from "./Interface";

export const uploadCV = (
    candidateId: string,
    file: any,
    shouldUpdateCollection = true
): Promise<IUploadCVRes> => new Promise((resolve, reject) => {
    if (!file) {
        reject({
            result: false,
            code: "file_not_exists",
            message: "file not exists"
        });
        return;
    }
    const indexOfExtension = file.name.lastIndexOf(".");
    const { type } = file;
    const extension = file.name.slice(indexOfExtension);
    const acceptedFormats = [".pdf", ".doc", ".docx"];
    const fileType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/pdf"
    ];
    if (!(acceptedFormats.includes(extension) && fileType.includes(type))) {
        reject({
            result: false,
            code: "wrong_file_format",
            message: "file format not accepted"
        });
        return;
    }
    try {
        (async () => {
            const path = `candidates/attachments/cv/cv-${candidateId}${extension}`;
            const mainContract = storage
                .ref()
                .child(path);
            await mainContract.put(file);
            const url = await mainContract.getDownloadURL();

            if (shouldUpdateCollection) {
                await candidatesRef.doc(candidateId).update({
                    cv_url: url
                });
            }
            const resp: IUploadCVRes = {
                cv_url: url,
                result: true
            };
            resolve(resp);
        })();
    } catch (error) {
        reject({
            result: false,
            code: "upload_failed",
            message: "There is a problem with upload attachment file, please try later."
        });
    }
});
