import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        textAlign: "right",
        marginBottom: theme.spacing(0.5),
    },
    header: {
        height: "39px",
        backgroundColor: "#221A16",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0 22px",
        color: "#FFF",
        borderRadius: " 10px 10px 0px 0px",
    },
    boxRoot: {
        width: "601px",
        margin: "20px auto",
        [theme.breakpoints.down("xs")]: {
            width: "94%",
            margin: "7rem auto",
        },
        backgroundColor: "#ffff",
        borderRadius: "10px",
        position: "relative",
        lineHeight: "21px",
        "& .MuiAccordion-root": {
            "&::before": {
                display: "none",
            },
        },
        "& .MuiPaper-elevation1": {
            boxShadow: "unset",
        },
        "& .MuiAccordionDetails-root": {
            flexDirection: "column",
            gap: "30px",
            "& p": {
                lineHeight: "24px",
                fontSize: "14px",
            },
        },
        "& .MuiIconButton-label": {
            "& svg": {
                width: "1.5em",
                height: "1.5em",
                fill: "#9c9c9c",
            },
        },
    },
    accordionContainer: {
        transition: "all 0.2s ease",
        "& > .Mui-expanded": {
            backgroundColor: "#fff",
            border: "none",
            margin: "unset",
        },
        "& .MuiAccordionSummary-root": {
            flexDirection: "row-reverse",
            padding: "0px",
        },
        "& .MuiAccordionSummary-expandIcon": {
            transform: "unset",
        },
        "& .MuiIconButton-edgeEnd": {
            marginRight: "unset",
            paddingLeft: "0px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "unset",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "unset",
        },
    },
    headerLabel: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#FFFFFF",
    },
    heading: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#221A16",
    },
}));

export default useStyles;
