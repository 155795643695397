/* eslint-disable consistent-return */
import React, {
    FC, useEffect, useState, useRef, useCallback
} from "react";
import {
    Box,
    Grid,
    FormControl, Chip
} from "@material-ui/core";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import CreatableSelect from "react-select/creatable";
import useStyles from "./style";
import EditExperience from "../Experience/EditExperience";
import useAppContext from "../../../../context/store/store";
import {
    doSetProfileData,
    doResetEditProfileData,
    doStartSearchCandidates, doUpdateProfile
} from "../../../../context/reducer/Actions";
import EditEducation from "../Education/EditEducation";
import { IData } from "../Enums";
import { functions } from "../../../../firebase";
import { toast } from "react-toastify";
import { uploadCV } from "../../../../Utils/UploadCV";
import { validateFields, ValidateFields_ } from "../../../../Utils/ValidateFields";
import { IUploadCVRes } from "../../../../Utils/Interface";
import { Controller, useForm } from "react-hook-form";
import { EditProfileSchema } from "../../../../Utils/FormValidationSchema";
import { navigate, RouteComponentProps } from "@reach/router";
import { selectStyles } from "./utils";
import {
    ErrorMsg,
    SuccessMsg,
    SubmitButton,
    UploadButton,
    PeepsAvatarPicker,
    experienceLevelList,
    relocationStatusList,
    EConnectedLinks,
    ICandidate,
    IConnectedLinksObj,
    IEducation,
    IExperience,
    ISkill,
    ERelocationStatus,
    JobFunctionList,
    EditVettingFields,
    FormSalaryField, SelectMenu, CustomInput, IAlgoliaCandidate
} from "compass-shared";
import { ICandidateNote } from "../../CreateCandidate/interface";
import { ROUTE_AUTHENTICATED_SEARCH } from "../../../../routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateCandidate, getJobSkills } from "../../../../redux/services/candidates/candidates.actions";
import { RootState } from "../../../../redux/store";

type ProfileDataPropsType = {
    candidate: ICandidate & ICandidateNote;
    currentCandidateId: string;
    getCandidateData: Function;
}

const EditProfile: FC<RouteComponentProps & ProfileDataPropsType> = ({
    candidate,
    currentCandidateId,
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [thumbnail, setThumbnail] = useState<string | undefined>();
    const [peepAvatarUrl, setPeepAvatarUrl]: any = useState("");
    const [cvFile, setCvFile] = useState<File | null>();
    const [minSalary, setMinSalary] = useState<number>(0);
    const [maxSalary, setMaxSalary] = useState<number>(1000000);
    const [relocationStatus, setRelocationStatus] = useState<ERelocationStatus | "">("");
    const [mainSkillError, setMainSkillError] = useState(false);
    const [isOpenToRemote, setIsOpenToRemote] = useState(false);
    const [isAPlayer, setIsAPlayer] = useState(false);
    const [isSkillsAssessed, setIsSkillsAssessed] = useState(false);
    const [mainSkills, setMainSkills] = useState([] as ISkill[]);
    // const [searchText, setSearchText] = useState<string>("");
    const [skillsOptions, setSkillsOptions] = useState([]);
    const [selectedJobFunction, setSelectedJobFunction] = useState("" as string);
    const [defaultSkills, setDefaultSkills]: any = useState([]);

    const reduxDispatch = useDispatch();
    const candidateLists: any = useSelector((state: RootState) => state.candidates, shallowEqual);

    const [{
        editProfile: {
            experiences, educations, skills
        },
        candidates,
        editProfileErrors = {}
    }, dispatch]: any = useAppContext();

    const aspirationEditText = useRef("");
    const firstNameRef: any = useRef();
    const lastNameRef: any = useRef();
    const emailRef: any = useRef();
    const linkdinRef: any = useRef();
    const locationRef: any = useRef();
    const jobFunctionRef: any = useRef();
    const seniorityRef: any = useRef();
    const careerAspRef: any = useRef();
    const selectSkillsRef: any = useRef();

    useEffect(() => {
        setLoading(true);
        const _experiencesObj = {} as any;
        const _educationsObj = {} as any;
        // eslint-disable-next-line no-return-assign
        ((candidate && candidate.experiences) || []).map((exp: any) => (
            _experiencesObj[new Date().getTime() + Math.floor((Math.random() * 1000))] = exp));
        // eslint-disable-next-line no-return-assign
        ((candidate && candidate.educations) || []).map((ed: any) => (
            _educationsObj[new Date().getTime() + Math.floor((Math.random() * 1000))] = ed));
        ((candidate && candidate.skills) || []).map(((skill) => dispatch(doSetProfileData({
            key: "skills",
            changeType: "modify",
            value: skill
        }))));

        dispatch(doSetProfileData({ key: "experiences", value: _experiencesObj }));
        dispatch(doSetProfileData({ key: "educations", value: _educationsObj }));
        if (!candidates.length) {
            dispatch(doStartSearchCandidates({ isFilterRequest: true }));
        }
        setThumbnail(candidate.thumbnail_avatar_url);
        setCvFile(undefined);
        setMinSalary(candidate.min_salary_per_year);
        setMaxSalary(candidate.max_salary_per_year);
        setRelocationStatus(candidate.relocation_status || "");
        setLoading(false);
        setSelectedJobFunction(candidate.job_function || "");
        setIsSkillsAssessed(candidate.is_skills_assessed);
        setIsAPlayer(candidate.is_a_player);
        setIsOpenToRemote(candidate.is_open_to_remote);
        return () => {
            dispatch(doResetEditProfileData());
        };
    }, []);

    const setSkills = useCallback(() => {
        if (candidate?.skills?.length) {
            const parsedSkill: any = [];
            candidate.skills.map((el: any) => {
                if (el.name) {
                    parsedSkill.push({
                        value: el.name.toLowerCase().replace(/\W/g, ""),
                        label: el.name
                    });
                } else if (typeof el === "string") {
                    parsedSkill.push({
                        value: el.toLowerCase().replace(/\W/g, ""),
                        label: el
                    });
                }
            });
            setDefaultSkills(parsedSkill);
        }
    }, [candidate.skills]);

    useEffect(() => {
        setSkills();
    }, [setSkills]);

    useEffect(() => {
        if (skills?.length) {
            const mainSkillsArr: ISkill[] = skills?.length ? skills.filter((skill: ISkill) => skill.is_top_skill) : [];
            setMainSkillError(mainSkillsArr.length <= 0);
            setMainSkills(skills);
        } else {
            setMainSkillError(false);
        }
    }, [skills]);

    useEffect(() => {
        if (candidateLists?.jobFunctionSkills?.skills) {
            const opts: any = [];
            candidateLists.jobFunctionSkills.skills.map((el: any) => {
                opts.push({
                    value: el.toLowerCase(), label: el
                });
            });
            setSkillsOptions(opts);
        }
    }, [candidateLists.jobFunctionSkills]);

    const getSkills = useCallback(async () => {
        if (selectedJobFunction) {
            await reduxDispatch(getJobSkills({
                jobfunction: selectedJobFunction
            }));
        } else {
            await reduxDispatch(getJobSkills({
                jobfunction: ""
            }));
        }
    }, [selectedJobFunction]);

    useEffect(() => {
        getSkills();
    }, [getSkills]);

    const {
        errors, handleSubmit, control, clearError, register, setValue, setError, triggerValidation, getValues
    } = useForm({
        validationSchema: EditProfileSchema,
        defaultValues: {
            lastName: candidate.lastName,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            linkedIn: candidate.connected_links!.filter((v) => v.key === EConnectedLinks.LINKEDIN)[0]?.link || "",
            firstName: candidate.firstName,
            email: candidate.email,
            phone: candidate.phone,
            experience_level: candidate.experience_level || "",
            job_function: candidate.job_function || "",
            is_open_to_remote: candidate.is_open_to_remote,
            is_a_player: candidate.is_a_player,
            is_skills_assessed: candidate.is_skills_assessed,
            location: candidate.location,
            skills: selectSkillsRef?.current?.state?.value,
            relocation_status: candidate.relocation_status || "",
            note: candidate.note || ""
        }
    });

    const selectChangeHandler = (e: any) => {
        const selectedJob = e[0].value;
        setSelectedJobFunction(selectedJob);
        setValue("skills", []);
        setDefaultSkills([]);
        if (selectSkillsRef?.current?.select?.select) {
            selectSkillsRef.current.select.select.clearValue();
        }
        return handleSelectChange(e);
    };

    useEffect(() => {
        if (candidate.note) {
            aspirationEditText.current = candidate.note;
            register("note");
            setTimeout(() => {
                setValue("note", candidate.note || "");
            });
        }
    }, [candidate.note]);

    const handleChange = ([e]: any) => e.target.value;

    const uploadCVFunction = async (candidateId: string): Promise<IUploadCVRes> => {
        if (!cvFile) { return {}; }
        try {
            const resp: IUploadCVRes = await uploadCV(candidateId, cvFile, false);
            return resp;
        } catch (error) {
            if (error.code === "upload_failed") {
                toast.error(<ErrorMsg text={error.message} />);
            }
            return {};
        }
    };

    // eslint-disable-next-line consistent-return,@typescript-eslint/no-unused-vars
    const onSubmit = async (formData: any): Promise<void> => {
        if (selectSkillsRef?.current?.state?.value?.length > 10) {
            setError("skills", "max", "10 is the maximum number of skills required");
            return;
        }

        // input validation
        const _experiences: IExperience[] = Object.values(experiences || {});
        const _educations: IEducation[] = Object.values(educations || {});
        const educationIds = Object.keys(educations || 0);
        const experienceIds = Object.keys(experiences || 0);
        let isExperienceFieldsValid = true;
        let isEducationFieldsValid = true;
        if (experienceIds.length) {
            // Check that each field is valid
            // eslint-disable-next-line no-restricted-syntax
            for (const id of experienceIds) {
                const experience = experiences[id] || {};
                isExperienceFieldsValid = validateFields(ValidateFields_.Experience, { id, ...experience }, dispatch).valid;
            }
        }
        if (educationIds.length) {
            // Check that each field is valid
            // eslint-disable-next-line no-restricted-syntax
            for (const id of educationIds) {
                const education = educations[id] || {};
                isEducationFieldsValid = validateFields(ValidateFields_.Education, { id, ...education }, dispatch).valid;
            }
        }

        const fieldErrors = !!Object.keys(editProfileErrors).length;

        if (!isExperienceFieldsValid || !isEducationFieldsValid || fieldErrors) {
            return;
        }

        setLoading(true);
        let connected_links: IConnectedLinksObj[] = [];
        if (candidate && candidate.connected_links && candidate.connected_links.length > 0) {
            connected_links = [...candidate.connected_links].filter((v) => v.key !== EConnectedLinks.LINKEDIN);
        }
        connected_links.push(
            { key: EConnectedLinks.LINKEDIN, link: formData.linkedIn ? formData.linkedIn.replace(/\/$/, "") : "" }
        );

        const selectedSkills: ISkill[] = [];
        if (selectSkillsRef.current.state.value.length) {
            selectSkillsRef.current.state.value.map((el:any) => {
                selectedSkills.push({
                    name: el.label,
                    is_top_skill: true,
                    rate: 0
                });
            });
        }

        // eslint-disable-next-line no-param-reassign
        delete formData.linkedIn;
        const profileEditData: IData = {
            id: currentCandidateId,
            data: {
                ...formData,
                skills: selectedSkills,
                currency: "euro",
                connected_links,
                experiences: _experiences,
                educations: _educations,
                min_salary_per_year: minSalary,
                max_salary_per_year: maxSalary,
                relocation_status: formData.relocation_status[0] || ""
            }
        };

        try {
            const uploadCVResp: IUploadCVRes = await uploadCVFunction(currentCandidateId);

            if (uploadCVResp && uploadCVResp.cv_url) {
                profileEditData.data.cv_url = uploadCVResp.cv_url;
            }
            if (peepAvatarUrl) {
                profileEditData.data.original_avatar_url = peepAvatarUrl;
                profileEditData.data.thumbnail_avatar_url = peepAvatarUrl;
            }
            const editProfileAsync: any = functions.httpsCallable("candidates-editProfile");
            const editProfileResp: any = (await editProfileAsync(profileEditData)).data;
            if (!editProfileResp.result) {
                setLoading(false);
                toast.error(<ErrorMsg text={editProfileResp?.message || ""} />);
                // eslint-disable-next-line consistent-return
                return;
            }
            dispatch(doUpdateProfile({
                currentCandidateId,
                candidatesInfo: profileEditData
            }));

            const candidateSkills = mainSkills.length
                ? mainSkills.map((skill: any) => skill.name)
                : [];

            const candidatesData: Array<any> = [...candidateLists?.candidateList.candidates];

            candidatesData.forEach((candidateInfo : IAlgoliaCandidate, index: number) => {
                if (candidateInfo.objectID === currentCandidateId) {
                    candidatesData[index] = {
                        ...candidateInfo,
                        ...profileEditData.data,
                        candidateSkills,
                        top_skills: candidateSkills
                    };
                }
            });

            await reduxDispatch(
                updateCandidate({
                    candidates: {
                        ...candidateLists?.candidateList,
                        candidates: candidatesData
                    }
                })
            );

            setLoading(false);
            toast.success(<SuccessMsg text="Candidate edited" />);
            navigate(ROUTE_AUTHENTICATED_SEARCH);
        } catch (err) {
            setLoading(false);
            navigate(ROUTE_AUTHENTICATED_SEARCH);
            toast.error(<ErrorMsg text="Something went wrong, please try again!" />);
        }
    };

    const handleSelectChange = ([event]: any) => event.value || "";

    useEffect(() => {
        register("skills");
        setValue("skills", defaultSkills);
        if (defaultSkills.length && (defaultSkills.length >= 3 && defaultSkills.length <= 10)) {
            clearError("skills");
        }
        if (defaultSkills.length) {
            triggerValidation("skills");
        }
    }, [defaultSkills, setValue]);

    const handleChangeEditable = (evt: any) => {
        aspirationEditText.current = evt.target.value;
        if (aspirationEditText.current.length) {
            clearError("note");
        }
    };

    const sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br"],
        allowedAttributes: { a: ["href"] }
    };

    const sanitize = () => {
        const sanitizedHTML = sanitizeHtml(aspirationEditText.current);
        aspirationEditText.current = sanitizedHTML;
        setValue("note", aspirationEditText.current);
        if (aspirationEditText.current.length) {
            register("note");
            clearError("note");
            setValue("note", aspirationEditText.current);
        } else {
            triggerValidation("note");
        }
    };

    useEffect(() => {
        const skillsError = (errors.skills || {}) as any;
        if (skillsError.type && skillsError.type === "max") {
            toast.error(<ErrorMsg text={skillsError?.message || ""} />);
        }
    }, [errors]);

    const onKeyPress = (e: any) => {
        if (e && (e.key === "Enter")) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleChangeSelect = (newValue: any) => {
        setValue("skills", newValue);
        setDefaultSkills(newValue);
        triggerValidation("skills");
    };

    const customStyles = {
        ...selectStyles,
        control: (styles: any) => ({
            ...styles,
            backgroundColor: "white",
            // border: styles.isFocused ? 0 : '1px solid #AEAEB2',
            border: styles.isFocused ? 0
                : errors.skills ? "1px solid  rgb(229, 115, 115)" : "1px solid #AEAEB2",
            // This line disable the blue border
            padding: "2px 0px",
            borderRadius: 8,
            boxShadow: styles.isFocused ? 0 : 0,
            "&:hover": {
            //    border: styles.isFocused ? 0 : '1px solid #363840'
                border: styles.isFocused ? 0
                    : errors.skills ? "1px solid  rgb(229, 115, 115)" : "1px solid #363840",
            }
        }),
    };

    return (
        <Box className={classes.boxRoot}>
            <div className={classes.header}>
                <label className={classes.headerLabel}> Edit Candidate </label>
            </div>
            <form autoComplete="off" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                >
                    <Grid item sm={12} className={classes.personalInfo}>
                        <Grid container spacing={2}>
                            <Grid
                              style={{ padding: "0 22px" }}
                              item
                              xs={12}
                              md={12}
                              className={`${classes.imageUpload}
                                 ${classes.avatarButton}`}
                            >
                                <PeepsAvatarPicker
                                  loading={loading}
                                  currentAvatarUrl={thumbnail}
                                  onChangeAvatar={setPeepAvatarUrl}
                                  styles={classes.peepAvatar}
                                />

                                <Grid style={{ position: "relative" }} className={classes.uploadCv} item xs={12} sm={6}>
                                    <UploadButton
                                      loading={loading}
                                      text="Upload CV"
                                      accept=".pdf,.doc,.docx"
                                      themeButton={false}
                                      className={classes.uploadCVButton}
                                      onSelect={({ file }: { file: any }) => {
                                          const indexOfExtension = file.name.lastIndexOf(".");
                                          const { type } = file;
                                          const extension = file.name.slice(indexOfExtension);
                                          const acceptedFormats = [".pdf", ".doc", ".docx"];
                                          const fileType = [
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                              "application/msword",
                                              "application/pdf"
                                          ];

                                          if (acceptedFormats.includes(extension) && fileType.includes(type)) {
                                              setCvFile(file);
                                          } else {
                                              setCvFile(undefined);
                                              toast.error(
                                                  <ErrorMsg text="Wrong file type, please upload .pdf, .doc or .docx" />
                                              );
                                          }
                                      }}
                                    />
                                    <span style={{
                                        position: "absolute",
                                        top: "45px",
                                        left: "3px",
                                        fontWeight: 500
                                    }}
                                    >
                                This is optional
                                    </span>
                                    <div style={{ position: "absolute", marginTop: "4px" }}>
                                        { cvFile?.name
                                            ? (
                                                <span>
                                                    <Chip
                                                      label={cvFile?.name}
                                                      size="small"
                                                      onDelete={() => setCvFile(null)}
                                                      className={classes.chip}
                                                      classes={{
                                                          deleteIcon: classes.deleteIcon
                                                      }}
                                                    />
                                                </span>
)
                                            : ""}
                                    </div>
                                </Grid>

                            </Grid>

                            <div className={classes.sectionDivider} />

                            {/* Name */}
                            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item sm={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        First Name
                                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                            </Box>
                                        </Grid>
                                        <Controller
                                          as={(
                                              <CustomInput
                                                fullWidth
                                                autoComplete="given-name"
                                                placeholder="First Name"
                                                inputRef={firstNameRef}
                                                errors={errors?.firstName?.message}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: `${classes.outlineInput} ${errors.firstName ? classes.errorBorder : ""}`
                                                }}
                                              />
                                          )}
                                          control={control}
                                          onFocus={() => firstNameRef?.current?.focus()}
                                          onChange={handleChange}
                                          name="firstName"
                                        />
                                        {errors?.firstName && (
                                            <Box color="error.main">
                                                {(errors.firstName as any).message}
                                            </Box>
                                    )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item sm={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            Last Name
                                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                            </Box>
                                        </Grid>
                                        <Controller
                                          as={(
                                              <CustomInput
                                                fullWidth
                                                autoComplete="family-name"
                                                placeholder="Last Name"
                                                inputRef={lastNameRef}
                                                errors={errors?.lastName?.message}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: `${classes.outlineInput} ${errors.lastName ? classes.errorBorder : ""}`
                                                }}
                                              />
                                          )}
                                          onFocus={() => lastNameRef?.current?.focus()}
                                          control={control}
                                          onChange={handleChange}
                                          name="lastName"
                                        />
                                        {errors?.lastName && (
                                            <Box color="error.main">
                                                {(errors.lastName as any).message}
                                            </Box>
                                    )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Phone emaail */}
                            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item xs={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            Email
                                            </Box>
                                        </Grid>
                                        <Controller
                                          as={(
                                              <CustomInput
                                                fullWidth
                                                name="email"
                                                placeholder="Email Address"
                                                inputRef={emailRef}
                                                errors={errors?.email?.message}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: `${classes.outlineInput} ${errors.email ? classes.errorBorder : ""}`
                                                }}
                                              />
                                                )}
                                          control={control}
                                          onFocus={() => emailRef?.current?.focus()}
                                          onChange={handleChange}
                                          name="email"
                                        />

                                        {errors?.email && (
                                            <Box color="error.main">
                                                {(errors.email as any).message}
                                            </Box>
                                            )}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid item xs={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            Phone
                                            </Box>
                                        </Grid>

                                        <CustomInput
                                          fullWidth
                                          id="phone"
                                          placeholder="Phone Number"
                                          name="phone"
                                          className={classes.input}
                                          inputRef={register}
                                          onChange={() => triggerValidation("phone")}
                                          classes={{
                                              root: classes.inputRoot,
                                              input: `${classes.outlineInput} ${errors.phone ? classes.errorBorder : ""}`
                                          }}
                                        />

                                        {errors.phone
                                    && (
                                        <Box color="error.main">
                                            {(errors.phone as any).message}
                                        </Box>
                                    )}

                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Linked In */}
                            <Grid className={classes.inputField} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item xs={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            LinkedIn
                                            </Box>
                                        </Grid>
                                        <Controller
                                          as={(
                                              <CustomInput
                                                name="linkedIn"
                                                fullWidth
                                                placeholder="URL"
                                                inputRef={linkdinRef}
                                                errors={errors?.linkedIn?.message}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: `${classes.outlineInput} ${errors.linkedIn ? classes.errorBorder : ""}`
                                                }}
                                              />
                                          )}
                                          control={control}
                                          onChange={handleChange}
                                          onFocus={() => linkdinRef?.current?.focus()}
                                          name="linkedIn"
                                        />

                                        {errors?.linkedIn && (
                                            <Box color="error.main">
                                                {(errors.linkedIn as any).message}
                                            </Box>
                                    )}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                        <Grid item xs={12}>
                                            <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            Location
                                            </Box>
                                        </Grid>
                                        <Controller
                                          as={(
                                              <CustomInput
                                                fullWidth
                                                placeholder="e.g. Germany, Berlin"
                                                inputRef={locationRef}
                                                errors={errors?.location?.message}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: `${classes.outlineInput} ${errors.location ? classes.errorBorder : ""}`
                                                }}
                                              />
                                        )}
                                          control={control}
                                          onFocus={() => locationRef?.current?.focus()}
                                          onChange={handleChange}
                                          name="location"
                                        />
                                        {errors?.location && (
                                            <Box color="error.main">
                                                {(errors.location as any).message}
                                            </Box>
                                    )}

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={classes.inputContainer} style={{ padding: "0 22px" }} item xs={12}>

                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Relocation
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <Controller
                                              as={(
                                                  <SelectMenu
                                                    disabled={false}
                                                    defaultValue={getValues().relocation_status as string}
                                                    items={relocationStatusList.map(({ name, value }: any) => ({
                                                        title: name,
                                                        value
                                                    }))}
                                                    label="Relocation"
                                                  />
                                            )}
                                              name="relocation_status"
                                              control={control}
                                              onChange={handleSelectChange}
                                            />
                                            {errors.relocation_status
                                        && (
                                            <Box color="error.main">
                                                {(errors.relocation_status as any).message}
                                            </Box>
                                        )}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid className={classes.inputContainer} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Job Function
                                            <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <Controller
                                              as={(
                                                  <SelectMenu
                                                    disabled={false}
                                                    defaultValue={getValues().job_function as string}
                                                    items={JobFunctionList}
                                                    label="Job Functions"
                                                    inputRef={jobFunctionRef}
                                                    errors={errors?.job_function?.message}
                                                  />
                                            )}
                                              name="job_function"
                                              control={control}
                                              onFocus={() => jobFunctionRef?.current?.focus()}
                                              onChange={selectChangeHandler}
                                            />
                                            {errors.job_function
                                        && (
                                            <Box color="error.main">
                                                {(errors.job_function as any).message}
                                            </Box>
                                        )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={classes.inputContainer} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Seniority
                                            <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <Controller
                                              as={(
                                                  <SelectMenu
                                                    disabled={false}
                                                    defaultValue={getValues().experience_level as string}
                                                    items={experienceLevelList.map(({ name, value }: any) => ({
                                                        title: name,
                                                        value
                                                    }))}
                                                    label="Seniority"
                                                    errors={errors?.experience_level?.message}
                                                    inputRef={seniorityRef}
                                                  />
                                            )}
                                              control={control}
                                              onFocus={() => seniorityRef?.current?.focus()}
                                              name="experience_level"
                                              onChange={handleSelectChange}
                                            />
                                            {errors.experience_level
                                        && (
                                            <Box color="error.main">
                                                {(errors.experience_level as any).message}
                                            </Box>
                                        )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={`${classes.inputContainer} ${classes.salaryField}`} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                        Expected Salary
                                        </Box>
                                    </Grid>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <FormSalaryField
                                          max={maxSalary}
                                          min={minSalary}
                                          setMaxSalary={setMaxSalary}
                                          setMinSalary={setMinSalary}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid className={classes.selectField} style={{ padding: "0 22px" }} item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                            Hard Skills / Spoken Languages
                                            <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                            <br />
                                            <span
                                              style={{
                                                  fontSize: 10,
                                                  fontWeight: 400
                                              }}
                                            >
                                                Press Enter to submit your entry
                                            </span>
                                        </Box>

                                        <Grid style={{ position: "relative" }} item xs={12}>
                                            <div onKeyPress={onKeyPress}>
                                                <CreatableSelect
                                                  closeMenuOnSelect={false}
                                                  value={defaultSkills}
                                                  isMulti
                                                  options={selectedJobFunction ? skillsOptions : []}
                                                  placeholder="Hard Skills / Spoken Languages"
                                                  onChange={handleChangeSelect}
                                                  styles={customStyles}
                                                  ref={selectSkillsRef}
                                                />
                                            </div>
                                            {errors.skills
                                        && (
                                            <Box color="error.main">
                                                {(errors.skills as any).message}
                                            </Box>
                                        )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div className={classes.sectionDivider} />

                            <Grid style={{ padding: "0 24px" }} className={classes.checkBoxes} item xs={12}>
                                <EditVettingFields
                                  control={control}
                                  isAPlayer={isAPlayer}
                                  isOpenToRemote={isOpenToRemote}
                                  isSkillsAssessed={isSkillsAssessed}
                                  setIsAPlayer={setIsAPlayer}
                                  setIsOpenToRemote={setIsOpenToRemote}
                                  setIsSkillsAssessed={setIsSkillsAssessed}
                                />
                            </Grid>

                            <Grid style={{ padding: "0 22px", marginTop: "6px" }} item xs={12}>

                                <Box className={classes.labelText} fontWeight="fontWeightBold" fontSize={10}>
                                    Career Aspirations
                                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                                </Box>
                                <ContentEditable
                                  innerRef={careerAspRef}
                                  onFocus={() => careerAspRef?.current?.focus()}
                                  className={`${classes.contentEditable} ${errors.note ? classes.errorBorder : ""}`}
                                  tagName="div"
                                  html={aspirationEditText.current}
                                  onChange={handleChangeEditable}
                                  onBlur={sanitize}
                                />

                                {errors.note
                                && (
                                    <Box color="error.main">
                                        {(errors.note as any).message}
                                    </Box>
                                )}

                            </Grid>

                            <div className={classes.sectionDivider} />

                        </Grid>
                    </Grid>
                    {/* Experience */}
                    <Grid item xs={12}>
                        <EditExperience experiences={experiences || []} disabled={loading} />
                    </Grid>

                    <div className={classes.sectionDivider} />

                    {/* Educations */}
                    <Grid item xs={12}>
                        <EditEducation educations={educations || []} disabled={loading} />
                    </Grid>
                    <Grid style={{ padding: "0 24px" }} item xs={12}>
                        <SubmitButton
                          loading={loading}
                          className={classes.submit}
                          text={loading ? "Saving Changes..." : "Save Changes"}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default EditProfile;
