import React, { FC, useState } from "react";
import {
    AutoSizer, Column, Table, TableCellRenderer
} from "react-virtualized";
import { Launch } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import TableCell from "@material-ui/core/TableCell";
import {
    Avatar, FormControl, Grid
} from "@material-ui/core";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { Controller } from "react-hook-form";
import clsx from "clsx";

import useStyles from "./style";
import { IProps } from "./interface";
import {
    SelectMenu
} from "compass-shared";

const JobOpenings: FC<IProps> = ({
    formatWords,
    isOwnerJobOwner,
    renderActions,
    handleActionSelect,
    onRowClick,
    control,
    allJobOpening,
    columns
}) => {
    const classes = useStyles();

    const [inHover, setHover] = useState(false as boolean);

    const formatRecruiters = (recruiters: Array<any>): Array<any> => {
        if (!recruiters.length) return [];
        return recruiters;
    };

    const renderCellContent = (dataKey: string, data: any) => {
        switch (dataKey) {
            case "job_function":
                return (
                    <div style={{ fontSize: 15 }}>
                        {
                            data?.urlToJobDescription
                                ? (
                                    <a
                                      style={{ display: "flex" }}
                                      className={classes.urlToJobDescription}
                                      target="_blank"
                                      href={data?.urlToJobDescription}
                                    >
                                        {" "}
                                        { data?.name }
                                        <Launch style={{ marginLeft: "6px" }} />
                                    </a>
)
                                : (
                                    <div>
                                        {" "}
                                        { data?.name }
                                        {" "}
                                    </div>
)
                        }
                        { data?.companyName
                            ? (
                                <div className="company-name" style={{ marginTop: "4px" }}>
                                    {" "}
@
                                    { data?.companyName }
                                    {" "}

                                </div>
)
                            : ""}
                    </div>
                );

            case "requirements": {
                const skills = data?.skills.length
                    ? data?.skills.reduce((curr: string, next: string) => `${curr}, ${next}`)
                    : "-";
                return (
                    <div className={classes.requirements}>
                        <div className="list-item">
                            <span className="title"> Job Function: </span>
                            <span>
                                { formatWords(data?.job_function || "") }
                            </span>
                        </div>
                        <div className="list-item">
                            <span className="title"> Seniority: </span>
                            <span>
                                { formatWords(data?.experience_level || "") }
                            </span>
                        </div>
                        <div className="list-item">
                            <span className="title"> Location: </span>
                            <span>
                                { formatWords(data?.location || "") }
                            </span>
                        </div>
                        <div className="list-item">
                            <span className="title">Skills: </span>
                            <span>
                                {
                                    skills.toLowerCase() === "none" ? "-" : skills
                                    }
                            </span>
                        </div>
                    </div>
                );
            }

            case "status":
                return (
                    <div
                      className="status-item"
                      style={{
                          color: data.is_active === undefined
                              ? "#008000"
                              : data.is_active === "Open" ? "#008000" : "#FF0000"
                      }}
                    >
                        {" "}
                        { data.is_active === undefined ? "Open" : data.is_active === "Open" ? "Open" : "Archived"}
                        {" "}
                    </div>
                );

            case "recruiters":
                return (
                    <div
                      style={{
                          display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"
                      }}
                    >
                        {
                            formatRecruiters(data?.recruiters || []).map(((recruiter: any, index: number) => (
                                <div
                                  data-for="recruiter"
                                  data-tip={`${recruiter?.firstName} ${recruiter?.lastName}`}
                                  data-iscapture="true"
                                  key={index.toString()}
                                  onMouseEnter={() => setHover(true)}
                                  onMouseLeave={() => setHover(false)}
                                  style={{ cursor: "pointer" }}
                                >
                                    {

                                            recruiter?.thumbnail_avatar_url
                                                ? (
                                                    <Avatar
                                                      key={index.toString()}
                                                      className={index === 0 && isOwnerJobOwner(data?.recruiter_id)
                                                          ? classes.ownAvatar : classes.recruiterAvatar}
                                                      src={recruiter?.thumbnail_avatar_url}
                                                    />
)
                                                : (
                                                    <Avatar
                                                      key={index.toString()}
                                                      className={index === 0 && isOwnerJobOwner(data?.recruiter_id)
                                                          ? classes.ownAvatar
                                                          : classes.recruiterAvatar}
                                                    >
                                                        {" "}
                                                        <PersonRoundedIcon className={classes.personIcon} />
                                                        {" "}
                                                    </Avatar>
)
                                        }
                                </div>
                            )))
                        }
                    </div>
                );

            case "actions":
                return (
                    <Grid container spacing={2} className={classes.flexCenterWrapper}>
                        <Grid className={classes.actionContainer} item xs={12} sm={3} md={2}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                              classes={{ root: classes.textFieldRoot }}
                            >
                                <Controller
                                  as={(
                                      <SelectMenu
                                        items={
                                            renderActions(
                                                data.recruiter_id,
                                                data?.is_active ? data?.is_active === "Open" : undefined
                                            )
                                        }
                                        label="Action"
                                      />
                                    )}
                                  name="actions"
                                  control={control}
                                  defaultValue=""
                                  onChange={(value) => handleActionSelect(value, data)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                );
            default:
                return <></>;
        }
    };
    const cellRenderer: TableCellRenderer = ({ dataKey, rowData }) => (
        <TableCell
          component="div"
          className={`${clsx(classes.tableCell, classes.flexContainer, {
              [classes.noClick]: onRowClick == null,
          })} ${dataKey}`}
          variant="body"
          style={{ height: 140 }}
          align="right"
        >
            { renderCellContent(dataKey, rowData)}
        </TableCell>
    );
    const headerRenderer = ({ label }: any) => (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
          variant="head"
          style={{ height: 48 }}
          align="right"
        >
            <span className="header-title">{label}</span>
        </TableCell>
    );

    const getRowClassName = ({ index }: any) => clsx(classes.tableRow, classes.flexContainer, {
        [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });

    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                      height={height}
                      width={width}
                      rowHeight={140}
                      gridStyle={{
                          direction: "inherit",
                      }}
                      rowCount={allJobOpening.length}
                      rowGetter={({ index }) => allJobOpening[index]}
                      headerHeight={48}
                      className={classes.table}
                      rowClassName={getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }: any, index: number) => (
                            <Column
                              key={dataKey}
                              headerRenderer={(headerProps) => headerRenderer({
                                  ...headerProps,
                                  columnIndex: index,
                              })}
                              className={classes.flexContainer}
                              cellRenderer={cellRenderer}
                              dataKey={dataKey}
                              {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
            {inHover
                && (
                <ReactTooltip
                  id="recruiter"
                  place="bottom"
                  type="light"
                  effect="solid"
                  textColor="#707070"
                  backgroundColor="#E1E0E1"
                  border
                  borderColor="#dadde9"
                  className={classes.reactTooltip}
                />
                )}
        </>
    );
};

export default JobOpenings;
