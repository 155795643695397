import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    parent: {
        display: "flex",
        height: "100vh",
        paddingTop: 41,
        // alignItems: 'center',
        justifyContent: "center"
    },
    cardContainer: {
        width: "71.7em",
        height: "49.7em",
        borderRadius: 10,
        border: 0,
        boxSizing: "border-box",
        boxShadow: "0px 0px 17px 2px rgba(0,0,0,0.06)"
    },
    cardHeaderRoot: {
        backgroundColor: "#221A16",
        padding: "12px 0px 7px 20px"
    },
    cardHeaderTitle: {
        fontSize: 15,
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: "15px",
        color: "#ffffff",
    },
    cardContent: {
        padding: "20px 20px 11px 20px !important"
    },
    cardContentHeader: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
    },
    cardToText: {
        color: "#AEAEB2",
        fontFamily: "Montserrat",
        fontSize: 13,
        fontWeight: 400,
        lineHeight: "17px",
        padding: "5px 0px",
        display: "flex"
    },
    emailText: {
        color: "#2F2824",
        fontFamily: "Montserrat",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "17px",
        padding: "5px 0px",
        display: "flex"
    },
    cardEmailText: {
        fontSize: 13,
        color: "#2F2824",
        lineHeight: "16px",
        marginLeft: 10,
        fontWeight: 600,
        padding: "5px 0px",
    },
    imageSRC: {
        borderRadius: "50%",
        height: theme.spacing(18.5),
        width: theme.spacing(18.5),
    },
    personIcon: {
        fontSize: "40px"
    },
    imageContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    emailBodyTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 13
    },
    openInNew: {
        padding: 0,
        marginRight: 4
    },
    avatartTextItem: {
        // width: 74,
        width: 85,
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: 13,
        lineHeight: "13px",
        color: "#000000"
    },
    contentEditable: {
        border: "1px solid #D8D9DF",
        boxSizing: "border-box",
        borderRadius: "7px",
        padding: 10,
        fontFamily: "Montserrat",
        fontSize: 14,
        lineHeight: "18px",
        color: "#2F2824",
        marginTop: 20,
        minHeight: 272,
        transition: theme.transitions.create(["border"]),
        "&:focus": {
            borderRadius: 8,
            border: "1px solid #363840",
            transition: theme.transitions.create(["border"]),
        },
        "&:hover": {
            border: "1px solid #363840",
            transition: theme.transitions.create(["border"]),
        }
    },
    sendIntro: {
        marginTop: "11px",
        width: "100%",
        height: 30,
        borderRadius: 7,
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 15,
        lineHeight: "18px",
        textAlign: "center",
        color: "#221A16",
        backgroundColor: "#FCC46B",
        textTransform: "capitalize",
        "&:hover, &:active": {
            backgroundColor: "#ffb237",
            boxShadow: "none"
        }
    },
    rejectSendIntro: {
        backgroundColor: "#F38A87",
        "&:hover": {
            backgroundColor: "rgba(243,138,135,.8)",
            boxShadow: "none"
        }
    },
    dialogPaperRoot: {
        backgroundColor: "#525252",
        width: 420,
        // height: 290,
        padding: "10px",
        borderRadius: "10px"
    },
    dialogContainer: {
        height: "auto",
        marginTop: "100px"
    },
    dialogTitleRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 0,
        paddingBottom: 17
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    contentRoot: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "0px 20px 20px 20px",
        width: 400,
        height: 328
    },
    itemButton: {
        minWidth: 22,
        height: 22,
        backgroundColor: "#FF6A66",
        color: "#FFFFFF",
        textTransform: "none",
        padding: 0,
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        },
        marginLeft: 4,
        fontSize: 10,
        fontWeight: "bold",
    },
    titleText: {
        fontSize: 18,
        color: "#2F2824",
        fontWeight: "bold",
        lineHeight: "22px",
        paddingTop: 15,
        paddingBottom: 15,
    },
    bodyText: {
        fontSize: "14px",
        lineHeight: "22px",
        color: "#2F2824",
        textAlign: "center",
        paddingBottom: 30
    },
    requestIntro: {
        width: 235,
        height: 40,
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: "#221A16",
        color: "#ffffff",
        textTransform: "none",
        marginRight: 5,
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none"
        }
    },
}));

export default useStyles;
