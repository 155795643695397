import Chip from "@material-ui/core/Chip";
import React from "react";
import useStyles from "./style";

interface ISkillChipProps {
  label: string;
  styles?: any;
    setHoverState?: boolean
}

export default function SkillChip({ label, styles, setHoverState }: ISkillChipProps) {
    const classes = useStyles();

    const classNames = setHoverState ? `${classes.skillChip} ${classes.addHover}` : classes.skillChip;

    return (
        <Chip
          key={label}
          className={classNames}
          style={{
              ...styles
          }}
          size="small"
          label={label}
        />
    );
}
